import React from "react";
// Customizable Area Start
import { Box, Button, Checkbox, FormControlLabel, FormGroup, FormHelperText, Grid, IconButton, Input, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, ThemeProvider, Typography, styled } from "@material-ui/core";
import RootCauseValidationController,
{
    IWhyAnalysis,
    Props
} from "./RootCauseValidationController.web";
import {  Field, FieldArray, Form, Formik } from "formik";
import { webStyle } from "./postCreationPopUp/styles";
import { deleteIcon, saveIcon, upload } from "./assets";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { KeyboardArrowDown } from "@material-ui/icons";
import { theme } from "./RootCauseInvestigation.web";
import { CustomMenuItem } from "./ReportingInvestigation.web";
import Loader from "../../../components/src/Loader.web";
const validCauses = [
    "No Training",
    "Irregularity in Machine Inspection",
    "Lack of PPE availability",
    "Outdated Machinery",
    "Inadequate SOPs",
    "Poorly written SOPs",
];
// Customizable Area End

export default class RootCauseValidation extends RootCauseValidationController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderWhyAnalysis = () => {
        const { d5FormData } = this.state;
    
        const initialValues = {
            whyAnalysis: d5FormData.attributes.valid_causes.map((cause: any) => ({
                valid_causes_attributes: [
                    ...(cause.description1 ? [{ name: `Why 01`, description: cause.description1, isEditing: false ,id:cause.id}] : []),
                    ...(cause.description2 ? [{ name: `Why 02`, description: cause.description2, isEditing: false ,id:cause.id}] : []),
                    ...(cause.description3 ? [{ name: `Why 03`, description: cause.description3, isEditing: false ,id:cause.id}] : []),
                    ...(cause.description4 ? [{ name: `Why 04`, description: cause.description4, isEditing: false ,id:cause.id}] : []),
                    ...(cause.description5 ? [{ name: `Why 05`, description: cause.description5, isEditing: false ,id:cause.id}] : [])
                ]
            }))
        };
    
        return (
            <Formik
                enableReinitialize={true}
                data-test-id="whyanalysisTestId"
                initialValues={initialValues}
                onSubmit={(values) => {
                    this.postD5PartTwoForm(values,"next");
                }}
            >
                {({ setFieldValue, values }) => (
                    <Form
                        translate={undefined}
                        className="formStyle"
                        data-test-id="formTestId"
                    >
                        <Box>
                            <FieldArray name="whyAnalysis" data-test-id="whyAnalysis">
                                {({ push: pushWhy, remove: removeWhy }) => (
                                    <>
                                        {values.whyAnalysis?.map((whyAnalysis: any, index: number) => (
                                            <Box key={index} className="categoryContainer">
                                                <Typography
                                                    variant="h3"
                                                    style={{ marginBottom: "16px" }}
                                                    data-test-id="categoryTitle"
                                                >
                                                    {`Why Analysis 0${index + 1}`}
                                                </Typography>
    
                                                <FieldArray
                                                    name={`whyAnalysis[${index}].valid_causes_attributes`}
                                                    data-test-id="whyAnalysisList"
                                                >
                                                    {({ push: pushCause, remove: removeCause }) => (
                                                        <>
                                                            <TableContainer className="tableContainer">
                                                                <Table>
                                                                    <TableHead>
                                                                        <TableRow className="tableHeader">
                                                                            <TableCell className="tableHeaderCell">Why</TableCell>
                                                                            <TableCell className="tableHeaderCell">Description</TableCell>
                                                                            <TableCell className="tableHeaderCell"></TableCell>
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        {whyAnalysis.valid_causes_attributes.map((cause: any, causeIndex: number) => (
                                                                            <TableRow key={causeIndex} className="tableRow">
                                                                                <TableCell className="tableRowCell">{`Why 0${causeIndex + 1}`}</TableCell>
                                                                                <TableCell className="tableRowCell">
                                                                                    {cause.isEditing ? (
                                                                                        <Input
                                                                                            placeholder="Description"
                                                                                            fullWidth
                                                                                            type="text"
                                                                                            disableUnderline
                                                                                            name="description"
                                                                                            style={{
                                                                                                border: "1px solid #DCDCDC",
                                                                                                backgroundColor: "#FFFFFF",
                                                                                                borderRadius: "8px",
                                                                                                height: "44px",
                                                                                                padding: "16.5px",
                                                                                            }}
                                                                                            value={cause.description}
                                                                                            onChange={(event) =>
                                                                                                setFieldValue(`whyAnalysis[${index}].valid_causes_attributes[${causeIndex}].description`, event.target.value)
                                                                                            }
                                                                                            data-test-id="description"
                                                                                        />
                                                                                    ) : (
                                                                                        cause.description
                                                                                    )}
                                                                                </TableCell>
                                                                                <TableCell className="tableRowCell">
                                                                                    <Box style={{ display: "flex", justifyContent: "center" }}>
                                                                                        {cause.isEditing ? (
                                                                                            <>
                                                                                                <IconButton
                                                                                                    onClick={() => {
                                                                                                        setFieldValue(`whyAnalysis[${index}].valid_causes_attributes[${causeIndex}].isEditing`, false);
                                                                                                    }}
                                                                                                    data-test-id="saveItemBtn"
                                                                                                >
                                                                                                    <img src={saveIcon} className="saveIcon" alt="Save" />
                                                                                                </IconButton>
                                                                                                <IconButton
                                                                                                    data-test-id="removeItemBtn"
                                                                                                     onClick={()=>{
                                                                                                        this.removeD5PartTwoForm(cause.id,"remove");
                                                                                                        removeCause(causeIndex)
                                                                                                    }}
                                                                                                >
                                                                                                    <img src={deleteIcon} className="deleteIcon" alt="Delete" />
                                                                                                </IconButton>
                                                                                            </>
                                                                                        ) : (
                                                                                            <IconButton
                                                                                                onClick={() => {
                                                                                                    setFieldValue(`whyAnalysis[${index}].valid_causes_attributes[${causeIndex}].isEditing`, true);
                                                                                                }}
                                                                                                data-test-id="moreIcon"
                                                                                            >
                                                                                                <MoreVertIcon />
                                                                                            </IconButton>
                                                                                        )}
                                                                                    </Box>
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        ))}
                                                                    </TableBody>
                                                                </Table>
    
                                                                 {whyAnalysis?.valid_causes_attributes?.length < 5 && (
                                                                    <Button
                                                                        data-test-id="addCauseButtonWhy"
                                                                        className="addCauseButton"
                                                                        onClick={() =>
                                                                            pushCause({
                                                                                name: `Why 0${whyAnalysis?.valid_causes_attributes?.length + 1}`,
                                                                                description: "",
                                                                                isEditing: true
                                                                            })
                                                                        }
                                                                    >
                                                                        + Add Another Cause
                                                                    </Button>
                                                                )}
                                                            </TableContainer>
                                                        </>
                                                    )}
                                                </FieldArray>
                                            </Box>
                                        ))}
    
                                        {/* Hide outer button after 5 Why Analysis records */}
                                        {values?.whyAnalysis?.length < 5 && (
                                            <Button
                                                data-test-id="addAnotherWhyButton"
                                                className="addCauseButton addAnotherWhyBtn"
                                                onClick={() =>
                                                    pushWhy({
                                                        valid_causes_attributes: [
                                                            {
                                                                name: `Why 01`,
                                                                description: "",
                                                                isEditing: true
                                                            }
                                                        ]
                                                    })
                                                }
                                            >
                                                + Add another Why Analysis
                                            </Button>
                                        )}
                                    </>
                                )}
                            </FieldArray>
    
                            <Box className="actionButtons" style={{ padding: "0px" }}>
                                <Box>
                                    <Button data-test-id="prevButton2" onClick={() => this.handleBack()} className="prevButton">
                                        Previous
                                    </Button>
                                </Box>
                                <Box style={{ display: "flex", gap: "20px" }}>
                                    <Button data-test-id="saveButton2" className="saveButton"
                                    onClick={()=>this.postD5PartTwoForm(values ,"save")}>
                                        Save
                                    </Button>
                                    <Button data-test-id="nextButton2" className="nextButton" type="submit">
                                        Next
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </Form>
                )}
            </Formik>
        );
    };
    
    renderImageInput = (values: any, setFieldValue: (field: string, value: any) => void) => {
        const getImageSource = () => {
            if (values.image) return URL.createObjectURL(values.image);
            if (values.imageUrl) return values.imageUrl;
            return upload;
        };
    
        const getImageAltText = () => {
            if (values.image) return "Uploaded";
            if (values.imageUrl) return "Existing";
            return "Placeholder";
        };
    
        const getButtonText = () => {
            if (values.image) return "Replace Image";
            if (values.imageUrl) return "Replace Existing Image";
            return "Upload Image";
        };
    
        return (
            <FormGroup className="input-block" style={webStyle.wideInputBlock as React.CSSProperties}>
                <FormHelperText style={webStyle.label}>Related Photo / Sketch</FormHelperText>
                <Button
                    component="label"
                    style={{ ...webStyle.input, ...webStyle.upload } as React.CSSProperties}
                >
                    <div className="imageMain" style={webStyle.uploadImg as React.CSSProperties}>
                        <img
                            className="imageInner"
                            src={getImageSource()}
                            alt={getImageAltText()}
                        />
                        <div style={webStyle.fontBlock}>
                            {getButtonText()}
                        </div>
                    </div>
    
                    <input
                        data-test-id="upldFileId"
                        type="file"
                        name="image"
                        accept="image/png, image/jpeg"
                        onChange={(event) => {
                            const file = event?.target.files?.[0];
                            if (file) {
                                setFieldValue("image", file);
                                setFieldValue("imageUrl", "");
                            }
                        }}
                        hidden
                    />
                </Button>
            </FormGroup>
        );
    };
    
    

    renderTable = (values: any, setFieldValue: any, remove: any) => {
        return (
            <TableBody>
                {Array.isArray(values?.causesList) && values.causesList.length > 0 ? (
                    values.causesList.map((cause: any, causeIndex: any) => (
                        <TableRow key={causeIndex} className="tableRow">
                            <TableCell className="tableRowCell">{cause.name}</TableCell>
                            <TableCell className="tableRowCell">
                                {cause.isEditing ? (
                                    <Field
                                        data-test-id="description"
                                        as={TextField}
                                        style={{ ...webStyle.textareaStyle, backgroundColor: "#FFFFFF" }}
                                        placeholder="Description"
                                        multiline
                                        maxRows={3}
                                        minRows={3}
                                        InputProps={{ disableUnderline: true }}
                                        className="textarea"
                                        name={`causesList[${causeIndex}].description`}
                                    />
                                ) : (
                                    <Typography>{cause.description}</Typography>
                                )}
                            </TableCell>
                            <TableCell className="tableRowCell">
                                {cause.isEditing ? (
                                    <Field
                                        data-test-id="howChecked"
                                        as={TextField}
                                        style={{ ...webStyle.textareaStyle, backgroundColor: "#FFFFFF" }}
                                        placeholder="How it was checked"
                                        multiline
                                        maxRows={3}
                                        minRows={3}
                                        InputProps={{ disableUnderline: true }}
                                        className="textarea"
                                        name={`causesList[${causeIndex}].howChecked`}
                                    />
                                ) : (
                                    <Typography>{cause.howChecked}</Typography>
                                )}
                            </TableCell>
                            <TableCell className="tableRowCell">
                                {cause.isEditing ? (
                                    <Field
                                        data-test-id="result"
                                        as={TextField}
                                        style={{ ...webStyle.textareaStyle, backgroundColor: "#FFFFFF" }}
                                        placeholder="Result"
                                        multiline
                                        maxRows={3}
                                        minRows={3}
                                        InputProps={{ disableUnderline: true }}
                                        className="textarea"
                                        name={`causesList[${causeIndex}].result`}
                                    />
                                ) : (
                                    <Typography>{cause.result}</Typography>
                                )}
                            </TableCell>
                            <TableCell className="tableRowCell">
                                <Box style={{ display: "flex", justifyContent: "center" }}>
                                    {cause.isEditing ? (
                                        <>
                                            <IconButton
                                                data-test-id="saveItemBtn"
                                                onClick={() => {
                                                    const updatedCauses = [...values.causesList];
                                                    updatedCauses[causeIndex].isEditing = false;
                                                    setFieldValue("causesList", updatedCauses);
                                                }}
                                            >
                                                <img src={saveIcon} className="saveIcon" alt="save" />
                                            </IconButton>
                                            <IconButton
                                                data-test-id="removeItemBtn"

                                                onClick={() => {
                                                    this.removeD5First(values.causesList[causeIndex].id, "remove");
                                                    remove(causeIndex);
                                                }}                                            >
                                                <img src={deleteIcon} className="deleteIcon" alt="delete" />
                                            </IconButton>
                                        </>
                                    ) : (
                                        <IconButton
                                            data-test-id="threeDotMenuBtn"
                                            onClick={() => {
                                                const updatedCauses = [...values.causesList];
                                                updatedCauses[causeIndex].isEditing = true;
                                                setFieldValue("causesList", updatedCauses);
                                            }}
                                        >
                                            <MoreVertIcon />
                                        </IconButton>
                                    )}
                                </Box>
                            </TableCell>
                        </TableRow>
                    ))
                ) : (
                    <TableRow>
                        <TableCell colSpan={5} align="center">No causes added yet</TableCell>
                    </TableRow>
                )}
            </TableBody>
        );
    };
    
    renderCheckBox = (values: any, setFieldValue: (field: string, value: any) => void) => {
        return (
            <FormGroup row>
                {(Array.isArray(values?.selectedCauses) ? values.selectedCauses : []).map((cause:any, index:number) => (
                    <FormControlLabel
                        data-test-id="checkBoxTestID"
                        key={index}
                        control={
                            <Checkbox
                                checked={cause.isActive}
                                onChange={() => {
                                    const updatedCauses = [...values.selectedCauses];
                                    updatedCauses[index].isActive = !updatedCauses[index].isActive;
                                    setFieldValue("selectedCauses", updatedCauses);
                                }}
                                color="primary"
                            />
                        }
                        label={cause.name}
                    />
                ))}
            </FormGroup>
        );
    };
    
    renderDFiveInitialForm = () => {
        const { d5FormData, selectedCauses } = this.state;
        const existingImageUrl = d5FormData?.attributes?.root_cause_document?.file_url || null;
    
        return (
            <Formik
                data-test-id="dFiveFormTestId"
                enableReinitialize={true}
                initialValues={{
                    causeText: d5FormData?.attributes?.cause_description || "",
                    category: d5FormData?.attributes?.category_name || "",
                    cause: d5FormData?.attributes?.cause_type || "",
                    image: null as File | null,
                    imageUrl: existingImageUrl,
                    causesList: d5FormData?.attributes?.another_causes?.length
                        ? d5FormData.attributes.another_causes.map((cause) => ({
                            name: "Cause 01",
                            description: cause.describe_cause || "",
                            howChecked: cause.checked_cause || "",
                            result: cause.result || "",
                            isEditing: false,
                            id:cause.id ||""
                        }))
                        : [],
                    selectedCauses: selectedCauses.map((cause) => ({
                        name: cause.name,
                        isActive: cause.isActive
                    }))
                }}
                onSubmit={(values) => {
                    this.postD5Form(values,"next");
                }}
            >
                {({ values, setFieldValue }) => (
                    <Form className="formStyle" style={{ display: "flex", flexDirection: "column" }} translate={undefined}>
                        <Grid container spacing={2} style={{ marginTop: "20px" }}>
                            <Grid item xs={12} sm={6}>
                                <Typography className="fieldText">Category</Typography>
                                {this.renderCategorySelect(setFieldValue, values)}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography className="fieldText">Cause Type</Typography>
                                {this.renderCauseSelect(setFieldValue, values)}
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className="fieldText">Select Valid Causes</Typography>
                                {this.renderCheckBox(values, setFieldValue)}
                               
                            </Grid>

    
                             <Grid item xs={12} sm={6}>
                                <Typography className="fieldText">Cause Description</Typography>
                                <Field
                                    as={TextField}
                                    data-test-id="detailsCasueTestID"
                                    style={webStyle.textareaStyle}
                                    placeholder="Enter Cause"
                                    multiline
                                    maxRows={3}
                                    minRows={3}
                                    InputProps={{ disableUnderline: true }}
                                    name="causeText"
                                    value={values?.causeText}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setFieldValue("causeText", event.target.value);
                                    }}
                                    className="textarea"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                {this.renderImageInput(values, setFieldValue)}
                            </Grid>

                            <Grid item xs={12}>
                                <FieldArray name="causesList" data-test-id="causeList">
                                    {({ push, remove }) => (
                                        <>
                                            <TableContainer className="tableContainer">
                                                <Table>
                                                    <TableHead>
                                                        <TableRow className="tableHeader">
                                                            <TableCell className="tableHeaderCell">Cause</TableCell>
                                                            <TableCell className="tableHeaderCell">Describe Cause</TableCell>
                                                            <TableCell className="tableHeaderCell">How it was checked?</TableCell>
                                                            <TableCell className="tableHeaderCell">Result</TableCell>
                                                            <TableCell className="tableHeaderCell">Actions</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    {this.renderTable(values, setFieldValue, remove)}
                                                    
                                                </Table>
                                            </TableContainer>

                                            <Button
                                            id="addCauseButton"
                                                data-test-id="addCauseButton"
                                                className="addCauseButton"
                                                onClick={() =>
                                                    push({
                                                        name: `Cause 0${values.causesList.length + 1}`,
                                                        description: "",
                                                        howChecked: "",
                                                        result: "",
                                                        isEditing: true
                                                    })
                                                }
                                            >
                                                + Add Another Cause
                                            </Button>
                                        </>
                                    )}
                                </FieldArray>
                            </Grid>
    
                            <Grid item xs={12}>
                                <Box className="actionButtons">
                                    <Box>
                                        <Button className="prevButton" onClick={this.props.handlePrev}>
                                            Previous
                                        </Button>
                                    </Box>
                                    <Box style={{ gap: "20px", display: "flex" }}>
                                        <Button className="saveButton"
                                             data-test-id="saveButton"
                                            onClick={() => { this.postD5Form(values, "save") }}
>
                                            Save
                                        </Button>
                                        <Button
                                            type="submit"
                                            data-test-id="nextButton1"
                                            className="nextButton"
                                          
                                        >
                                            Next
                                        </Button>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        );
    };
    

    renderCauseSelect = (
        setFieldValue: (field: string, value: string) => void,
        values: { cause: string }
    ) => {
        return (
            <Field
                data-test-id="cause"
                name="cause"
                as={Select}
                displayEmpty
                onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                    const value = event.target.value as string;
                    setFieldValue("cause", value);
                }}
                IconComponent={KeyboardArrowDown}
                fullWidth
                 value={values?.cause || this.state.d5FormData?.attributes?.cause_type || ""}

                className="inputFieldDropDown"
                MenuProps={{ disableScrollLock: true }}
                renderValue={
                    values?.cause
                        ? undefined
                        : () => (
                            <div style={webStyle.selectPlaceholder as React.CSSProperties}>
                                Select Cause
                            </div>
                        )
                }
            >
                <CustomMenuItem value="" disabled>Select Cause</CustomMenuItem>

                {this.state.rootCauses?.map((cause) => (
                    <CustomMenuItem key={cause.id} value={cause.description}>
                        {cause.description}
                    </CustomMenuItem>
                ))}
            </Field>
        );
    };

    renderCategorySelect = (
        setFieldValue: (field: string, value: string) => void,
        values: { category: string }
    ) => {
        return (
            <Field
                data-test-id="category"
                name="category"
                as={Select}
                displayEmpty
                onChange={(event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
                    const value = event.target.value as string;    
                    setFieldValue("category", value);
                    this.setState(
                        { category: value }, 
                        () => this.getRootCause()
                    );
                }}
                value={values?.category || this.state.d5FormData?.attributes?.category_name || ""}
                IconComponent={KeyboardArrowDown}
                fullWidth
                className="inputFieldDropDown"
                MenuProps={{ disableScrollLock: true }}
                renderValue={
                    values?.category
                        ? undefined
                        : () => (
                            <div style={webStyle.selectPlaceholder as React.CSSProperties}>
                                Select Category
                            </div>
                        )
                }
            >
                <CustomMenuItem value="" disabled>Select Category</CustomMenuItem>
    
                {Array.isArray(this.state.categoryData?.data) 
    ? this.state.categoryData.data.map((item, index) => (
        <CustomMenuItem key={index} value={item.category}>
            {item.category}
        </CustomMenuItem>
    ))
    : null}


            </Field>
        );
    };
    
    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            <ThemeProvider theme={theme}>
                <Wrapper>
                    <Loader loading={this.state.isLoader} />

                    {(() => {
                        switch (this.state.step) {
                            case 1:
                                return this.renderDFiveInitialForm();
                            case 2:
                                return this.renderWhyAnalysis();
                        }
                    })()}
                </Wrapper>

            </ThemeProvider>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
export const Wrapper = styled(Box)({
    backgroundColor: "#FFF",
    border: "10px solid rgb(238, 238, 238)",
    padding: "20px",
   "& .imageMain": {
    position: "relative",
    overflow: "hidden",
    maxHeight: "118px",
    width: "100%", 
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#f5f5f5",
    borderRadius: "8px",
},

"& .imageInner": {
    maxHeight: "100px",
    maxWidth: "100%", 
    objectFit: "cover", 
    transition: "transform 0.3s ease-in-out",
    "&:hover": {
        transform: "scale(1.05)"
    }
},
    
        "& .inputFieldDropDown": {
        width: "100%",
        height: "50px",
        fontFamily: "clotherRegular",
        border: "1px solid #CBD5E1",
        borderRadius: "8px",
        display: "flex",
        justifyContent: "cente r",
        padding: "0px 10px",
        margin: "5px 0px",
        boxSizing: "border-box",
        "& .MuiSelect-icon": {
            right: "5px",
            color: "#0E172A",
        },
        "& .MuiInput-root": {
            "&:focus": {
                outline: "none",
            },
            "&::after": {
                content: "none",
            },
            "&::before": {
                content: "none",
            },
        },
        "&.MuiInput-underline:after": {
            borderBottom: "0px solid red",
        },
        "&.MuiInput-underline:before": {
            borderBottom: "0px solid red",
        },
        "&:hover:not(.Mui-disabled):after": {
            borderBottom: "0px solid red",
        },
        "&:hover:not(.Mui-disabled):before": {
            borderBottom: "0px solid red",
        },
        "& .MuiSelect-select:focus": {
            backgroundColor: "transparent",
        },
    },
    "& .headerText": {
        margin: "15px 0px",
        color: "#2B2A29",
        fontWeight: 700,
        fontFamily: "clotherRegular",
        fontSize: "22px"
    },
    "& .fieldText": {
        color: "#2B2A29",
        fontWeight: 400,
        fontFamily: "clotherRegular",
        fontSize: "16px",
        lineHeight: "18px",
        marginBottom: "10px",
    },
    "& .errorTxt": {
        color: "red"
    },
    "& .headingTxt": {
        color: '#2B2A29',
        backgroundColor: "#FAF3FD",
        fontSize: "17px",
        fontFamily: "clotherRegular",
        padding: "10px 20px"
    },

    "& .tableContainer": {
        borderTopLeftRadius: "8px",
        borderTopRightRadius: "8px",
        borderBottom: "1px solid #EEEEEE",
        marginBottom: "30px",
      },
      "& .tableHeader": {
        backgroundColor: "#8735E1",
      },
      "& .tableHeaderCell":  {
          fontFamily: "'ClotherRegular'",
          fontSize: "16px",
          fontWeight: 700,
          lineHeight: "18px",
          color: "#FFFFFF",
      },
      "& .tableRow": {
          border: "0.5px solid #EEEEEE",
          backgroundColor: "#EEEEEE",
      },
      "& .tableRowCell": {
          fontFamily: "'ClotherRegular'",
          fontSize: "16px",
          fontWeight: 400,
          lineHeight: "22px",
          padding: "12px",
          color: "#2B2A29",
      },
      "& .deleteIcon": {
        height: "28px",
        width: "28px",
        },
      "& .saveIcon": {
        width: "28px",
        height: "28px",
      },
      "& .addCauseButton": {
          height: "50px",
          width: "240px",
          textTransform: "none",
          marginTop: "18px",
          color: "#8735E1",
          border: "1px solid #8735E1",
          fontSize: "16px",
          fontFamily: "'ClotherRegular'",
          lineHeight: "22px",
          fontWeight: 700,
      },
      "& .addAnotherWhyBtn": {
        width: "500px",
        height: "50px",
      },
      "& .actionButtons": {
        display: "flex",
        justifyContent: "space-between",
        marginTop: "20px",
      },
    "& .prevButton, .saveButton": {
        height: "50px",
        width: "160px",
        border: "1px solid #8735E1",
        borderRadius: "8px",
        textTransform: "none",
        fontFamily: "'ClotherRegular'",
        fontSize: "20px",
        fontWeight: 700,
        lineHeight: "22px",
        color: "#8735E1",
    },
      "& .nextButton": {
          height: "50px",
          width: "160px",
          fontFamily: "'ClotherRegular'",
          color: "#FFFFFF",
          fontWeight: 700,
          fontSize: "20px",
          backgroundColor: "#8735E1",
          lineHeight: "22px",
          textTransform: "none",
          borderRadius: "8px",
      },
});
// Customizable Area End