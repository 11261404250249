import React from "react";
// Customizable Area Start
import { 
    Box,
    Button,
    Grid,
    InputAdornment,
    Select,
    Snackbar,
    TextField,
    ThemeProvider, 
    Typography, 
    styled 
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { KeyboardArrowDown } from "@material-ui/icons";
import 
    VarifyActionD6Controller,{
        IIncidentImplementAction,
        Props
} from "./VarifyActionD6Controller.web";
import { theme } from "./RootCauseInvestigation.web";
import { Field, FieldArray, Form, Formik } from "formik";
import { webStyle } from "./postCreationPopUp/styles";
import { Wrapper } from "./RootCauseValidation.web";
import { CustomMenuItem } from "./ReportingInvestigation.web";
import DatePicker  from "react-datepicker";
import { webStyles } from "../../../components/src/styles";
import { calendarLogo } from "../../../components/src/assets";
// Customizable Area End

export default class VarifyActionD6 extends VarifyActionD6Controller {
  constructor(props: Props) {
    super(props);
  // Customizable Area Start
  // Customizable Area End
  }

  // Customizable Area Start
  renderCategorySelect = (
          setFieldValue: {
            (field: string,
              value: any,
              shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string): void;
          },
          item: IIncidentImplementAction,
          index: number,
      ) => {
          return (
              <Field
                  data-test-id="category"
                  name={`incident_implement_actions.${index}.category_id`}
                  as={Select}
                  displayEmpty
                  onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                    const selectedValue = event.target.value as string;
                    setFieldValue(`incident_implement_actions[${index}].category_id`, event.target.value)
                    this.getSubCategory(selectedValue,index)
                  }}
                  IconComponent={KeyboardArrowDown}
                  fullWidth
                  className="inputFieldDropDown"
                  MenuProps={{ disableScrollLock: true }}
                  placeholder="Category"
                  renderValue={
                      item.category_id
                          ? undefined
                          : () => <div style={webStyle.selectPlaceholder as React.CSSProperties}>Category</div>
                  }
              >
                  <CustomMenuItem value="" disabled>Select Category</CustomMenuItem>
                  {this.state.categoriesList.map((category) => (
                    <CustomMenuItem key={category.id} value={category.id}>
                      {category.name}
                    </CustomMenuItem>
                   ))}
              </Field>
          );
  };

  renderAssignPersonSelect = (
    setFieldValue: {
      (field: string,
        value: any,
        shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string): void;
    },
      item: IIncidentImplementAction,
      index: number,
) => {
    return (
        <Field
            data-test-id="assignPerson"
            name={`incident_implement_actions.${index}.assignee_id`}
            as={Select}
            displayEmpty
            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                setFieldValue(`incident_implement_actions[${index}].assignee_id`, event.target.value)
            }}
            IconComponent={KeyboardArrowDown}
            fullWidth
            className="inputFieldDropDown"
            MenuProps={{ disableScrollLock: true }}
            renderValue={
                item.assignee_id
                    ? undefined
                    : () => <div style={webStyle.selectPlaceholder as React.CSSProperties}>Assign Person</div>
            }
        >
            <CustomMenuItem value="" disabled>Select Person</CustomMenuItem>
            {this.state.leadershipUserDetails.map((user) => (
                <CustomMenuItem key={user.id} value={user.id}>
                    {user.full_name}
                </CustomMenuItem>
            ))}
        </Field>
    );
};

renderSubCategorySelect = (
    setFieldValue: {
      (field: string,
        value: any,
        shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string): void;
    },
    item: IIncidentImplementAction,
    index: number,
) => {
    const subCategoryList = this.state.filteredSubCategoryList[index] || [];
    return (
        <Field
            data-test-id="subCategory"
            name={`incident_implement_actions.${index}.sub_category_id`}
            as={Select}
            displayEmpty
            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                setFieldValue(`incident_implement_actions[${index}].sub_category_id`, event.target.value)
            }}
            IconComponent={KeyboardArrowDown}
            fullWidth
            className="inputFieldDropDown"
            MenuProps={{ disableScrollLock: true }}
            renderValue={
                item.sub_category_id
                    ? undefined
                    : () => <div style={webStyle.selectPlaceholder as React.CSSProperties}>Sub Category</div>
            }
        >
            <CustomMenuItem value="" disabled>Select Sub Category</CustomMenuItem>
            {subCategoryList.map((category) => (
                    <CustomMenuItem key={category.id} value={category.id}>
                      {category.name}
                    </CustomMenuItem>
            ))}
        </Field>
    );
};

renderStatusSelect = (
    setFieldValue: {
      (field: string,
        value: any,
        shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string): void;
    },
    item: IIncidentImplementAction,
    index: number,
) => {
    return (
        <Field
            data-test-id="status"
            name={`incident_implement_actions.${index}.status`}
            as={Select}
            displayEmpty
            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                setFieldValue(`incident_implement_actions[${index}].status`, event.target.value)
            }}
            IconComponent={KeyboardArrowDown}
            fullWidth
            className="inputFieldDropDown"
            MenuProps={{ disableScrollLock: true }}
            renderValue={
                item.status
                    ? undefined
                    : () => <div style={webStyle.selectPlaceholder as React.CSSProperties}>Status</div>
            }
        >
            <CustomMenuItem value="" disabled>Select Status</CustomMenuItem>
            <CustomMenuItem value="due">Due</CustomMenuItem>
            <CustomMenuItem value="completed">Completed</CustomMenuItem>
            <CustomMenuItem value="overdue">Overdue</CustomMenuItem>
        </Field>
    );
};
      
  renderDSixForm = () => {
    return (
        <Formik
            data-test-id="dSixFormTestId"
            enableReinitialize
            initialValues={{
                incident_implement_actions: this.state.incident_implement_actions
              }}
            onSubmit={(values) => {
                if (this.state.isSave) {
                    this.handleSaveD6Form(values,false)
                } else {
                    this.handleSaveD6Form(values,true)
                }
            }}
        >
            {({ values, setFieldValue }) => (
                <Form className="formStyle" style={{ display: "flex", flexDirection: "column" }} translate={undefined}>
                    <FieldArray name="incident_implement_actions" data-test-id="dSixFormFieldArray">
                    {({ push, remove }) => (  
                        <Grid container spacing={2} style={{ marginTop: "20px" }}>
                            {values.incident_implement_actions.map((item, index) => (
                                <>
                                <Grid item xs={12}>
                                        {index > 0 && (
                                            <Button
                                                data-test-id="removeBtn"
                                                className="removeBtn"
                                                onClick={() => remove(index)}
                                            >
                                                Remove
                                            </Button>
                                        )}
                                </Grid>
                                    <Grid item xs={12} md={7}>
                                        <Typography className="fieldText">Why</Typography>
                                        <Field
                                            data-test-id="whyTextField"
                                            as={TextField}
                                            fullWidth
                                            style={{
                                                border: "1px solid #DCDCDC",
                                                backgroundColor: "#F3F3F3",
                                                borderRadius: "8px",
                                                height: "54px",
                                                boxSizing: "border-box",
                                                padding: "12.5px",
                                            }}
                                            placeholder="Enter Why"
                                            disabled={true}
                                            InputProps={{ disableUnderline: true }}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                //handle change
                                            }}
                                            name={`incident_implement_actions.${index}.reason`}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Typography className="fieldText">Implement / Verify Action 01 </Typography>
                                        <Field
                                            data-test-id="action1TextField"
                                            as={TextField}
                                            style={{
                                                ...webStyle.textareaStyle,
                                                width: "96%",
                                            }}
                                            multiline
                                            fullWidth
                                            maxRows={3}
                                            minRows={3}
                                            placeholder="Action Description"
                                            InputProps={{ disableUnderline: true }}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                setFieldValue(`incident_implement_actions[${index}].action_description`, event.target.value)
                                            }}
                                            className="textarea"
                                            name={`incident_implement_actions.${index}.action_description`}
                                        />
                                    </Grid>

                                    <Grid item xs={4}>
                                        {this.renderAssignPersonSelect(setFieldValue, item, index)}
                                    </Grid>
                                    <Grid item xs={4}>
                                        {this.renderCategorySelect(setFieldValue, item, index)}
                                    </Grid>
                                    <Grid item xs={4}>
                                        {this.renderSubCategorySelect(setFieldValue, item, index)}
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <Box 
                                            sx={webStyles.dateFieldWrapper}
                                        >
                                            <style>
                                                {`.react-datepicker-popper {
                                                z-index: 4 !important;
                                                }`}
                                            </style>
                                            <DatePicker 
                                                scrollableYearDropdown
                                                showYearDropdown
                                                yearDropdownItemNumber={100}
                                                dateFormat="dd/MM/yyyy"
                                                dateFormatCalendar="MMMM"
                                                data-test-id="targetDate"
                                                placeholderText="Target Date"
                                                name={`incident_implement_actions.${index}.target_date`}
                                                wrapperClassName="date-picker"
                                                selected={item.target_date}
                                                onChange={(date) => setFieldValue(`incident_implement_actions[${index}].target_date`, date)}
                                                minDate={new Date(Date.now())}
                                                popperClassName=""
                                                customInput={
                                                    <TextField
                                                      fullWidth
                                                      value={item.target_date}
                                                      placeholder="Target Date"
                                                      style={{
                                                          backgroundColor: "#FFFFFF",
                                                          borderRadius: "8px",
                                                          height: "50px",
                                                          border: "1px solid #DCDCDC",
                                                          padding: "10px 12.5px",
                                                          margin: "5px 0px",
                                                          boxSizing: "border-box",
                                                    }}
                                                      InputProps={{
                                                        endAdornment: (
                                                          <InputAdornment position="end">
                                                            <img
                                                              src={calendarLogo}
                                                              alt="Calendar Icon"
                                                              style={{ cursor: "pointer", width: 24, height: 24 }}
                                                            />
                                                          </InputAdornment>
                                                        ),
                                                        disableUnderline: true,
                                                      }}
                                                    />
                                                  }
                                            />
                                        </Box>
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        {this.renderStatusSelect(setFieldValue, item, index)}
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Field
                                            data-test-id="followUp"
                                            as={TextField}
                                            style={{
                                                ...webStyle.textareaStyle,
                                                width: "96%",
                                            }}
                                            multiline
                                            fullWidth
                                            maxRows={3}
                                            minRows={3}
                                            placeholder="Follow Up (if any)"
                                            InputProps={{ disableUnderline: true }}
                                            values={item.follow_up}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                setFieldValue(`incident_implement_actions[${index}].follow_up`, event.target.value)
                                            }}
                                            className="textarea"
                                            name={`incident_implement_actions.${index}.follow_up`}
                                        />
                                    </Grid>
                                </>
                            ))}

                                <Button
                                    data-test-id="addCauseButtonWhy"
                                    className="addCauseButton addAnotherWhyBtn"
                                    onClick={() => push({
                                            id: null,
                                            reason: 'Lorem ipsum',
                                            action_description: '',
                                            assignee_id: '',
                                            category_id: '',
                                            subcategory_id: '',
                                            target_date: '',
                                            status: '',
                                            follow_up: '',
                                    })}>
                                    Add another Implement / Verify Action
                                </Button>

                            <Grid item xs={12}>
                                <Box className="actionButtons">
                                    <Box>
                                        <Button className="prevButton" onClick={this.props.handlePrev}>Previous</Button>
                                    </Box>
                                    <Box
                                        style={{
                                            gap: "20px",
                                            display: "flex",
                                        }}>
                                        <Button
                                            className="saveButton"
                                            data-test-id="saveButton"
                                            type="submit"
                                            onClick={() => this.setState({ isSave: true })}
                                        >
                                            Save
                                        </Button>
                                        <Button 
                                            className="nextButton"
                                            data-test-id="nextButton"
                                            type="submit"
                                            onClick={() => this.setState({ isSave: false })}
                                        >Next
                                        </Button>
                                    </Box>
                                </Box>
                            </Grid>
                    </Grid>
                    )}
                    </FieldArray>
                </Form>
            )}
        </Formik>
    );
  }

    renderSnackbar() {
      const { responseStatus, errorMsg } = this.state;
      if (!errorMsg) return null;
  
      return (
        <Snackbar
          data-test-id="snackbar"
          open={responseStatus !== null}
          autoHideDuration={3000}
          onClose={this.handleCloseSnackbar}
        >
          <Alert
            onClose={this.handleCloseSnackbar}
            severity={responseStatus || undefined}
          >
            {errorMsg}
          </Alert>
        </Snackbar>
      );
    }
  // Customizable Area End

  // Customizable Area Start
  render() {
    return (
      <ThemeProvider theme={theme}>
            <Wrapper>
                <MainContainer>
                    {this.renderSnackbar()}
                    {this.renderDSixForm()}
                </MainContainer>
            </Wrapper>
      </ThemeProvider>
    );
  }
  // Customizable Area End
}
// Customizable Area Start
const MainContainer = styled(Box)({
    "& .removeBtn": {
        color: "#8735E1",
        fontSize: "14px",
        textTransform: "none",
        fontFamily: "ClotherRegular",
        fontWeight: 700,
        lineHeight: "22px",
        display: "flex",
        alignItems: "center",
        '&:hover': {
            textDecoration: "underline",
            backgroundColor: "transparent",
        },
    },
})
// Customizable Area End