import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
export const configJSON = require("./config");
import { getStorageData } from "../../../framework/src/Utilities";
import { sendAPIRequest } from "../../../components/src/Utils";
import { mic, micStart } from "./assets";
import moment from "moment";

// Customizable Area End

export interface Props {
  navigation: any;
  // Customizable Area Start
  handlePrev: () => void;
  handleNext: () => void;
  // Customizable Area End
}




export interface IPreventOccurrence {
  name: string;
  placeholder: string;
  multiline?: boolean;
  maxRows?: number;
  minRows?: number;
  className?: string;
}
export interface Member {
    id: number;
    activated: boolean;
    email: string;
    official_email: string | null;
    user_name: string | null;
    first_name: string | null;
    last_name: string | null;
    full_name: string | null;
    gender: "Male" | "Female" | null;
    date_of_birth: string | null;
    country_code: string | null;
    phone_number: number | null;
    full_phone_number: string | null;
    sub_category_id: number | null;
    category_id: number | null;
    role_id: number | null;
    employee_id: string | null;
    employee_type_id: number | null;
    profile_location_id: number | null;
    country_id: number | null;
    work_location_id: number | null;
    is_profile_completed: boolean;
    is_safety_incharge: boolean;
    level: number | null;
    deactivated: boolean;
    supervisor_id: number | null;
    points: number | null;
    platform: string | null;
  }
  
  interface MembersData {
    accounts: Member[];
  }

  interface IMetaData {
    pagination: IPagination;
  };
  
  interface IPagination {
    current_page: number;
    next_page: number | null;
    prev_page: number | null;
    total_pages: number;
    total_count: number;
    current_count: number;
    per_page: number;
  };

  export interface PreventOccurenceActions {
    data: Datum[];
  }
  
  export interface Datum {
    id: string;
    type: string;
    attributes: DatumAttributes;
  }
  
  export interface DatumAttributes {
    target_date: Date;
    status: string;
    department: Department;
    assignee: TrainedMember;
  }

  export interface TrainedMember {
    id: number;
    first_name: null;
    last_name: null;
    full_phone_number: string;
    country_code: number | null;
    full_name: string;
    icf_name: null | string;
    icf_number: number | null;
    employee_type_id: number | null;
    category_id: number | null;
    sub_category_id: number | null;
    profile_location_id: number | null;
    work_location_id: number | null;
    blood_group_id: number;
    is_profile_completed: boolean;
    points: number;
    language: null;
    icf_number_code: null | string;
    supervisor_id: number | null;
    is_safety_incharge: boolean;
    level: null | string;
    country_id: number | null;
    deactivated: boolean;
    percentage: string;
    other_work_location: null | string;
    broad_risk_category_id: number | null;
    visible_to: null | string;
    is_leadership: boolean;
  }
  

  export interface Department {
    id: number;
    name: string;
    created_at: Date;
    updated_at: Date;
    parent_id: null;
    rank: null;
    sub_category_type: null;
    category_id: number;
    profile_location_id: number;
    country_id: number;
  }
  

  interface ILeadershipUserData {
    id: string;
    type: string;
    attributes: IUserAttributes;
  };

  interface IUserAttributes {
    activated: boolean;
    email: string;
    official_email: string;
    user_name: string | null;
    first_name: string | null;
    last_name: string | null;
    full_name: string;
    gender: string;
    contractor_name: string;
    icf_name: string;
    icf_number: string | null;
    date_of_birth: string;
    country_code: number;
    phone_number: string | null;
    full_phone_number: string;
    type: string;
    created_at: string;
    updated_at: string;
    device_id: string | null;
    unique_auth_id: string;
    profile_image: any[];
    sub_category_id: string | null;
    category_id: string | null;
    role_id: string | null;
    employee_id: string;
    employee_type_id: string | null;
    profile_location_id: string | null;
    country_id: string | null;
    work_location_id: string | null;
    blood_group_id: string | null;
    is_profile_completed: boolean;
    icf_number_code: string;
    is_safety_incharge: boolean;
    level: string | null;
    deactivated: boolean;
    other_work_location: string;
    supervisor_id: number;
    points: number | null;
    platform: string | null;
    role_name: string | null;
    function_name: string | null;
    sub_function_name: string | null;
    employee_type_name: string | null;
    blood_group_name: string | null;
    location_name: string | null;
    country_name: string | null;
    supervisor_name: string;
    work_location_name: string | null;
    broad_risk_category: any[];
    my_goals: any[];
    risk_levels: any[];
    followings: number;
    followers: number;
    posts_count: number;
    posts: IPosts;
  };
  
  interface IPosts {
    data: any[];
  };

  export interface ISelectOptions {
    value: number | string;
    name: string;
  };
  export interface GetD7PostCreationAPIResponse {
    data: GetD7PostCreationAPIData;
  }
  
  export interface GetD7PostCreationAPIData {
    id: string;
    type: string;
    attributes: GetD7PostCreationAPIAttributes;
  }
  
  export interface GetD7PostCreationAPIAttributes {
    id: number;
    document_name: string;
    document_number: string;
    version: string;
    target_date: Date;
    remark: string;
    brief_description: string;
    risk_assessment_update_required: boolean;
    sop_and_wi_update_required: boolean;
    safety_absolute_violation: boolean;
    prevent_occurence_actions: PreventOccurenceActions;
    prevent_occurence_documentations: PreventOccurenceDocumentation[];
    trained_members: TrainedMember[];
  }

  export interface PreventOccurenceDocumentation {
    id: number;
    target: string;
    name: string;
    number: string;
    version: string;
    target_date: Date;
    remark: string;
    incident_prevent_occurence_id: number;
    created_at: Date;
    updated_at: Date;
  }

  export interface Document {
    id: string;
    documentFor: string;
    documentName: string;
    documentNumber: string;
    version: string;
    date: string | null; 
    remark: string;
  }

  interface SpeechRecognitionEvent extends Event {
    results: {
      [index: number]: {
        [index: number]: {
          transcript: string;
        };
      };
    };
  }
 
  interface S {
    step: number;
    documentName: string;
    documentNumber: string;
    documentVersion: string;
    documentDate: string;
    documentRemark: string;
  
    wisopocp: Array<{
      documentName: string;
      documentNumber: string;
      version: string;
      date: Date;
      remark: string;
    }>;
  
    format: {
      documentName: string;
      documentNumber: string;
      version: string;
      date: Date;
      remark: string;
    };
  
    pmCheck: Array<{
      documentName: string;
      documentNumber: string;
      version: string;
      date: Date;
      remark: string;
    }>;
  
    others: Document[];
    membersList: Member[];
    feasibility: string;
    actionDetailsList: {
        id?: number;
        functionOrDepartment: string;
        functionOrDepartmentID: string;
        assignTo: string;
        assignToID: string;
        targetDate: string | Date;
        status: string;
      }[];
    riskAssessment: string;
    sopWi: string;
    absoluteVoilation: string;
    newActionDetailsList: {
        id?: number;
        functionOrDepartment: string;
        functionOrDepartmentID: string;
        assignTo: string;
        assignToID: string;
        targetDate: string | Date;
        status: string;
      };
    assignToOptions: ISelectOptions[]
    buLoactionList: any
    trainedSelectedMemberList: {
        id?: number,
        name: string;
      }[];
    isSaved: boolean;
    listening: boolean;
    stoppedByTimeout: boolean,
    responseStatus: "success" | "error" | null;
    errorMsg: string;
    isAlert: boolean;
    alertMsg: string;
  }

interface SS {}

export default class PreventOccurrenceController extends BlockComponent<
                 Props,
                 S,
                 SS
               > {
                 // Customizable Area Start
                 getTrainedMemberCallId: string = "";
                 callGetLeadershipUsersToAssign: string = "";
                 getBuLocationCallId: string = "";
                 addD7PostCreationDetailsApiCallID: string = "";
                 getD7PostCreationDetailsApiCallID: string = "";
                 private recognition: any | undefined;
                 // Customizable Area End

                 constructor(props: Props) {
                   super(props);
                   this.receive = this.receive.bind(this);
                   // Customizable Area Start
                   this.subScribedMessages = [
                     getName(MessageEnum.AccoutLoginSuccess),
                     getName(MessageEnum.RestAPIResponceMessage),
                     getName(MessageEnum.SessionSaveMessage),
                     getName(MessageEnum.SessionResponseMessage),
                   ];
                   this.state = {
                     step: 1,
                     documentDate: "",
                     documentName: "",
                     documentNumber: "",
                     documentRemark: "",
                     documentVersion: "",

                     wisopocp: [
                       {
                         documentName: "",
                         documentNumber: "",
                         version: "",
                         date: new Date(),
                         remark: "",
                       },
                     ],
                     format: {
                       documentName: "",
                       documentNumber: "",
                       version: "",
                       date: new Date(),
                       remark: "",
                     },
                     pmCheck: [
                       {
                         documentName: "",
                         documentNumber: "",
                         version: "",
                         date: new Date(),
                         remark: "",
                       },
                     ],

                     others: [
                       {
                         id: "",
                         documentFor: "",
                         documentName: "",
                         documentNumber: "",
                         version: "",
                         date: "",
                         remark: "",
                       },
                     ],
                     membersList: [],
                     feasibility: "",
                     actionDetailsList: [
                       {
                         assignTo: "",
                         assignToID: "",
                         functionOrDepartmentID: "",
                         functionOrDepartment: "",
                         status: "",
                         targetDate: "",
                       },
                     ],
                     riskAssessment: "No",
                     sopWi: "No",
                     absoluteVoilation: "No",
                     newActionDetailsList: {
                       assignTo: "",
                       assignToID: "",
                       functionOrDepartmentID: "",
                       functionOrDepartment: "",
                       status: "",
                       targetDate: "",
                     },
                     assignToOptions: [],
                     buLoactionList: [],
                     trainedSelectedMemberList: [
                       { name: "", id: 0 },
                       { name: "", id: 0 },
                       { name: "", id: 0 },
                     ],
                     isSaved: false,
                     listening: false,
                     stoppedByTimeout: false,
                     responseStatus: "success",
                     errorMsg: "",
                     isAlert: false,
                     alertMsg: ""
                   };

                   // Customizable Area End
                   runEngine.attachBuildingBlock(
                     this as IBlock,
                     this.subScribedMessages
                   );
                 }

                 async componentDidMount() {
                   super.componentDidMount();
                   // Customizable Area Start
                   this.getTrainedMembers();
                   this.getLeadershipUsersToAssign();
                   this.getBuLOcationList();
                   this.getD7FormData();

                   // Customizable Area End
                 }

                 async receive(from: string, message: Message) {
                   // Customizable Area Start
                   if (
                     getName(MessageEnum.RestAPIResponceMessage) === message.id
                   ) {
                     const apiRequestCallId = message.getData(
                       getName(MessageEnum.RestAPIResponceDataMessage)
                     );

                     const responseJson = message.getData(
                       getName(MessageEnum.RestAPIResponceSuccessMessage)
                     );

                     if (this.getTrainedMemberCallId === apiRequestCallId) {
                       this.handleGetTrainedMembersApiResponse(responseJson);
                     }

                     if (
                       this.callGetLeadershipUsersToAssign === apiRequestCallId
                     ) {
                       this.handleGetLeadershipApiResponse(responseJson);
                     }

                     if (this.getBuLocationCallId === apiRequestCallId) {
                       this.handleGetBuLocationApiResponse(responseJson);
                     }

                     if (
                       this.addD7PostCreationDetailsApiCallID ===
                       apiRequestCallId
                     ) {
                       this.handleD7ApiResponse(responseJson);
                     }

                     if (
                       this.getD7PostCreationDetailsApiCallID ===
                       apiRequestCallId
                     ) {
                       this.handleD7PostCreationApiResponse(responseJson);
                     }
                   }
                   // Customizable Area End
                 }

                 // Customizable Area Start

                 handleNext = () => {
                   this.setState((prevState) => {
                     const nextStep = Math.min(prevState.step + 1, 3);
                     return { step: nextStep };
                   });
                 };

                 handlePrevious = () => {
                   this.setState((prevState) => ({
                     step: Math.max(prevState.step - 1, 1),
                   }));
                 };

                 getTrainedMembers = async () => {
                   const token = await getStorageData("authToken");

                   this.getTrainedMemberCallId = sendAPIRequest(
                     configJSON.getTagPeopleEndPoint,
                     {
                       method: configJSON.getApiMethodType,
                       headers: {
                         token,
                       },
                     }
                   );
                 };

                 handleGetTrainedMembersApiResponse = (
                   responseJson: MembersData
                 ) => {
                   this.setState({ membersList: responseJson.accounts });
                 };

                 getLeadershipUsersToAssign = async () => {
                   const token = await getStorageData("authToken");

                   this.callGetLeadershipUsersToAssign = sendAPIRequest(
                     configJSON.leaderShipEndpoint,
                     {
                       method: configJSON.getApiMethodType,
                       headers: {
                         token,
                       },
                     }
                   );
                 };

                 handleGetLeadershipApiResponse = (
                   responseJSON: Record<string, unknown>
                 ) => {
                   const response = responseJSON as {
                     leadership_user: {
                       meta?: IMetaData;
                       data?: ILeadershipUserData[];
                     };
                   };

                   if (response.leadership_user.data) {
                     let data = response.leadership_user.data;
                     let leadershipUsers: ISelectOptions[] = [];

                     data.forEach((user) => {
                       leadershipUsers.push({
                         value: user.id,
                         name: user.attributes.full_name,
                       });
                     });

                     this.setState({ assignToOptions: leadershipUsers });
                   }
                 };

                 getBuLOcationList = async () => {
                   const token = await getStorageData("authToken");

                   this.getBuLocationCallId = sendAPIRequest(
                     configJSON.allFunctionsApiEndPoint,
                     {
                       method: configJSON.getApiMethodType,
                       headers: {
                         token,
                       },
                     }
                   );
                 };

                 handleGetBuLocationApiResponse = (responseJson: any) => {
                   this.setState({
                     buLoactionList: responseJson.sub_functions.data,
                   });
                 };

                 addD7PostCreationDetailsAPICall = async (
                   values: any,
                   isComplete: boolean
                 ) => {
                   const token = await getStorageData("authToken");
                   let createPostId = await getStorageData("createPostId");

                   const header = {
                     "Content-Type": "application/json",
                     token: token,
                   };
                   let otherDocumentDeatils =
                     values.others?.map((item: any) => ({
                       id: item.id || null,
                       target: item.documentFor,
                       name: item.documentName,
                       number: item.documentNumber,
                       version: item.version,
                       target_date: item.date,
                       remark: item.remark,
                     })) || [];

                   const trainderMemberList =
                     values.trainedSelectedMemberList?.map(
                       (item: any) => item.id
                     ) || [];
                   const preventActionList =
                     values.actionDetailsList?.map((item: any) => ({
                       id: item.id ? item.id : null,
                       department_id: item.functionOrDepartment,
                       assignee_id: item.assignTo,
                       target_date: item.targetDate,
                       status: item.status?.toLowerCase(),
                     })) || [];
                   const httpBody = {
                     post: {
                       id: createPostId,
                       d7_completed: isComplete,
                       incident_prevent_occurence: {
                         document_name: values.documentName,
                         document_number: values.documentNumber,
                         version: values.documentVersion,
                         target_date: values.documentDate,
                         remark: values.documentRemark,
                         brief_description: values.feasibility,
                         risk_assessment_update_required:
                           values.riskAssessment ===
                           "Yes",
                         sop_and_wi_update_required:
                           values.sopWi === "Yes",
                         safety_absolute_violation:
                           values.absoluteVoilation === "Yes",
                         prevent_occurence_actions: preventActionList,
                         prevent_occurence_documentations: otherDocumentDeatils,
                         trained_members: trainderMemberList,
                       },
                     },
                   };

                   const d7RequestMessage = new Message(
                     getName(MessageEnum.RestAPIRequestMessage)
                   );

                   this.addD7PostCreationDetailsApiCallID =
                     d7RequestMessage.messageId;

                   d7RequestMessage.addData(
                     getName(MessageEnum.RestAPIRequestBodyMessage),
                     JSON.stringify(httpBody)
                   );

                   d7RequestMessage.addData(
                     getName(MessageEnum.RestAPIResponceEndPointMessage),
                     configJSON.d7PostCreationApiEndPoint
                   );

                   d7RequestMessage.addData(
                     getName(MessageEnum.RestAPIRequestHeaderMessage),
                     JSON.stringify(header)
                   );

                   d7RequestMessage.addData(
                     getName(MessageEnum.RestAPIRequestMethodMessage),
                     "PATCH"
                   );

                   runEngine.sendMessage(d7RequestMessage.id, d7RequestMessage);
                 };

                 handleD7ApiResponse(responseJSON: any) {
                    if(responseJSON?.errors){
                        this.setState({ responseStatus: 'error', errorMsg: responseJSON?.errors[0] })
                      }else{
                        this.setState({ responseStatus: 'success', errorMsg: "Data saved succesfully" })
                        this.getD7FormData();

                      }
                 }

                 getD7FormData = async () => {
                   const token = await getStorageData("authToken");
                   let createPostId = await getStorageData("createPostId");

                   this.getD7PostCreationDetailsApiCallID = sendAPIRequest(
                     `${configJSON.getLoadD7ApiEndPoint}?id=${createPostId}`,
                     {
                       method: configJSON.getApiMethodType,
                       headers: {
                         token,
                       },
                     }
                   );
                 };

                 handleD7PostCreationApiResponse(
                   responseJson: GetD7PostCreationAPIResponse
                 ) {
                   const { attributes } = responseJson.data;
                   const formattedDate = this.formatDate(
                     attributes.target_date
                   );

                   const documentDetailsList = this.mapDocumentDetails(
                     attributes.prevent_occurence_documentations
                   );
                   const actionDetailsList = this.mapActionDetails(
                     attributes.prevent_occurence_actions.data
                   );
                   const trainedMemberList = this.mapTrainedMembers(
                     attributes.trained_members
                   );

                   this.setState({
                     documentName: attributes.document_name,
                     documentNumber: attributes.document_number,
                     documentVersion: attributes.version,
                     documentDate: formattedDate,
                     documentRemark: attributes.remark,
                     others: documentDetailsList,
                     feasibility: attributes.brief_description,
                     riskAssessment: attributes.risk_assessment_update_required
                       ? "Yes"
                       : "No",
                     sopWi: attributes.sop_and_wi_update_required
                       ? "Yes"
                       : "No",
                     absoluteVoilation: attributes.safety_absolute_violation
                       ? "Yes"
                       : "No",
                     actionDetailsList,
                     trainedSelectedMemberList: trainedMemberList.length
                       ? trainedMemberList
                       : this.getDefaultTrainedMembers(),
                   });
                 }

                 formatDate(date: string | null | Date): string {
                   return date ? moment(date).format("YYYY/MM/DD") : "";
                 }

                 mapDocumentDetails(documents: any[]) {
                   return documents.map((item) => ({
                     id: item.id?.toString() || "",
                     date: this.formatDate(item.target_date),
                     documentFor: item.target,
                     documentName: item.name,
                     documentNumber: item.number,
                     remark: item.remark,
                     version: item.version,
                   }));
                 }

                 mapActionDetails(actions: any[]) {
                   return actions.map((item) => ({
                     assignTo: item.attributes.assignee?.full_name,
                     assignToID: item.attributes.assignee?.id?.toString(),
                     functionOrDepartmentID: item.attributes.department?.id?.toString(),
                     functionOrDepartment: item.attributes.department?.name,
                     status: item.attributes.status,
                     targetDate: this.formatDate(item.attributes.target_date),
                   }));
                 }

                 mapTrainedMembers(members: any[]) {
                   return members.map((item) => ({
                     id: item.id ?? undefined,
                     name: item.full_name,
                   }));
                 }

                 getDefaultTrainedMembers() {
                   return [
                     { name: "", id: 0 },
                     { name: "", id: 0 },
                     { name: "", id: 0 },
                   ];
                 }
                

                 renderMic() {
                   return this.state.listening ? micStart : mic;
                 }
                 

                 handleSpeech = (setFieldValue: any, fieldName: string) => {
                    const SpeechRecognition =
                      (window as any).SpeechRecognition || (window as any).webkitSpeechRecognition;
                  
                    if (!SpeechRecognition) {
                      console.error("Speech Recognition API is not supported in this browser.");
                      return;
                    }
                  
                    const recognition = new SpeechRecognition();
                    recognition.lang = "en-US";
                    this.setState({ listening: true });
                  
                    recognition.onstart = () => {
                      console.warn("🎙️ Speech recognition started...");
                    };
                  
                    recognition.onresult = (event: SpeechRecognitionEvent) => {
                      const transcript = event.results[0][0].transcript;
                      setFieldValue(fieldName, transcript);
                      console.warn("📝 Text:", transcript);
                    };
                  
                    recognition.onerror = (event: Event) => {
                      const error = (event as any).error;
                      console.error("❌ Speech recognition error:", error);
                      this.setState({ listening: false });
                    };
                  
                    recognition.onend = () => {
                      console.warn("Speech recognition ended.");
                      this.setState({ listening: false });
                    };
                  
                    recognition.start(); 
                  };
                      
                  handleCloseSnackbarAlert = () => {
                    this.setState({ responseStatus: null, errorMsg: ""});
                  }
                  alertClose=()=>{
                    this.setState({isAlert:!this.state.isAlert})
                  }

                 // Customizable Area End
               }
