import React from "react";
// Customizable Area Start
import { 
    Box,
    Button,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    ThemeProvider, 
    createTheme, 
    styled,
    Typography,
    Input,
    Snackbar
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Alert from "@material-ui/lab/Alert";
import RootCauseInvestigationController,
{
    Props,
    StepEnum
} from "./RootCauseInvestigationController.web";
import { 
  deleteIcon, 
  machineIcon, 
  managementIcon, 
  manIcon, 
  materialIcon, 
  methodIcon, 
  motherNatureIcon, 
  overviewIcon, 
  saveIcon } from "./assets";
import CustomD4OverviewContainer from "../../../components/src/CustomD4OverviewContainer.web";
import i18n from "i18next";

export const theme = createTheme({
  typography: {
    h3: {
        fontFamily: "ClotherRegular",
        fontSize: "20px",
        fontWeight: 400,
        lineHeight: "24px",
    },
    h6: {
      fontWeight: 500,
      color:'#A1A1A1'
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

// Customizable Area End

// Customizable Area Start
export default class RootCauseInvestigation extends RootCauseInvestigationController {
  constructor(props: Props) {
      super(props);
      // Customizable Area Start
      // Customizable Area End
    }

    // Customizable Area Start
    renderStepIdentifier = (title: StepEnum, icon: any) => {
        const isActive = this.state.activeTab?.toLowerCase() === title?.toLowerCase()
    
        return (
          <>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              className="stepIdentifier"
              style={{
                border: isActive ? `2px solid var(--Purple, #8735E1)` : "none",
              }}
              onClick={() => this.setActiveTab(title)}
              data-test-id="categories"
            >
              <img src={icon} alt={`${title} icon`} style={{ width: "32px", height: "32px" }}/>
              <Typography
                style={{
                  fontWeight: isActive ? 700 : 400,
                  textAlign: "center",
                  fontSize: "14px",
                  lineHeight: "22px",
                  fontFamily: "ClotherRegular"
                }}
              >
                {title}
              </Typography>
            </Box>
          </>
        );
      };

    renderStepSeparator = () => (
      <div
        style={{
          width: "3.5rem",
          height: "1px",
          backgroundColor: "rgb(135 53 225)",
        }}
      />
    );
  
    renderSteps = () => {
        return (
          <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
            {this.state.isD4Completed && (this.renderStepIdentifier(i18n.t(StepEnum.Overview), overviewIcon))}
            {this.state.isD4Completed && this.renderStepSeparator()}
            {this.renderStepIdentifier(i18n.t(StepEnum.Man), manIcon)}
            {this.renderStepSeparator()}
            {this.renderStepIdentifier(i18n.t(StepEnum.Machine), machineIcon)}
            {this.renderStepSeparator()}
            {this.renderStepIdentifier(i18n.t(StepEnum.Method), methodIcon)}
            {this.renderStepSeparator()}
            {this.renderStepIdentifier(i18n.t(StepEnum.Material), materialIcon)}
            {this.renderStepSeparator()}
            {this.renderStepIdentifier(i18n.t(StepEnum.Management), managementIcon)}
            {this.renderStepSeparator()}
            {this.renderStepIdentifier(i18n.t(StepEnum.MotherNature), motherNatureIcon)}
          </div>
        );
    };

    renderSnackbar() {
      const { responseStatus, errorMsg } = this.state;
      if (!errorMsg) return null;
    
      return (
        <Snackbar
          data-test-id="snackbar"
          open={responseStatus !== null}
          autoHideDuration={3000}
          onClose={this.handleCloseSnackbar}
        >
          <Alert
            onClose={this.handleCloseSnackbar}
            severity={responseStatus || undefined}
          >
            {errorMsg}
          </Alert>
        </Snackbar>
      );
    }
    
    renderCustomD4Container(tab: StepEnum, image: string, title: string, index: number, isTopView: boolean) {
      return (
        <Box className={`${this.state.activeTab === tab ? "active" : "tabContent"}`}>
          <div className="alignCenter">
            <CustomD4OverviewContainer
              isTopView={isTopView}
              image={image}
              title={title}
              causeList={this.state.categories[index]?.causes || []}
              shouldShowProblemView={false}
            />
          </div>
        </Box>
      );
    }
    
    renderCategoryTable() {
      const { categories } = this.state;
      return categories.map((category, index) => (
        <Box key={index} className="categoryContainer">
          <Typography variant="h3" style={{ marginBottom: "16px" }} data-test-id="categoryTitle">{category.name}</Typography>
          <TableContainer className="tableContainer">
            <Table>
              <TableHead>
                <TableRow className="tableHeader">
                  <TableCell className="tableHeaderCell">{category.name}</TableCell>
                  <TableCell className="tableHeaderCell">Cause Description</TableCell>
                  <TableCell className="tableHeaderCell"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {category.causes.map((cause, causeIndex) => (
                  <TableRow key={causeIndex} className="tableRow">
                    <TableCell className="tableRowCell">{cause.name}</TableCell>
                    <TableCell className="tableRowCell">
                      {cause.isEditing ? (
                        <Input
                          placeholder={i18n.t("Description")}
                          fullWidth
                          type="text"
                          disableUnderline
                          name="description"
                          style={{
                            border: "1px solid #DCDCDC",
                            backgroundColor: "#FFFFFF",
                            borderRadius: "8px",
                            height: "44px",
                            padding: "16.5px",
                          }}
                          onChange={(e: { target: { value: string; }; }) => this.handleCauseChange(index, causeIndex, e.target.value)}
                          data-test-id="description"
                        />
                      ) : (
                        cause.description
                      )}
                    </TableCell>
                    <TableCell className="tableRowCell">
                      <Box style={{ display: "flex", justifyContent: "center", }}>
                        {cause.isEditing ? (
                          <>
                            <IconButton data-test-id="saveItemBtn" onClick={() => this.saveCause(index, causeIndex)}>
                              <img src={saveIcon} className="saveIcon" />
                            </IconButton>
                            <IconButton data-test-id="removeItemBtn" onClick={() => this.deleteCause(index, causeIndex)}>
                              <img src={deleteIcon} className="deleteIcon" />
                            </IconButton>
                          </>
                        ) : (
                          <MoreVertIcon
                          style={{color:'#DCDCDC'}}
                            data-test-id="moreIcon"
                          />
                        )}
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Button data-test-id="addCauseButton" className="addCauseButton" onClick={() => this.addCause(index)}>
            + Add Another Cause
          </Button>
        </Box>
      ));
    }
    
    // Customizable Area End

  render() {
    // Customizable Area Start
    const { isD4Completed } = this.state;
    // Customizable Area End
    return (
        <ThemeProvider theme={theme}>
           {this.renderSnackbar()}
            <Wrapper>
              <Box className="headingTxtWrapper">
                <Typography data-test-id="titleText" className="headingTxt">D4: Root Cause Investigation</Typography>
              </Box>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "24px",
                  borderBottom: "1px solid #D9D9D9",
                  marginBottom: "30px",
                }}
              >
                {this.renderSteps()}
              </Box>
              <Box className={`${this.state.activeTab === StepEnum.Man ? "active" : "tabContent"}`}>
                {isD4Completed ? (
                  <div className="alignCenter">
                    {this.renderCustomD4Container(StepEnum.Man, manIcon, StepEnum.Man, 0, true)}
                  </div>
                ): (
                  <div style={{ width: "100%" }}>
                    {this.renderCategoryTable()}
                    
                  </div>
                )}
              </Box>
              {this.renderCustomD4Container(StepEnum.Machine, machineIcon, StepEnum.Machine, 1, true)}
              {this.renderCustomD4Container(StepEnum.Method, methodIcon, StepEnum.Method, 2, true)}
              {this.renderCustomD4Container(StepEnum.Material, materialIcon, StepEnum.Material, 3, true)}
              {this.renderCustomD4Container(StepEnum.Management, managementIcon, StepEnum.Management, 4, true)}
              {this.renderCustomD4Container(StepEnum.MotherNature, motherNatureIcon, StepEnum.MotherNature, 5, true)}
              <Box className={`${this.state.activeTab === StepEnum.Overview ? "active" : "tabContent"}`}>
                <Box display="flex" justifyContent="space-around" style={{ marginBottom: "-80px", width: "80%" }}>
                {this.renderCustomD4Container(StepEnum.Overview, manIcon, StepEnum.Man, 0, true)}
                {this.renderCustomD4Container(StepEnum.Overview, machineIcon, StepEnum.Machine, 1, true)}
                {this.renderCustomD4Container(StepEnum.Overview, methodIcon, StepEnum.Method, 2, true)}
                
                </Box>
                <Box display="flex" justifyContent="space-around" alignItems="center" style={{ width: "100%" }}>
                  <div
                    style={{
                      width: "100%",
                      height: "2px",
                      backgroundColor: "#9519EF",
                    }}
                  />
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    className="stepIdentifier"
                    style={{
                      width: "140px",
                      height: "100px",
                      border: "1px solid #8735E1",
                    }}
                  >
                    <Typography
                      style={{
                        fontWeight: 700,
                        textAlign: "center",
                        fontSize: "20px",
                        lineHeight: "22px",
                        fontFamily: "ClotherRegular"
                      }}
                    >
                      Problem
                    </Typography>
                  </Box>
                </Box>
                <Box display="flex" justifyContent="space-around" style={{ marginTop: "-79px", width: "80%" }}>
                  {this.renderCustomD4Container(StepEnum.Overview, materialIcon, StepEnum.Material, 3, false)}
                  {this.renderCustomD4Container(StepEnum.Overview, managementIcon, StepEnum.Management, 4, false)}
                  {this.renderCustomD4Container(StepEnum.Overview, motherNatureIcon, StepEnum.MotherNature, 5, false)}
                </Box>
              </Box>
              <Box className="actionButtons" >
            <Box>
              <Button className="prevButton" onClick={this.props.handlePrev}>Previous</Button>
            </Box> 
            <Box style={{ display: "flex", gap: "20px" }}>
              <Button className="saveButton" data-test-id="saveButton" onClick={() => this.submitRootCausesForm()} > Save </Button>
              <Button className="nextButton" data-test-id="saveButton2" onClick={() => this.handleNextbutton()} >Next</Button>
            </Box>
          </Box>
            </Wrapper>
        </ThemeProvider>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
export const Wrapper = styled(Box)({
    padding: "0px",
    backgroundColor: "#FFFFFF",
    "& .categoryContainer": {
        padding: "30px",
      },
      "& .tableContainer": {
        borderBottom: "1px solid #EEEEEE",
        borderTopLeftRadius: "8px",
        borderTopRightRadius: "8px",
      },
      "& .tableHeader": {
        backgroundColor: "#8735E1",
      },
      "& .tableHeaderCell":  {
        color: "#FFFFFF",
        fontFamily: "'ClotherRegular'",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "18px",
      },
      "& .tableRow": {
        backgroundColor: "#F8F8F8",
        border: "0.5px solid #EEEEEE",
      },
      "& .tableRowCell": {
        color: "#2B2A29",
        fontFamily: "'ClotherRegular'",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "22px",
        padding: "12px",
      },
      "& .saveIcon": {
        width: "28px",
        height: "28px",
      },
      "& .deleteIcon": {
        width: "28px",
        height: "28px",
      },
      "& .addCauseButton": {
        width: "240px",
        minHeight: "50px",
        marginTop: "18px",
        textTransform: "none",
        border: "1px solid #8735E1",
        color: "#8735E1",
        fontFamily: "'ClotherRegular'",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "22px",
      },
      "& .actionButtons": {
        display: "flex",
        justifyContent: "space-between",
        marginTop: "20px",
        padding: "0px 30px 30px",
      },
      "& .prevButton, .saveButton": {
        width: "160px",
        height: "50px",
        textTransform: "none",
        border: "1px solid #8735E1",
        borderRadius: "8px",
        color: "#8735E1",
        fontFamily: "'ClotherRegular'",
        fontSize: "20px",
        fontWeight: 700,
        lineHeight: "22px",
      },
      "& .nextButton": {
        width: "160px",
        height: "50px",
        color: "#FFFFFF",
        fontFamily: "'ClotherRegular'",
        fontSize: "20px",
        fontWeight: 700,
        lineHeight: "22px",
        backgroundColor: "#8735E1",
        borderRadius: "8px",
        textTransform: "none",
      },
      "& .stepIdentifier": {
        display: 'flex',
        width: '105px',
        height: '74px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        flexShrink: 0,
        borderRadius: '8px',
        background: '#FAF3FD',
        boxSizing: "border-box",
        margin: "10px 0px",
      },
      "& .tabContentWrapper": {
        display: "flex",
        justifyContent: "center",
      },
      "& .tabContent": {
        display: "none",
      },
      "& .active": {
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
      },
      "& .alignCenter": {
        display: "flex",
        justifyContent: "center",
      },

      "& .fieldText": {
        fontFamily: "'ClotherRegular'",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "18px",
        color: "#2B2A29",
        marginBottom: "10px",
      }
})
// Customizable Area End