// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent"
import * as Yup from "yup"
import { runEngine } from "../../../framework/src/RunEngine"
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum"
import { getStorageData, setStorageData } from "../../../framework/src/Utilities"
import { Message } from "../../../framework/src/Message"
import { IBlock } from "../../../framework/src/IBlock"
import { FormikProps, FormikTouched } from "formik"
import { mic, micStart, } from "./assets"
import React from 'react';
export interface SelectEventType {
    target: {
        value: string | unknown;
    }
};
interface FormAttributes {
  id: number;
  description: string;
  target_date: string;
  explaination: string;
  status: string;
  work_location: string | null;
  d3_completed: boolean;
  assignee: {

    id: number
  }
}

interface FormData {
  id: string;
  type: string;
  attributes: FormAttributes;
}

interface APIPayloadType {
    contentType?: string;
    method: string;
    endPoint: string;
    body?: object;
    token?: string;
    type?: string;
  };

interface SpeechRecognitionEvent extends Event {
  resultIndex: number
  results: {
    length: number
    [index: number]: {
      isFinal: boolean
      [index: number]: {
        transcript: string;
      };
    };
  };
}

interface SpeechRecognitionError extends Event {
  error: string;
  message: string;
  timeStamp: number;
}
  interface AccountData {
    id: number;
    activated: boolean;
    email: string;
    official_email: string;
    user_name: string | null;
    first_name: string | null;
    last_name: string | null;
    full_name: string;
    gender: string;
    date_of_birth: string;
    country_code: number;
    phone_number: number;
    full_phone_number: string;
    sub_category_id: number | null;
    category_id: number | null;
    role_id: number;
    employee_id: string;
    employee_type_id: number;
    profile_location_id: number | null;
    country_id: number | null;
    work_location_id: number | null;
    is_profile_completed: boolean;
    is_safety_incharge: boolean;
    level: number | null;
    deactivated: boolean;
    supervisor_id: number | null;
    points: number;
    platform: string | null;
  }

  interface ValidResponseType {
    sub_functions: {
      data: Array<{
        id: string;
        type: string;
        attributes: {
          id: number;
          created_at: string;
          updated_at: string;
          name: string;
        };
      }>;
    };
    message: string; 
    post: string; 
    accounts: AccountData[];
    data: {
      attributes: {
        d2_completed: null | boolean;
        d3_completed: null | boolean;
        id: number;
        general_description: string;
        time_occurs_description: string;
        location_description: string;
        related_people_description: string;
        explaination: string;
        "events": [],
         
      };
    };
  }

  interface Event {
    id: number | null;
    event_date: string;
    event_time: string;
    description: string;
  }

  interface DTwoFormValue {
    general_description: string;
    time_occurs_description: string;
    location_description: string;
    related_people_description: string;
    explaination: string;
    events: Event[]
  }

  interface ContainmentAction {
    containmentActionDescription: string;
    how: string;
    locations: string;
    assignActionTo: string;
    targetDate: string;
    status: string;
  }

interface IncedentListApiResponse {
  data: IncidentPost[];
}

interface IncidentPost {
  id: string;
  type: string;
  attributes: IncidentAttributes;
}

interface IncidentAttributes {
  id: number;
  iir_no: string;
  incident_location: string | null;
  created_at: string;
  d1_completed: boolean | null;
  d2_completed: boolean | null;
  d3_completed: boolean | null;
  d4_completed: boolean | null;
  d5_completed: boolean | null;
  d6_completed: boolean | null;
  d7_completed: boolean | null;
  d8_completed: boolean | null;
  incident_status: string;
  cft_leader: CftLeader | null;
}

interface CftLeader {
  id: number;
  first_name: string | null;
  last_name: string | null;
  full_phone_number: string;
  country_code: string | null;
  phone_number: string | null;
  email: string;
  activated: boolean;
  device_id: string | null;
  unique_auth_id: string;
  password_digest: string;
  type: string | null;
  created_at: string;
  updated_at: string;
  user_name: string | null;
  platform: string | null;
  user_type: string | null;
  app_language_id: number | null;
  last_visit_at: string | null;
  is_blacklisted: boolean;
  suspend_until: string | null;
  status: string;
  role_id: number | null;
  gpa: string | null;
  full_name: string;
  gender: string | null;
  date_of_birth: string | null;
  age: number | null;
  employee_id: string;
  official_email: string;
  unique_identification_number: string | null;
  contractor_name: string;
  icf_name: string;
  icf_number: string | null;
  employee_type_id: number | null;
  category_id: number;
  sub_category_id: number;
  profile_location_id: number;
  work_location_id: number;
  blood_group_id: number | null;
  is_profile_completed: boolean;
  points: number | null;
  language: string | null;
  icf_number_code: string;
  supervisor_id: number | null;
  is_safety_incharge: boolean;
  level: string | null;
  country_id: number;
  deactivated: boolean;
  percentage: string;
  other_work_location: string;
  broad_risk_category_id: number | null;
  visible_to: string;
  is_leadership: boolean;
  safety_manager: boolean;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    // Customizable Area Start
    navigation: any;
    // Customizable Area End
}

// Customizable Area Start
export interface FunctionData {
  
  "id":string;
  "type":string;
  "attributes": {
      "id": number,
      "created_at":string;
      "updated_at":string;
      "name":string;
  }

}
interface ContainmentAction {
  containmentActionDescription: string;
  how: string;
  locations: string;
  assignActionTo: string;
  targetDate: string;
  status: string;
}

interface DThreeFormValues {
  containmentActions: ContainmentAction[];
}

interface Step {
    label: string;
    count: number;
}

type FilterOption = {
  key: string;
  value: string;
};

interface IncidentProgress {
  id: string;
  title: string;
  completed: boolean;
}
// Customizable Area End

interface S {
    // Customizable Area Start
    activeStep: number;
  step: Step[];
  isLoader: boolean;
  accountData: Array<AccountData>;
  dTwoFormValue: DTwoFormValue;
  isD2Complete: boolean | null;
  isD3Complete: boolean | null;
  dThreeFormValue: DThreeFormValues;
  functionData:Array<FunctionData>;
  formValues: {
    data: FormData[];
  };
  isD2FormStep1: boolean;
  newEvent: Event;
  isCountinueButtonClicked: boolean;
  isAlert:boolean;
  alertMsg:string;
  isSave:boolean;
  isFilterOpen: boolean;
  filterStatusOptions: FilterOption[];
  selectedFilterStatus: string[];
  backupSelectedFilterStatus: string[];
  isListBoxClicked: boolean;
  incedentListData: IncidentPost[];
  isListDataLoading: boolean;
  listOfIncidentProgress: IncidentProgress[],
  selectedIncedent: IncidentPost | null,
  responseStatus: "success" | "error" | null;
  errorMsg: string;
  isCheckValue:string;
  listeningWhen:boolean;
  listeningWhat:boolean;
  listeningHow:boolean;
  listeningWho:boolean;
  listeningWhere:boolean;

    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    // Customizable Area End
}

export default class ReportingInvestigation extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    formikRef: FormikProps<any> | null = null;
  activePesonExpertContractorCallId: string = "";
  getD2FormCallId: string = "";
  getD3FormCallId: string = "";

  postD2FormApicallId: string = "";
  postD3FormApicallId: string = "";
  functionAPICallId: string = "";
  getIncedentDataListApiCallId: string = "";
  recognition: any

    // Customizable Area End

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.SessionResponseMessage)
          ];
          this.receive = this.receive.bind(this);
          runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)
        this.state = {
          isSave:false,
            isFilterOpen: false,
            isListBoxClicked: false,
            listOfIncidentProgress: [] as IncidentProgress[],
            selectedIncedent: null,
            isListDataLoading: false,
            filterStatusOptions: [
              { key: "Reported", value: "reported" },
              { key: "Under Investigation", value: "under_investigation" },
              { key: "Concluded", value: "concluded" },
            ],
            incedentListData: [],
            selectedFilterStatus: [],
            backupSelectedFilterStatus: [],
            isLoader:false,
            accountData: [],
            activeStep: 1,
            step: [
                { label: "D2", count: 1 },
                { label: "D3", count: 2 },
                { label: "D4", count: 3 },
                { label: "D5", count: 4 },
                { label: "D6", count: 5 },
                { label: "D7", count: 6 },
                { label: "D8", count: 7 },
          ],
          dTwoFormValue: {
            general_description: "",
            time_occurs_description: "",
            location_description: "",
            related_people_description: "",
            explaination: "",
            events: []
          },
            isD2Complete:false,
            isD2FormStep1: true,
            newEvent: {
              id:null,
              description: "",
              event_date: "",
              event_time: "",
            },
            isCountinueButtonClicked: false,
  isD3Complete: false,

  functionData: [],

            dThreeFormValue: {  
              containmentActions: [
                {
                  containmentActionDescription: "",
                  how: "",
                  locations: "",
                  assignActionTo: "",
                  targetDate: "",
                  status: "",
                },
              ]
            },
            

            formValues: {
              data: [],
            },
            isAlert:false,
            alertMsg:"",

        responseStatus: null,
        errorMsg: "",
        isCheckValue:"",
          listeningWhen: false,
          listeningWhat: false,
          listeningHow: false,
          listeningWho: false,
          listeningWhere: false,
         };
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        this.getIncedentListData();
        this.getActivePesonExpertContractor();
        this.getd2FormApi();
        this.getFunction();
        this.getd3FormApi();
       };

       async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
          let responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
          let apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
          );
           if (this.isValidResponse(responseJson)) {
  
            this.apiSucessCall(apiRequestCallId, responseJson);
          }

          if(apiRequestCallId === this.getIncedentDataListApiCallId){
            this.handleResponseIncedentListData(responseJson)
          }
        }
      }
    handleNext = () => {
        this.setState((prevState) => ({
            activeStep: Math.min(prevState.activeStep + 1, prevState.step.length),
            isSave:false
        }));
    };

    handlePrev = () => {
        this.setState((prevState) => ({
            activeStep: Math.max(prevState.activeStep - 1, 0),
        }));
    };
    getLabelColor = (index: number) => {
        const { activeStep } = this.state;
        if (index + 1 === activeStep) return "#9519EF";
        if (index < activeStep) return "#21C274";
        return "#A1A1A1";
    };


    getLineColor = (index: number) => {
        const { activeStep } = this.state;
        if (index + 1 < activeStep) return "#21C274";
        return "#D9D9D9";
    };

    formD2Validation = Yup.object().shape({
      general_description: Yup.string().required("What field is required").nullable(),
      time_occurs_description: Yup.string().required("When field is required").nullable(),
      location_description: Yup.string().required("Where field is required").nullable(),
      related_people_description: Yup.string().required("Who field is required").nullable(),
      explaination: Yup.string().required("How field is required").nullable(),
    });

    formD2ValidationStep2 = Yup.object().shape({
      events: Yup.array()
        .min(1, "At least one event is required"),
    });

    validateStep1Form = async () => {
      if (this.formikRef) {
        const errors = await this.formikRef.validateForm();
        if (Object.keys(errors).length === 0) {
          this.setState({ isD2FormStep1: false });
        } else {
          const touchedFields = Object.keys(errors).reduce((acc, key) => {
            acc[key] = true;
            return acc;
          }, {} as FormikTouched<any>);
  
          this.formikRef.setTouched(touchedFields, false);
        }
      }
    };

    formD3Validation = Yup.object().shape({
      data: Yup.array().of(
        Yup.object().shape({
          attributes: Yup.object().shape({
            description: Yup.string().required("Containment Action Description is required"),
            explaination: Yup.string().required("Explanation is required"),
            work_location: Yup.string().nullable().required("Work location is required"),
            assignee: Yup.object().shape({
              id: Yup.string().required("Assignee is required"),
            }),
            target_date: Yup.date()
              .nullable()
              .required("Target Date is required")
              .min(new Date(), "Date cannot be in the past"),
            status: Yup.string().required("Status is required"),
          }),
        })
      ),
    });
    
 
  handleSubmitD2From = (values: DTwoFormValue) => {
    this.setState({ dTwoFormValue: values ,})
    this.postD2Api(true)
  }

    getTextByStep = () => {
        switch (this.state.activeStep) {
            case 1:
                return "D2: Problem Description";
            case 2:
                return "D3: Containment Action";
        }
    };   
    isValidResponse = (responseJson: ValidResponseType) => {
      return (responseJson && responseJson.data)
        || responseJson.accounts
        || responseJson.message
      || responseJson.sub_functions
     
    };
  
    apiSucessCall = async (apiRequestCallId: string, responseJson: ValidResponseType) => {
     
      if (apiRequestCallId === this.activePesonExpertContractorCallId) {
        this.activePesonExpertContractorSucessCallBack(responseJson);
      }
       
      if (apiRequestCallId === this.getD2FormCallId) {
        this.getD2FormSucessCallBack(responseJson);
      }

      if (apiRequestCallId === this.getD3FormCallId) {
        this.getD3FormSucessCallBack(responseJson);
      }

      if (apiRequestCallId === this.postD2FormApicallId) {
        this.postD2FormSucessCallBack(responseJson);
      }


      if (apiRequestCallId === this.postD3FormApicallId) {
        this.postD3FormSucessCallBack(responseJson);
      }
      if (apiRequestCallId === this.functionAPICallId) {
        this.functionSucessCallBack(responseJson);
      }
    };

    functionSucessCallBack = (responseJson: ValidResponseType) => {
      this.setState({
        functionData: responseJson.sub_functions.data as FunctionData[],
        isLoader: false
      });
    };

    activePesonExpertContractorSucessCallBack = (responseJson: ValidResponseType) => {
       
        this.setState({
            isLoader: false,
            accountData: responseJson.accounts
        })
      };

  getD2FormSucessCallBack = (responseJson: ValidResponseType) => {
    this.setState({
      isLoader: false,
      isD2Complete: responseJson.data.attributes.d2_completed,
      dTwoFormValue:responseJson.data.attributes
    })
  };

  getD3FormSucessCallBack = (responseJson: any) => {
    let formData = [];
    
    if (responseJson.data) {
        formData = Array.isArray(responseJson.data) ? responseJson.data : [responseJson.data];
    }

    this.setState({
        isLoader: false,
        formValues: {
            data: formData
        }
    });
};
  navigationCheck = () => {
    if (this.state.isCheckValue === "submit") {
      this.setState((prevState) => ({
        activeStep: prevState.activeStep + 1,
        isCheckValue: ""
      }));
    } else if (this.state.isCheckValue === "save") {
      this.getd2FormApi();
      this.setState({ isCheckValue: "" });
    }
  };


  postD2FormSucessCallBack = (responseJson: ValidResponseType) => {
    this.setState({
      isLoader: false,
      alertMsg: responseJson.message,
      isAlert: !this.state.isAlert
     }, () => {
      this.navigationCheck()
    });
  };
  postD3FormSucessCallBack = (responseJson: ValidResponseType) => {
    this.setState({
      isLoader: false,
      alertMsg: responseJson.message,
      isAlert: !this.state.isAlert,
     }, () => {
      if (!this.state.isSave) {
        this.handleNext();
      }
    });
  };
  
  
    apiCall = async (apiData: APIPayloadType) => {
        const { contentType, method, endPoint, body, type } = apiData;
        let token = await getStorageData("authToken")
         const header = {
          "Content-Type": contentType,
          token: token
        };
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          endPoint
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          method
        );
    body && type !== "formData"
      ? requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      )
      : requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  getActivePesonExpertContractor = async () => {
    { this.setState({ isLoader: true }) }
    this.activePesonExpertContractorCallId = await this.apiCall({
      contentType: configJSON.notificationsContentType,
      method: configJSON.notificationsApiMethodType,
      endPoint: configJSON.activePesonExpertContractorEndpoint,
    });
  };

  postD2Api = async (isD2Complete:boolean, isCheckValue?:string) => {
     let createPostId = await getStorageData("createPostId");
     const body ={
      "post": {
          "id": createPostId,
          "general_description": this.state.dTwoFormValue.general_description,
          "time_occurs_description": this.state.dTwoFormValue.time_occurs_description,
          "location_description": this.state.dTwoFormValue.location_description,
          "related_people_description": this.state.dTwoFormValue.related_people_description,
          "explaination": this.state.dTwoFormValue.explaination,
          "d2_completed": isD2Complete,
          "events": this.state.dTwoFormValue.events
      }
  }
    this.postD2FormApicallId = await this.apiCall({
      contentType:configJSON.dashboarContentType,
      method: configJSON.patchApiMethodType,
      endPoint: configJSON.postD2FormEndPoint,
      body: body,
    });
  };

  getd2FormApi = async () => {
      { this.setState({ isLoader: true }) }
      let createPostId = await getStorageData("createPostId");
      this.getD2FormCallId = await this.apiCall({
        contentType: configJSON.notificationsContentType,
        method: configJSON.notificationsApiMethodType,
        endPoint: configJSON.getD2FormEndpoint +createPostId,
      });
    };

    getd3FormApi = async () => {
      { this.setState({ isLoader: true }) }
      let createPostId = await getStorageData("createPostId");
      this.getD3FormCallId = await this.apiCall({
        contentType: configJSON.notificationsContentType,
        method: configJSON.notificationsApiMethodType,
        endPoint: configJSON.getD3FormEndpoint +createPostId,
      });
    };

    postD3Api = async (isD3Complete: boolean) => {
      let createPostId = await getStorageData("createPostId");
    
      const incidentContainmentActions = this.state.formValues.data.map((item: { 
        attributes: { 
          description: string; 
          assignee: { id: number }; 
          work_location: string | number | null; 
          target_date: string; 
          explaination: string; 
          status: string; 
        } 
      }) => ({
        id: null,  
        description: item.attributes.description,
        assignee_id: item.attributes.assignee.id,
        work_location_id: typeof item.attributes.work_location === "number" 
          ? item.attributes.work_location 
          : 0,  
        target_date: item.attributes.target_date,
        explaination: item.attributes.explaination,
        status: item.attributes.status,
      }));
    
      const body = {
        post: {
          id: createPostId,
          d3_completed: isD3Complete,
          incident_containment_actions: incidentContainmentActions,
        },
      };
      this.postD3FormApicallId = await this.apiCall({
        contentType: configJSON.dashboarContentType,
        method: configJSON.patchApiMethodType,
        endPoint: configJSON.postD3FormEndPoint,
        body: body,
      });
    };

    renderMicWhat() {
      return this.state.listeningWhat ? micStart : mic;
    }
    
    renderMicWhen() {
      return this.state.listeningWhen ? micStart : mic;
    }
    
    renderMicWhere() {
      return this.state.listeningWhere ? micStart : mic;
    }
    
    renderMicWho() {
      return this.state.listeningWho ? micStart : mic;
    }
    
    renderMicHow() {
      return this.state.listeningHow ? micStart : mic;
    }
    
  handleSpeech = (setFieldValue: (field: string, value: string) => void, fieldName: string) => {
    const SpeechRecognition =
      (window as any).SpeechRecognition || (window as any).webkitSpeechRecognition;

    if (!SpeechRecognition) {
      console.error("Speech Recognition API is not supported in this browser.");
      return;
    }

    if (this.state.listeningWhat) {
      this.recognition?.stop();
      this.setState({ listeningWhat: false });
      return;
    }

    const recognition = new SpeechRecognition();
    this.recognition = recognition;

    recognition.lang = "en-US";
    recognition.continuous = true;
    recognition.interimResults = true;

    this.setState({ listeningWhat: true });

    recognition.onstart = () => {
      console.warn("🎙️ Speech recognition started...");
    };

    recognition.onresult = (event: SpeechRecognitionEvent) => {
      let finalTranscript = "";

      for (let i = event.resultIndex; i < event.results.length; i++) {
        if (event.results[i].isFinal) {
          finalTranscript += event.results[i][0].transcript;
        }
      }

      if (finalTranscript) {
        let updatedText = fieldName || "";

        updatedText = updatedText.trim();
        if (updatedText && !updatedText.endsWith(" ")) {
          updatedText += " ";
        }
        updatedText += finalTranscript;

        setFieldValue(fieldName, updatedText);
      }
    };

    recognition.onerror = (event: Event) => {
      const error = (event as any).error;
      console.error("❌ Speech recognition error:", error);
      this.setState({ listeningWhat: false });
    };

    recognition.onend = () => {
      console.warn("Speech recognition ended.");
      if (this.state.listeningWhat) {
        recognition.start();
      }
    };

    recognition.start();
  };
  

    handleCloseSnackbarAlert = () => {
      this.setState({ responseStatus: null, errorMsg: ""});
    }

      getFunction= async () => {
            {this.setState({isLoader:true})}
            this.functionAPICallId = await this.apiCall({
              contentType: configJSON.notificationsContentType,
              method: configJSON.notificationsApiMethodType,
              endPoint: configJSON.functionEndPoint,
            });
          };

          handleSubmitd3 = (values: S["formValues"]) => {
            this.setState({ formValues: values }, () => {
               this.postD3Api(this.state.isSave)
            });
          };

          handleNextButton=() =>{
            this.postD3Api(true);
            this.handleNext()
          };

          alertClose=()=>{
            this.setState({isAlert:!this.state.isAlert})
          }

          filterModalClose = () => {
            this.setState({
              isFilterOpen: false,
              selectedFilterStatus: [...this.state.backupSelectedFilterStatus],
            });
          }

          handleApplyFilter = () => {
            this.setState({
              isFilterOpen: false,
              backupSelectedFilterStatus: [...this.state.selectedFilterStatus],
            },() => {
              this.getIncedentListData()
            });
          };

          handleFilterStatusClick = (status: string) => {
            this.setState((prevState) => ({
              selectedFilterStatus: prevState.selectedFilterStatus.includes(status)
                ? prevState.selectedFilterStatus.filter((item) => item !== status)
                : [...prevState.selectedFilterStatus, status],
            }));
          };
          
          handleClearFilter = () => {
            this.setState({
              selectedFilterStatus: []
            })
          }

          handleClearAllFilter = () => {
            this.setState({
              isFilterOpen: false,
              selectedFilterStatus: [],
              backupSelectedFilterStatus: [],
            },() => {
              this.getIncedentListData()
            });
          }

          getIncedentListData = async () => {
            const queryString = this.state.selectedFilterStatus
                  .map((status) => `statuses[]=${status}`)
                  .join("&");
            const header = {
                token: await getStorageData("authToken", false),
                "Content-Type": "application/json",
            };

            this.setState({ isListDataLoading: true })

            const getAllRequestMsg = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            this.getIncedentDataListApiCallId = getAllRequestMsg.messageId;

            getAllRequestMsg.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                `bx_block_dashboard/incident_posts?${queryString}`
            );
            getAllRequestMsg.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );

            getAllRequestMsg.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                "GET"
            );
            runEngine.sendMessage(getAllRequestMsg.id, getAllRequestMsg);
        }

        handleResponseIncedentListData = (responseJson: IncedentListApiResponse) => {
          this.setState({ incedentListData: responseJson?.data, isListDataLoading: false })
        }

        handleClickListBox = (incident: IncidentPost) => {
          setStorageData("createPostId", incident?.id);
          const progressList = this.mapIncidentToProgress(incident);
          this.setState({
            listOfIncidentProgress: progressList,
            selectedIncedent: incident,
          },() => {
            this.setState({ isListBoxClicked: true })
          });
        }

        mapIncidentToProgress = (incident: IncidentPost): IncidentProgress[] => {
          return [
            { id: "D2", title: "Problem Description", completed: incident?.attributes?.d2_completed || false },
            { id: "D3", title: "Containment Action", completed: incident?.attributes?.d3_completed || false },
            { id: "D4", title: "Root Cause Investigation", completed: incident?.attributes?.d4_completed || false },
            { id: "D5", title: "Root Cause Validation", completed: incident?.attributes?.d5_completed || false },
            { id: "D6", title: "Implement / Verify Action", completed: incident?.attributes?.d6_completed || false },
            { id: "D7", title: "Prevent Occurrence", completed: incident?.attributes?.d7_completed || false },
            { id: "D8", title: "Team Recognition", completed: incident?.attributes?.d8_completed || false },
          ];
        };

        handleContinueClick = () => {
          const { listOfIncidentProgress } = this.state;
        
          const lastCompletedIndex = listOfIncidentProgress
            .slice()
            .reverse()
            .findIndex((step) => step.completed);
        
          const activeStep = lastCompletedIndex !== -1
            ? listOfIncidentProgress.length - lastCompletedIndex + 1
            : 1;
        
          this.setState({ isCountinueButtonClicked: true },() => {
            this.getd2FormApi()
            this.getd3FormApi()
            this.setState({ activeStep: activeStep })
          })
        };

        handleClickItemFromIncedent = (index: number) => {
          this.setState({ isCountinueButtonClicked: true },() => {
            if (index === 0 || index === 1) {
              this.getd2FormApi();
              this.getd3FormApi();
            }
            this.setState({ activeStep: index + 1 })
          })
        }
    // Customizable Area End
}
