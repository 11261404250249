import React from "react";
// Customizable Area Start
import { Backdrop, Box, Button, Chip, Grid, InputAdornment, MenuItem, Modal, Select, Snackbar, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@material-ui/core"
import ReportingInvestigationController, { Props } from "./ReportingInvestigationController.web"
import * as IMG_CONST from "./assets"
import { ErrorMessage, Field, FieldArray, Form, Formik, FormikHelpers, FormikProps } from "formik"
import { webStyle } from "./postCreationPopUp/styles"
import DatePicker from "react-datepicker"
import RootCauseValidation from "./RootCauseValidation.web";
import RootCauseInvestigation from "./RootCauseInvestigation.web";
import { NavigateNext, Timer, MoreVert, Check, Add, ArrowForwardIos } from "@material-ui/icons";
import i18n from "i18next";
import VarifyActionD6 from "./VarifyActionD6.web";
import DeleteIcon from "@material-ui/icons/Delete";
import Loader from "../../../components/src/Loader.web";
import { calendarLogo } from "../../../components/src/assets";
import { saveIcon, deleteIcon, buttonFilterIcon, imageLocation, imageCalender, imageUser, modalClose, arrowRightMobile, selectLanguage } from "./assets";
import moment from "moment";
import IncidentReportingD8 from "./IncidentReportingD8.web";
import { Alert } from "@material-ui/lab";
import PreventOccurrence from "./PreventOccurrence.web";

// Customizable Area End

export default class ReportingInvestigation extends ReportingInvestigationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
      renderSnackbar() {
        const { responseStatus, errorMsg } = this.state;
        if (!errorMsg) return null;
      
        return (
          <Snackbar
            data-test-id="snackbar"
            open={responseStatus !== null}
            autoHideDuration={3000}
            onClose={this.handleCloseSnackbarAlert}
          >
            <Alert
              onClose={this.handleCloseSnackbarAlert}
              severity={responseStatus || undefined}
            >
              {errorMsg}
            </Alert>
          </Snackbar>
        );
      }
  renderMainBox = () => {
    return (
      <Box style={{ backgroundColor: "#EEEEEE"}}>
        <Box style={{ display: "flex", justifyContent: "space-between" }}>
          <Box className="breadcrumbWrapper">
            <Typography className="breadcrumbItem">{i18n.t('Home')}</Typography>
            <NavigateNext className="navigateIcon" />
            <Typography className="breadcrumbItem">{i18n.t('Post Creation')}</Typography>
            <NavigateNext className="navigateIcon" />
            <Typography className="breadcrumbActiveItem">{i18n.t('Incident Reporting')}</Typography>
          </Box>
        </Box>
        <Typography className="headerText">Post Creation</Typography>
        {this.renderStepper()}

        <Typography className="headingTxt">{this.getTextByStep()}</Typography>
      </Box>)
  }
  renderDTwoForm = () => {
    return (
      <Box className="formWrapper">
        <Box className="headingTxtWrapper">
          <Typography className="headingTxt">D2: Problem Description</Typography>
        </Box>
        <Formik
          enableReinitialize={true}
          innerRef={(ref) => (this.formikRef = ref as FormikProps<any>)}
          data-test-id="dTwoFormTestId"
          initialValues={this.state.dTwoFormValue}
          validationSchema={
            this.state.isD2FormStep1
              ? this.formD2Validation
              : this.formD2ValidationStep2
          }
          onSubmit={(values) => {
            this.handleSubmitD2From(values);
          }}
        >
          {({ values, setFieldValue, handleSubmit, errors }) => (
            <Form
              onSubmit={handleSubmit}
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
              }}
              className="formStyle"
              translate={undefined}
            >
              {this.state.isD2FormStep1 && (
                <Grid container style={{ marginTop: "20px" }}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={6}>
                    <Box style={{ display:"flex", justifyContent:"space-between"}}>
                    <Typography className="fieldText">What</Typography>

                      <img
                          onClick={() => this.handleSpeech(setFieldValue, "general_description")}
                          height="21px"
                          width="21px"
                          src={this.renderMicWhat()}
                          alt="mic icon"
                          data-test-id="speechTextinputWhat"
                          style={{ cursor: "pointer" }}
                        />
                      </Box>
                      <TextField
                        data-test-id="whatTestId"
                        style={webStyle.textareaStyle}
                        placeholder="Describe What"
                        maxRows={3}
                        minRows={3}
                        multiline
                        InputProps={{ disableUnderline: true }}
                        onChange={(event) =>
                          setFieldValue(
                            "general_description",
                            event.target.value
                          )
                        }
                        className="textarea"
                        name="general_description"
                        value={values.general_description || ""}
                      />
                      <ErrorMessage
                        name="general_description"
                        component="div"
                        className="errorTxt"
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <Box style={{ display:"flex", justifyContent:"space-between"}}>
                        <Typography className="fieldText">When</Typography>
                        <img
                          onClick={() => this.handleSpeech(setFieldValue, "time_occurs_description")}
                          height="21px"
                          width="21px"
                          src={this.renderMicWhen()}
                          alt="mic icon"             
                           data-test-id="speechTextinputWhen"

                          style={{ cursor: "pointer" }}
                        />
                      </Box>
                      <TextField
                        data-test-id="whenTestId"
                        style={webStyle.textareaStyle}
                        placeholder="Enter Date or Time"
                        maxRows={3}
                        minRows={3}
                        multiline
                        InputProps={{ disableUnderline: true }}
                        onChange={(event) =>
                          setFieldValue(
                            "time_occurs_description",
                            event.target.value
                          )
                        }
                        className="textarea"
                        name="time_occurs_description"
                        value={values.time_occurs_description || ""}
                      />
                      <ErrorMessage
                        name="time_occurs_description"
                        component="div"
                        className="errorTxt"
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    spacing={2}
                    alignItems="center"
                    style={{ marginTop: "20px" }}
                  >
                    <Grid item xs={6}>
                       <Box style={{ display:"flex", justifyContent:"space-between"}}>
                    <Typography className="fieldText">Where</Typography>

                      <img
                          onClick={() => this.handleSpeech(setFieldValue, "location_description")}
                          height="21px"
                          width="21px"
                          src={this.renderMicWhere()}
                          alt="mic icon"
                          data-test-id="speechTextinputWhere"
                          style={{ cursor: "pointer" }}
                        />
                      </Box>
                      <TextField
                        data-test-id="whereTestId"
                        style={webStyle.textareaStyle}
                        placeholder="Enter Location"
                        maxRows={3}
                        minRows={3}
                        multiline
                        InputProps={{ disableUnderline: true }}
                        onChange={(event) =>
                          setFieldValue(
                            "location_description",
                            event.target.value
                          )
                        }
                        className="textarea"
                        name="location_description"
                        value={values.location_description || ""}
                      />
                      <ErrorMessage
                        name="location_description"
                        component="div"
                        className="errorTxt"
                      />
                    </Grid>

                    <Grid item xs={6}>
                    <Box style={{ display:"flex", justifyContent:"space-between"}}>
                    <Typography className="fieldText">Who</Typography>

                      <img
                          onClick={() => this.handleSpeech(setFieldValue, "related_people_description")}
                          height="21px"
                          width="21px"
                          src={this.renderMicWho()}
                          alt="mic icon"
                          data-test-id="speechTextinputWho"
                          style={{ cursor: "pointer" }}
                        />
                      </Box>
                       <TextField
                        data-test-id="whoTestId"
                        style={webStyle.textareaStyle}
                        placeholder="Enter Who"
                        maxRows={3}
                        minRows={3}
                        multiline
                        InputProps={{ disableUnderline: true }}
                        onChange={(event) =>
                          setFieldValue(
                            "related_people_description",
                            event.target.value
                          )
                        }
                        className="textarea"
                        name="related_people_description"
                        value={values.related_people_description || ""}
                      />
                      <ErrorMessage
                        name="related_people_description"
                        component="div"
                        className="errorTxt"
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    spacing={2}
                    alignItems="center"
                    style={{ marginTop: "20px" }}
                  >
                    <Grid item xs={6}>
                    <Box style={{ display:"flex", justifyContent:"space-between"}}>
                    <Typography className="fieldText">How</Typography>

                      <img
                          onClick={() => this.handleSpeech(setFieldValue, "explaination")}
                          height="21px"
                          width="21px"
                          src={this.renderMicHow()}
                          alt="mic icon"
                          data-test-id="speechTextinputHow"
                          style={{ cursor: "pointer" }}
                        />
                      </Box>
                       <TextField
                        data-test-id="howTestId"
                        style={webStyle.textareaStyle}
                        placeholder="Explain How"
                        maxRows={3}
                        minRows={3}
                        multiline
                        InputProps={{ disableUnderline: true }}
                        onChange={(event) =>
                          setFieldValue("explaination", event.target.value)
                        }
                        className="textarea"
                        name="explaination"
                        value={values.explaination || ""}
                      />
                      <ErrorMessage
                        name="explaination"
                        component="div"
                        className="errorTxt"
                      />
                    </Grid>
                    <Grid item xs={6}></Grid>
                  </Grid>
                </Grid>
              )}

              {!this.state.isD2FormStep1 && (
                <FieldArray name="events" data-test-id="d2FormFieldArray">
                  {({ push, remove }) => (
                    <Box
                      marginTop={"30px"}
                      marginBottom={"100px"}
                      paddingLeft={"30px"}
                    >
                      <TableContainer
                        style={{
                          borderTopLeftRadius: "8px",
                          borderTopRightRadius: "8px",
                          overflow: "hidden",
                        }}
                      >
                        <Table>
                          <TableHead
                            style={{
                              backgroundColor: "#8735E1",
                              color: "#fff",
                            }}
                          >
                            <TableRow>
                              <TableCell
                                style={{
                                  ...webStyles.tableCellFont,
                                  borderTopLeftRadius: "8px",
                                }}
                              >
                                Description
                              </TableCell>
                              <TableCell style={webStyles.tableCellFont}>
                                Event Date
                              </TableCell>
                              <TableCell style={webStyles.tableCellFont}>
                                Shift & Time
                              </TableCell>
                              <TableCell
                                style={{
                                  ...webStyles.tableCellFont,
                                  borderTopRightRadius: "8px",
                                }}
                              />
                            </TableRow>
                          </TableHead>
                          <TableBody
                            style={{
                              backgroundColor: "#F8F8F8",
                            }}
                          >
                            {values?.events?.map((event, index) => (
                              <TableRow key={index}>
                                <TableCell>{event.description}</TableCell>
                                <TableCell>
                                  {moment(event.event_date).format(
                                    "DD/MM/YYYY"
                                  )}
                                </TableCell>
                                <TableCell>
                                  {/^(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}[+-]\d{2}:\d{2})$/.test(
                                    event.event_time
                                  )
                                    ? moment
                                        .parseZone(event.event_time)
                                        .format("HH:mm")
                                    : event.event_time}
                                </TableCell>
                                <TableCell align="center">
                                  <MoreVert
                                    style={{
                                      color: "#A1A1A1",
                                      cursor: "pointer",
                                      width: 20,
                                      height: 20,
                                    }}
                                  />
                                </TableCell>
                              </TableRow>
                            ))}
                            <TableRow>
                              <TableCell>
                                <TextField
                                  name="description"
                                  placeholder="Description"
                                  data-test-id="event_description_test_id"
                                  variant="outlined"
                                  size="small"
                                  fullWidth
                                  value={this.state.newEvent.description}
                                  onChange={(e) =>
                                    this.setState({
                                      newEvent: {
                                        ...this.state.newEvent,
                                        description: e.target.value,
                                      },
                                    })
                                  }
                                  InputProps={{
                                    style: {
                                      border: "1px solid #DCDCDC",
                                      borderRadius: "8px",
                                      height: "40px",
                                    },
                                  }}
                                />
                              </TableCell>
                              <TableCell>
                                <Box display={"flex"} flexDirection={"column"}>
                                  <style>
                                    {`.react-datepicker-popper {
                                                z-index: 4 !important;
                                    }`}
                                  </style>
                                  <DatePicker
                                    scrollableYearDropdown
                                    showYearDropdown
                                    yearDropdownItemNumber={100}
                                    dateFormat="dd/MM/yyyy"
                                    dateFormatCalendar="MMMM"
                                    placeholderText="Assigned On"
                                    wrapperClassName="date-picker"
                                    minDate={new Date(Date.now())}
                                    selected={
                                      this.state.newEvent.event_date
                                        ? new Date(
                                            this.state.newEvent.event_date
                                          )
                                        : null
                                    }
                                    data-test-id="event_date_test_id"
                                    onChange={(date) =>
                                      this.setState({
                                        newEvent: {
                                          ...this.state.newEvent,
                                          event_date: date
                                            ? date.toISOString().split("T")[0]
                                            : "",
                                        },
                                      })
                                    }
                                    popperClassName=""
                                    customInput={
                                      <TextField
                                        fullWidth
                                        placeholder="Assigned On"
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          backgroundColor: "#FFFFFF",
                                          borderRadius: "8px",
                                          height: "40px",
                                          border: "2px solid #DCDCDC",
                                          padding: "10px",
                                          boxSizing: "border-box",
                                        }}
                                        InputProps={{
                                          endAdornment: (
                                            <InputAdornment position="end">
                                              <img
                                                src={calendarLogo}
                                                alt="Calendar Icon"
                                                style={{
                                                  cursor: "pointer",
                                                  width: 20,
                                                  height: 20,
                                                  color: "#A1A1A1",
                                                }}
                                              />
                                            </InputAdornment>
                                          ),
                                          disableUnderline: true,
                                        }}
                                      />
                                    }
                                  />
                                </Box>
                              </TableCell>
                              <TableCell>
                                <TextField
                                  data-test-id="event_time_test_id"
                                  name="event_time"
                                  type="time"
                                  variant="outlined"
                                  size="small"
                                  fullWidth
                                  value={this.state.newEvent.event_time}
                                  onChange={(e) =>
                                    this.setState({
                                      newEvent: {
                                        ...this.state.newEvent,
                                        event_time: e.target.value,
                                      },
                                    })
                                  }
                                  InputProps={{
                                    style: {
                                      border: "1px solid #DCDCDC",
                                      borderRadius: "8px",
                                      height: "40px",
                                    },
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <Timer
                                          style={{
                                            color: "#A1A1A1",
                                            cursor: "pointer",
                                            width: 20,
                                            height: 20,
                                          }}
                                        />
                                      </InputAdornment>
                                    ),
                                  }}
                                  placeholder={
                                    !this.state.newEvent.event_time
                                      ? "Enter Time"
                                      : ""
                                  }
                                  inputProps={{
                                    step: 60,
                                    pattern: "[0-9]{2}:[0-9]{2}",
                                  }}
                                />
                              </TableCell>
                              <TableCell>
                                <Box
                                  display={"flex"}
                                  alignItems={"center"}
                                  padding={"0px 10px"}
                                >
                                  <img
                                    src={saveIcon}
                                    data-test-id="saveIconEvent"
                                    onClick={() => {
                                      if (
                                        this.state.newEvent.description &&
                                        this.state.newEvent.event_date &&
                                        this.state.newEvent.event_time
                                      ) {
                                        push(this.state.newEvent);
                                        this.setState({
                                          newEvent: {
                                            id: null,
                                            description: "",
                                            event_date: "",
                                            event_time: "",
                                          },
                                        });
                                      }
                                    }}
                                    width={28}
                                    height={28}
                                  ></img>
                                  <img
                                    src={deleteIcon}
                                    data-test-id="deleteIconEvent"
                                    onClick={() => {
                                      this.setState({
                                        newEvent: {
                                          id: null,
                                          description: "",
                                          event_date: "",
                                          event_time: "",
                                        },
                                      });
                                    }}
                                    width={28}
                                    height={28}
                                    style={{ marginLeft: "10px" }}
                                  ></img>
                                </Box>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                      {errors.events && (
                        <Typography
                          variant="body2"
                          color="error"
                          style={{ marginTop: "8px", marginLeft: "30px" }}
                        >
                          {errors.events}
                        </Typography>
                      )}
                    </Box>
                  )}
                </FieldArray>
              )}

        {this.renderBtn()}
            </Form>
          )}
        </Formik>
      </Box>
    );
  };
  
  renderBtn =()=>{
    return(
      <Box
      style={{
        justifyContent: "space-between",
        display: "flex",
        marginTop: "10px",
      }}
    >
      <Box style={{ display: "flex" }}>
        
          <Button
            className="prevBtn"
          data-test-id="previousBtn"

            onClick={(e) => {
              e?.preventDefault();
              if(this.state.isD2FormStep1===false){
                this.setState({ isD2FormStep1: true });
              }
              else {
                this.setState({ isCountinueButtonClicked:false});
              }
            }}
          >
            Previous
          </Button>
        
      </Box>
      <Box style={{ gap: "20px", display: "flex" }}>
        <Button
          className="prevBtn"
          onClick={() => {
            this.setState({ isCheckValue: "save" });
           }}
          type="submit"
        >
          Save
        </Button>

        <Button
          className="nextBtn"
          type="submit"
          data-test-id="nextButtonD2Step1"
          onClick={(e) => {
            if (this.state.isD2FormStep1 === true) {
              this.validateStep1Form();
            }
            else {
              this.setState({ isCheckValue: "submit" });
            }
          }}
        >
          Next
        </Button>
        
      </Box>              </Box>
    )
  }
renderDescrptionFiled = (index: number) => {
  return (
    <Field
      as={TextField}
      data-test-id={`containmentActionDescriptionTestId-${index}`}
      style={webStyle.textareaStyle}
      placeholder="Containment Action Description"
      multiline
      maxRows={3}
      minRows={3}
      InputProps={{ disableUnderline: true }}
      className="textarea"
      name={`data[${index}].attributes.description`}
    />
  );
};

 
renderExplaination = (
  index: number,
  values: { data: { attributes: { explaination: string } }[] },
  setFieldValue: FormikHelpers<{ data: { attributes: { explaination: string } }[] }>["setFieldValue"]
) => {
  return (
    <Field
    as={TextField}
      data-test-id={`howTestId-${index}`}
      style={webStyle.textareaStyle}
      placeholder="How"
      multiline
      maxRows={3}
      minRows={3}
      InputProps={{ disableUnderline: true }}
      className="textarea"
      name={`data[${index}].attributes.explaination`}
      value={values.data[index].attributes.explaination}
      onChange={(e:any) =>
        setFieldValue(`data[${index}].attributes.explaination`, e.target.value)
      }
    />
  );
};
  renderWorkLocation = (
    index: number,
    values: { data: any },
    setFieldValue: (field: string, value: string) => void
  ) => {
    return (
      <Field
        name={`data[${index}].attributes.work_location`}
        as={Select}
        displayEmpty
        fullWidth
        className="inputFieldDropDown"
        MenuProps={{ disableScrollLock: true }}
        value={values.data[index].attributes.work_location || ""}
        onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
          setFieldValue(`data[${index}].attributes.work_location`, event.target.value as string)
        }
        renderValue={
          values.data[index].attributes.work_location
            ? undefined
            : () => <div style={webStyle.selectPlaceholder}>Select location</div>
        }
      >
        <CustomMenuItem value="" disabled>Select location</CustomMenuItem>
        {this.state.functionData.map((option) => (
          <CustomMenuItem key={option.attributes.id} value={option.attributes.id}>
            {option.attributes.name}
          </CustomMenuItem>
        ))}
      </Field>
    );
  };
  renderAssigne = (
    index: number,
    values: any,
    setFieldValue: (field: string, value: string) => void
  ) => {
    return (
      <Field
        name={`data[${index}].attributes.assignee.id`}
        as={Select}
        displayEmpty
        fullWidth
        className="inputFieldDropDown"
        MenuProps={{ disableScrollLock: true }}
        value={values.data[index].attributes.assignee?.id || ""}
        onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
          setFieldValue(`data[${index}].attributes.assignee.id`, event.target.value as string)
        }
        renderValue={
          values.data[index].attributes.assignee?.id
            ? undefined
            : () => <div style={webStyle.selectPlaceholder}>Select assignee</div>
        }
      >
        <CustomMenuItem value="" disabled>Select assignee</CustomMenuItem>
        {this.state.accountData?.map((account) => (
          <CustomMenuItem key={account.id} value={account.id}>
            {account.full_name}
          </CustomMenuItem>
        ))}
      </Field>
    );
  };

  renderStatus = (index: number, values: any, setFieldValue: Function) => {
    return (
      <Field
        name={`data[${index}].attributes.status`}
        as={Select}
        displayEmpty
        fullWidth
        className="inputFieldDropDown"
        MenuProps={{ disableScrollLock: true }}
        onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
          setFieldValue(`data[${index}].attributes.status`, event.target.value)
        }
        renderValue={
          values.data[index].attributes.status
            ? undefined
            : () => <div style={webStyle.selectPlaceholder}>Select status</div>
        }
      >
        <CustomMenuItem value="" disabled>
          Select status
        </CustomMenuItem>
        <CustomMenuItem value="due">Due</CustomMenuItem>
        <CustomMenuItem value="overdue">Overdue</CustomMenuItem>
        <CustomMenuItem value="completed">Completed</CustomMenuItem>
      </Field>
    );
  };
  

  renderD3Form = () => {  
    return (
      <Box className="formWrapper">
          {this.renderSnackbar()}
        <Box className="headingTxtWrapper">
          <Typography className="headingTxt">D3: Containment Action</Typography>
        </Box>
        <Formik
          enableReinitialize={true}
          data-test-id="dThreeFormTestId"
          validationSchema={this.formD3Validation}
          onSubmit={this.handleSubmitd3}
          initialValues={{ data: this.state.formValues?.data || [] }}>
          {({ values, setFieldValue,isValid, dirty }) => (
            <Form className="formStyle" style={{ display: "flex", flexDirection: "column" }} translate={undefined}>
              <FieldArray name="data">
                {({ push, remove }) => (
              <Grid container spacing={2} alignItems="center">
                {values.data?.map((item, index) => (
                  <Grid item xs={12} key={index} container spacing={2}>
                        <Grid item xs={6}>
                          <Typography className="fieldText">Containment Action {index + 1}</Typography>
                          {this.renderDescrptionFiled(index)}
                      <ErrorMessage name={`data[${index}].attributes.description`} component="div" className="errorTxt" />
                    </Grid>
  
                        <Grid item xs={6}>
                          <Typography style={{ height: "24px" }}></Typography>
                          {this.renderExplaination(index, values, setFieldValue)}
                          <ErrorMessage name={`data[${index}].attributes.explaination`} component="div" className="errorTxt" />
                        </Grid>
  
                        <Grid item xs={6}>

                    {this.renderWorkLocation(index, values, setFieldValue)}
                          <ErrorMessage name={`data[${index}].attributes.work_location`} component="div" className="errorTxt" />
                        </Grid>
  
                        <Grid item xs={6}>
                          {this.renderAssigne(index, values, setFieldValue)}
                          <ErrorMessage name={`data[${index}].attributes.assignee.id`} component="div" className="errorTxt" />
                        </Grid>
  
                        <Grid item xs={6}>
                          
                      <DatePicker
                      data-test-id={`targetDateTestId-${index}`}
                      className="inputFieldDate"
                       scrollableYearDropdown
                        showYearDropdown
                        yearDropdownItemNumber={100}
                        dateFormat="dd/MM/yyyy"
                        dateFormatCalendar="MMMM"
                        name="joiningDate"
                        placeholderText="Select Date"
                        wrapperClassName="date-picker"
                        selected={values.data[index].attributes.target_date ? new Date(values.data[index].attributes.target_date) : null}
                        customInput={
                          <TextField
                            fullWidth
                            // value={values.joiningDate ? values.joiningDate : ""}
                            placeholder="Select Date"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <img
                                    src={IMG_CONST.calendarIcon}
                                    alt="Calendar Icon"
                                    style={{ cursor: "pointer", width: 24, height: 24 }}
                                  />
                                </InputAdornment>
                              ),
                            }}
                          />
                        }
                        popperClassName=""
                        minDate={new Date()}
                        onChange={(date) => 
                          setFieldValue(
                            `data[${index}].attributes.target_date`, 
                            date instanceof Date ? date.toISOString() : ""
                          )
                        }
                       />
                          <ErrorMessage name={`data[${index}].attributes.target_date`} component="div" className="errorTxt" />
                        </Grid>
  
                        <Grid item xs={6}>
                          {this.renderStatus(index, values, setFieldValue)}
                          <ErrorMessage name={`data[${index}].attributes.status`} component="div" className="errorTxt" />
                        </Grid>
  
                         {index > 0 && (
                          <Grid item xs={12}>
                            <Button
                              className="removeBtn"
                              data-test-id="removeBtnTstId"
                              onClick={() => remove(index)}
                              startIcon={<DeleteIcon style={{ color: "red" }} />}
                            >
                              Remove
                            </Button>
                          </Grid>
                        )}
                      </Grid>
                    ))}
  
                    {/* Add Button */}
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                      <Button
                        className="addBtn"
                        onClick={() =>
                          push({
                            id: `${Date.now()}`,
                            type: "incident_d3_form",
                            attributes: {
                              description: "",
                              target_date: "",
                              explaination: "",
                              status: "due",
                              work_location: null,
                              assignee: { id: null },
                              d3_completed: true
                            }
                          })
                        }
                      >
                        Add another Containment Action
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </FieldArray>
  
              <Box style={{ justifyContent: "space-between", display: "flex", marginTop: "10px" }}>
                <Box style={{ display: "flex" }}>
                  {this.state.activeStep !== 1 && (
                    <Button className="prevBtn" onClick={this.handlePrev}>
                      Previous
                    </Button>
                  )}
                </Box>
                <Box style={{ gap: "20px", display: "flex" }}>
                  <Button
                  type="submit"
                    onClick={() => this.setState({isSave:true})}
                    className="prevBtn"
                    data-test-id="previousBtn"
                  >
                    Save
                  </Button>
  
                  <Button className="nextBtn" type="submit"
                    onClick={() => this.setState({isSave:false})}
                    >
                    Next
                  </Button>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    );
  };
  
  renderStepper = () => {
    const { step, activeStep } = this.state;

    return (
      <StepperBox>
        <Box style={{ display: "flex", flexDirection: "row" }}>
          {step.map((item, index) => (
            <Box
              key={index}
              style={{
                display: "flex",
                flexDirection: "row",
                marginRight: "20px",
                gap: "10px",
                alignItems: "center",
              }}
            >
              {index + 1 >= activeStep && (
                <Typography className={index + 1 === activeStep ? "countActive" : "countDeActive"}>
                  {item.count}
                </Typography>
              )}

              {index + 1 < activeStep && <img src={IMG_CONST.correct} alt={`Step ${index + 1}`} />}

              <Typography style={{ color: this.getLabelColor(index) }}>
                {item.label}
              </Typography>

              {index !== step.length - 1 && (
                <Box
                  style={{
                    backgroundColor: this.getLineColor(index),
                    width: "54px",
                    height: "2px",
                  }}
                ></Box>
              )}
            </Box>
          ))}
        </Box>
      </StepperBox>
    );
  };

  renderForm = () => {
    switch (this.state.activeStep) {
      case 1:
        return this.renderDTwoForm()
      case 2:
        return this.renderD3Form()
      case 3:
        return <RootCauseInvestigation navigation={""} handlePrev={this.handlePrev} handleNext={this.handleNext} />
      case 4:
        return <RootCauseValidation navigation={""} handlePrev={this.handlePrev} handleNext={this.handleNext}/>
      case 5:
        return <VarifyActionD6 navigation={""} handlePrev={this.handlePrev} handleNext={this.handleNext}/>
      case 6: 
      return <PreventOccurrence navigation={""} handlePrev={this.handlePrev} handleNext={this.handleNext}/>
      case 7: 
        return <IncidentReportingD8 navigation={this.props.navigation} functionData={this.state.functionData} handlePrev={this.handlePrev}/>
    }
  }

  renderListingOfReporting = () => {
    const { isListBoxClicked, selectedIncedent } = this.state
    return (
      <Box>
        <Box display="flex" alignItems="center">
          <Typography
            style={webStyles.breadcrumbText}
            data-test-id="breadCrumbText"
            onClick={() => {
              this.setState({ isListBoxClicked: false, selectedIncedent: null, listOfIncidentProgress: [] });
            }}
          >
            Reporting & Investigation
          </Typography>
          {isListBoxClicked && (
            <React.Fragment>
              <img src={arrowRightMobile} style={{ margin: "0 5px" }} width={13} height={13}/>
              <Typography style={webStyles.incidentTitle}>
                {selectedIncedent?.attributes?.iir_no}
              </Typography>
            </React.Fragment>
          )}
        </Box>
        {!isListBoxClicked && this.renderListBoxNotClickedData()}
        {isListBoxClicked && this.renderBoxClickedData()}
      </Box>
    );
  };

  renderListBoxNotClickedData = () => {
    return (
      <React.Fragment>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          marginTop={"10px"}
        >
          <Typography style={webStyles.headerText}>
            Reporting & Investigation
          </Typography>
          <img
            src={buttonFilterIcon}
            width={28}
            height={28}
            style={{ cursor: "pointer" }}
            data-test-id="filterIconTestId"
            onClick={() => {
              this.setState({
                isFilterOpen: true,
                backupSelectedFilterStatus: [...this.state.selectedFilterStatus],
              });
            }}
          />
        </Box>
        <Box marginTop={"20px"}>{this.renderListing()}</Box>
      </React.Fragment>
    );
  };

  renderBoxClickedData = () => {
    const { selectedIncedent } = this.state
    return (
      <React.Fragment>
        <Box marginTop={"10px"}>
          <Typography style={webStyles.headerText}>
            {selectedIncedent?.attributes?.iir_no}
          </Typography>
        </Box>
        <Box
          marginTop={"20px"}
          style={webStyles.listBoxClickedBox}
        >
          {this.renderListOfIncedentProgress()}
        </Box>
        <Box
          display={"flex"}
          justifyContent={"flex-end"}
          marginTop={"30px"}
        >
          <Button
            data-test-id="countinueInvestigationButton"
            variant="contained"
            style={{
              ...webStyles.countinueButton,
              textTransform: "none",
              whiteSpace: "nowrap",
              width: "auto",
            }}
            onClick={() => {
              this.handleContinueClick()
            }}
          >
            Continue Investigation
          </Button>
        </Box>
      </React.Fragment>
    );
  };

  renderListOfIncedentProgress = () => {
    const { listOfIncidentProgress } = this.state;
    return (
      <Grid container spacing={1}>
        {listOfIncidentProgress.map((item,index) => (
          <Grid item xs={12} key={item.id}>
            <Box
              data-test-id="itemFromIncedentList"
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
              padding={"10px"}
              bgcolor={"#F8F8F8"}
              style={{
                cursor: "pointer"
              }}
              onClick={() => {
                this.handleClickItemFromIncedent(index)
              }}
            >
              <Box display="flex" alignItems="center">
                <Typography style={webStyles.listText}>
                  {item.id}: {item.title}
                </Typography>
                {item.completed && (
                  <img
                    src={selectLanguage}
                    width={24}
                    height={24}
                  />
                )}
              </Box>
              <ArrowForwardIos
                style={{ color: "#2B2A29", fontSize: 14 }}
              />
            </Box>
          </Grid>
        ))}
      </Grid>
    );
  };

  renderFilterModal = () => {
    const {
      isFilterOpen,
      filterStatusOptions,
      selectedFilterStatus,
    } = this.state;
    return (
      <Modal
        open={isFilterOpen}
        onClose={() => this.filterModalClose()}
        style={webStyles.modalStyle}
        BackdropComponent={Backdrop}
      >
        <Box style={webStyles.boxModalStyle}>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography style={webStyles.filterHeaderText}>
              Filters
            </Typography>
            <img
              src={modalClose}
              data-test-id="closeModalFilter"
              onClick={() => {
                this.filterModalClose();
              }}
              width={12}
              height={12}
              style={{ cursor: "pointer" }}
            />
          </Box>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            marginTop={"15px"}
          >
            <Typography style={webStyles.filterStatusText}>
              Status
            </Typography>
            <Typography
              style={webStyles.filterClearText}
              data-test-id="clearButton"
              onClick={() => {
                this.handleClearFilter();
              }}
            >
              Clear
            </Typography>
          </Box>
          <Box marginTop={"15px"}>
            <Box
              style={{
                display: "flex",
                gap: "10px",
                flexWrap: "wrap",
              }}
            >
              {filterStatusOptions.map((status) => (
                <Chip
                  data-test-id="chipFilter"
                  key={status.key}
                  label={
                    <Box
                      component="span"
                      style={{
                        display: "inline-block",
                        marginTop: "3px",
                      }}
                    >
                      {status.key}
                    </Box>
                  }
                  clickable
                  icon={
                    selectedFilterStatus.includes(status.value) ? (
                      <Check
                        style={{ color: "#fff", fontSize: 18 }}
                      />
                    ) : (
                      <Add
                        style={{ color: "#888", fontSize: 18 }}
                      />
                    )
                  }
                  onClick={() =>
                    this.handleFilterStatusClick(status.value)
                  }
                  style={{
                    backgroundColor: selectedFilterStatus.includes(
                      status.value
                    )
                      ? "#8735E1"
                      : "#fff",
                    color: selectedFilterStatus.includes(status.value)
                      ? "#fff"
                      : "#A1A1A1",
                    borderRadius: "8px",
                    padding: "6px",
                    height: 38,
                    fontWeight: 400,
                    fontSize: "14px",
                    border: selectedFilterStatus.includes(status.value)
                      ? "none"
                      : "1px solid #DCDCDC",
                  }}
                />
              ))}
            </Box>
          </Box>
          <Box
            display={"flex"}
            justifyContent={"flex-end"}
            style={{ gap: "1rem" }}
            marginTop={"200px"}
          >
            <Button
              data-test-id="clearAllButton"
              onClick={() => this.handleClearAllFilter()}
              variant="outlined"
              style={{
                ...webStyles.clearAllButton,
                textTransform: "none",
              }}
            >
              Clear All
            </Button>
            <Button
              data-test-id="applyFilterButton"
              onClick={() => this.handleApplyFilter()}
              variant="contained"
              style={{
                ...webStyles.applyButton,
                textTransform: "none",
              }}
            >
              Apply
            </Button>
          </Box>
        </Box>
      </Modal>
    );
  };

  getStatusColor = (status: string, faded = false) => {
    switch (status) {
      case 'Reported':
        return faded ? '#FFCCCC' : '#FA0000';
      case 'Under Investigation':
        return faded ? '#FFE5B4' : '#DF9300';
      case 'Concluded':
        return faded ? '#B2F2D2' : '#009A4F'; 
      default:
        return faded ? '#F5F5F5' : '#e0e0e0';
    }
  };

  renderListing = () => {
    const { incedentListData, isListDataLoading } = this.state

    if(isListDataLoading){
      return <Loader loading={isListDataLoading} />;
    }
    return (
      <Grid container spacing={2}>
        {incedentListData.length > 0 && incedentListData.map((incident) => (
          <Grid item xs={12} sm={6} md={4} key={incident.id}>
            <Box
              data-test-id="listingBox"
              style={webStyles.listingBox}
              onClick={() => {
                this.handleClickListBox(incident)
              }}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography style={webStyles.listingTitleText}>
                  {incident?.attributes?.iir_no}
                </Typography>
                <Chip
                  label={incident?.attributes?.incident_status}
                  size="small"
                  style={{
                    ...webStyles.listingStatusChip,
                    backgroundColor: this.getStatusColor(incident?.attributes?.incident_status, true),
                    color: this.getStatusColor(incident?.attributes?.incident_status),
                  }}
                />
              </Box>
              <Box display="flex" alignItems="center" mt={"10px"}>
                <img
                  src={imageUser}
                  width={14}
                  height={14}
                  style={{ marginRight: "12px" }}
                />
                <Typography style={webStyles.cftLeaderText}>
                  CFT Leader: {incident?.attributes?.cft_leader?.full_name}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" mt={"10px"}>
                <img
                  src={imageCalender}
                  width={14}
                  height={14}
                  style={{ marginRight: "12px" }}
                />
                <Typography style={webStyles.cftLeaderText}>
                  Incident Happened on: {incident?.attributes?.created_at && moment(incident?.attributes?.created_at).format("DD/MM/YYYY")}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" mt={"10px"}>
                <img
                  src={imageLocation}
                  width={14}
                  height={14}
                  style={{ marginRight: "12px" }}
                />
                <Typography style={webStyles.cftLeaderText}>
                  Incident Location: {incident?.attributes?.incident_location}
                </Typography>
              </Box>
            </Box>
          </Grid>
        ))}
        {incedentListData.length === 0 && (
          <Grid item xs={12} style={{...webStyles.headerText, textAlign: 'center'}}>
            No data available!!
          </Grid>
        )}
      </Grid>
    );
  }

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <React.Fragment>
        {!this.state.isCountinueButtonClicked && (
          <Box style={webStyles.mainBoxListing}>
            {this.renderListingOfReporting()}
            {this.renderFilterModal()}
          </Box>
        )}
        {this.state.isCountinueButtonClicked && (
          <MainBox>
            <Loader loading={this.state.isLoader} />
            <Snackbar
              open={this.state.isAlert}
              autoHideDuration={3000}
              onClose={this.alertClose}
              data-test-id="alertTestId"
            >
              <Alert severity="success">{this.state.alertMsg}</Alert>
            </Snackbar>
            {this.renderMainBox()}
            {this.renderForm()}
          </MainBox>
        )}
      </React.Fragment>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const MainBox = styled(Box)(({ theme }) => ({  "& .inputFieldDate": {
    width: "145%",
    justifyContent: "center",
    padding: "0px 10px",
    borderRadius: "8px",
    margin: "5px 0px",
    display: "flex",
    height: "48px",
    border: "1px solid #CBD5E1",
    boxSizing: "border-box",
    fontSize: "14px",
    fontFamily: "clotherRegular",
    fontWeight: "bold",
    "& .MuiInput-root": {
      "&:focus": {
        outline: "none",
      },
      "&::before": {
        content: "none",
      },
      "&::after": {
        content: "none",
      },
    },
    [theme.breakpoints.down(1178)]: {
      width: "100%",
    },
  },
  "& .addBtn": {
    marginTop: "20px",
    boxSizing: "border-box",
    textTransform: "capitalize",
    color: "#8735E1",
    backgroundColor: "#FFF",
    border: "1px solid #8735E1",
    width: "100%",
    fontFamily: "clotherRegular",
    height: '45px',
    fontWeight: "bold"
  },
  "& .inputField": {
    width: "100%",
    border: "1px solid #CBD5E1",
    borderRadius: "8px",
    height: "45px",
    margin: "5px 0px",
    display: "flex",
    justifyContent: "center",
    padding: "0px 10px",
    boxSizing: "border-box",
    fontSize: "14px",
    fontFamily: "clotherRegular",
    fontWeight: "bold",
    "& .MuiInput-root": {
      "&:focus": {
        outline: "none",
      },
      "&::before": {
        content: "none",
      },
      "&::after": {
        content: "none",
      },
    },
  },
  "& .inputFieldDropDown": {
    width: "100%",
    margin: "5px 0px",
    border: "1px solid #CBD5E1",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "cente r",
    padding: "0px 10px",
    height: "50px",
    fontFamily: "clotherRegular",
    boxSizing: "border-box",
    "& .MuiSelect-icon": {
      color: "#0E172A",
      right: "5px"
    },
    "& .MuiInput-root": {
      "&:focus": {
        outline: "none",
      },
      "&::after": {
        content: "none",
      },
      "&::before": {
        content: "none",
      },
    },
    "&.MuiInput-underline:before": {
      borderBottom: "0px solid red",
    },
    "&.MuiInput-underline:after": {
      borderBottom: "0px solid red",
    },
    "&:hover:not(.Mui-disabled):after": {
      borderBottom: "0px solid red",
    },
    "&:hover:not(.Mui-disabled):before": {
      borderBottom: "0px solid red",
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "transparent",
    },
  },
  border: "10px solid rgb(238, 238, 238)",
  padding: "20px",
  "& .errorTxt": { color: "red" },
  "& .headerText": {
    margin: "15px 0px",
    color: "#2B2A29",
    fontWeight: 700,
    fontFamily: "clotherRegular",
    fontSize: "22px"
  },
  "& .formWrapper": {
    padding: "10px 20px",
    backgroundColor: "#FFFFFF",
  },
  "& .headingTxtWrapper": {
    display: "flex", 
    alignItems: "center", 
    height: "60px", 
    backgroundColor: "#FAF3FD", 
    paddingLeft: "30px",
  },
  "& .headingTxt": {
    color: '#2B2A29',
    fontSize: "17px",
    fontFamily: "clotherRegular",
  },
  "& .subClass": {
    color: '##A1A1A1',
    fontSize: "14px",
    fontFamily: "clotherRegular",
  },
  "& .prevBtn": {
    borderRadius: "8px",
    width: "160px",
    height: "50px",
    color: "#8735E1",
    fontSize: "20px",
    fontWeight: 700,
    lineHeight: "22px",
    textTransform: "none",
    border: "1px solid #8735E1",
    fontFamily: "'ClotherRegular'",
  },
  "& .nextBtn": {
    width: "160px",
    textTransform: "none",
    fontSize: "20px",
    fontWeight: 700,
    lineHeight: "22px",
    height: "50px",
    color: "#FFFFFF",
    backgroundColor: "#8735E1",
    borderRadius: "8px",
    fontFamily: "'ClotherRegular'",
  },

  // breadcrumb css
  "& .breadcrumbWrapper": {
      display: 'flex',
    },
    "& .breadcrumbItem": {
      fontFamily: 'ClotherRegular',
      fontSize: '16px',
      lineHeight: '22px',
      color: '#A1A1A1',
      fontWeight: 600,
      cursor: 'pointer',
    },
    "& .navigateIcon": {
      fill: '#DCDCDC'
    },
    "& .breadcrumbActiveItem": {
      color: "#8735E1",
      fontSize: "16px",
      lineHeight: "22px",
      fontFamily: 'ClotherBold',
      fontWeight: 700,
      cursor: "pointer",
    },

  }));

const StepperBox = styled(Box)({
  paddingBottom: "15px",
  display: "flex",
  flexDirection: "column",
  "& .countActive": {
    color: "#FFF",
    background: "#8735E1",
    padding: "5px",
    borderRadius: "50%",
    width: "20px",
    height: "20px",
    textAlign: "center"
  },
  "& .countDeActive": {
    color: "#A1A1A1",

    width: "20px",
    height: "20px",
    textAlign: "center"
  },

})


export const CustomMenuItem = styled(MenuItem)({
  "&:hover": {
    backgroundColor: "#F9F3FF",
    color: "#8735E1",

  },

});

const webStyles = {
  listText: {
    fontSize: "20px",
    fontWeight: 700,
    color: "#2B2A29",
    fontFamily: "clotherRegular",
    marginBottom: "5px",
    marginRight: "16px"
  },
  clearAllButton: {
    border: "1px solid #8735E1",
    borderRadius: "8px",
    backgroundColor: "#fff",
    fontWeight: 700,
    fontFamily: "clotherRegular",
    fontSize: "20px",
    color: "#8735E1",
    width:"160px",
    height:"50px"
  },
  applyButton: {
    borderRadius: "8px",
    backgroundColor: "#8735E1",
    fontWeight: 700,
    fontSize: "20px",
    fontFamily: "clotherRegular",
    color: "#fff",
    width:"160px",
    height:"50px",
  },
  countinueButton: {
    borderRadius: "8px",
    backgroundColor: "#8735E1",
    fontWeight: 700,
    fontSize: "20px",
    fontFamily: "clotherRegular",
    color: "#fff",
    height:"50px",
  },
  filterStatusText: {
    fontSize: "1.125rem",
    fontWeight: 400,
    color: "black",
    fontFamily: "clotherRegular",
  },
  filterClearText: {
    fontSize: "0.875rem",
    fontWeight: 400,
    color: "#8735E1",
    fontFamily: "clotherRegular",
    cursor:"pointer"
  },
  breadcrumbSeparator: {
    margin: "0 5px",
    fontSize: "14px",
    color: "#DCDCDC",
  },
  incidentTitle: {
    fontSize: "14px",
    color: "#8735E1",
    fontWeight: 700,
    cursor: "pointer",
    fontFamily: "clotherRegular",
  },
  boxModalStyle: {
    backgroundColor: '#fff',
    borderRadius: 8,
    boxShadow: '0px 3px 6px rgba(0,0,0,0.1)',
    padding: "25px",
    width: 620,
  },
  modalStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  listingStatusChip: {
    fontSize: "0.75rem",
    fontWeight: 700,
    fontFamily: "clotherRegular",
    padding: "6px 5px"
  },
  listingTitleText: {
    fontSize: "1rem",
    fontWeight: 700,
    color: "#2B2A29",
    fontFamily: "clotherRegular",
  },
  cftLeaderText: {
    fontSize: "1rem",
    fontWeight: 400,
    color: "#A1A1A1",
    fontFamily: "clotherRegular",
  },
  listingBox: {
    borderRadius: "8px",
    border: "1px solid #DCDCDC",
    padding: "1rem",
    backgroundColor: "#fff",
    cursor: 'pointer'
  },
  chipStyle: {
    margin: 4,
    backgroundColor: '#EDEDED',
  },
  chipSelectedStyle: {
    backgroundColor: '#7B61FF',
    color: '#fff',
    margin: 4,
  },
  filterHeaderText: {
    fontSize: "1.5rem",
    fontWeight: 700,
    color: "#2B2A29",
    fontFamily: "clotherRegular",
  },
  headerText: {
    fontSize: "1.625rem",
    fontWeight: 700,
    color: "#2B2A29",
    fontFamily: "clotherRegular",
  },
  breadcrumbText: {
    fontSize: "0.875rem",
    fontWeight: 400,
    color: "#A1A1A1",
    fontFamily: "clotherRegular",
    cursor: "pointer"
  },
  mainBoxListing: {
    padding: "30px"
  },
  tableCellFont: {
    fontFamily: "clotherRegular",
    fontWeight: 700,
    fontSize: "1rem",
    color: "#fff"
  },
  listBoxClickedBox:{
    backgroundColor: "#fff",
    padding: "30px"
  }
}
// Customizable Area End
