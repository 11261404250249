import React from "react";
// Customizable Area Start
import { 
    Box,
    Button,
    Checkbox,
    Container,
    Divider,
    FormControlLabel,
    Grid,
    Input,
    Radio,
    RadioGroup,
    Snackbar,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    ThemeProvider, 
    Typography, 
    createTheme, 
    styled 
} from "@material-ui/core";
import SafetyInteractionSelectionController,
{
    Props
} from "./SafetyInteractionSelectionController.web";
import { NavigateNext } from "@material-ui/icons";
import { webStyles } from "./SafetyInteraction.web";
import CustomSelect from "../../../../components/src/CustomSelect.web";
import { webStyle } from "../../../dashboard/src/postCreationPopUp/styles";
import Alert from "@material-ui/lab/Alert";

const theme = createTheme({
    typography: {
        h2: {
            fontFamily: "ClotherRegular",
            fontSize: "26px",
            fontWeight: 700,
            lineHeight: "32px",
            color: "#2B2A29",
        },
        h6: {
            fontFamily: "ClotherRegular",
            fontSize: "20px",
            fontWeight: 700,
            lineHeight: "22px",
            color: "#18181E",
        },
        subtitle2: {
            fontFamily: "ClotherRegular",
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: "22px",
            color: "#2B2A29",
        }
    },
    overrides: {
        MuiRadio: {
            colorSecondary: {
                '&$checked': {
                  color: "#9519EF",
              },
              }
        },
        MuiCheckbox: {
            colorSecondary: {
                '&$checked': {
                  color: "#9519EF",
              },
              }
        }
    }
});
  

  // Customizable Area End
export default class SafetyInteractionSelection extends SafetyInteractionSelectionController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

    // Customizable Area Start
    renderSafetyInteractionForm = () => {
        const formSections = this.state.observationCategoriesData.filter(section => 
            section.items.some(item => item.status === "at_risk")
        );
        return (
            <form>
                {formSections.map((sec, categoryIndex) => (
                    <Box key={categoryIndex}>
                        <Box className="sectionTitle">
                            <Typography variant="h6" data-test-id="sectionTitle" gutterBottom>
                                {sec.section}
                            </Typography>
                        </Box>
                        <Box className="formFieldsWrapper">
                            {sec.items.filter((item) => item.status === "at_risk").map((item, itemIndex) => (
                                <Grid container spacing={2} key={itemIndex}>
                                    <Grid item xs={12} md={6}>
                                        <Typography className="fieldLabelText">Condition</Typography>
                                        <Input
                                            placeholder="Condition"
                                            fullWidth
                                            type="text"
                                            disableUnderline
                                            name="condition"
                                            className="inputField disabledInput"
                                            disabled
                                            value={sec.items[0].name}
                                            data-test-id="condition"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Typography className="fieldLabelText">On the Spot Close (Positive Action)</Typography>
                                        <Box className="radioGroupWrapper">
                                            <RadioGroup
                                                row
                                                value={item.in_the_spot_close}
                                                data-test-id="radioBtnSpotClose"
                                                onChange={(event) => this.handleInputChange(sec.form_name, item.name, event)}
                                                className="radioGroup"
                                                name="in_the_spot_close"
                                            >
                                                <FormControlLabel disabled={!this.state.isEditable} value={"true"} control={<Radio color="secondary" />} label="Yes" />
                                                <FormControlLabel disabled={!this.state.isEditable} value={"false"} control={<Radio color="secondary" />} label="No" />
                                            </RadioGroup>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider style={{ margin: "14px -30px", color: "#F3F3F3"}}/>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Typography className="fieldLabelText">Condition</Typography>
                                        <Input
                                            placeholder="Condition"
                                            fullWidth
                                            type="text"
                                            disableUnderline
                                            disabled
                                            name="condition1"
                                            className="inputField disabledInput"
                                            value={item.name}
                                            data-test-id="condition1"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Typography className="fieldLabelText">Risk Potential</Typography>
                                        <CustomSelect
                                            placeholder="Select Risk Potential"
                                            onChange={(event) => this.handleInputChange(sec.form_name, item.name, event)}
                                            className={`selectField ${this.state.isSafetyManager && !this.state.isEditable ? "disabledInput" : ""}`}
                                            options={[
                                                { name: "High", value: "high" },
                                                { name: "Medium", value: "medium" },
                                                { name: "Low", value: "low" },
                                            ]}
                                            value={item.risk_potential}
                                            name="risk_potential"
                                            data-test-id="riskPotential"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Box display="flex" justifyContent="space-between" mb={1}>
                                            <Box>
                                                <Typography className="fieldLabelText">Comments</Typography>
                                            </Box>
                                            <Box>
                                                <img
                                                    height="21px"
                                                    width="21px"
                                                    src={this.renderMic(item.name)}
                                                    onClick={async () => {
                                                        await this.handleSpeech(item.name);
                                                        this.setMikeData(sec.form_name, item.name, "comment")
                                                    }}
                                                    alt="mic icon"
                                                    data-test-id="speechTextComments"
                                                />
                                            </Box>
                                        </Box>
                                        <Box>
                                        <TextField
                                            data-test-id="comments"
                                            style={{
                                                ...webStyle.textareaStyle,
                                                width: "93%"
                                            }}
                                            placeholder="Comments"
                                            maxRows={3}
                                            minRows={3}
                                            multiline
                                            value={item.comment}
                                            InputProps={{ disableUnderline: true }}
                                            onChange={(event) => this.handleInputChange(sec.form_name, item.name, event)}
                                            className={`textarea ${this.state.isSafetyManager && !this.state.isEditable ? "disabledInput" : ""}`}
                                            name="comment"
                                        />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Box display="flex" justifyContent="space-between" mb={1}>
                                            <Box>
                                                <Typography className="fieldLabelText">Create Action</Typography>
                                            </Box>
                                            <Box>
                                                <img
                                                    height="21px"
                                                    width="21px"
                                                    src={this.renderMic(item.name)}
                                                    onClick={async () => {
                                                        await this.handleSpeech(item.name);
                                                        this.setMikeData(sec.form_name, item.name, "action")
                                                    }}
                                                    alt="mic icon"
                                                    data-test-id="speechTextAction"
                                                />
                                            </Box>
                                        </Box>
                                        <TextField
                                            data-test-id="createAction"
                                            style={{
                                                ...webStyle.textareaStyle,
                                                width: "93%"
                                            }}
                                            placeholder="Audit Finding / Problem"
                                            maxRows={3}
                                            minRows={3}
                                            multiline
                                            value={item.action}
                                            InputProps={{ disableUnderline: true }}
                                            onChange={(event) => this.handleInputChange(sec.form_name, item.name, event)}
                                            className={`textarea ${this.state.isSafetyManager && !this.state.isEditable ? "disabledInput" : ""}`}
                                            name="action"
                                        />
                                    </Grid>
                                </Grid>
                            ))}
                        </Box>
                    </Box>
                ))}
            </form>
        );
    }

    renderSnackbar() {
              const { responseStatus, errorMsg } = this.state;
              if (!errorMsg) return null;
            
              return (
                <Snackbar
                  data-test-id="snackbar"
                  open={responseStatus !== null}
                  onClose={this.handleCloseSnackbar}
                  autoHideDuration={3000}
                >
                  <Alert
                    onClose={this.handleCloseSnackbar}
                    severity={responseStatus || undefined}
                  >
                    {errorMsg}
                  </Alert>
                </Snackbar>
              );
            }
    // Customizable Area End

  render() {
     // Customizable Area Start
    const { screen, isSafetyManager, isEditable } = this.state;
    return (
        <ThemeProvider theme={theme}>
            {this.renderSnackbar()}
            <MainContainer>
                <Box mb={2} style={webStyles.breadcrumbWrapper1}>
                    <Typography
                        style={webStyles.breadcrumbItem1}
                        data-test-id="navigateSafetyInteraction"
                        onClick={() => this.handleNavigation("SafetyInteraction")}
                    >Safety Interaction</Typography>
                    <NavigateNext style={webStyles.navigateIcon1} />
                    <Typography 
                        style={webStyles.breadcrumbItem1}
                        data-test-id="navigateSafetyInteractionNumber"
                        onClick={() => this.handleNavigation("SafetyInteractionObservationCategories")}
                    >
                        Safety Interaction Number
                    </Typography>
                    <NavigateNext style={webStyles.navigateIcon1} />
                    <Typography style={webStyles.breadcrumbActiveItem1} data-test-id="breadcrumbActiveItem">
                        {screen}
                    </Typography>
                </Box>
                <ThemeProvider theme={theme}>
                    <Box mb={2}>
                        <Typography variant="h2" className="titleText" data-test-id="titleText">
                            Safety Interaction
                        </Typography>
                    </Box>
                    <Container className="container">
                        {screen === "Selection" ? (
                            <Box>
                                {this.state.observationCategoriesData.map((obs, categoryIndex) => (
                                    <Box key={categoryIndex}>
                                        <Box className="sectionTitle">
                                            <Typography variant="h6" gutterBottom>
                                                {obs.section}
                                            </Typography>
                                        </Box>
                                        <Box className="tableWrapper">
                                            <TableContainer className="tableContainer">
                                                <Table className="tableEle">
                                                    <TableHead className="tableHeader">
                                                        <TableRow>
                                                            <TableCell className="tableHeaderCell" style={{ width: "255px" }}>Title</TableCell>
                                                            <TableCell className="tableHeaderCell">Select Appropriate Option</TableCell>
                                                            <TableCell className="tableHeaderCell">Check If Positive Action Done</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {obs.items.map((item, itemIndex) => (
                                                            <TableRow key={item.name}>
                                                                <TableCell className="tableRowCell" style={{ width: "255px" }}>
                                                                    <Typography variant="subtitle2" style={{ marginLeft: "8px" }}>
                                                                        {item.name}
                                                                    </Typography>
                                                                </TableCell>
                                                                <TableCell className="tableRowCell">
                                                                    <RadioGroup
                                                                        row
                                                                        value={item.status}
                                                                        data-test-id="radioBtn"
                                                                        onChange={(e) => this.handleRadioChange(categoryIndex, itemIndex, e.target.value)}
                                                                        className="radioGroup">
                                                                        <FormControlLabel disabled={!isEditable} value="safe" control={<Radio color="secondary" />} label="Safe" />
                                                                        <FormControlLabel disabled={!isEditable} value="at_risk" control={<Radio color="secondary" />} label="At Risk" />
                                                                    </RadioGroup>
                                                                </TableCell>
                                                                <TableCell className="tableRowCell">
                                                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                                                        <Checkbox
                                                                            checked={item.positive_action}
                                                                            data-test-id="checkbox"
                                                                            onChange={() => this.handleCheckboxChange(categoryIndex, itemIndex)}
                                                                            color="secondary"
                                                                            disabled={!isEditable}
                                                                        />
                                                                        <Typography variant="subtitle2" style={{ marginLeft: "8px" }}>Positive Actions</Typography>
                                                                    </Box>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Box>
                                    </Box>
                                ))}
                            </Box>
                        ): (
                            this.renderSafetyInteractionForm()
                        )}
                    </Container>
                    <Box className="actionButtons" >
                        <Box>
                            <Button
                                data-test-id="prevButton" 
                                className="prevButton" 
                                onClick={() => this.handlePrev()}
                            >Previous</Button>
                        </Box>
                        <Box style={{ display: "flex", gap: "20px" }}>
                            <Button 
                                className="saveButton"
                                data-test-id="saveButton"
                                onClick={() => this.handleSaveOrEdit()}
                            >
                                {isSafetyManager && !isEditable ? "Edit" : "Save"}
                            </Button>
                            <Button
                                className="nextButton" 
                                data-test-id="nextButton"
                                onClick={() => this.handleNext()}
                            >Next</Button>
                        </Box>
                    </Box>
                </ThemeProvider>
            </MainContainer>
        </ThemeProvider>
    );
    // Customizable Area End
  }
}
// Customizable Area Start
const MainContainer = styled(Box)({
    height: "100vh",
    backgroundColor: "#F3F3F3",
    padding: "30px",

    "& .container": {
        background: "#fff",
        boxShadow: "4px 0px 4px 0px rgba(0, 0, 0, 0.02)",
        borderRadius: "8px",
        padding: "0px",
        paddingBottom: "30px",
        marginBottom: "30px",
        maxWidth: "100%",
        margin: "0px",
      },

      "& .sectionTitle": {
        backgroundColor: "#FAF3FD", 
        height: "60px", 
        display: "flex", 
        alignItems: "center",
        paddingLeft: "30px",
        marginBottom: "30px",
      },

      "& .tableWrapper": {
        marginBottom: "20px",
        padding: "0px 30px",
        boxSizing: "border-box",
      },

      "& .tableContainer": {
        borderTopLeftRadius: "8px",
        borderTopRightRadius: "8px",
      },

      "& .tableEle": {
        backgroundColor: "#F8F8F8 !important",
        borderBottom: "1px solid #EEEEEE",
        borderRadius: "8px",
      },
    
        "& .tableHeader": {
            backgroundColor: "#6a0dad",
            border: "1px solid transperant",
            borderTopLeftRadius: "8px",
            borderTopRightRadius: "8px",
        },


      "& .tableHeaderCell":  {
          fontFamily: "'ClotherRegular'",
          fontSize: "16px",
          fontWeight: 700,
          lineHeight: "18px",
          color: "#FFFFFF",
      },

      "& .tableRowCell": {
        color: "#2B2A29",
        fontFamily: "'ClotherRegular'",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "22px",
        padding: "12px",
      },

      "& .tableRow": {
        backgroundColor: "#F8F8F8",
        border: "0.5px solid #EEEEEE",
      },
    
      "& .radioGroup": {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      },

      "& .formFieldsWrapper": {
        padding: "30px",
      },

      "& .radioGroupWrapper": {
        width: "145px",
      },

    "& .inputField": {
        border: "1px solid #DCDCDC",
        backgroundColor: "#FFFFFF",
        borderRadius: "8px",
        height: "54px",
        padding: "16.5px",
    },

    "& .disabledInput": {
        backgroundColor: "#F3F3F3 !important",
    },

    "& .selectField": {
        backgroundColor: "#FFFFFF",
        borderRadius: "8px",
        height: "54px",
        padding: "16.5px",
    },

    
    "& .fieldLabelText": {
        color: "#2B2A29",
        fontWeight: 400,
        fontFamily: "clotherRegular",
        fontSize: "16px",
        lineHeight: "18px",
        marginBottom: "10px",
    },


      "& .actionButtons": {
        display: "flex",
        justifyContent: "space-between",
        marginTop: "20px",
        paddingBottom: "40px",
      },
      "& .prevButton, .saveButton": {
          height: "50px",
          width: "160px",
          border: "1px solid #8735E1",
          textTransform: "none",
          color: "#8735E1",
          borderRadius: "8px",
          fontSize: "20px",
          fontFamily: "'ClotherRegular'",
          lineHeight: "22px",
          fontWeight: 700,
      },
      "& .nextButton": {
          height: "50px",
          width: "160px",
          fontFamily: "'ClotherRegular'",
          color: "#FFFFFF",
          fontSize: "20px",
          fontWeight: 700,
          lineHeight: "22px",
          borderRadius: "8px",
          backgroundColor: "#8735E1",
          textTransform: "none",
      },
})
// Customizable Area End