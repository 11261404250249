import React from "react";
// Customizable Area Start
import { 
  Box,
  Button,
  Container,
  Grid,
  ThemeProvider, 
  Typography, 
  createTheme, 
  styled } from "@material-ui/core";
import SafetyInteractionObservationCategoriesController,{Props} from "./SafetyInteractionObservationCategoriesController.web";
import { webStyles } from "./SafetyInteraction.web";
import { NavigateNext } from "@material-ui/icons";

const theme = createTheme({
  overrides: {
    MuiTypography: {
      h2: {
        fontFamily: "ClotherRegular",
        fontSize: "26px",
        fontWeight: 700,
        lineHeight: "32px",
        color: "#2B2A29",
      },
      h6: {
        fontFamily: "ClotherRegular",
        fontSize: "18px",
        fontWeight: 400,
        lineHeight: "16px",
        color: "#2B2A29",
      },
    },
  },
});
  // Customizable Area End


export default class SafetyInteractionObservationCategories extends SafetyInteractionObservationCategoriesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

    // Customizable Area Start
    // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <ThemeProvider theme={theme}>
        <MainContainer>
            <Box mb={2} style={webStyles.breadcrumbWrapper1}>
              <Typography
                style={webStyles.breadcrumbItem1}
                data-test-id="navigateSafetyInteraction"
                onClick={() => this.handleNavigation("SafetyInteraction")}
              >Safety Interaction</Typography>
              <NavigateNext style={webStyles.navigateIcon1} />
              <Typography style={webStyles.breadcrumbActiveItem1}>
                Safety Interaction Number
              </Typography>
            </Box>
            <Box mb={2}>
            <Typography variant="h2" className="titleText" data-test-id="titleText">
              Safety Interaction
            </Typography>
            </Box>
            <Container className="container">
            <Box>
              <Box mb={3} className="observationCategoriesHeader">
                <Box>
                  <Typography variant="h6" gutterBottom className="observationTitleText">
                    Observation Categories
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="h6" gutterBottom className="observationTitleText">
                    Observed?
                  </Typography>
                </Box>
              </Box>
              <Grid container spacing={2} style={{ gap: "12px" }}>
                {this.state.observationCategories.map((obs) => (
                  <Grid item xs={12} key={obs.category} className="observationItem">
                    <Typography className="observationItemLabel">{`${obs.category}: ${obs.label}`}</Typography>
                    <Typography className="observationItemText">{obs.observed ? "Yes" : "No"}</Typography>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Container>
          <div className="buttonWrapper">
            <Button 
              variant="contained" 
              className="continueButton"
              onClick={() => this.handleNavigation("SafetyInteractionSelection")}
            >
              Continue Investigation
            </Button>
          </div>
        </MainContainer>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const MainContainer = styled(Box)({
  height: "100vh",
  backgroundColor: "#F8F8F8",
  padding: "30px",

  "& .container": {
    background: "#fff",
    borderRadius: "8px",
    boxShadow: "4px 0px 4px 0px rgba(0, 0, 0, 0.02)",
    padding: "30px",
    margin: "0px",
    marginBottom: "30px",
    maxWidth: "100%",
  },

  "& .titleText": {
    fontFamily: "ClotherRegular",
    fontSize: "26px",
    fontWeight: 700,
    lineHeight: "32px",
    color: "#2B2A29",
  },

  "& .observationTitleText": {
    fontFamily: "ClotherRegular",
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#2B2A29",
  },

  "& .observationCategoriesHeader": {
    display: "flex",
    justifyContent: "space-between",
  },

  "& .observationItem": {
    display: "flex",
    height: "60px",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "12px 16px",
    background: "#F8F8F8",
    borderRadius: "6px",
  },

  "& .observationItemLabel": {
    fontFamily: "ClotherRegular",
    fontSize: "20px",
    fontWeight: 700,
    lineHeight: "24px",
    color: "#2B2A29",
  },

  "& .observationItemText": {
    fontFamily: "ClotherRegular",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "16px",
    color: "#2B2A29",
    marginRight: "18px",
  },

  "& .buttonWrapper": {
    display: "flex",
    justifyContent: "flex-end",
  },

  "& .continueButton": {
    height: "50px",
    backgroundColor: "#8735E1",
    fontFamily: "ClotherRegular",
    fontSize: "20px",
    fontWeight: 700,
    lineHeight: "22px",
    color: "#FFFFFF",
    textTransform: "none",
  },
})
// Customizable Area End