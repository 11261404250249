import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { PostSettings } from "./postCreationPopUp/PostCreationPopUp.web";
import { Notifications } from "../../../components/src/types";
import { getStorageData } from "framework/src/Utilities";
import { setStorageData } from "../../../framework/src/Utilities";
import { ChangeEvent } from "react";
import i18n from "i18next"
import { PostData } from "./ProfileController.web";
import moment from "moment";
export const configJSON = require("./config.js");

export interface PostDataBody {
  attributes: {
    operation_identification_id: number | null,
    post_concern_id: number | null,
    hazard_id: number | null,
    frequency_id: number | null,
    severity_id: number | null,
    consequence_id: number | null,
    risk_level_name: string,
    status: string,
    id?: string,
    is_empty?: boolean,
  }
}

export interface PostRes {
  data: PostData[],
  meta: NotificationMeta,
}

export interface SearchPostData {
  "id": number,
  "attributes": {
    "description": string,
    "created_at": string,
    "location": string,
    "image_url": string,
    "profile_pic": string,
    "user_name": string,
    "work_location": string,
    did_you_like: boolean,
    comments_count: number,
    likes_count: number,
    account_id: number,
    id: number,
    comments: any[],
    openCommentsList: boolean,
    commentLikeLoader: boolean,
  }
}

export interface TagsData {
  attributes: {
    id: string,
    name: string
  }
  id: string,
  type: string,
}

export interface FunCtionData {
  attributes: {
    id: number,
    name: string
  }
  id: number,
  type: string,
}

export interface WorkLocationData {
  attributes: {
    name: string,
    profile_location: {
      country_id: number,
      created_at: string,
      id: number,
      name: string
    }
  }
}

export interface OperationIdentificationData {
  attributes: {
    id: number,
    name: string,
  }
}

export interface MockUsersSearchResult {
  "id": number,
  "attributes": {
    "email": string,
    "official_email": string,
    "full_name": string,
    "profile_image": string,
    "role_name": string,
    "location_name": string,
    "work_location_name": string,
  }
}

export interface LocationUserData {
  // "id": number | undefined,
  "attributes": {
    "email": string,
    "official_email": string,
    "full_name": string,
    "role_name": string,
    "location_name": string,
    "work_location_name": string,
  }
}

export interface Notification {
  id: number,
  attributes: {
    id: number,
    contents: string,
    is_read: boolean,
    created_at: string,
    account: {
      data: {
        id: number,
        attributes: {
          full_name: string,
          profile_image: string
        }
      }
    }
  }
}

export interface NotificationMeta {
  pagination: {
    current_page: number,
    next_page: number | null,
    prev_page: number | null,
    total_pages: number,
    total_count: number,
    current_count: number,
    per_page: number
  }
};

export interface NotificationsData {
  data: Notification[],
  meta: NotificationMeta
}

export interface CommentData {
  id: number,
  attributes: {
    comment: string,
    created_at: string,
    account: {
      profile_image: string,
      full_name: string
    }
  }
}
// Customizable Area End

export const webConfigJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  closeModel?: any;
  logoutClear?: any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  openCommentsList: boolean
  openPostOption: boolean
  anchorEl: null | HTMLElement
  anchorElReportComment: null | HTMLElement
  dashboardData: PostData[]
  totalCandidates: string
  type: string
  token: string
  errorMsg: string
  loading: boolean
  showPostPopUp: boolean
  isOpen: any
  onClose: any
  isPopupImgOpen: boolean
  currentImage: string
  children: any
  isModalOpen: any
  selectedLanguage: string
  isTextFieldFocused: boolean
  postSettings: PostSettings
  isSelected: string
  notifications: Notifications | null
  isNotificationsOpen: boolean
  notificationsRead: boolean
  userName: string
  accountDetails: any
  postCreationFinished: boolean
  showPostCreationFinished: boolean
  isLoading: boolean
  loader: boolean
  buLoaction: any
  machineWorkLocation: any
  dropOpen: any
  postDataLoading: boolean
  loadingData: boolean
  emptyPostId: number
  uploadedImageUrl: string
  postDescription: string
  showAiTag: boolean
  storyImageId: number
  searchText: string
  activeButton: string
  searchVisible: boolean
  totalCountForUser: number
  currentPage: number
  totalCountForPost: number
  userSearchResult: {
    id: number
    attributes: {
      email: string
      official_email: string
      full_name: string
      role_name: string
      profile_image: string
      location_name: string
      work_location_name: string
    }
  }[]
  postSearchResult: PostData[]
  reportPostModal: boolean
  reportCommentModal: boolean
  reportTags: TagsData[]
  reportdescription: string
  postIsReported: boolean
  selectedTags: number[]
  reportPostId: number
  comments: CommentData[]
  isReadArray: boolean
  postComment: string
  errorMsgModal: boolean
  isDescriptionErr: boolean
  totalComment: number
  commentsloading: boolean
  currentpostId: number
  commentLikeLoader: boolean
  addCommentsLoader: boolean
  reportCommentOption: boolean
  currentCommentPage: number
  currentCommentId: number
  totalPages: number
  operationsIdentifications: OperationIdentificationData[]
  postFromNotification: boolean
  postCurrentPage: number
  postsPagination: NotificationMeta
  aiImageprocessing: boolean
  aiProcessingError: string | null
  language_name:string
  isIncident:boolean;
  expandedPostId: number[];
  currentImageIndexMap: {
    [key: string]: number;
  };
  // Customizable Area End
}
interface SS {}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiDashboardItemCallId: string = "";
  dashboardApiCallId: string = "";
  apiGetQueryStrinurl: string = "";
  apiPostsItemCallId: string = "";
  apiCreatePostsItemCallId: string = "";
  getAllPostDataCallId: string = "";
  getNotificationsCallId: string = "";
  getAccountInfoDetailsCallID: string = "";
  getSubFuncLocationCallID: string = "";
  getMachineWorkLocationCallID: string = "";
  apiCreateEmptyPostId: string = "";
  apiUploadImageId: string = "";
  getSearchApiCallId: string = "";
  apiGetReportsTags: string = "";
  ReportPostAPICallId: string = "";
  LikePostAPICallID: string = "";
  CommentPostAPICallID: string = "";
  GetCommentsAPICallID: string = "";
  reportCommentsAPICallID: string = "";
  getOperationsIdentificationsId: string = "";
  getPostDetailDataById: string = "";
  updateNotificationApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      type: "",
      expandedPostId: [],
      currentImageIndexMap: {},
      dashboardData: [],
      totalCandidates: "",
      errorMsg: "",
      token: "",
      loading: false,
      showPostPopUp: false,
      isTextFieldFocused: false,
      selectedLanguage: "",
      isModalOpen: false,
      isOpen: false,
      onClose: "",
      isPopupImgOpen: false,
      currentImage: "",
      children: "",
      postSettings: {
        employeeIds: ["AB123", "test"],
        machineIds: ["Machine FL26", "test"],
        processAndOperationIds: ["Machine FL26", "test"],
        location: "BU25",
        shifts: ["shift1", "shift2", "shift3"],
        concerns: ["Unsafe Act", "Safe Act", "Not Evaluated"],
        hazardSelects: ["low", "high", "medium"],
        frequencies: [],
        severities: [],
        consequences: ["straight", "curve"],
        hashtags: null,
      },
      isSelected: "",
      notifications: null,
      isNotificationsOpen: false,
      notificationsRead: true,
      userName: "",
      accountDetails: {},
      postCreationFinished: false,
      showPostCreationFinished: true,
      isLoading: false,
      loader: false,
      buLoaction: [],
      machineWorkLocation: [],
      dropOpen: null,
      postDataLoading: false,
      loadingData: false,
      emptyPostId: 0,
      uploadedImageUrl: '',
      postDescription: '',
      showAiTag: false,
      storyImageId: 0,
      searchText: "",
      activeButton: "Posts",
      searchVisible: false,
      totalCountForUser: 0,
      currentPage: 1,
      totalCountForPost: 0,
      userSearchResult: [],
      postSearchResult: [],
      openPostOption: false,
      anchorEl: null,
      anchorElReportComment: null,
      reportPostModal: false,
      reportCommentModal: false,
      reportTags: [],
      reportdescription: "",
      postIsReported: false,
      selectedTags: [],
      reportPostId: 0,
      openCommentsList: false,
      comments: [],
      isReadArray:false,
      postComment: "",
      errorMsgModal: false,
      isDescriptionErr: false,
      totalComment: 0,
      commentsloading: false,
      currentpostId: 0,
      commentLikeLoader: false,
      addCommentsLoader: false,
      reportCommentOption: false,
      currentCommentPage: 0,
      currentCommentId: 0,
      totalPages: 0,
      operationsIdentifications: [],
      postFromNotification: false,
      postCurrentPage: 1,
      postsPagination: {
        pagination: {
          current_page: 1,
          next_page: null,
          prev_page: null,
          total_pages: 1,
          total_count: 0,
          current_count: 1,
          per_page: 10
        }
      },
      aiImageprocessing: false,
      aiProcessingError: null,
      language_name:"",
      isIncident: false
    };
    this.createPost = this.createPost.bind(this);
    this.goToNotificationsPage = this.goToNotificationsPage.bind(this);
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getDashboardData();
    // Customizable Area Start
    // this.handleGetLanguage()

    let token = await getStorageData("authToken", false)
    this.setState({ token: token },() => {
        this.getNotifications();
    })
    this.getAccountInfoDetailsAPI();
    this.getSubFuncLocationAPI();
    this.getReportsTags();
    this.getMachineWorkLocationAPI(0)
    this.getOperationsIdentifications(0);
    const postId = parseInt(await getStorageData("postId"));
    if (postId && postId > 0) {
      this.setState({ postFromNotification: true });
      this.getPostDetailData(postId);
      setStorageData('postId', '0');
    } else {
      this.getPostData();
    }
    // Customizable Area End
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    const webHeader = {};
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDashboardItemCallId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.dashboardGetUrl
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && !responseJson.errors) {
        this.setState({ loading: false, errorMsg: "" });
        this.getReciveFunction(apiRequestCallId, responseJson);
        this.viewCommentsRes(apiRequestCallId, responseJson)
        this.getreciveSubFunctionWork(apiRequestCallId, responseJson);
        this.handlePostDetailResponse(apiRequestCallId, responseJson);
        this.handleUpdateNotificationApiResp(apiRequestCallId, responseJson)
      } else if (responseJson && responseJson.errors) {
        let errorMsg = responseJson.errors[0].message;
        if (errorMsg === "Something went wrong") {
          errorMsg = "Manager is not assigned to your profile, Please contact your Administration";
        }        this.setState({
          loading: false,
          loader: false,
          isLoading: false,
          errorMsg: errorMsg,
          errorMsgModal: true,
          comments: [],
          totalComment: 0,
          commentsloading: false,
          currentCommentPage: 0,
          anchorElReportComment: null,
          reportCommentOption: false,
          commentLikeLoader: false,
          currentpostId: 0,
          aiImageprocessing: false,
          aiProcessingError: configJSON.aiProcessErrorMessage,
        });
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start

  openPopup = (imageUrl :string) => {
    this.setState({ isPopupImgOpen: true, currentImage: imageUrl  });
  };

  closePopup = () => {
    this.setState({ isPopupImgOpen: false, currentImage: "" });
  };

  handleBackdropClick = () => {
      this.closePopup();
  };

  dropDownOpen = () => {
    this.setState({
      dropOpen: true,
    });
  };

  handleClose = () => {
    this.setState({
      dropOpen: null,
    });
  };

  logout = () => {
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = "/";
    this.handleClose();
  };

handleUpdateNotificationApiResp = (apiRequestCallId: string, responseJson: any) => {
  if (apiRequestCallId === this.updateNotificationApiCallId && responseJson.message) {
    this.goToNotificationsPage();
  }
} 

updateReadNotificationApiCall = () => {
  this.setState({isReadArray : false})

  const headers = {
      token: this.state.token,
      "Content-Type": "application/json",
  };

  const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  );
  this.updateNotificationApiCallId = requestMessage.messageId;
  requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateNotificationApiEndPoint}`
  );
  requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
  );
  requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchApiMethodType
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
}

  getPostData = () => {
    this.setState({ postCreationFinished: false, isLoading: true });
    const headers = {
      "Content-Type": "application/json",
      token: this.state.token,
    };
    const getAllRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllPostDataCallId = getAllRequestMsg.messageId;
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.postsAPIEndPoint}?per_page=10&page=${this.state.postCurrentPage}`
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(getAllRequestMsg.id, getAllRequestMsg);
  };

  getDashboardDataRes = () => {
    if (this.state.searchVisible) {
      this.getSearchApiCall()
    } else {
      this.getPostData();
    }
  }

  viewCommentsRes = (apiRequestCallId: string, responseJson: {
    total_comment: number; data: CommentData[];
  }) => {
    if (apiRequestCallId === this.GetCommentsAPICallID) {
      if (responseJson.data) {
        let commentsData = this.state.comments.concat(responseJson.data);
        this.setState({ comments: commentsData, totalComment: responseJson.total_comment, commentsloading: false });
      }
    }
  }

  likesCommentRes = (apiRequestCallId: string, responseJson: { posts?: PostRes; data: { id: number }; notifications: NotificationsData; message?: string; }) => {
    if (apiRequestCallId === this.CommentPostAPICallID) {
      if (responseJson.data) {
        this.setState({ commentLikeLoader: false, postComment: "" });
        this.getPostData();
        this.getComment(this.state.currentpostId)
      }
    }

    if (apiRequestCallId === this.reportCommentsAPICallID) {
      this.setState({
        commentsloading: false,
        anchorElReportComment: null,
        reportCommentOption: false,
        loading: false,
        postIsReported: true,
        reportPostModal: false,
      });
    }

    if (apiRequestCallId === this.LikePostAPICallID) {
      if (responseJson.data || responseJson.message) {
        this.setState({ errorMsgModal: false, isLoading: false, commentLikeLoader: false });
        this.toggleLikeIcon()
      } else {
        this.toggleLikeIcon()
      }
    }

    if (apiRequestCallId === this.ReportPostAPICallId) {
      if (responseJson.data) {
        this.setState({
          reportPostModal: false,
          loading: false,
          postIsReported: true,
          reportdescription: "",
        });
      } else {
        this.setState({
          loading: false,
          postIsReported: false,
          reportPostModal: true
        });
      }
    }
  }

  getReciveFunction = (apiRequestCallId: string, responseJson: { posts: PostRes; data: { attributes: { user_name: string }, id: number }; notifications: NotificationsData; message?: string; error?: string; hazard_explanation: string; most_prominent_hazards: string[]; output_image_url: string; }) => {
    this.likesCommentRes(apiRequestCallId, responseJson)
    if (apiRequestCallId === this.apiDashboardItemCallId) {
      this.setState({
        loading: false,
        loader: false,
        dashboardData: responseJson?.posts?.data || [],
        errorMsg: "",
      });
    }

    if (apiRequestCallId === this.apiPostsItemCallId) {
      const postSettings = this.parsePostSettings(responseJson);
      this.setState({
        postSettings: postSettings,
        postDataLoading: false,
        errorMsg: "",
        machineWorkLocation: [],
        operationsIdentifications: [],
      });
    }

    if (apiRequestCallId === this.apiCreatePostsItemCallId) {
      this.setState({
        loading: false,
        errorMsg: "",
        userName: responseJson?.data?.attributes?.user_name,
      });
      this.getPostData();
      this.setState({ machineWorkLocation: [] });
      if(this.state.showPostCreationFinished){
        this.setState({ postCreationFinished: true });
      }
    }

    if (apiRequestCallId === this.apiCreateEmptyPostId) {
      this.setState({ emptyPostId: responseJson?.data?.id, loading: false, },()=>{
         setStorageData("createPostId", this.state.emptyPostId);
      });
    }

    if (apiRequestCallId === this.apiUploadImageId) {
      if (responseJson?.hazard_explanation && responseJson?.most_prominent_hazards) {
        let description: string = responseJson?.hazard_explanation;
        const hazards: Array<string> = responseJson?.most_prominent_hazards;

        hazards.forEach((item: string) => {
          description = description.concat(` #${item.replace(/ /g, '')}`);
        });

        this.setState({
          postDescription: description,
          uploadedImageUrl: responseJson?.output_image_url,
          loading: false,
          showAiTag: true,
          aiImageprocessing: false,
          aiProcessingError: null,
        });
      } else {

        let errorMessage = configJSON.aiProcessErrorMessage;
        if (responseJson?.message) {
          if (responseJson?.error === configJSON.noManagerError) {
            errorMessage = "Please contact your admin to assign you a manager.";
          } else {
            errorMessage = "Manager is not assigned to your profile, Please contact your Administration";
          }
        }

        this.setState({
          postDescription: '',
          uploadedImageUrl: '',
          loading: false,
          showAiTag: false,
          aiImageprocessing: false,
          aiProcessingError: errorMessage,
        });
      }
    }

    if (apiRequestCallId === this.getAllPostDataCallId) {
      responseJson.posts.data.forEach((data) => {
        data.attributes.commentLikeLoader = false
      });
      let existingDashboardData = this.state.dashboardData || [];
      this.setState({
        dashboardData: existingDashboardData.concat(responseJson?.posts?.data),
        postsPagination: responseJson?.posts?.meta,
        isLoading: false,
        loader: false,
        commentLikeLoader: false,
        addCommentsLoader: false
      });
    }

    if (apiRequestCallId === this.getAccountInfoDetailsCallID) {
      this.setState({
        accountDetails: responseJson?.data || {},
        loader: false,
        loadingData: false
      });
    }

    if (apiRequestCallId === this.getNotificationsCallId) {
      const currentNotifications = this.state.notifications?.data || [];
      const notifications = [
        ...currentNotifications,
        ...responseJson?.notifications.data,
      ];
      this.setState({
        notifications: { ...responseJson?.notifications, data: notifications },
        totalPages: responseJson.notifications.meta.pagination.total_pages,
        isReadArray: responseJson.notifications.data.some(notification => !notification.attributes.is_read)
      });
    }
  };

  getreciveSubFunctionWork = (apiRequestCallId: string, responseJson: { sub_functions: { data: FunCtionData[]; }; work_locations: { data: WorkLocationData[]; }; operation_identifications: { data: OperationIdentificationData[]; }; users: { data: MockUsersSearchResult[]; meta: NotificationMeta; }; posts: { data: PostData[]; meta: NotificationMeta; }; data: TagsData[]; }) => {
    if (apiRequestCallId === this.getSubFuncLocationCallID) {
      this.setState({ buLoaction: responseJson.sub_functions.data });
    }
    if (apiRequestCallId === this.getMachineWorkLocationCallID) {
      this.setState({ machineWorkLocation: responseJson.work_locations.data || [], operationsIdentifications: [] });
    }
    if (apiRequestCallId === this.getOperationsIdentificationsId) {
      this.setState({ operationsIdentifications: responseJson.operation_identifications.data || [] });
    }
    if (apiRequestCallId === this.getSearchApiCallId) {
      if (this.state.activeButton === "Users") {
        this.setState({
          userSearchResult: responseJson.users.data,
          totalCountForUser: responseJson.users.meta.pagination.total_count,
          isLoading: false,
          loader: false,
          commentLikeLoader: false
        });
      } else {
        responseJson.posts.data.forEach((data) => {
          data.attributes.commentLikeLoader = false
        })
        this.setState({
          postSearchResult: this.state.postSearchResult.concat(responseJson?.posts?.data),
          postsPagination: responseJson?.posts?.meta,
          isLoading: false,
          totalCountForPost: responseJson.posts.meta.pagination.total_count,
          loader: false,
          commentLikeLoader: false
        });
      }
    }

    if (apiRequestCallId === this.apiGetReportsTags) {
      this.setState({ reportTags: responseJson.data });
    }
    if (apiRequestCallId === this.getOperationsIdentificationsId) {
      this.setState({ operationsIdentifications: responseJson.operation_identifications.data });
    }
  };

  handlePostDetailResponse = (apiRequestCallId: string, responseJson: { data: PostData }) => {
    if (apiRequestCallId === this.getPostDetailDataById && responseJson.data) {
      this.setState({ dashboardData: [responseJson.data], isLoading: false });
    }
  }

  getPostSettings(): boolean {
    this.setState({ postCreationFinished: false });
    const header = {
      "Content-Type": configJSON.postsContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiPostsItemCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postsGetUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postsApiMethodType
    );
    this.setState({ postDataLoading: true });
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getNotifications(page = 1, perPage = 4): boolean {
    this.setState({ loading: true });

    const header = {
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getNotificationsCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.notificationsGetUrl}?page=${page}&per_page=${perPage}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.notificationsApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  createPost(postBody: any): boolean {
    this.setState({ loading: true });
    const { emptyPostId } = this.state;
    postBody.append('id', emptyPostId);
    postBody.append('is_empty', false);

    const header = {
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCreatePostsItemCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.postCreateUrl}/${emptyPostId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      postBody
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  goToNotificationsPage() {
    this.setState({ isNotificationsOpen: false });
  }

  getPropNames(object: { data: { attributes: { name: string } }[] }): string[] {
    return (
      object?.data?.map((dataVal: { attributes: { name: string } }) => dataVal.attributes.name) || [object]
    );
  }

  parsePostSettings(receivedData: any): PostSettings {
    return {
      consequences: receivedData.consequences?.data,
      frequencies: receivedData.frequencies?.data,
      hazardSelects: receivedData?.hazards?.data,
      severities: receivedData?.severities?.data,
      processAndOperationIds: receivedData.operation_identifications?.data,
      concerns: receivedData.post_concerns?.data,
      employeeIds: this.getPropNames(receivedData["employee_id"]),
      location: this.getPropNames(receivedData["location"])[0],
      machineIds: this.getPropNames(receivedData["work_location"]),
      shifts: receivedData.shifts?.data,
      hashtags: receivedData?.hashtags || [],
    };
  }

  showNotifications = (isNotificationsOpen: boolean) => {
    this.setState({ notifications: null });
    this.setState({ isNotificationsOpen });
    isNotificationsOpen && this.getNotifications();
  };

  openModal = () => {
    this.setState({ isModalOpen: true });
  };

  logoutClear = () => {
    localStorage.clear();
  };

  closeModal = () => {
    this.setState({ isModalOpen: false });
  };

  handleLanguageSelect = (language: string) => {
    this.setState({ selectedLanguage: language });
  };

  handleTextFieldBlur = () => {
    this.setState({ isTextFieldFocused: false });
  };

  handleSelect = (language: string, language_name: string) => {
    this.setState({ isSelected: language, language_name })
  };

  handleTextFieldFocus = () => {
    this.setState({ isTextFieldFocused: true });
  };

  changeLanguage = () => {
    if (this.state.language_name) {
      i18n.changeLanguage(this.state.language_name);
      localStorage.setItem('language_name', this.state.language_name)
      window.location.reload();
      this.closeModal();
    }
  }

  handleCloseStoryImage = () => {
    this.setState({ storyImageId: 0 })
  }
  handleChangeStoryImageId = (storyId: number) => {
    this.setState({ storyImageId: storyId })
  }

  handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchText: event.target.value })
  }

  handleSearchKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && this.state.searchText.length >= 3) {
      this.setState({
        postCurrentPage: 1, isLoading: true, postSearchResult: [],
        postsPagination: {
          pagination: {
            current_page: 1,
            next_page: null,
            prev_page: null,
            total_pages: 1,
            total_count: 0,
            current_count: 1,
            per_page: 10
          }
        }
      }, () => {
        this.getSearchApiCall();
      });
    }
  }

  getAvatar = (image: string, defaultImage: string) => {
    return (image !== undefined && image.length > 0 && image) || defaultImage
  }

  handleClearSearch = () => {
    this.setState({ searchText: "", searchVisible: false })
  }

  handlePostsClick = () => {
    this.setState({ activeButton: "Posts" }, () => {
      if (this.state.searchText.length >= 3) {
        this.setState({ currentPage: 1, loader: true }, () => {
          this.getSearchApiCall();
        });
      }
    })
  }

  handlePageChange = (event: ChangeEvent<unknown>, newPage: number) => {
    this.setState({ currentPage: newPage, loader: true }, () => {
      this.getSearchApiCall();
    });
  }

  handleUsersClick = () => {
    this.setState({ activeButton: "Users" }, () => {
      if (this.state.searchText.length >= 3) {
        this.setState({ currentPage: 1, loader: true }, () => {
          this.getSearchApiCall();
        });
      }
    })
  }

  getSearchApiCall = () => {
    this.setState({
      isLoading: true,
      loader: false,
      searchVisible: true
    });

    const headers = {
      token: this.state.token,
    };
    const users = true

    const formData: any = new FormData();
    formData.append("search", this.state.searchText);
    formData.append("per_page", 10);
    formData.append("page", this.state.postCurrentPage);
    if (this.state.activeButton === "Users") {
      formData.append("user", users);
    } else {
      formData.append("post", users);
    }
    const requestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSearchApiCallId = requestMsg.messageId;
    requestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.searchApiEndPoint}`
    );
    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.searchApiMethodType
    );
    runEngine.sendMessage(requestMsg.id, requestMsg);
  }

  getAccountInfoDetailsAPI = async () => {
    let SignUpID = JSON.parse(await getStorageData("SignUpID"));
    const headers = {
      token: this.state.token,
    };
    const getAllRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.accountAPIEndPoint}/${SignUpID}`
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    this.setState({ loadingData: true })
    this.getAccountInfoDetailsCallID = getAllRequestMsg.messageId;
    runEngine.sendMessage(getAllRequestMsg.id, getAllRequestMsg);
  };

  getSubFuncLocationAPI = async () => {
    const headers = {
      token: this.state.token,
    };
    const getAllRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.allFunctionsApiEndPoint
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    this.getSubFuncLocationCallID = getAllRequestMsg.messageId;
    runEngine.sendMessage(getAllRequestMsg.id, getAllRequestMsg);
  };

  getMachineWorkLocationAPI = async (machineId: string | number) => {
    setStorageData("machineId", machineId);
    const headers = {
      token: this.state.token,
    };
    const getAllRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.SubFunctionsApiEndPoint}?sub_function_id=${machineId}`
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    this.getMachineWorkLocationCallID = getAllRequestMsg.messageId;
    runEngine.sendMessage(getAllRequestMsg.id, getAllRequestMsg);
  }

  goToUserProfile = (userId: number | undefined) => {
    setStorageData("otherUserId", userId)
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "Profile");
    message.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(message);
  }

  handlePostOption = (event: { currentTarget: HTMLElement | null }, postData: PostData) => {
    setStorageData("reportPostId", postData.attributes.id)
    this.setState({ openPostOption: !this.state.openPostOption, anchorEl: event.currentTarget })
  }

  handleReportForPost = (postCreatedByAccountId: string) => {
    if (this.state?.accountDetails.attributes?.employee_id === postCreatedByAccountId) {
      this.setState({ errorMsg: "User can not report his own post" , errorMsgModal: true, openPostOption: false})
    } else {
      this.setState({ reportPostModal: true, anchorEl: null, openPostOption: false })
    }
  }

  getReportsTags = () => {
    const headers = {
      "Content-Type": "application/json",
      token: this.state.token,
    };
    const getAllRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetReportsTags = getAllRequestMsg.messageId;
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.tagsAPIEndPoint
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(getAllRequestMsg.id, getAllRequestMsg);
  };

  handledescriptionChange = (event: { target: { value: string; }; }) => {
    this.setState({
      reportdescription: event?.target.value,
      isDescriptionErr: false
    })
  }

  getTagColor = (tagId: number) => {
    return this.state.selectedTags.includes(tagId) ? true : false;
  }

  addSelectedTags = (tagId: number) => {
    this.setState({ isDescriptionErr: false })
    this.setState((prevState) => {
      const isSelected = [...prevState.selectedTags];
      const selectedTags = isSelected.indexOf(tagId);
      if (selectedTags === -1) {
        isSelected.push(tagId);
      } else {
        isSelected.splice(selectedTags, 1);
      }
      return { selectedTags: isSelected };
    });
  }

  reportPost = async () => {
    this.setState({ loader: true });
    let reportPostId = await getStorageData("reportPostId");
    if (this.state.reportdescription === "" && this.state.selectedTags.length === 0) {
      this.setState({ isDescriptionErr: true })
      return false
    }
    const headers = {
      "Content-Type": "application/json",
      token: this.state.token,
    };
    const formdata = {
      "description": this.state.reportdescription,
      "post_id": parseInt(reportPostId),
      "report_tag_ids": this.state.selectedTags,
    }
    const getAllRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.ReportPostAPICallId = getAllRequestMsg.messageId;
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.repostPostAPIEndPoint
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(formdata)
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(getAllRequestMsg.id, getAllRequestMsg);
  }

  closeReportModal = () => {
    this.setState({
      postIsReported: false,
      reportPostModal: false,
      openPostOption: false,
      loading: false,
      loader: false,
      reportCommentModal: false,
      selectedTags: [],
      reportdescription: '',
    })
  }

  closeErrorModal = () => {
    this.setState({ errorMsg: "", errorMsgModal: false})
  }

  proceedToLike = (data: {
    commentLikeLoader: boolean; did_you_like: boolean; id: number;
  }) => {
    this.setState({
      dashboardData: this.state.dashboardData,
      postSearchResult: this.state.postSearchResult,
      commentLikeLoader: true,
      currentpostId: data.id,
    })
    if (data.did_you_like) {
      this.unLikePost(data.id)
    } else {
      this.likePost(data.id)
    }
  }

  unLikePost = (postId: number) => {
    const apiHeader = {
      "Content-Type": configJSON.dashboarContentType,
      token: this.state.token,
    }
    let formdata = {
      data: {
        type: "unlike",
        attributes: {
          post_id: postId
        }
      }
    }
    const likePostRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    likePostRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.LikePostApiEndPoint
    );
    likePostRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(apiHeader)
    );
    likePostRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(formdata)
    );
    likePostRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postsApiPostMethodType
    );
    this.LikePostAPICallID = likePostRequestMsg.messageId;
    runEngine.sendMessage(likePostRequestMsg.id, likePostRequestMsg);
  }

  likePost = async (postId: number) => {
    let formdata = {
      "data": {
        type: "like",
        attributes: {
          post_id: postId
        }
      }
    }
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: this.state.token,
    }
    const likePostRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    likePostRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.LikePostApiEndPoint
    );
    likePostRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    likePostRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(formdata)
    );
    likePostRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postsApiPostMethodType
    );
    this.LikePostAPICallID = likePostRequestMsg.messageId;
    runEngine.sendMessage(likePostRequestMsg.id, likePostRequestMsg);
  }

  proceedToAddComment = (event: { keyCode?: number; target: { value: string; }; }, postId: number) => {
    this.setState({ postComment: event?.target?.value })
    if (event.keyCode === 13) {
      this.AddComment(postId)
    }
  }

  AddComment = (postId: number) => {
    this.setState({ addCommentsLoader: true, currentpostId: postId })
    let apiData = {
      "comment": {
        "comment": this.state.postComment,
        "commentable_type": "BxBlockPosts::Post",
        "commentable_id": postId
      }
    }
    const headers = {
      "Content-Type": configJSON.dashboarContentType,
      token: this.state.token,
    };
    const getAllRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.AddCommentPostApiEndPoint}`
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(apiData)
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    this.CommentPostAPICallID = getAllRequestMsg.messageId;
    runEngine.sendMessage(getAllRequestMsg.id, getAllRequestMsg);
  }

  showComments = (postData: PostData, postIndex: number) => {
    if (postData?.attributes?.comments_count > 0) {
      const id = postData.attributes.id;
      this.state.dashboardData.forEach((data, index) => {
        if (index !== postIndex) {
          data.attributes.openCommentsList = false
          this.setState({
            dashboardData: this.state.dashboardData
          })
        } else {
          data.attributes.openCommentsList = !data.attributes.openCommentsList
          this.setState({
            dashboardData: this.state.dashboardData
          })
        }
      })
      this.setState({ comments: [], currentCommentPage: 0 }, () => {
        this.getComment(id)
      })
    }
  }

  showSearchPostComments = (postData: PostData, postIndex: number) => {
    if (postData?.attributes?.comments_count > 0) {
      const id = postData.attributes.id;
      this.setState({ comments: [] })
      this.state.postSearchResult.forEach((data, index) => {
        if (index !== postIndex) {
          data.attributes.openCommentsList = false
          this.setState({
            postSearchResult: this.state.postSearchResult
          })
        } else {
          data.attributes.openCommentsList = true
          this.setState({
            postSearchResult: this.state.postSearchResult
          })
        }
      })
      this.getComment(id)
    }
  }

  getComment = (id: number | undefined) => {
    this.setState({
      currentCommentPage: this.state.currentCommentPage + 1,
      commentsloading: true,
      totalComment: 0,
    })
    let tokenValue = localStorage.getItem("authToken");
    const headers = {
      "Content-Type": configJSON.apiContentType,
      token: tokenValue,
    };
    const getAllRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_comments/comments?commentable_id=${id}&per_page=5&page=${this.state.currentCommentPage + 1}`
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    this.GetCommentsAPICallID = getAllRequestMsg.messageId;
    runEngine.sendMessage(getAllRequestMsg.id, getAllRequestMsg);
  }

  showReportComment = (event: any, id: number) => {
    this.setState({
      reportCommentOption: !this.state.reportCommentOption,
      anchorElReportComment: event.currentTarget,
      currentCommentId: id,
    })
  }

  showCommentLoader = (postIndex: number) => {
    this.state.postSearchResult.forEach((data, index) => {
      if (index !== postIndex) {
        data.attributes.openCommentsList = false
        this.setState({
          postSearchResult: this.state.postSearchResult
        })
      } else {
        data.attributes.openCommentsList = true
        this.setState({
          postSearchResult: this.state.postSearchResult
        })
      }
    })
  }


  handleOpenReportCommentModal = (commentData: any) => {
    if (this.state?.accountDetails.attributes?.employee_id === commentData.attributes?.account.employee_id) {
      this.setState({ errorMsg: "User can not report his own comment", errorMsgModal: true, anchorElReportComment: null })
    } else {
      this.setState({ reportCommentModal: true, reportPostModal: true, anchorEl: null, openPostOption: false })
    }
  }

  reportComment = () => {
    // this.showCommentLoader(postIndex)
    this.setState({ commentsloading: true })
    let apiData = {
      "comment_id": this.state.currentCommentId,
      "description": this.state.reportdescription,
      "report_tag_ids": this.state.selectedTags,
    }
    const headers = {
      "Content-Type": configJSON.notificationsContentType,
      token: this.state.token,
    };
    const getAllRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.reportCommentsAPIEndPoint}`
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(apiData)
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    this.reportCommentsAPICallID = getAllRequestMsg.messageId;
    runEngine.sendMessage(getAllRequestMsg.id, getAllRequestMsg);
  }

  timetoSince = (date: string) => {
    let createdAt = date;
    let currentTime = moment();
    let createdTime = moment(createdAt);
    let diffTime = currentTime.diff(createdTime, 'seconds');
    if (diffTime < 60) {
      return diffTime + ' seconds ago'
    } else if (diffTime < 3600) {
      return Math.floor(diffTime / 60) + ' minutes ago'
    } else if (diffTime < 86400) {
      return Math.floor(diffTime / 3600) + ' hours ago'
    } else if (diffTime < 2592000) {
      return Math.floor(diffTime / 86400) + ' days ago'
    } else if (diffTime < 31536000) {
      return Math.floor(diffTime / 2592000) + ' months ago'
    } else {
      return Math.floor(diffTime / 31536000) + ' years ago'
    }
  }

  setCurrentPostId = (id: number) => {
    const { currentpostId } = this.state;
    if (currentpostId != id) {
      this.setState({ currentpostId: id, postComment: '' });
    } else {
      this.setState({ currentpostId: id });
    }
  }

  handleReportApiCall = () => {
    if (this.state.reportCommentModal) {
      this.reportComment();
    } else {
      this.reportPost();
    }
  }

  viewNotificationPost = (item: boolean) => {
    this.setState({ isNotificationsOpen: true });
  }

  getOperationsIdentifications = async (machineId: string | number) => {
    const headers = {
      token: this.state.token,
    };
    const getAllRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.operationsIdentificationsEndPoint}?work_location_id=${machineId}`
    );
    this.getOperationsIdentificationsId = getAllRequestMsg.messageId;
    runEngine.sendMessage(getAllRequestMsg.id, getAllRequestMsg);
  }

  getPostDetailData = (postId: number) => {
    this.setState({ isLoading: true });
    const header = {
      "token": this.state.token
    };

    const getMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    getMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'Get'
    );

    getMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getPostDataById}/${postId}`
    );

    this.getPostDetailDataById = getMessage.messageId;
    getMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(getMessage.id, getMessage);
  }

  handleLoadAllPosts = () => {
    setStorageData('postId', '0');
    this.setState({ dashboardData: [], isLoading: true, postFromNotification: false }, () => {
      this.getPostData();
    })
  }

  loadMorePosts = () => {
    this.setState({ postCurrentPage: this.state.postCurrentPage + 1 }, () => {
      if (this.state.searchVisible) {
        this.getSearchApiCall();
      } else {
        this.getPostData();
      }
    });
  }

  toggleLikeIcon = () => {
    const { currentpostId, dashboardData, postSearchResult, searchVisible } = this.state;
    let updatedPosts: any = [];
    let updatedSearchPosts: any = [];
    if (searchVisible) {
      updatedSearchPosts = postSearchResult.map((item: any) => {
        if (item.attributes.id === currentpostId) {
          item.attributes.did_you_like = !item.attributes.did_you_like;
          if (item.attributes.did_you_like) {
            item.attributes.likes_count = item.attributes.likes_count + 1;
          }
          if (!item.attributes.did_you_like && item.attributes.likes_count > 0) {
            item.attributes.likes_count = item.attributes.likes_count - 1;
          }
        }
        return item;
      });
    } else {
      updatedPosts = dashboardData.map((item: any) => {
        if (item.attributes.id === currentpostId) {
          item.attributes.did_you_like = !item.attributes.did_you_like;
          if (item.attributes.did_you_like) {
            item.attributes.likes_count = item.attributes.likes_count + 1;
          }
          if (!item.attributes.did_you_like && item.attributes.likes_count > 0) {
            item.attributes.likes_count = item.attributes.likes_count - 1;
          }
        }
        return item;
      });
    }
    this.setState({ currentpostId: 0, dashboardData: updatedPosts, postSearchResult: updatedSearchPosts });
  }

  createEmptyPost = () => {
    this.setState({ loading: true });

    const { token } = this.state;
    const header = { token: token };
    const payload = { is_empty: true };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postCreateUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(payload)
    );

    this.apiCreateEmptyPostId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postsApiPostMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleUploadImage = (formData: FormData) => {
    const { emptyPostId } = this.state;
    
    formData.append("id", emptyPostId.toString()); 
    
    this.uploadImageApi(formData);
  };

  uploadImageApi = (payload: any) => {
    this.setState({ aiImageprocessing: true });

    const header = {
      token: this.state.token,
      contentType: "multipart/form-data",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiUploadImageId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postImageUploadUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      payload
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  onIncidentClick = () => {
    this.setState({
      isIncident: !this.state.isIncident,
      showPostPopUp: false
    });
  };

  handleSeeMore = (postId: number) => {
    this.setState((prevState) => ({
      expandedPostId: [...prevState.expandedPostId, postId],
    }));
  };  

  handleSeeLess = (postId: number) => {
    this.setState((prevState) => ({
      expandedPostId: prevState.expandedPostId.filter((id) => id !== postId),
    }));
  };

  handleImageClick = (postId: string, index: number) => {
    this.setState((prevState) => ({
      currentImageIndexMap: {
        ...prevState.currentImageIndexMap,
        [postId]: index,
      },
    }));
  };
  // Customizable Area End
}
