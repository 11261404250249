import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { getStorageData } from "../../../../framework/src/Utilities";
import { sendAPIRequest } from "../../../../components/src/Utils";
import { mic, micStart } from "../../../dashboard/src/assets";
// Customizable Area Start
interface SpeechRecognitionEvent extends Event {
  results: {
    [index: number]: {
      [index: number]: {
        transcript: string;
      };
    };
  };
}
export const configJSON = require("../config");

type SafetyDataKeys = keyof ISafetyData;

interface ISafetyInteractionSelectionItem {
  id: number;
  form_name: string;
  name: string;
  custom_action: boolean;
  status: string;
  positive_action: boolean;
  in_the_spot_close: boolean;
  comment: string | null;
  risk_potential: string | null;
  action: string | null;
  safety_interaction_id: number;
  created_at: string;
  updated_at: string;
}

interface ISafetyData {
  reaction_of_people: ISafetyInteractionSelectionItem[];
  position_of_people: ISafetyInteractionSelectionItem[];
  personal_protective_equipment: ISafetyInteractionSelectionItem[];
  tool_and_equipment_listing: ISafetyInteractionSelectionItem[];
  procedure: ISafetyInteractionSelectionItem[];
  house_keeping: ISafetyInteractionSelectionItem[];
}

interface ISafetyInteractionItem {
  id: number | null;
  name: string;
  custom_action?: boolean;
  status?: string;
  positive_action?: boolean;
  in_the_spot_close?: boolean;
  comment?: string | null;
  risk_potential?: string | null;
  action?: string | null;
}

interface IObservationCategoryItem {
  id?: number | null,
  name: string;
  status: string;
  positive_action: boolean;
  form_name: string, 
  custom_action: boolean, 
  in_the_spot_close: boolean, 
  comment: string, 
  risk_potential: string, 
  action: string,
};

interface IObservationCategory {
  section: string;
  form_name: SafetyDataKeys;
  items: IObservationCategoryItem[];
};
// Customizable Area End


export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  observationCategoriesData: IObservationCategory[];
  screen: "Selection" | "Form";
  responseStatus: "success" | "error" | null;
  errorMsg: string;
  isSafetyManager: boolean;
  isEditable: boolean;
  listening: boolean;
  listeningField: string;
  transcription: string;
  stoppedByTimeout: boolean;
  // Customizable Area End
}
interface SS {}

export default class SafetyInteractionSelectionController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  private recognition: any | undefined;
  callGetSafetyInteractionSelection: string = "";
  callUpdateSafetyInteractionSelection: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
    this.state = {
      observationCategoriesData: configJSON.observationCategoriesData,
      screen: "Selection",
      responseStatus: null,
      errorMsg: "",
      isSafetyManager: false,
      isEditable: true,
      listening: false,
      listeningField: "",
      transcription: "",
      stoppedByTimeout: false,
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    const isSafetyManager = await getStorageData("isSafetyManager");
    this.setState({ isSafetyManager,  isEditable: isSafetyManager ? false : true });
    this.getSafetyInteractionSelection();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJSON = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    this.apiSuccessCallBackController(apiRequestCallId, responseJSON);
    // Customizable Area End
  }

  // Customizable Area Start
  apiSuccessCallBackController = (
    apiRequestCallId: string,
    responseJSON: Record<string, unknown>
  ) => {
    const successCallbackMap = {
        [this.callGetSafetyInteractionSelection]: this.handleGetSafetyInteractionSelectionApiResponse,
        [this.callUpdateSafetyInteractionSelection]: this.handleUpdateSafetyInteractionSelectionApiResponse,
    };

    if (apiRequestCallId) {
      const successCallback: (responseJSON: Record<string, unknown>) => void =
        successCallbackMap[apiRequestCallId];
      !!successCallback && successCallback(responseJSON);
    }
  };

  updateObservationCategoriesData = (responseData: ISafetyData) => {
    return this.state.observationCategoriesData.map(category => {
      // Get corresponding response data for the category
      const responseItems = responseData[category.form_name] || [];
  
      // Update category items
      const updatedItems = category.items.map(item => {
        // Find the matching item in the response data
        const responseItem = responseItems.find(resp => resp.name === item.name);
  
        if (responseItem) {
          // Update the item with new values from response
          return {
            ...item,
            ...responseItem,
            comment: responseItem.comment ?? "",
            risk_potential: responseItem.risk_potential ?? "",
            action: responseItem.action ?? "",
          };
        }
  
        return item;
      });
  
      return { ...category, items: updatedItems };
    });
  }

  handleGetSafetyInteractionSelectionApiResponse = (responseJSON: Record<string, unknown>) => {
    if (this.handleErrorResponse(responseJSON)) {
      return;
    }

    const response = responseJSON as {
      data: ISafetyData;
    };

    if(response.data){
        let observationCategoriesData = this.updateObservationCategoriesData(response.data);
      this.setState({ observationCategoriesData });
    }
  };

  handleUpdateSafetyInteractionSelectionApiResponse = (responseJSON: Record<string, unknown>) => {
    if (this.handleErrorResponse(responseJSON)) {
      return;
    }

    const response = responseJSON as {
      message: string;
    };

    if(response.message){
      this.setState({ responseStatus: "success", errorMsg: response.message});
    }
  };

  getSafetyInteractionSelection = async () => {
      const safetyInteractionId = await getStorageData("safetyInteractionId");
      const token = await getStorageData("authToken");
  
      this.callGetSafetyInteractionSelection = sendAPIRequest(
        `${configJSON.safetyInteractionSelectionApiEndPoint}?id=${safetyInteractionId}`,
        {
          method: configJSON.validationApiMethodType,
          headers: {
            token,
          },
        }
      );
    };

    generateUpdateSafetyInteractionSelectionPayload = async () => {
      const safetyInteractionId = await getStorageData("safetyInteractionId");
      const safetyInteraction: Record<string, ISafetyInteractionItem[]>  = {};
    
      this.state.observationCategoriesData.forEach((category, index) => {
        const sectionKey = category.form_name;
        safetyInteraction[sectionKey] = category.items.map((item) => ({
          id: item.id || null,
          name: item.name,
          custom_action: item.custom_action,
          status: item.status,
          positive_action: item.positive_action,
          in_the_spot_close: item.in_the_spot_close,
          comment: item.comment || "",
          risk_potential: item.risk_potential || "",
          action: item.action || "",
        }));
      });
    
      return { safety_interaction: {
        id: safetyInteractionId,
        ...safetyInteraction
      }};
    };

    updateSafetyInteractionSelection = async () => {
      const token = await getStorageData("authToken");
      const payload =  await this.generateUpdateSafetyInteractionSelectionPayload();
  
      this.callUpdateSafetyInteractionSelection = sendAPIRequest(
        configJSON.safetyInteractionUpdateSelectionApiEndPoint,
        {
          method: configJSON.patchMethodType,
          headers: {
            "Content-Type": configJSON.validationApiContentType,
            token,
          },
          body: payload
        }
      );
    };

  handleErrorResponse = (responseJSON: Record<string, unknown>) => {
    const {
      errors: possibleErrors
    } = responseJSON;

    if (possibleErrors) {
      return true;
    }
    return false;
  };

  handleCheckboxChange = (categoryIndex: number, itemIndex: number) => {
    this.setState((prevState) => {
      const updatedObservations = prevState.observationCategoriesData.map((category, cIndex) =>
        cIndex === categoryIndex
          ? {
              ...category,
              items: category.items.map((item, iIndex) =>
                iIndex === itemIndex ? { ...item, positive_action: !item.positive_action } : item
              )
            }
          : category
      );

      return { observationCategoriesData: updatedObservations };
    });
  }

  handleRadioChange = (categoryIndex: number, itemIndex: number, value: string) => {
    this.setState((prevState) => {
      const updatedObservations = [...prevState.observationCategoriesData];
      updatedObservations[categoryIndex] = {
        ...updatedObservations[categoryIndex],
        items: updatedObservations[categoryIndex].items.map((item, iIndex) =>
          iIndex === itemIndex ? { ...item, status: value } : item
        )
      };
      return { observationCategoriesData: updatedObservations };
    });
  };

  handleInputChange = (sectionName: string, sectionAttrName: string, event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | { name?: string; value: unknown; }>) => {
    const { name, value } = event.target;

    if (name) {
      this.setState((prevState) => {
        const updatedObservations = prevState.observationCategoriesData.map(section => {
          if (section.form_name === sectionName) {
            return {
              ...section,
              items: section.items.map(attribute =>
                attribute.name === sectionAttrName
                  ? { ...attribute, [name]: value }
                  : attribute
              )
            };
          }
          return section;
        });
        return { observationCategoriesData: updatedObservations };
      });
    }
  };

  handleNavigation = (route: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), route);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  handlePrev = () => {
    if(this.state.screen === "Selection"){
      this.handleNavigation("SafetyInteractionObservationCategories");
    }
    else{
      this.setState({ screen: "Selection" });
    }
  };

  handleSaveOrEdit = () => {
    if(this.state.isSafetyManager){
      if(this.state.isEditable){
        this.updateSafetyInteractionSelection();
        this.setState({ isEditable: false });
      }
      else{
        this.setState({ isEditable: true });
      }
    }
    else{
      this.updateSafetyInteractionSelection();
    }
  };

  handleNext = () => {
    if(this.state.screen === "Selection"){
      const formSections = this.state.observationCategoriesData.some(section =>
        section.items.some(item => item.status === "at_risk")
      );
      if(formSections){
        this.setState({ screen: "Form"});
      }
      else if(this.state.isEditable){
        this.updateSafetyInteractionSelection();
      }
      else{
        this.handleNavigation("ObserveAndTiming");
      }
    }
    else if(this.state.screen === "Form"){
      this.updateSafetyInteractionSelection();
      this.handleNavigation("ObserveAndTiming");
    }
  };

  handleCloseSnackbar = () => {
    this.setState({ responseStatus: null, errorMsg: ""});
  };

  renderMic(fieldName: string) {
    return this.state.listening && this.state.listeningField === fieldName ? micStart : mic;
};
  
  setMikeData = async (sectionName: string, sectionAttrName: string, fieldName: string) => {
    this.setState((prevState) => {
      const updatedObservations = prevState.observationCategoriesData.map(section => {
        if (section.form_name === sectionName) {
          return {
            ...section,
            items: section.items.map(attribute =>
              attribute.name === sectionAttrName
                ? { ...attribute, [fieldName]: this.state.transcription }
                : attribute
            )
          };
        }
        return section;
      });

      return { 
        observationCategoriesData: updatedObservations,
        transcription: "",
       };
    });
  };

  handleSpeech = (fieldName: string) => {
    return new Promise((resolve, reject) => {
      const SpeechRecognition = (window as any).SpeechRecognition || (window as any).webkitSpeechRecognition;

      if (!SpeechRecognition) {
        console.error("Speech Recognition API is not supported in this browser.");
        return;
      }

      const recognition = new SpeechRecognition();
      recognition.lang = "en-US";

      this.setState({ 
        listening: true, 
        listeningField: fieldName 
      });
      recognition.onstart = () => {
        console.warn("🎙️ Speech recognition started...");
      };

      recognition.onresult = (event: SpeechRecognitionEvent) => {
        const transcript = event.results[0][0].transcript;
        this.setState((prevState) => ({
          transcription: prevState.transcription ? `${prevState.transcription} ${transcript}` : transcript
        }));

        console.warn("📝 Text:", transcript);
        resolve(transcript);
      };

      recognition.onerror = (event: Event) => {
        const error = (event as any).error;
        console.error("❌ Speech recognition error:", error);
        this.setState({ 
          listening: false, 
          listeningField: "" 
        });
        reject(new Error(error));
      };

      recognition.onend = () => {
        console.warn("Speech recognition ended.");
        this.setState({ listening: false, listeningField: "" });
      };

      recognition.start();
    });
  };
  // Customizable Area End
}

