import React from "react";

//Customizable Area Start
import { Box, Button, Input, Typography } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import {
  logo,
  forgotPasswordLogo,
  leftLogoForgotPass,
} from "./assets";
import ForgotPasswordController, { Props } from "./ForgotPasswordController";
import ErrorMessage from "./ErrorMessage";
import Loader from "../../../components/src/Loader.web";
//Customizable Area End

//Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
  typography: {
    fontFamily: "ClotherRegular",
    h2: {
      fontSize: "28px",
      lineHeight: "24px",
      fontWeight: "bold",
      marginBottom: "15px",
      color: "#18181E",
    },
    body2: {
      color: "#A1A1A1",
      fontSize: "18px",
      lineHeight: "18px",
    },
  },
  overrides: {
    MuiInputBase: {
      root: {
        height: "54px",
        border: "1px solid #DCDCDC",
        padding: "15px 15px",
        fontSize: "16px",
        lineHeight: "18px",
        color: "#2B2A29",
        fontWeight: 400,
        letterSpacing: "0.5px",
        textOverflow: "ellipsis",
        justifyContent: "flex-start",
        borderRadius: "8px",
        width: "100%",
        "&.Mui-disabled": {
          cursor: "no-drop",
        },
        "&:hover, &.Mui-focused": {
          border: `1px solid #8735E1`,
        },
      },
    },
    MuiButton: {
      root: {
        textTransform: "none",
        height: "54px",
        boxShadow: "none",
        width: "100%",
        lineHeight: "22px",
        border: "none",
      },
      contained: {
        boxShadow: "none",
        background: "#8735E1",
        backgroundColor: "#8735E1",
        borderRadius: "8px",
        fontWeight: 700,
        fontSize: "20px",
        color: "#fff",
        "&.MuiButton-contained:hover": {
          boxShadow: "none",
          background: "#8735E1",
        },
        // "&:hover, &:focus": {
        //   boxShadow: "none",
        //   background: "#8735E1",
        // },
      },
    },
  },
});
//Customizable Area End

export default class ForgotPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    //Customizable Area Start
    this.sendVerificationCode = this.sendVerificationCode.bind(this);
    //Customizable Area End
  }

  render() {
    //Customizable Area Start
    return (
      <ThemeProvider theme={theme}>
        <Box sx={webStyle.forgotPasswordSection}>
          <Box sx={webStyle.forgotPasswordLeft}>
            <figure>
              <img width={500} src={leftLogoForgotPass} alt="" />
            </figure>
          </Box>
          <Box sx={webStyle.forgotPasswordRight}>
            <Box style={webStyle.forgotPasswordRightInner}>
              <Box sx={webStyle.forgotPasswordHeader}>
                <Box sx={webStyle.forgotPasswordLogo}>
                  <figure>
                    <img src={logo} alt="" />
                  </figure>
                </Box>
              </Box>

              <Box sx={webStyle.textCenter}>
                <Box
                  sx={webStyle.imgContainer}
                  style={{
                    background: "rgba(135,53,225,0.1)",
                  }}
                >
                  <figure>
                    <img src={forgotPasswordLogo} alt="" />
                  </figure>
                </Box>
                <Typography variant="h2">Forgot Password</Typography>
                <Typography variant="body2">
                  Please enter your registered email address or mobile number to
                  receive a verification code
                </Typography>
                <Box style={webStyle.textField}>
                  <Input
                    data-test-id="txtInputEmail"
                    placeholder="Email Address or Mobile Number"
                    fullWidth={true}
                    value={this.state.mobileOrEmail}
                    onChange={(e) =>
                      this.setForgotPassswordEmail(e.target.value)
                    }
                    type="text"
                    disableUnderline
                    onKeyPress={(e) => this.handleSendCodeOnEnterKey(e)}
                  />
                </Box>
                <ErrorMessage error={this.state.emailVerificationScreenError} />
                <Button
                  data-test-id="btnOtpForPhoneNumberButton"
                  onClick={() => this.sendVerificationCode()}
                  variant="contained"
                  disabled={this.state.isLoading}
                >
                  Send Code
                </Button>
                <Button
                  data-test-id="btnLogin"
                  style={webStyle.btnStyle}
                  onClick={()=> this.navigationToAnyPage("Home")}
                >
                  Back to Login
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
        <Loader loading={this.state.isLoading} />
      </ThemeProvider>
    );
    //Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  forgotPasswordSection: {
    width: "100%",
    display: "flex",
    height: "100vh",
    overflow: "hidden",
  },
  forgotPasswordLeft: {
    maxwidth: "60%",
    width: "100%",
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: "60%",
  },
  forgotPasswordRight: {
    background: "#fff",
    maxWidth: "40%",
    width: "100%",
    padding: "20px 0",
    display: "flex",
    overflowY: "auto",
  },
  forgotPasswordRightInner: {
    maxWidth: "400px",
    width: "100%",
    margin: "0 auto",
    fontFamily: "ClotherRegular",
  },
  forgotPasswordHeader: {
    marginBottom: "50px",
    textAlign: "center",
  },
  forgotPasswordLogo: {
    display: "inline-block",
    marginBottom: "15px",
    width: "100%",
    textAlign: "center",
  },
  textField: {
    width: "100%",
    marginBottom: "18px",
    marginTop: "18px",
  },
  textCenter: {
    textAlign: "center",
  },
  imgContainer: {
    margin: "0 auto",
    marginBottom: "20px",
    width: "90px",
    height: "90px",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  btnStyle: {
    color: "#8735E1",
    fontSize: "18px",
    fontWeight: 700,
    marginTop: "18px",
    cursor: "pointer",
  },
  errorMessage: {
    color: "#F30202",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "18px",
    marginBottom: "18px",
  }
};
// Customizable Area End
