import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from 'react';
import { getStorageData } from "../../../framework/src/Utilities";
export const configJSON = require("./config");

export enum StepEnum {
  Man = "Man",
  Machine = "Machine",
  Method = "Method",
  Material = "Material",
  Management = "Management",
  MotherNature = "Mother Nature",
  Overview = "Overview",
}


export interface ICause {
    name: string;
    description: string;
    isEditing: boolean;
};
  
export interface ICategory {
    name: string;
    causes: ICause[];
};

interface PayloadCause {
  id: string | null;
  description: string;
  order: number;
}

interface PayloadCategory {
  category: string;
  causes: PayloadCause[];
}

interface Payload {
  post: {
    id: string;
    d4_completed: boolean;
    root_causes: PayloadCategory[];
  };
}
// Customizable Area End


export interface Props {
  navigation: any;
  // Customizable Area Start
  handlePrev:  () => void;
  handleNext:  () => void;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  categories: ICategory[];
  activeTab: StepEnum;
  responseStatus: "success" | "error" | null;
  errorMsg: string;
  isD4Completed: boolean;
  // Customizable Area End
}
interface SS {
    // Customizable Area Start
    // Customizable Area End
}

export default class RootCauseInvestigationController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getRootCauseDataAPICallId: string = "";
  updateD4FormAPICallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
    this.state = {
        activeTab: StepEnum.Man,
        categories: this.getInitialCategories(),
        responseStatus: null,
        errorMsg: "",
        isD4Completed: false,
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getRootCausesData();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (webResponseJson && !webResponseJson.errors) {
        if (this.updateD4FormAPICallId === webApiRequestCallId) {
          this.setState(
            { responseStatus: "success", errorMsg: webResponseJson.message, isD4Completed: true }
          );
        }
        
        else if(this.getRootCauseDataAPICallId === webApiRequestCallId){
          if(webResponseJson.data){
            const categories = this.convertJsonToState(webResponseJson.data);

            this.setState({ categories, isD4Completed: webResponseJson.d4_completed });
          }
        }
      }
      else{
        this.setState({ responseStatus: "error", errorMsg: webResponseJson.errors[0]});
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  convertJsonToState = (responseData: any) => {
    return responseData.map((category: { category: any; root_causes: any[]; }) => {
      // Create a new category object with 'name' from the 'category' in JSON data
      return {
        name: category.category,
        causes: category.root_causes.map(cause => {
          return {
            name: `Cause ${String(cause.order).padStart(2, '0')}`, // "Cause 01", "Cause 02", etc.
            description: cause.description,
            isEditing: false, // Set to false by default as per the current state format
          };
        }),
      };
    });
  }

  handleCauseChange(categoryIndex: number, causeIndex: number, value: string) {
    const updatedCategories = [...this.state.categories];
    updatedCategories[categoryIndex].causes[causeIndex].description = value;
    this.setState({ categories: updatedCategories });
  }

  saveCause(categoryIndex: number, causeIndex: number) {
    const updatedCategories = [...this.state.categories];
    updatedCategories[categoryIndex].causes[causeIndex].isEditing = false;
    this.setState({ categories: updatedCategories });
  }

  deleteCause(categoryIndex: number, causeIndex: number) {
    const updatedCategories = [...this.state.categories];
    updatedCategories[categoryIndex].causes.splice(causeIndex, 1);
    this.setState({ categories: updatedCategories });
  }

  addCause(categoryIndex: number) {
    const updatedCategories = [...this.state.categories];
    updatedCategories[categoryIndex].causes.push({ name: `Cause 0${updatedCategories[categoryIndex].causes.length + 1}`, description: '', isEditing: true });
    this.setState({ categories: updatedCategories });
  }
  
  setActiveTab(title: StepEnum) {
    if(this.state.isD4Completed){
      this.setState({ activeTab: title});
    }
    else{
      this.setState({ responseStatus: "error", errorMsg: "Please fill the details first"});
    }
  }

  convertStateToPayload = async (categories: ICategory[]): Promise<Payload> => {
  let createPostId = await getStorageData("createPostId");

    return {
      post: {
        id: createPostId,
        d4_completed: false,
        root_causes: categories.map((category) => ({
          category: category.name,
          causes: category.causes.map((cause, index) => ({
            id: null,
            description: cause.description,
            order: index + 1,
          })),
        })),
      },
    };
  };

     
  handleNextbutton =() => {
      this.props.handleNext();
    }

  getRootCausesData = async () => {
  let createPostId = await getStorageData("createPostId");
    
    let tokenValue = localStorage.getItem("authToken");
    const headers = {
      token: tokenValue 
    };
    
    const getAllRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getRootCauseDataAPICallId = getAllRequestMsg.messageId;
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getRootCausesAPIEndPoint}?id=${createPostId}`
    );

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getAllRequestMsg.id, getAllRequestMsg);
  }

  submitRootCausesForm = async () => {
    let formdata = await this.convertStateToPayload(this.state.categories);

    let token = await getStorageData("authToken", false);
    const headers = {
      "Content-Type": configJSON.dashboarContentType,
      token: token,
    };
    const getPostRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    getPostRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.d4FormAPIEndPoint}`
    );
    getPostRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getPostRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(formdata)
    );
    getPostRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchMethodType
    );
    this.updateD4FormAPICallId = getPostRequestMsg.messageId;
    runEngine.sendMessage(getPostRequestMsg.id, getPostRequestMsg);
  }

  handleCloseSnackbar = () => {
    this.setState({ responseStatus: null, errorMsg: ""});
  }

  getInitialCategories = () => {
    const categories = [StepEnum.Man, StepEnum.Machine, StepEnum.Method, StepEnum.Material, StepEnum.Management, StepEnum.MotherNature];

    return categories.map((category) => {
      return {
        name: category,
        causes: [
          {
            name: "Cause 01",
            description: "",
            isEditing: true,
          },
        ],
      }
    })
  }
  // Customizable Area End
}

