// Customizable Area Start
import React, { ChangeEvent, FocusEvent } from "react";

import {
  Button,
  Grid,
  Typography,
  FormHelperText,
  Box,
  Select,
  Input,
  MenuItem,
  Checkbox,
  Switch,
  Radio,
  Dialog,
  DialogContent,
  Breadcrumbs,
  DialogTitle,
} from "@material-ui/core";
import { Formik } from "formik";
import { SpeachToText, micStart, selectedTrue } from "./assets";
import {
  createTheme,
  ThemeProvider,
  withStyles,
} from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import { KeyboardArrowDown, NavigateNext } from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";
import StepLabel from "@material-ui/core/StepLabel";
import * as Yup from "yup";
import i18n from "i18next";
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

export const CustomCheckbox: React.ComponentType<any> = withStyles({
  root: {
    "&$checked": {
      color: "rgb(135, 53, 225)",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

interface Values {
  image: string;
}

interface Option {
  attributes: {
    id: number;
    name: string;
  };
}

interface RenderSelectFieldProps {
  label: string;
  name: string;
  value: number | string;
  handleChange: (event: ChangeEvent<{ name?: string; value: unknown }>) => void;
  options: Option[];
  selectedOptions: number[];
  handleBlur: (event: FocusEvent<{ name?: string; value: unknown }>) => void;
  removeIcon: (id: number) => void;
}

const firstSchema = Yup.object().shape({
  describeIssue: Yup.string().required(() => i18n.t("Description is required")),
  work_location: Yup.string().required(() => i18n.t("Location is required")),
  consern_type: Yup.string().required(() => i18n.t("Concern type is required")),
  hazard: Yup.string().required(() => i18n.t("Hazard is required")),
  frequency: Yup.string().required(() => i18n.t("Frequency is required")),
  sevirity: Yup.string().required(() => i18n.t("Severity is required")),
  potential_impact: Yup.string().required(() => i18n.t("Potential impact is required")),
  risk_levels: Yup.string().required(() => i18n.t("Risk levels is required")),
});
const secondSchema = Yup.object().shape({
  activityIdenty: Yup.string().required(() => i18n.t("Activity Identification is required")),
  TaskIdenty: Yup.string().required(() => i18n.t("Task Identification is required")),
});

const thirdSchema = Yup.object().shape({
    statutory_Concern: Yup.string().required(
      () => i18n.t("Statutory / Regulatory / Legal Concern is required")
    ),
    sop_Compliance: Yup.string().required(
      () => i18n.t("Standard Compliance - SOP Compliance is required")
    ),
    parameter_Violation: Yup.string().required(
      () => i18n.t("No Go Parameter Violation is required")
    ),
    safety_Violation: Yup.string().required(
      () => i18n.t("Safety Absolute Violation is required")
    ),
    corrective_Action: Yup.string().required(() => i18n.t("Corrective Action is required")),
    preventive_Acion: Yup.string().required(() => i18n.t("Preventive Action is required")),
    risk_count: Yup.string().required(() => i18n.t("Risk Count is required")),
});

const fourthSchema = Yup.object().shape({
  postingFor: Yup.string().required(() => i18n.t("Posting for is required")),
});

import SafetyInchargeApprovalController, {
  Props,
  configJSON,
} from "./SafetyInchargeflowController";
import TextArea from "antd/lib/input/TextArea";
import Loader from "../../../components/src/Loader.web";

export default class SafetyInchargeApprovalForm extends SafetyInchargeApprovalController {
  constructor(props: Props) {
    super(props);
  }

  renderImage = (values: Values) => {
    const { selectedImageIndex } = this.state;
    return (
      <div style={webStyle.detailsBox as React.CSSProperties}>
        {this.state.combineAndDetailsPost.map((item, index) => (
          <div key={index} style={{ position: "relative" }}>
            <Radio
              style={webStyle.radioBtn as React.CSSProperties}
              checkedIcon={<img src={selectedTrue} />}
              value={values.image}
              data-test-id="handleImageSelect"
              checked={selectedImageIndex === item.attributes.id}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                this.handleImageSelect(event, item.attributes.id)
              }
            />
            <img
              src={
                item.attributes.output_image_url ? item.attributes.output_image_url : item.attributes.image_url ||
                "https://e7.pngegg.com/pngimages/21/312/png-clipart-camera-computer-icons-graphy-camera-icon-camera-lens-camera-icon.png"
              }
              style={webStyle.images}
            />
          </div>
        ))}
      </div>
    );
  };

  renderDescribeIssueSection = (
    handleChange: any,
    handleBlur: any,
    values: any,
    errors: any,
    touched: any,
    setFieldValue: any
  ) => (
    <Grid item xs={12} sm={12} md={6}>
      <Box display={"flex"} justifyContent={"space-between"}>
        <Typography style={webStyle.relatedImgText}>
          {i18n.t("Describe Issue")}
        </Typography>
        <img
          src={this.state.listening ? micStart : SpeachToText}
          style={webStyle.mikeIcon}
          data-test-id="toggleDescribe"
          onClick={async () => {
            await this.toggleListening("listening", "stoppedByTimeout");
            setFieldValue(
              "describeIssue",
              values.describeIssue + " " + this.state.transcription
            );
          }}
        />
      </Box>
      <textarea
        placeholder={i18n.t("Describe Issue")}
        name="describeIssue"
        id="describeIssue"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.describeIssue}
        data-test-id="describeIssue"
        style={webStyle.textArea as React.CSSProperties}
      />
      <Typography style={webStyle.errorStyle}>
        {errors.describeIssue !== "" &&
          touched.describeIssue &&
          errors.describeIssue}
      </Typography>
    </Grid>
  );

  renderLocationSelect = (
    handleChange: any,
    handleBlur: any,
    values: any,
    errors: any,
    touched: any
  ) => (
    <Grid item xs={12} sm={12} md={6}>
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <label>{i18n.t("Location - BU or Cell")}</label>
        <Box style={{ display: "flex", alignItems: "center" }}>
          <Switch />
          <label className="related-title">{i18n.t("Auto detect Location")}</label>
        </Box>
      </Box>
      <Input
        placeholder={i18n.t("Location")}
        fullWidth={true}
        type="text"
        disabled
        disableUnderline
        name="work_location"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.work_location}
        data-test-id="location"
        style={webStyle.labelStyle}
      />
      <Typography style={webStyle.errorStyle}>
        {errors.work_location != "" && touched.work_location && errors.work_location}
      </Typography>
    </Grid>
  );

  renderConcernSelect = (
    handleChange: any,
    handleBlur: any,
    values: any,
    errors: any,
    touched: any
  ) => (
    <Grid item xs={12} sm={12} md={6} style={{ marginTop: "8px" }}>
      <label
        style={{
          ...webStyle.label,
          display: "flex",
          alignItems: "center",
        }}
      >
        {i18n.t("Concern is related to")}
      </label>
      <Select
        name="consern_type"
        data-id="concernInputId"
        data-test-id="concernInputId"
        label="concern"
        disableUnderline
        style={
          {
            ...webStyle.input,
            ...webStyle.inputStd,
          } as React.CSSProperties
        }
        renderValue={
          values.consern_type
            ? undefined
            : () => <div style={{ color: "#DCDCDC" }}>{i18n.t("Select Concern")}</div>
        }
        IconComponent={KeyboardArrowDown}
        onBlur={handleBlur}
        onChange={handleChange}
        value={values.consern_type}
        displayEmpty
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          getContentAnchorEl: null,
          PaperProps: {
            style: {
              maxHeight: "200px",
              width: "250px",
            },
          },
        }}
      >
        {this.state.post_concerns.map((concern: { attributes: { id: number, name: string } }, index: number) => {
          return (
            <MenuItem value={concern.attributes.id} key={index}>
              {concern.attributes.name}
            </MenuItem>
          );
        })}
      </Select>
      <Typography style={webStyle.errorStyle}>
        {errors.consern_type != "" &&
          touched.consern_type &&
          errors.consern_type}
      </Typography>
    </Grid>
  );

  renderHazaedSelect = (
    handleChange: any,
    handleBlur: any,
    values: any,
    errors: any,
    touched: any
  ) => (
    <Grid item xs={12} sm={12} md={6}>
      <label style={webStyle.textFieldLabel}>{i18n.t("Hazard")}</label>
      <Select
        name="hazard"
        data-id="HazardInputId"
        data-test-id="HazardInputId"
        label={i18n.t("Hazard")}
        displayEmpty
        style={
          {
            ...webStyle.input,
            ...webStyle.inputStd,
          } as React.CSSProperties
        }
        value={values.hazard}
        renderValue={
          values.hazard
            ? undefined
            : () => <div style={{ color: "#DCDCDC" }}>{i18n.t("Select Hazard")}</div>
        }
        disableUnderline
        onChange={handleChange}
        onBlur={handleBlur}
        IconComponent={KeyboardArrowDown}
        MenuProps={{
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          PaperProps: {
            style: {
              maxHeight: "200px",
              width: "250px",
            },
          },
        }}
      >
        {this.state.hazards.map((hazard: { attributes: { id: number, name: string } }, index: number) => (
          <MenuItem value={hazard.attributes.id} key={index}>
            {hazard.attributes.name}
          </MenuItem>
        ))}
      </Select>
      <Typography style={webStyle.errorStyle}>
        {errors.hazard != "" && touched.hazard && errors.hazard}
      </Typography>
    </Grid>
  );
  
  renderFrequencySelect = (
    handleChange: any,
    handleBlur: any,
    values: any,
    errors: any,
    touched: any,
    setFieldValue: any
  ): JSX.Element => (
    <Grid item xs={12} sm={12} md={6}>
      <label style={webStyle.textFieldLabel}>{i18n.t("Frequency")}</label>
      <Select
        name="frequency"
        id="frequency"
        data-id="FrequencyInputId"
        IconComponent={KeyboardArrowDown}
        disableUnderline
        data-test-id="FrequencyInputId"
        label={i18n.t("Frequency")}
        displayEmpty
        value={values.frequency}
        style={
          {
            ...webStyle.input,
            ...webStyle.inputStd,
          } as React.CSSProperties
        }
        onChange={(event) => {
          handleChange(event, "frequency");
          this.setFrequencyData(event, values, setFieldValue)
        }}
        onBlur={handleBlur}
        renderValue={
          values.frequency
            ? undefined
            : () => <div style={{ color: "#DCDCDC" }}>{i18n.t("Select Frequency")}</div>
        }
        MenuProps={{
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          PaperProps: {
            style: {
              maxHeight: "200px",
              width: "250px",
            },
          },
        }}
      >
        {this.state.frequencies.map((frequencie: { attributes: { id: number, name: string } }, index: number) => {
          return (
            <MenuItem value={frequencie.attributes.id} key={index}>
              {frequencie.attributes.name}
            </MenuItem>
          );
        })}
      </Select>
      <Typography style={webStyle.errorStyle}>
        {errors.frequency != "" && touched.frequency && errors.frequency}
      </Typography>
    </Grid>
  );
  
  renderSeviritySelect = (
    handleChange: any,
    handleBlur: any,
    values: any,
    errors: any,
    touched: any,
    setFieldValue: any
  ): JSX.Element => (
    <Grid item xs={12} sm={12} md={6}>
      <label style={webStyle.textFieldLabel}>{i18n.t("Sevirity")}</label>
  
      <Select
        name="sevirity"
        id="sevirity"
        value={values.sevirity}
        IconComponent={KeyboardArrowDown}
        disableUnderline
        displayEmpty
        data-test-id="sevirity"
        style={
          {
            ...webStyle.input,
            ...webStyle.inputStd,
          } as React.CSSProperties
        }
        renderValue={
          values.sevirity
            ? undefined
            : () => <div style={{ color: "#DCDCDC" }}>{i18n.t("Select Sevirity")}</div>
        }
        onChange={(event) => {
          handleChange(event, "sevirity");
          this.setSevirityData(event, values, setFieldValue)
        }}
        onBlur={handleBlur}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          getContentAnchorEl: null,
          PaperProps: {
            style: {
              maxHeight: "200px",
              width: "250px",
            },
          },
        }}
      >
        {this.state.severities.map((severitie: { attributes: { id: number, name: string } }, index: number) => {
          return (
            <MenuItem value={severitie.attributes.id} key={index}>
              {severitie.attributes.name}
            </MenuItem>
          );
        })}
      </Select>
      <Typography style={webStyle.errorStyle}>
        {errors.sevirity != "" && touched.sevirity && errors.sevirity}
      </Typography>
    </Grid>
  );
  
  renderConsequenceSelect = (
    handleChange: any,
    handleBlur: any,
    values: any,
    errors: any,
    touched: any,
  ): JSX.Element => (
    <Grid item xs={12} sm={12} md={6}>
      <label style={webStyle.textFieldLabel}>
        {i18n.t("Consequence(HSE) - Potential Impact")}
      </label>
      <Select
        style={
          {
            ...webStyle.input,
            ...webStyle.inputStd,
          } as React.CSSProperties
        }
        value={values.potential_impact}
        IconComponent={KeyboardArrowDown}
        disableUnderline
        data-test-id="potential_impact"
        displayEmpty
        renderValue={
          values.potential_impact
            ? undefined
            : () => (
              <div style={{ color: "#DCDCDC" }}>{i18n.t("Select potential Impact")}</div>
            )
        }
        name="potential_impact"
        onChange={handleChange}
        onBlur={handleBlur}
        MenuProps={{
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          PaperProps: {
            style: {
              maxHeight: "200px",
              width: "250px",
            },
          },
        }}
      >
        {this.state.consequences.map((consequence, index) => (
          <MenuItem key={index} value={consequence.attributes.id}>
            {consequence.attributes.name}
          </MenuItem>
        ))}
      </Select>
      <Typography style={webStyle.errorStyle}>
        {errors.potential_impact != "" &&
          touched.potential_impact &&
          errors.potential_impact}
      </Typography>
    </Grid>
  );
  
  renderRiskLevelName = (handleChange: any, handleBlur: any, values: any) => (
    <Grid
      item
      lg={6}
      md={6}
      container
      alignItems="center"
      justifyContent="space-between"
    >
      <FormHelperText style={webStyle.label}>{i18n.t("Risk Levels")}</FormHelperText>
      <Input
        placeholder={i18n.t("Risk Levels")}
        fullWidth={true}
        type="text"
        disableUnderline
        name="risk_levels"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.risk_levels}
        data-test-id="full-name"
        style={{
          ...webStyle.labelStyle,
          borderColor:
            values.risk_levels == ""
              ? "#DCDCDC"
              : this.checkborderColor(values.risk_levels),
          backgroundColor:
            values.risk_levels == ""
              ? "#FFFFFF"
              : this.checkbackgroundColor(values.risk_levels),
        }}
        disabled
      />
    </Grid>
  );
  
  renderSelectField = ({
    label,
    name,
    value,
    handleChange,
    options,
    selectedOptions,
    handleBlur,
    removeIcon,
  }: RenderSelectFieldProps) => {
    return (
      <Grid item lg={6} md={6} sm={6} xs={12}>
        <FormHelperText style={webStyle.label}>{i18n.t("label")}</FormHelperText>
        <Select
          name={name}
          style={{ ...webStyle.input, ...webStyle.inputStd }}
          IconComponent={KeyboardArrowDown}
          value={value}
          renderValue={
            value
              ? undefined
              : () => <div style={{ color: "#DCDCDC" }}>{i18n.t("label")}</div>
          }
          disableUnderline
          displayEmpty
          onClick={(e: any) => handleChange(e)}
          onBlur={handleBlur}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            PaperProps: {
              style: {
                maxHeight: "200px",
                width: "250px",
              },
            },
            getContentAnchorEl: null,
          }}
          data-test-id="selectField"
        >
          {options.map((option: { attributes: { id: number, name: string } }) => (
            <MenuItem
              key={option.attributes.id}
              value={option.attributes.id}
              style={
                selectedOptions.includes(option.attributes.id)
                  ? webStyle.selectedStyle
                  : {}
              }
            >
              <CustomCheckbox
                checked={selectedOptions.includes(option.attributes.id)}
              />
              {option.attributes.name}
            </MenuItem>
          ))}
          {options.length === 0 && (
            <MenuItem value={""}>
              {i18n.t("No Data")}
            </MenuItem>
          )}
        </Select>
        <div style={webStyle.divStyle}>
          {options
            .filter((item: { attributes: { id: number } }) => selectedOptions.includes(item.attributes.id))
            .map((option: { attributes: { id: number, name: string } }, index: number) => (
              <Box
                style={webStyle.chipsBox}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                key={index}
              >
                <Typography style={webStyle.chipsText as React.CSSProperties}>{option.attributes.name}</Typography>
                <CloseIcon
                  data-test-id="closeicon"
                  style={webStyle.closeIconStyle}
                  onClick={() => removeIcon(option.attributes.id)}
                />
              </Box>
            ))}
        </div>
      </Grid>
    );
  };

  getStyleDropDown = (selectedData: number[], index: number): React.CSSProperties => {
    return selectedData.includes(index) ? webStyle.selectedStyle : {};
  };

  renderPostingLevelFields = (values: any, handleBlur: any) => {
    switch (values.postingFor) {
      case "Global Level":
        return (
          <>
            {this.renderSelectField({
              label: i18n.t("Select Country"),
              name: "countryLevel",
              value: values.countryLevel,
              handleChange: this.handleChangeCountry,
              options: this.state.countryDropDownData,
              selectedOptions: this.state.selectedOptionsCountry,
              handleBlur: handleBlur,
              removeIcon: this.removeCountry,
            })}

            {this.renderSelectField({
              label: i18n.t("Select Plant"),
              name: "plantLevel",
              value: values.plantLevel,
              handleChange: this.handleChangePlantLevel,
              options: this.state.plantDropdownData,
              selectedOptions: this.state.selectedOptionsPlant,
              handleBlur: handleBlur,
              removeIcon: this.removePlant,
            })}

            {this.renderSelectField({
              label: i18n.t("Select BU"),
              name: "buLevel",
              value: values.buLevel,
              handleChange: this.handleChangeBULevel,
              options: this.state.buDropdownData,
              selectedOptions: this.state.selectedOptionsBU,
              handleBlur: handleBlur,
              removeIcon: this.removeBU,
            })}

            <Grid item lg={6} md={6} sm={6} xs={12}>
              <FormHelperText style={webStyle.label} data-test-id="designationLevel2">
              {i18n.t("Select Designation")}
              </FormHelperText>
              <Select
                name="designationLevel"
                value={values.designationLevel}
                data-id="designationLevel"
                style={{ ...webStyle.input, ...webStyle.inputStd }}
                renderValue={
                  values.designationLevel
                    ? undefined
                    : () => (
                      <div style={webStyle.selectPlaceholder}>
                        Select Designation
                      </div>
                    )
                }
                IconComponent={KeyboardArrowDown}
                displayEmpty
                disableUnderline
                onChange={(event) => {
                  this.handleChangeDesignation(event);
                }}
                onBlur={handleBlur}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  PaperProps: {
                    style: {
                      maxHeight: "200px",
                      width: "250px",
                    },
                  },
                  getContentAnchorEl: null,
                }}
              >
                {this.state.designationDropdownData.map((desigDrop: { attributes: { id: number, name: string } }) => (
                  <MenuItem
                    value={desigDrop.attributes.id}
                    key={desigDrop.attributes.id}
                    style={this.getStyleDropDown(
                      this.state.selectedOptionsDesignation,
                      desigDrop.attributes.id
                    )}
                  >
                    <CustomCheckbox
                      checked={this.state.selectedOptionsDesignation.includes(
                        desigDrop.attributes.id
                      )}
                    />
                    {desigDrop.attributes.name}
                  </MenuItem>
                ))}
                {this.state.designationDropdownData.length === 0 && (
                  <MenuItem value={""}>
                    {i18n.t("No Data")}
                  </MenuItem>
                )}
              </Select>
              <div style={webStyle.divStyle}>
                {this.state.designationDropdownData
                  .filter((data: { attributes: { id: number } }) =>
                    this.state.selectedOptionsDesignation.includes(
                      data.attributes.id
                    )
                  )
                  .map((desigData: { attributes: { id: number, name: string } }, index: number) => (
                    <Box
                      style={webStyle.chipsBox}
                      justifyContent={"center"}
                      display={"flex"}
                      alignItems={"center"}
                      key={index}
                    >
                      <Typography style={webStyle.chipsText as React.CSSProperties}>{desigData.attributes.name}</Typography>
                      <CloseIcon
                        style={webStyle.closeIconStyle}
                        onClick={() => this.removeDesignation(desigData.attributes.id)}
                      />
                    </Box>
                  ))}
              </div>
            </Grid>
          </>
        );
      case "Country Level":
        return (
          <>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <FormHelperText style={webStyle.label}>
              {i18n.t("Select Plant")}
              </FormHelperText>
              <Select
                name="plantLevel"
                IconComponent={KeyboardArrowDown}
                style={{ ...webStyle.input, ...webStyle.inputStd }}
                data-id="SelectInputId"
                value={values.plantLevel}
                renderValue={
                  values.plantLevel
                    ? undefined
                    : () => <div style={{ color: "#DCDCDC" }}>{i18n.t("Select Plant")}</div>
                }
                disableUnderline
                onBlur={handleBlur}
                displayEmpty
                onChange={(event) => {
                  this.handleChangePlantLevel(event);
                }}
                MenuProps={{
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  PaperProps: {
                    style: {
                      width: "250px",
                      maxHeight: "200px",
                    },
                  },
                }}
              >
                {this.state.plantDropdownData.map((option: { attributes: { id: number, name: string } }) => (
                  <MenuItem
                    value={option.attributes.id}
                    key={option.attributes.id}
                    style={this.getStyleDropDown(
                      this.state.selectedOptionsPlant,
                      option.attributes.id
                    )}
                  >
                    <CustomCheckbox
                      checked={this.state.selectedOptionsPlant.includes(
                        option.attributes.id
                      )}
                    />
                    {option.attributes.name}
                  </MenuItem>
                ))}
              </Select>
              <div style={webStyle.divStyle}>
                {this.state.plantDropdownData
                  .filter((item: { attributes: { id: number } }) =>
                    this.state.selectedOptionsPlant.includes(item.attributes.id)
                  )
                  .map((option: { attributes: { id: number, name: string } }, index: number) => (
                    <Box
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      style={webStyle.chipsBox}
                      key={index}
                    >
                      <Typography style={webStyle.chipsText as React.CSSProperties}>{option.attributes.name}</Typography>
                      <CloseIcon
                        style={webStyle.closeIconStyle}
                        onClick={() => this.removePlant(option.attributes.id)}
                      />
                    </Box>
                  ))}
              </div>
            </Grid>


            {this.renderSelectField({
              label: i18n.t("Select BU"),
              name: "buLevel",
              value: values.buLevel,
              handleChange: this.handleChangeBULevel,
              options: this.state.buDropdownData,
              selectedOptions: this.state.selectedOptionsBU,
              handleBlur: handleBlur,
              removeIcon: this.removeBU,
            })}


            {this.renderSelectField({
              label: i18n.t("Select Designation"),
              name: "designationLevel",
              value: values.designationLevel,
              handleChange: this.handleChangeDesignation,
              options: this.state.designationDropdownData,
              selectedOptions: this.state.selectedOptionsDesignation,
              handleBlur: handleBlur,
              removeIcon: this.removeDesignation,
            })}
          </>
        );
      case "Plant Level":
        return (
          <>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <FormHelperText style={webStyle.label}>{i18n.t("Select BU")}</FormHelperText>
              <Select
                name="buLevel"
                data-id="buLevel"
                style={{ ...webStyle.input, ...webStyle.inputStd }}
                IconComponent={KeyboardArrowDown}
                value={values.buLevel}
                renderValue={
                  values.buLevel
                    ? undefined
                    : () => <div style={{ color: "#DCDCDC" }}>{i18n.t("Select BU")}</div>
                }
                disableUnderline
                displayEmpty
                onChange={(event) => {
                  this.handleChangeBULevel(event);
                }}
                onBlur={handleBlur}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  PaperProps: {
                    style: {
                      maxHeight: "200px",
                      width: "250px",
                    },
                  },
                  getContentAnchorEl: null,
                }}
              >
                {this.state.buDropdownData.map((buDropDawn: { attributes: { id: number, name: string } }) => (
                  <MenuItem
                    key={buDropDawn.attributes.id}
                    value={buDropDawn.attributes.id}
                    style={this.getStyleDropDown(
                      this.state.selectedOptionsBU,
                      buDropDawn.attributes.id
                    )}
                  >
                    <CustomCheckbox
                      checked={this.state.selectedOptionsBU.includes(
                        buDropDawn.attributes.id
                      )}
                    />
                    {buDropDawn.attributes.name}
                  </MenuItem>
                ))}
              </Select>
              <div style={webStyle.divStyle}>
                {this.state.buDropdownData
                  .filter((item: { attributes: { id: number } }) =>
                    this.state.selectedOptionsBU.includes(item.attributes.id)
                  )
                  .map((buData: { attributes: { id: number, name: string } }, index: number) => (
                    <Box
                      style={webStyle.chipsBox}
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      key={index}
                    >
                      <Typography style={webStyle.chipsText as React.CSSProperties}>{buData.attributes.name}</Typography>
                      <CloseIcon
                        style={webStyle.closeIconStyle}
                        onClick={() => this.removeBU(buData.attributes.id)}
                      />
                    </Box>
                  ))}
              </div>
            </Grid>

            {this.renderSelectField({
              label: i18n.t("Select Designation"),
              name: "designationLevel",
              value: values.designationLevel,
              handleChange: this.handleChangeDesignation,
              options: this.state.designationDropdownData,
              selectedOptions: this.state.selectedOptionsDesignation,
              handleBlur: handleBlur,
              removeIcon: this.removeDesignation,
            })}
          </>
        );
      case "BU Level":
        return (
          <>
            {this.renderSelectField({
              label: i18n.t("Select Designation"),
              name: "designationLevel",
              value: values.designationLevel,
              handleChange: this.handleChangeDesignation,
              options: this.state.designationDropdownData,
              selectedOptions: this.state.selectedOptionsDesignation,
              handleBlur: handleBlur,
              removeIcon: this.removeDesignation,
            })}
          </>
        );
      default:
        return null;
    }
  };

  getSpeechIcon = (listening: boolean) => {
    return listening ? micStart : SpeachToText;
  };

  renderStepForm() {
    switch (this.state.activeStep) {
      case 0:
        return this.renderStepOneForm();
      case 1:
        return this.renderStepTwoForm();
      case 2:
        return this.renderStepThreeForm();
      case 3:
        return this.renderStepFourForm();
      default:
        return null;
    }
  }

  renderStepOneForm = () => {
    const { selectedData } = this.state;
    return (
      selectedData && (
        <Formik
          data-test-id="Formik"
          initialValues={{
            image: "",
            describeIssue: "",
            work_location: "",
            consern_type: "",
            hazard: "",
            frequency: "",
            sevirity: "",
            potential_impact: "",
            risk_levels: "",
          }}
          validationSchema={firstSchema}
          onSubmit={(values) => {
            if (values) {
              this.setState(
                {
                  postingData: values,
                },
                () => {
                  this.goToNextStep();
                  this.setState({ listening: false });
                  this.recognition?.stop();
                }
              );
            }
          }}
          innerRef={(formik: any) => (this.formik = formik)}
        >
          {({
            handleChange,
            handleBlur,
            errors,
            values,
            touched,
            submitForm,
            setFieldValue,
          }) => (
            <>
              <Grid container style={webStyle.mainGrid} spacing={2}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={6}>
                    <Typography style={webStyle.relatedImgText}>
                    {i18n.t("Related Image")}
                    </Typography>
                    {this.renderImage(values)}
                  </Grid>

                  {this.renderDescribeIssueSection(
                    handleChange,
                    handleBlur,
                    values,
                    errors,
                    touched,
                    setFieldValue
                  )}
                </Grid>

                <Grid container spacing={2}>
                  {this.renderLocationSelect(
                    handleChange,
                    handleBlur,
                    values,
                    errors,
                    touched
                  )}
                  {this.renderConcernSelect(
                    handleChange,
                    handleBlur,
                    values,
                    errors,
                    touched
                  )}
                </Grid>
                <Grid container spacing={2}>
                  {this.renderHazaedSelect(
                    handleChange,
                    handleBlur,
                    values,
                    errors,
                    touched
                  )}
                  {this.renderFrequencySelect(
                    handleChange,
                    handleBlur,
                    values,
                    errors,
                    touched,
                    setFieldValue
                  )}
                </Grid>
                <Grid container spacing={2}>
                  {this.renderSeviritySelect(
                    handleChange,
                    handleBlur,
                    values,
                    errors,
                    touched,
                    setFieldValue
                  )}
                  {this.renderConsequenceSelect(
                    handleChange,
                    handleBlur,
                    values,
                    errors,
                    touched
                  )}
                </Grid>

                <Grid container spacing={2}>
                  {this.renderRiskLevelName(handleChange, handleBlur, values)}
                </Grid>
              </Grid>
              <Box
                style={{
                  marginTop: "25px",
                  marginLeft: "4px",
                  display: "flex",
                  width: "100%",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  style={webStyle.transButton as React.CSSProperties}
                  data-test-id="nextbutton"
                  onClick={submitForm}
                >
                  <Typography
                    style={webStyle.buttonStyle as React.CSSProperties}
                  >
                    {i18n.t("Next")}
                  </Typography>
                </Button>
              </Box>
            </>
          )}
        </Formik>
      )
    );
  };

  renderStepTwoForm = () => {
    return (
      <Formik
        data-test-id="Formik"
        initialValues={{
          activityIdenty: "",
          TaskIdenty: "",
        }}
        validationSchema={secondSchema}
        onSubmit={(values) => {
          if (values) {
            this.setState(
              {
                postingData: values,
              },
              () => {
                this.goToNextStep();
              }
            );
          }
        }}
        innerRef={(formik: any) => (this.formik = formik)}
      >
        {({
          handleChange,
          handleBlur,
          errors,
          values,
          touched,
          submitForm,
        }) => (
          <>
            <Grid container style={webStyle.mainGrid} spacing={2}>
              <Box style={{ minHeight: "50vh", width: "100%" }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <FormHelperText style={webStyle.label}>
                    {i18n.t("Activity Identification")}
                    </FormHelperText>
                    <Select
                      name="activityIdenty"
                      data-test-id="activityIdenty"
                      label="activityIdenty"
                      displayEmpty
                      style={
                        {
                          ...webStyle.input,
                          ...webStyle.inputStd,
                        } as React.CSSProperties
                      }
                      value={values.activityIdenty}
                      renderValue={
                        values.activityIdenty
                          ? undefined
                          : () => (
                            <div style={{ color: "#DCDCDC" }}>
                              {i18n.t("Select Activity Identification")}
                            </div>
                          )
                      }
                      disableUnderline
                      onChange={handleChange}
                      onBlur={handleBlur}
                      IconComponent={KeyboardArrowDown}
                      MenuProps={{
                        getContentAnchorEl: null,
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        PaperProps: {
                          style: {
                            maxHeight: "200px",
                            width: "250px",
                          },
                        },
                      }}
                    >
                      {this.state.activationIdentification.map(
                        (activity, index) => (
                          <MenuItem value={activity.attributes.id} key={index}>
                            {activity.attributes.name}
                          </MenuItem>
                        )
                      )}
                    </Select>
                    <Typography style={webStyle.errorStyle}>
                      {errors.activityIdenty !== "" &&
                        touched.activityIdenty &&
                        errors.activityIdenty}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FormHelperText style={webStyle.label}>
                    {i18n.t("Task Identification")}
                    </FormHelperText>
                    <Select
                      name="TaskIdenty"
                      data-test-id="TaskIdenty"
                      label="TaskIdenty"
                      displayEmpty
                      style={
                        {
                          ...webStyle.input,
                          ...webStyle.inputStd,
                        } as React.CSSProperties
                      }
                      value={values.TaskIdenty}
                      renderValue={
                        values.TaskIdenty
                          ? undefined
                          : () => (
                            <div style={{ color: "#DCDCDC" }}>
                              {i18n.t("Select Task Identification")}
                            </div>
                          )
                      }
                      disableUnderline
                      onChange={handleChange}
                      onBlur={handleBlur}
                      IconComponent={KeyboardArrowDown}
                      MenuProps={{
                        getContentAnchorEl: null,
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        PaperProps: {
                          style: {
                            maxHeight: "200px",
                            width: "250px",
                          },
                        },
                      }}
                    >
                      {this.state.TaskIdentification.map((task, index) => (
                        <MenuItem value={task.attributes.id} key={index}>
                          {task.attributes.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <Typography style={webStyle.errorStyle}>
                      {errors.TaskIdenty !== "" &&
                        touched.TaskIdenty &&
                        errors.TaskIdenty}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item lg={6} md={6} xs={12}>
                    <FormHelperText style={webStyle.label}>
                    {i18n.t("Training Status")}
                    </FormHelperText>
                    <Input
                      fullWidth={true}
                      type="text"
                      disableUnderline
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value="N/A"
                      disabled
                      style={{
                        ...webStyle.labelStyle,
                        backgroundColor: "#F3F3F3",
                      }}
                    />
                  </Grid>

                  <Grid item lg={6} md={6} xs={12}>
                    <FormHelperText style={webStyle.label}>
                    {i18n.t("Last Safety Contact")} 
                    </FormHelperText>
                    <Input
                      fullWidth={true}
                      type="text"
                      disableUnderline
                      value="N/A"
                      disabled
                      style={{
                        ...webStyle.labelStyle,
                        backgroundColor: "#F3F3F3",
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Box style={webStyle.buttonBox}>
              <Button
                style={webStyle.transButton as React.CSSProperties}
                data-test-id="backToPrevStep"
                onClick={this.gorToPrevStep}
              >
                <Typography style={webStyle.buttonStyle as React.CSSProperties}>
                {i18n.t("Previous")}
                </Typography>
              </Button>

              <Button
                style={webStyle.transButton as React.CSSProperties}
                data-test-id="nextbutton"
                onClick={submitForm}
              >
                <Typography style={webStyle.buttonStyle as React.CSSProperties}>
                {i18n.t("Next")}
                </Typography>
              </Button>
            </Box>
          </>
        )}
      </Formik>
    );
  };

  renderStepThreeForm = () => {
    return (
      <Formik
        initialValues={{
          statutory_Concern: "",
          sop_Compliance: "",
          parameter_Violation: "",
          safety_Violation: "",
          corrective_Action: "",
          preventive_Acion: "",
          risk_count: "",
        }}
        validationSchema={thirdSchema}
        data-test-id="Formik"
        onSubmit={(values) => {
          if (values) {
            this.setState(
              {
                postingData: values,
              },
              () => {
                this.goToNextStep();
                this.setState({
                  listeningStatutory: false,
                  listeningSop: false,
                  listeningNogoViolation: false,
                  listeningSafetyViolation: false,
                  listeningCorrective: false,
                  listeningPreventive: false,
                });
                this.recognition?.stop();
              }
            );
          }
        }}
        innerRef={(formik: any) => (this.formik = formik)}
      >
        {({
          handleChange,
          handleBlur,
          errors,
          values,
          touched,
          submitForm,
          setFieldValue,
        }) => (
          <>
            <Grid container style={webStyle.mainGrid} spacing={2} justifyContent="space-between">
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
              >
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  width={"100%"}
                >
                  <FormHelperText style={webStyle.label}>
                  {i18n.t("statutory_concern_label")}
                  </FormHelperText>
                  <img
                    src={this.getSpeechIcon(this.state.listeningStatutory)}
                    style={webStyle.micStyle}
                    data-test-id="listeningStatutory"
                    onClick={async () => {
                      await this.toggleListening(
                        "listeningStatutory",
                        "stoppedByTimeoutStatutory"
                      );
                      setFieldValue(
                        "statutory_Concern",
                        values.statutory_Concern +
                        " " +
                        this.state.transcription
                      );
                    }}
                  />
                </Box>
                <textarea
                placeholder={i18n.t("statutory_concern_placeholder")}
                name="statutory_Concern"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.statutory_Concern}
                  data-test-id="statutory_Concern"
                  style={webStyle.textArea as React.CSSProperties}
                />
                <Typography style={webStyle.errorStyle}>
                  {errors.statutory_Concern != "" &&
                    touched.statutory_Concern &&
                    errors.statutory_Concern}
                </Typography>
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={6}
              >
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  width={"100%"}
                >
                  <FormHelperText style={webStyle.label}>
                   {i18n.t("Standard Compliance - SOP Compliance")}
                  </FormHelperText>
                  <img
                    src={this.getSpeechIcon(this.state.listeningSop)}
                    style={webStyle.micStyle}
                    data-test-id="listeningSop"
                    onClick={async () => {
                      await this.toggleListening(
                        "listeningSop",
                        "stoppedByTimeoutSop"
                      );
                      setFieldValue(
                        "sop_Compliance",
                        values.sop_Compliance + " " + this.state.transcription
                      );
                    }}
                  />
                </Box>
                <textarea
                  placeholder={i18n.t("SOP Compliance Description")}
                  name="sop_Compliance"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.sop_Compliance}
                  data-test-id="sop_Compliance"
                  style={webStyle.textArea as React.CSSProperties}
                />
                <Typography style={webStyle.errorStyle}>
                  {errors.sop_Compliance != "" &&
                    touched.sop_Compliance &&
                    errors.sop_Compliance}
                </Typography>
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={6}
              >
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  width={"100%"}
                >
                  <FormHelperText style={webStyle.label}>
                    {i18n.t("No Go Parameter Violation")}
                  </FormHelperText>
                  <img
                    src={this.getSpeechIcon(this.state.listeningNogoViolation)}
                    style={webStyle.micStyle}
                    data-test-id="NogoViolation"
                    onClick={async () => {
                      await this.toggleListening(
                        "listeningNogoViolation",
                        "stoppedByTimeoutNogoViolation"
                      );
                      setFieldValue(
                        "parameter_Violation",
                        values.parameter_Violation +
                        " " +
                        this.state.transcription
                      );
                    }}
                  />
                </Box>
                <TextArea
                  placeholder={i18n.t("No Go Parameter Violation Description")}
                  name="parameter_Violation"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.parameter_Violation}
                  data-test-id="parameter_Violation"
                  style={webStyle.textArea as React.CSSProperties}
                />
                <Typography style={webStyle.errorStyle}>
                  {errors.parameter_Violation != "" &&
                    touched.parameter_Violation &&
                    errors.parameter_Violation}
                </Typography>
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={6}
              >
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  width={"100%"}
                >
                  <FormHelperText style={webStyle.label}>
                    {i18n.t("Safety Absolute Violation")}
                  </FormHelperText>
                  <img
                    src={this.getSpeechIcon(
                      this.state.listeningSafetyViolation
                    )}
                    style={webStyle.micStyle}
                    data-test-id="listeningSafetyViolation"
                    onClick={async () => {
                      await this.toggleListening(
                        "listeningSafetyViolation",
                        "stoppedByTimeoutSafetyViolation"
                      );
                      setFieldValue(
                        "safety_Violation",
                        values.safety_Violation + " " + this.state.transcription
                      );
                    }}
                  />
                </Box>
                <TextArea
                  placeholder={i18n.t("Safety Absolute Violation Description")}
                  name="safety_Violation"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.safety_Violation}
                  data-test-id="safety_Violation"
                  style={webStyle.textArea as React.CSSProperties}
                />
                <Typography style={webStyle.errorStyle}>
                  {errors.safety_Violation != "" &&
                    touched.safety_Violation &&
                    errors.safety_Violation}
                </Typography>
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={6}
              >
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  width={"100%"}
                >
                  <FormHelperText style={webStyle.label}>
                    {i18n.t("Corrective Action")}
                  </FormHelperText>
                  <img
                    src={this.getSpeechIcon(this.state.listeningCorrective)}
                    style={webStyle.micStyle}
                    data-test-id="Corrective"
                    onClick={async () => {
                      await this.toggleListening(
                        "listeningCorrective",
                        "stoppedByTimeoutCorrective"
                      );
                      setFieldValue(
                        "corrective_Action",
                        values.corrective_Action +
                        " " +
                        this.state.transcription
                      );
                    }}
                  />
                </Box>
                <TextArea
                  placeholder={i18n.t("Corrective Action Description")}
                  name="corrective_Action"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.corrective_Action}
                  data-test-id="corrective_Action"
                  style={webStyle.textArea as React.CSSProperties}
                />
                <Typography style={webStyle.errorStyle}>
                  {errors.corrective_Action != "" &&
                    touched.corrective_Action &&
                    errors.corrective_Action}
                </Typography>
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={6}
              >
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  width={"100%"}
                >
                  <FormHelperText style={webStyle.label}>
                  {i18n.t("Preventive Action")}
                  </FormHelperText>
                  <img
                    src={this.getSpeechIcon(this.state.listeningPreventive)}
                    style={webStyle.micStyle}
                    data-test-id="listeningPreventive"
                    onClick={async () => {
                      await this.toggleListening(
                        "listeningPreventive",
                        "stoppedByTimeoutPreventive"
                      );
                      setFieldValue(
                        "preventive_Acion",
                        values.preventive_Acion + " " + this.state.transcription
                      );
                    }}
                  />
                </Box>
                <TextArea
                  placeholder={i18n.t("Preventive Action Description")}
                  name="preventive_Acion"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.preventive_Acion}
                  data-test-id="preventive_Acion"
                  style={webStyle.textArea as React.CSSProperties}
                />
                <Typography style={webStyle.errorStyle}>
                  {errors.preventive_Acion != "" &&
                    touched.preventive_Acion &&
                    errors.preventive_Acion}
                </Typography>
              </Grid>

              <Grid item container lg={6} md={6}>
                <FormHelperText style={webStyle.label}>
                  {i18n.t("Risk Count")}
                </FormHelperText>
                <Input
                  fullWidth={true}
                  placeholder={i18n.t("Enter Score")}
                  disableUnderline
                  name="risk_count"
                  id="risk_count"
                  onChange={(e) => {
                    if ((/^[1-9]\d*$/).test(e.target.value)) {
                      handleChange(e)
                    }
                  }}
                  onBlur={handleBlur}
                  value={values.risk_count}
                  data-test-id="risk_count"
                  style={webStyle.labelStyle}
                  inputMode="numeric"
                />
                <Typography style={webStyle.errorStyle}>
                  {errors.risk_count != "" &&
                    touched.risk_count &&
                    errors.risk_count}
                </Typography>
              </Grid>
            </Grid>
            <Box style={webStyle.buttonBox}>
              <Button
                onClick={this.gorToPrevStep}
                data-test-id="backToPrevStep"
                style={webStyle.transButton as React.CSSProperties}
              >
                <Typography style={webStyle.buttonStyle as React.CSSProperties}>
                  {i18n.t("Previous")}
                </Typography>
              </Button>

              <Button
                onClick={submitForm}
                data-test-id="nextbutton"
                style={webStyle.transButton as React.CSSProperties}
              >
                <Typography style={webStyle.buttonStyle as React.CSSProperties}>
                  {i18n.t("Next")}
                </Typography>
              </Button>
            </Box>
          </>
        )
        }
      </Formik>
    );
  };

  renderStepFourForm = () => {
    return (
      <Formik
        initialValues={{
          postingFor: "",
          countryLevel: "",
          plantLevel: "",
          buLevel: "",
          designationLevel: "",
        }}
        data-test-id="Formik"
        validationSchema={fourthSchema}
        onSubmit={(values, errors) => {
          if (values) {
            this.setState({ postingData: values });
            this.publishingPostBySafetyAPI();
          }
        }}
      >
        {({
          handleChange,
          handleBlur,
          errors,
          values,
          touched,
          submitForm,
        }) => (
          <>
            <Grid container style={webStyle.mainGrid} spacing={2}>
              <Box style={{ minHeight: "60vh", width: "100%" }}>
                <Grid container spacing={2}>
                  <Grid item lg={6} md={6} xs={12}>
                    <FormHelperText style={webStyle.label}>
                    {i18n.t("Posting For")}
                    </FormHelperText>
                    <Select
                      name="postingFor"
                      id="postingFor"
                      data-id="postingFor"
                      label="postingFor"
                      style={
                        {
                          ...webStyle.input,
                          ...webStyle.inputStd,
                        } as React.CSSProperties
                      }
                      renderValue={
                        values.postingFor
                          ? undefined
                          : () => (
                            <div style={{ color: "#DCDCDC" }}>
                              {i18n.t("Select Option")}
                            </div>
                          )
                      }
                      IconComponent={KeyboardArrowDown}
                      value={values.postingFor}
                      disableUnderline
                      displayEmpty
                      onChange={(event) => {
                        handleChange(event);
                        this.postingForDropdown(event);
                      }}
                      onBlur={handleBlur}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                    >
                      {this.state.postingForData.map((posting, index) => (
                        <MenuItem value={posting} key={index}>
                          {posting}
                        </MenuItem>
                      ))}
                    </Select>
                    <Typography style={webStyle.errorStyle}>
                      {errors.postingFor}
                    </Typography>
                  </Grid>

                  {this.renderPostingLevelFields(values, handleBlur)}
                </Grid>
              </Box>
            </Grid>
            <Box style={webStyle.buttonBox}>
              <Button
                style={webStyle.transButton as React.CSSProperties}
                onClick={this.gorToPrevStep}
                data-test-id="backToPrevStep"
              >
                <Typography style={webStyle.buttonStyle as React.CSSProperties}>
                {i18n.t("Previous")}
                </Typography>
              </Button>

              <Button
                onClick={submitForm}
                style={webStyle.fullButton as React.CSSProperties}
                data-test-id="nextbutton"
              >
                <Typography style={webStyle.buttonStyle as React.CSSProperties}>
                {i18n.t("Post")}
                </Typography>
              </Button>
            </Box>
          </>
        )}
      </Formik>
    );
  };
  render() {
    const stepLabels: Record<number, string> = {
      0: i18n.t("Step 1"),
      1: i18n.t("Step 2"),
      2: i18n.t("Step 3"),
      3: i18n.t("Step 4"),
    };

    const activeStepLabel = stepLabels[this.state.activeStep] || "";

    return (
      <ThemeProvider theme={theme}>
        <MainWrapper style={{ padding: "30px" }}>
          <Breadcrumbs
            style={{ marginLeft: "5px" }}
            aria-label="breadcrumb"
            separator={
              <NavigateNext fontSize="medium" style={{ color: "#DCDCDC" }} />
            }
          >
            <Typography
              style={{
                color: "#A1A1A1",
                fontFamily: "ClotherRegular",
                fontSize: "16px",
                lineHeight: "22px",
                textAlign: "left",
              }}
            >
              {i18n.t("Home")}
            </Typography>
            <Typography
              style={{
                color: "#A1A1A1",
                fontFamily: "ClotherRegular",
                fontSize: "16px",
                lineHeight: "22px",
                textAlign: "left",
              }}
            >
              {i18n.t("Post")}
            </Typography>
            <Typography
              style={{
                color: "#8735E1",
                fontFamily: "ClotherRegular",
                fontSize: "16px",
                fontWeight: 700,
                lineHeight: "22px",
                textAlign: "left",
              }}
            >
              {activeStepLabel}
            </Typography>
          </Breadcrumbs>
          <Typography style={webStyle.header}>
             {i18n.t("Post")}
          </Typography>
          <CustomStepper activeStep={this.state.activeStep}>
            {this.state.steps.map((stepKey: string, index: number) => (
              <Step key={index}>
                <StepLabel>{i18n.t(stepKey)}</StepLabel>
              </Step>
            ))}
          </CustomStepper>
          {
            !this.state.loader && (
              this.renderStepForm()
            )
          }
        </MainWrapper>
        {this.state.publishedPostSuccess && (
          <Dialog maxWidth="md" open={this.state.publishedPostSuccess}>
            <DialogContent style={{ fontSize: "20px" }}>
            {i18n.t("The safety concern successfully published.")}
            </DialogContent>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                padding: "20px",
              }}
            >
              {" "}
              <Button
                onClick={this.handleNavigate}
                data-test-id="handleClose"
                style={{
                  width: "100px",
                  background: "#673AB7",
                  color: "#fff",
                  height: "35px",
                }}
              >
                {i18n.t("OK")}
              </Button>
            </div>
          </Dialog>
        )}
        {this.state.errorModal && (
          <Dialog maxWidth="md" open={this.state.errorModal}>
            <DialogTitle>{i18n.t("Alert")}
            </DialogTitle>
            <DialogContent style={webStyle.dialogText}>
              {this.state.NOLevelForUser}
            </DialogContent>
            <Box style={{ padding: "20px 20px" }} display={"flex"} justifyContent={"flex-end"}>
              <Button
                onClick={this.handleNavigate}
                data-test-id="handleOk"
                style={webStyle.OkBtn}
              >
                {i18n.t("OK")}
              </Button>
            </Box>
          </Dialog>
        )}
        <Loader loading={this.state.loader} />
      </ThemeProvider>
    );
  }
}

const webStyle = {
  dialogText: {
    fontSize: "20px",
    fontFamily: 'ClotherRegular'
  },
  OkBtn: {
    width: "100px",
    background: "#673AB7",
    color: "#fff",
    height: "35px",
  },
  mikeIcon: {
    height: "24px",
    width: "24px",
    cursor: "pointer"
  },
  radioBtn: {
    position: "absolute",
    top: "-2px",
    left: "0px"
  },
  detailsBox: {
    display: "flex",
    gap: "10px",
    width: "100%",
    overflowX: "auto",
  },
  selectPlaceholder: {
    color: "#DCDCDC"
  },
  textFieldLabel: {
    display: "block",
    marginBottom: "8px",
    color: "#18181E",
    fontSize: "16px",
    lineHeight: "18px",
    fontFamily: "ClotherRegular",
  },
  errorStyle: {
    color: "#F30202",
    fontSize: "16px",
    fontWeight: 400,
    marginBottom: "18px",
  },
  textArea: {
    width: "100%",
    minHeight: "152px",
    padding: "15px",
    borderRadius: "8px",
    border: "1px solid #DCDCDC",
    background: "#FFFFFF",
    fontFamily: "ClotherRegular",
    fontSize: "16px",
    resize: 'none'
  },
  closeIconStyle: {
    paddingLeft: "8px",
    width: "16px",
    height: "16px",
    cursor: "pointer",
  },
  input: {
    borderRadius: "0.5rem",
    border: "1px solid #DCDCDC",
    fontFamily: "ClotherRegular",
    backgroundColor: "white",
    padding: "7px",
  },
  inputStd: {
    height: "3rem",
    width: "100%",
  },
  chipsBox: {
    padding: "10px 10.5px",
    height: "15px",
    borderRadius: "8px",
    background: "#8735E1",
    fontFamily: "ClotherRegular",
    fontSize: "14px",
    lineHeight: "22px",
    color: "#FFFFFF",
  },
  chipsText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: '100px'
  },
  label: {
    color: "var(--Black, #18181E)",
    fontFamily: "ClotherRegular",
    fontSize: "1rem",
    marginBottom: "8px",
  },
  images: {
    width: "143px",
    borderRadius: "8px",
    height: "120px",
  },
  descibedIssue: {
    fontFamily: "ClotherBold",
    lineHeight: "22px",
    fontSize: "16px",
    color: "#2B2A29",
  },
  describeBox: {
    minHeight: "122px",
    width: "100%",
    padding: "15px",
    borderRadius: "8px",
    border: "1px solid #DCDCDC",
  },
  transButton: {
    color: "#8735E1",
    border: "1px solid #8735E1",
    borderRadius: "8px",
    backgroundColor: "#FFFFFF",
    height: "50px",
    textTransform: "capitalize",
    minWidth: "160px",
  },
  fullButton: {
    color: "#FFFFFF",
    borderRadius: "8px",
    height: "50px",
    backgroundColor: "#8735E1",
    minWidth: "160px",
    textTransform: "capitalize",
  },
  buttonStyle: {
    fontSize: "20px",
    fontFamily: "ClotherBold",
    lineHeight: "22px",
    color: 'inherit',
    letterSpacing: "-0.40799999237060547px",
  },
  buttonBox: {
    marginTop: "25px",
    marginLeft: "4px",
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
  relatedImgText: {
    fontSize: "16px",
    fontFamily: "ClotherRegular",
    lineHeight: "18px",
    color: "#2B2A29",
    marginBottom: "10px",
  },
  labelStyle: {
    border: "1px solid #DCDCDC",
    borderRadius: "8px",
    fontFamily: "ClotherRegular",
    padding: "7px",
  },
  mainGrid: {
    marginTop: "15px",
    background: "#FFFFFF",
    padding: "30px 30px",
    borderRadius: "8px",
    marginLeft: "5px",
    width: "100%",
  },
  header: {
    fontFamily: "ClotherBold",
    fontSize: "24px",
    marginLeft: "5px",
    marginTop: "15px",
    lineHeight: "24px",
  },
  micStyle: {
    height: "24px",
    cursor: "pointer",
    width: "24px",
    marginTop: "10px",
  },
  divStyle: {
    display: "flex",
    gap: "10px",
    width: "100%",
    flexFlow: "wrap",
    marginTop: "15px",
  },
  selectedStyle: {
    color: "#8735e1",
    background: "#F9F3FF",
    fontWeight: 600,
  },
};

const CustomStepper = withStyles({
  root: {
    "&.MuiPaper-root": {
      background: "inherit",
      padding: 0,
      marginTop: "25px",
    },
    "& .MuiStepIcon-root": {
      color: "#FFFFFF",
    },
    "& .MuiStepIcon-text": {
      fill: "#A1A1A1",
      fontFamily: "ClotherRegular",
      fontSize: "14px",
      lineHeight: "18px",
      textAlign: "center",
    },
    "& .MuiStepIcon-root.MuiStepIcon-active": {
      color: "#8735E1",
      "& .MuiStepIcon-text": {
        fill: "#FFFFFF",
      },
    },
    "& .MuiStepIcon-root.MuiStepIcon-completed": {
      color: "#21C274",
      "& .MuiStepIcon-text": {
        fill: "#FFFFFF",
      },
    },
    "& .MuiStepLabel-label.MuiStepLabel-completed": {
      color: "#21C274",
    },
    "& .MuiStepLabel-label.MuiStepLabel-active": {
      color: "#8735E1",
      fontWeight: 700,
    },
    "& .MuiStepConnector-root": {
      width: "54px",
      flex: "inherit",
    },
    "& .MuiStepConnector-active": {
      "& .MuiStepConnector-line": {
        borderColor: "#21C274",
      },
    },
  },
})(Stepper);

const MainWrapper = withStyles({
  root: {
    "& .MuiSelect-select:focus": {
      backgroundColor: 'white'
    },
  }
})(Grid)

// Customizable Area End
