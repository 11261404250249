// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "framework/src/BlockComponent";
import { mic, micStart } from "./assets";
import { getStorageData } from "framework/src/Utilities";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
type AfterImageFileLink = string | ArrayBuffer | null;

export interface Category {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
}

interface CategoriesResponse {
  categories: Category[];
}

interface HazardAnalysisReport {
  statusCode: number;
  most_prominent_hazards?: string[];
  human_face_identified?: boolean;
  hazard_explanation?: string;
  corrective_actions?: string;
  preventive_actions?: string;
  output_image_url: string;
  message?: string;
  error?: string;
}

export interface Account {
  id: number;
  activated: boolean;
  email: string;
  official_email: string | null;
  user_name: string | null;
  first_name: string | null;
  last_name: string | null;
  full_name: string | null;
  gender: "Male" | "Female" | null;
  date_of_birth: string | null;
  country_code: string | null;
  phone_number: number | null;
  full_phone_number: string | null;
  sub_category_id: number | null;
  category_id: number | null;
  role_id: number | null;
  employee_id: string | null;
  employee_type_id: number | null;
  profile_location_id: number | null;
  country_id: number | null;
  work_location_id: number | null;
  is_profile_completed: boolean;
  is_safety_incharge: boolean;
  level: number | null;
  deactivated: boolean;
  supervisor_id: number | null;
  points: number | null;
  platform: string | null;
}

interface AccountsData {
  accounts: Account[];
}

export type FormValues = {
  goodPracticeNumber: string;
  location: string;
  category: string;
  afterImage: string | ArrayBuffer | null;
  afterImageDescription: string;
  beforeImage: string | null;
  beforeImageDescription: string;
  initiative: string;
  benefit: string;
  briefDescription: string;
};

export type FormValuesStep2 = {
  media: File[] | null;
  taggedPeople: Account[];
};
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  isGoodPractice: boolean;
  onGoodPracticeModalClick?: () => void;
  afterImageFile: AfterImageFileLink;
  afterImageDescription: string;
  subFunctionId: string;
  subFunctionName: string;
  emptyPostId: number;
  onSubmitDone? : () => void;
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export interface S {
  // Customizable Area Start
  listeningField: keyof S | null;
  briefDescription: string;
  beforeImageDescription: string;
  initiative: string;
  benefit: string;
  stoppedByTimeout: boolean;
  transcription: string;
  beforeImageFileLink: AfterImageFileLink;
  beforeImageFile: File | null;
  imageErrorMessage: string;
  isStep1: boolean;
  isStep2: boolean;
  previewUrlImages: string[];
  previewUrlVideos: string[];
  categories: Category[];
  goodPracticeNumber: string;
  activePeopleList: Account[];
  selectedImages: File[];
  selectedVideos: File[];
  formikFirstStepValues: FormValues | null;
  responseStatus: "success" | "error" | null;
  errorMsg: string;
  loading: boolean;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class GoodPracticeController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  protected recognitionGoodPractice: any | undefined;
  getCategoryForGoodPracticeApiCallId: string = "";
  getGoodPracticeNumberApiCallId: string = "";
  getTagPeopleApiCallId: string = "";
  postGoodPracticePostApiCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      listeningField: null as keyof S | null,
      briefDescription: "",
      beforeImageDescription: "",
      benefit: "",
      initiative: "",
      stoppedByTimeout: false,
      transcription: "",
      beforeImageFileLink: "",
      beforeImageFile: null,
      imageErrorMessage: "",
      isStep1: true,
      isStep2: false,
      previewUrlImages: [],
      previewUrlVideos: [],
      categories: [],
      goodPracticeNumber: "",
      activePeopleList: [],
      selectedImages: [],
      selectedVideos: [],
      formikFirstStepValues: null,
      responseStatus: null,
      errorMsg: "",
      loading: false
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (this.getCategoryForGoodPracticeApiCallId === apiRequestCallId) {
        this.handleResponseOfCategories(responseJson);
      }

      if (this.getGoodPracticeNumberApiCallId === apiRequestCallId) {
        this.setState({ goodPracticeNumber: responseJson?.good_practice_no });
      }

      if (apiRequestCallId === this.getTagPeopleApiCallId) {
        this.handleResponseTagPeopleList(responseJson);
      }

      if (apiRequestCallId === this.postGoodPracticePostApiCallId) {
        this.setState({ responseStatus: "success", errorMsg: responseJson?.message, loading: false })
        setTimeout(() => {
          this.props.onSubmitDone?.();
        }, 1500);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  componentDidMount(): Promise<void> {
    if ("SpeechRecognition" in window || "webkitSpeechRecognition" in window) {
      this.setupSpeechRecognitionGoodPractice();
    }
    this.fetchCategoryGoodPractice();
    this.fetchGoodPracticeNumber();
    this.getTagPeopleList();
    return Promise.resolve();
  }

  getTagPeopleList = async () => {
    const header = {
      token: await getStorageData("authToken", false),
    };

    const getAllRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTagPeopleApiCallId = getAllRequestMsg.messageId;

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTagPeopleEndPoint
    );

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(getAllRequestMsg.id, getAllRequestMsg);
  };

  handleResponseTagPeopleList = (responseJson: AccountsData) => {
    this.setState({ activePeopleList: responseJson?.accounts });
  };

  fetchCategoryGoodPractice = async () => {
    const { subFunctionId } = this.props;
    const header = {
      token: await getStorageData("authToken", false),
    };

    const getAllRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCategoryForGoodPracticeApiCallId = getAllRequestMsg.messageId;

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCategoryApiCallEndPoint}?sub_function_id=${subFunctionId}`
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(getAllRequestMsg.id, getAllRequestMsg);
  };

  handleResponseOfCategories = (responseJson: CategoriesResponse) => {
    this.setState({ categories: responseJson?.categories });
  };

  fetchGoodPracticeNumber = async () => {
    const { subFunctionId } = this.props;
    const header = {
      token: await getStorageData("authToken", false),
    };

    const getAllRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getGoodPracticeNumberApiCallId = getAllRequestMsg.messageId;

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getGoodPracticeNumberEndPoint}?sub_function_id=${subFunctionId}`
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(getAllRequestMsg.id, getAllRequestMsg);
  };

  setupSpeechRecognitionGoodPractice() {
    this.recognitionGoodPractice = new ((window as any).SpeechRecognition ||
      (window as any).webkitSpeechRecognition)();
    if (this.recognitionGoodPractice) {
      this.recognitionGoodPractice.lang = "en-US";
      this.recognitionGoodPractice.continuous = true;
      this.recognitionGoodPractice.interimResults = true;
      this.recognitionGoodPractice.onresult = (event: any) => {
        const transcription = Array.from(event.results)
          .map((result: any) => result[0].transcript)
          .join("");
        this.setState({ transcription });
      };
      const pageHiddenProp = this.getPageHiddenPropGoodPractice();
      if (pageHiddenProp) {
        this.addVisibilityChangeListener(pageHiddenProp);
      }
    }
  }

  getPageHiddenPropGoodPractice() {
    const hiddenProps = ["hidden", "webkitHidden", "mozHidden"];
    for (const prop of hiddenProps) {
      if (prop in document) {
        return prop;
      }
    }
    return null;
  }

  addVisibilityChangeListener(pageHiddenProp: string) {
    document.addEventListener(
      pageHiddenProp.replace(/[H|h]idden/, "") + "visibilitychange",
      () => {
        if ((document as any)[pageHiddenProp]) {
          if (this.recognitionGoodPractice && this.state.listeningField) {
            this.recognitionGoodPractice.stop();
            this.setState({ listeningField: null });
          }
        }
      }
    );
  }

  handleBriefDescriptionChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  ) => {
    event.persist();
    this.setState(
      {
        briefDescription: event.target.value,
      },
      () => {
        setFieldValue("briefDescription", this.state.briefDescription);
      }
    );
  };

  handleBeforeImageDescriptionChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  ) => {
    event.persist();
    this.setState(
      {
        beforeImageDescription: event.target.value,
      },
      () => {
        setFieldValue(
          "beforeImageDescription",
          this.state.beforeImageDescription
        );
      }
    );
  };

  handleInitiativeChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  ) => {
    event.persist();
    this.setState(
      {
        initiative: event.target.value,
      },
      () => {
        setFieldValue("initiative", this.state.initiative);
      }
    );
  };

  handleBenefitChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  ) => {
    event.persist();
    this.setState(
      {
        benefit: event.target.value,
      },
      () => {
        setFieldValue("benefit", this.state.benefit);
      }
    );
  };

  setDynamicData = (
    fieldName: keyof S,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  ) => {
    const updatedValue = `${this.state[fieldName] || ""} ${
      this.state.transcription
    }`.trim();
    this.setState(
      (prevState) =>
        ({
          ...prevState,
          [fieldName]: updatedValue,
        } as Pick<S, keyof S>)
    );
    setFieldValue(fieldName as string, updatedValue);
  };

  changeImg(event: React.ChangeEvent<HTMLInputElement>) {
    const files = event?.target.files;
    const fileReader = new FileReader();
    fileReader.addEventListener("load", async () => {
      this.setUpldFileLink(fileReader.result);
    });
    if (files?.length) {
      fileReader.readAsDataURL(files[0]);
    } else {
      this.setState({ beforeImageFileLink: "" });
    }
  }

  setUpldFileLink(upldFileLink: AfterImageFileLink) {
    this.setState({ beforeImageFileLink: upldFileLink });
  }

  updateImgs = (
    event: React.ChangeEvent<HTMLInputElement> | undefined,
    setFieldValue: (field: string, value: File | undefined) => void
  ) => {
    if (!event?.target?.files?.length) {
      this.setState({ imageErrorMessage: "No file selected." });
      return;
    }

    const file = event.target.files[0];
    const fileExt = this.getExtension(file.name);

    if (!["jpg", "jpeg", "png"].includes(fileExt || "")) {
      this.setState({
        imageErrorMessage: "Please select a valid image (JPG, JPEG, PNG).",
      });
      return;
    }

    setFieldValue("beforeImage", file);

    this.setState({ imageErrorMessage: "", beforeImageFileLink: "" }, () => {
      const reader = new FileReader();
      reader.onload = () => {
        this.setState({ beforeImageFileLink: reader.result as string, beforeImageFile: file });
      };
      reader.readAsDataURL(file);
    });
  };

  getExtension = (filename: string | undefined) => {
    return filename
      ?.split(".")
      .pop()
      ?.toLowerCase();
  };

  renderMic(fieldName: keyof S) {
    return this.state.listeningField === fieldName ? micStart : mic;
  }

  toggleListeningGoodPractice = async (fieldName: keyof S) => {
    if (this.recognitionGoodPractice) {
      if (this.state.listeningField === fieldName) {
        this.setState({ stoppedByTimeout: true });
        this.recognitionGoodPractice.stop();
        this.setState({ listeningField: null });
      } else {
        this.setState({ stoppedByTimeout: false, listeningField: fieldName });
        await this.recognitionGoodPractice.start();
        this.setState({ transcription: "" });
        setTimeout(() => {
          if (
            this.state.listeningField === fieldName &&
            !this.state.stoppedByTimeout
          ) {
            this.recognitionGoodPractice.stop();
            this.setState({ listeningField: null });
          }
        }, 30000);
      }
    }
  };

  handleVideoImageFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event?.target.files;
    if (!files) return;

    Array.from(files).forEach((file) => {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        if (file.type.startsWith("image")) {
          this.setState((prevState) => ({
            previewUrlImages: [
              ...prevState.previewUrlImages,
              fileReader.result as string,
            ],
            selectedImages: [...prevState.selectedImages, file],
          }));
        } else if (file.type.startsWith("video")) {
          this.setState((prevState) => ({
            previewUrlVideos: [
              ...prevState.previewUrlVideos,
              fileReader.result as string,
            ],
            selectedVideos: [...prevState.selectedVideos, file],
          }));
        }
      };
      fileReader.readAsDataURL(file);
    });
  };

  updateVideoImage = (
    event: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: (field: string, value: File[] | undefined) => void
  ) => {
    const files = event.target.files ? Array.from(event.target.files) : [];
    setFieldValue("media", files);
  };

  removeFile = (index: number, type: "image" | "video") => {
    if (type === "image") {
      this.setState((prevState) => ({
        previewUrlImages: prevState.previewUrlImages.filter(
          (_, i) => i !== index
        ),
        selectedImages: prevState.selectedImages.filter((_, i) => i !== index),
      }));
    } else {
      this.setState((prevState) => ({
        previewUrlVideos: prevState.previewUrlVideos.filter(
          (_, i) => i !== index
        ),
        selectedVideos: prevState.selectedVideos.filter((_, i) => i !== index),
      }));
    }
  };

  handleSubmitFinal = async (values: FormValuesStep2) => {
    const {
      selectedImages,
      selectedVideos,
      goodPracticeNumber,
      formikFirstStepValues,
      beforeImageDescription,
      beforeImageFile
    } = this.state;
    const { emptyPostId } = this.props;
    const formData = new FormData();
    formData.append("good_practice_no", goodPracticeNumber);
    formData.append("post_id", emptyPostId.toString());
    formData.append(
      "category_id",
      formikFirstStepValues?.category?.toString() || ""
    );
    formData.append("before_image_description", beforeImageDescription || "");
    formData.append("initiative", formikFirstStepValues?.initiative || "");
    formData.append("benefit", formikFirstStepValues?.benefit || "");
    formData.append("description", formikFirstStepValues?.briefDescription || "");
    formData.append("before_image", beforeImageFile ?? new Blob());
    selectedImages.forEach((image) => {
      formData.append(`additional_images[]`, image);
    });
    selectedVideos.forEach((video) => {
      formData.append(`videos[]`, video);
    });
    values.taggedPeople.forEach((person) => {
      formData.append("tag_people_ids[]", person.id.toString());
    });

    this.setState({ loading: true })

    const header = {
      token: await getStorageData("authToken", false),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postGoodPracticePostApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postGoodPracticeEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleCloseSnackbar = () => {
    this.setState({ responseStatus: null, errorMsg: ""});
  }
  // Customizable Area End
}
