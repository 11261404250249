// Customizable Area Start
import { IBlock } from "framework/src/IBlock";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import * as Yup from "yup";
import { Message } from "../../../../framework/src/Message";
import { setStorageData } from "../../../../framework/src/Utilities";
import { getStorageData } from "framework/src/Utilities";

export interface Item{
  type:string,
  attributes:{
    company_holder:string,
     doc:string,
     company_name:string
  },
  id:string
}

interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
  token?: string;
  type?: string;
};

interface AccountData {
  id: number;
  activated: boolean;
  email: string;
  user_name: string | null;
  first_name: string | null;
  last_name: string | null;
  category_id: number | null;
  full_name: string;
  gender: string;
  date_of_birth: string;
  country_code: number;
  phone_number: number;
  full_phone_number: string;
  sub_category_id: number | null;
  role_id: number;
  official_email: string;
  employee_id: string;
  employee_type_id: number;
  is_profile_completed: boolean;
  profile_location_id: number | null;
  country_id: number | null;
  work_location_id: number | null;
  is_safety_incharge: boolean;
  level: number | null;
  deactivated: boolean;
  points: number;
  platform: string | null;
  supervisor_id: number | null;
}

export const configJSON = require("../config");

export interface Props {
  navigation: any;
  id: string;
}

interface LocationData {
  id:number,
  name:string
}
interface LocationAttributes {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
  status: string;
}

interface LocationsResp {
  id: string; 
  type: string;
  attributes: LocationAttributes;
}

interface ActiveUser {
  id: number;
  name: string;
}
interface ProfileData {
  id: number,
  name: string,
  reviewerName:string,
  reviewerId:number 
}
interface S {
  activeButton: string;
  openFilterModal: boolean;
  applyFilter: boolean;
  activeTab:number;
  assignToType:string;
  safetyInteractions:any;
  token:string;
  errorMsg:string;
  formOneValue: any;
  loactionData:Array<LocationsResp>;
  accountUserData:Array<AccountData>;
  locationOptions:Array<LocationData>;
  assigneeOptions:Array<ActiveUser>;
  profileData:ProfileData
}

interface SS {
  id: any;
}

export default class SafetyInteractionController extends BlockComponent<
  Props,
  S,
  SS
> {
  getAssignedToMeCallId: string = "";
  startInteractionApiCallId: string ="";
  getAssignedToItemCallId: string ="";
  getFuncLocationCallID: string ="";
  getAssignedToUserItemCallId: string ="";
  postStartIntCallID: string ="";
  postNewIntCallID: string ="";
  getProfileInfoCallID: string ="";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      activeButton: "Assigned to Me",
      openFilterModal: false,
      applyFilter: false,
      activeTab:0,
      assignToType:"assigned_to_me",
      safetyInteractions:[],
      token:"",
      errorMsg:"",
      formOneValue: {},
      loactionData:[],
      accountUserData:[],
      locationOptions:[],
      assigneeOptions:[],
      profileData:{id:0,name:"",reviewerName:"",reviewerId:0}
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.getAssignedToMeCallId) {
        this.handleGetAssignDataApiResponse(responseJson)
      }
      if (apiRequestCallId === this.startInteractionApiCallId) {
        // this.handleStartInteractionApiResponse(responseJson)
      }
      if (apiRequestCallId === this.getAssignedToItemCallId) {
        this.handleGetAssignListItemApiResponse(responseJson)
      }
      if (apiRequestCallId === this.getFuncLocationCallID) {
        this.handleGetLocationApiResponse(responseJson)
      }

      if(apiRequestCallId && responseJson) {
      return  this.handleApiReceive(apiRequestCallId, responseJson)
      }
    }
  }    

    async componentDidMount() {
      super.componentDidMount();
      let token = await getStorageData("authToken", false);
      this.setState({ token });
      this.getAssignToData();
      this.getAccountDetailsAPI();
      this.getLocationAPI();
      this.getAssignToUserListItemData();
    }

    handleApiReceive = (apiRequestCallId: string, responseJson: any) => {
      if (apiRequestCallId === this.getAssignedToUserItemCallId) {
        this.handleActiveUserList(responseJson)
      }
      if (apiRequestCallId === this.postStartIntCallID) {
        this.handlePostStartInt(responseJson)
      }      
      if (apiRequestCallId === this.postNewIntCallID) {
        this.handlePostNewInt(responseJson)
      }
      if (apiRequestCallId === this.getProfileInfoCallID) {
        this.handleGetProfileInfo(responseJson)
      }
    }

  handleGetAssignDataApiResponse = (responseJson: any) => {
    if (responseJson && !responseJson.error) {
      this.setState({ safetyInteractions: responseJson.safety_interactions.data })
    } else if (responseJson && responseJson.error) {
      this.setState({
        errorMsg: responseJson.error
      });
    }
  }

  handleGetAssignListItemApiResponse = (responseJson: any) => {
    if (responseJson && !responseJson.error) {
      this.setState({formOneValue: responseJson.safety_interaction})
    } else if (responseJson && responseJson.error) {
      this.setState({
        errorMsg: responseJson.error
      });
    }
  }

  handleGetLocationApiResponse = (responseJson: any) =>{
    if (responseJson && !responseJson.error) {
      this.setState({loactionData: responseJson.sub_functions.data });
      const names = this.state.loactionData.map((item: any) => ({
        id: item.id,
        name: item.attributes.name
      }));
      this.setState({locationOptions:names})
    }

  }

  handleActiveUserList = (responseJson: any) => {
    this.setState({
      accountUserData: responseJson.accounts
    })
    const activeUsers = this.state.accountUserData
      .filter(user => user.activated)
      .map(user => ({ id: user.id, name: user.full_name }));

      this.setState({ assigneeOptions: activeUsers })

  };


  handleAssignToOthers =()=>{
    this.setState({activeButton: "Assigned to Others",assignToType:"assigned_to_other"},() => this.getAssignToData())
    
  };

  handleAssignToMe = () => {
    this.setState({activeButton: "Assigned to Me",assignToType:"assigned_to_me"},() => this.getAssignToData())
   
  };

  closeFilterModal = () => {
    this.setState({
      openFilterModal: false
    });
  };

  startInteractionSchema = Yup.object().shape({
    interactionNumber: Yup.string().required("Safety Interaction Number is required"),
    targetDate: Yup.date().required("Target Date is required").nullable(),
    location: Yup.string().required("Location is required"),
  });

  newInteractionSchema = Yup.object().shape({
    assignee: Yup.string().required("Assignee is required"),
    targetDate: Yup.date().required("Target Date is required").nullable(),
    location: Yup.string().required("Location is required"),
  });

  handleNavigateToSafetyInt = () => {
    this.setState({activeTab : 0 })
}

 handleInterationClick = async(id: number) => {
  await setStorageData("safetyInteractionId", id);
  const message: Message = new Message(getName(MessageEnum.NavigationMessage));
  message.addData(getName(MessageEnum.NavigationTargetMessage), `SafetyInteractionObservationCategories`);
  message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
  this.send(message);
 }
 
 getAssignToData = async () => {
  this.getAssignedToMeCallId = await this.apiCallFunction({
    contentType: configJSON.validationApiContentType,
    method: configJSON.validationApiMethodType,
    endPoint: `${configJSON.assignToAPiEndPoint}?type=${this.state.assignToType}`,
  });
};

getAssignToListItemData = async (id: number) => {
  this.getAssignedToItemCallId = await this.apiCallFunction({
    contentType: configJSON.validationApiContentType,
    method: configJSON.validationApiMethodType,
    endPoint: `${configJSON.assignToItemAPiEndPoint}${id}`,
  });
};

createSafetyIntApiCall = async () => {
  this.startInteractionApiCallId = await this.apiCallFunction({
    contentType: configJSON.validationApiContentType,
    method: configJSON.exampleAPiMethod,
    endPoint: configJSON.startInteractionApiEndPoint,
    body: {
      assigned_type: "myself",
      safety_interaction: {
        s1_observed: true,
      },
    },
  });
};

getAssignToUserListItemData = async () => {
  this.getAssignedToUserItemCallId = await this.apiCallFunction({
    contentType: configJSON.validationApiContentType,
    method: configJSON.validationApiMethodType,
    endPoint: configJSON.activeUsersEndpoint,
  });
};

getLocationAPI = async () => {
  this.getFuncLocationCallID = await this.apiCallFunction({
    contentType: configJSON.validationApiContentType, 
    method: configJSON.validationApiMethodType,
    endPoint: configJSON.locationEndPoint,
  });
};

postStartInteractionData = async (values:any) => {
  const body = {
    assigned_type: "my_self",
    safety_interaction: {
      assignee_id: values.assigneeId,
      s1_observed: true,
      location_id:values.location.id,
      target_date: values.targetDate,
      reviewer_id: values.reviewerId
    }
  };
  
  this.postStartIntCallID = await this.apiCallFunction({
    contentType: configJSON.validationApiContentType,
    method: configJSON.exampleAPiMethod,
    endPoint: `${configJSON.assignToAPiEndPoint}`,
    body
  });
};

postNewInteractionData = async (values: any) => {
  const body = {
    assigned_type: "other",
    safety_interaction: {
      assignee_id: values.assignee,
      reviewer_id: values.reviewer,
      s1_observed: true,
      location_id: values.location,
      target_date: values.targetDate,
    }
  };
  
  this.postNewIntCallID = await this.apiCallFunction({
    contentType: configJSON.validationApiContentType,
    method: configJSON.exampleAPiMethod,
    endPoint: `${configJSON.assignToAPiEndPoint}`,
    body
  });
};

handlePostStartInt = async(responseJson:any) =>{
if (responseJson && !responseJson.error) {
this.setState({activeTab:0})
} else if (responseJson && responseJson.error) {
  this.setState({
    errorMsg: responseJson.error
  });
}
}

handlePostNewInt = async(responseJson:any) =>{
  if (responseJson && !responseJson.error) {
  this.setState({activeTab:0})
  } else if (responseJson && responseJson.error) {
    this.setState({
      errorMsg: responseJson.error
    });
  }
}

handleAssignToMeListing =(id:number) =>{
   this.setState({ activeTab: 1 }, ()=> this.getAssignToListItemData(id))
}

handleAssignToOtherListing =(id:number) =>{
  this.setState({ activeTab: 2 }, ()=> this.getAssignToListItemData(id))
}

handleGetProfileInfo = (responseJson: any) => {
  if (responseJson && !responseJson.error) {

    const id = responseJson.data?.id;
    const name =responseJson.data?.attributes?.full_name
    const reviewerName = responseJson.data?.attributes?.supervisor_name;
    const reviewerId = responseJson.data?.attributes?.supervisor_id;

    this.setState({ 
      profileData: { id, name ,reviewerName,reviewerId } 
    });

  } else if (responseJson && responseJson.error) {
    this.setState({
      errorMsg: responseJson.error
    });
  }
}

getAccountDetailsAPI = async () => {
  let SignUpID = JSON.parse(await getStorageData("SignUpID"));
  this.getProfileInfoCallID = await this.apiCallFunction({
    contentType: configJSON.validationApiContentType, 
    method: configJSON.validationApiMethodType,
    endPoint: `${configJSON.getProfileEndPoint}${SignUpID}`,
  });
};

apiCallFunction = async (apiData: APIPayloadType) => {
  const { contentType, method, endPoint, body, type } = apiData;
  let token = await getStorageData("authToken")
   const header = {
    "Content-Type": contentType,
    token: token
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    endPoint
  )
  
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  )

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    method
  )
  body && type !== "formData"
    ? requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    )
    : requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  return requestMessage.messageId;
};

}

// Customizable Area End
