import {
  Box,
  Button,
  Grid,
  Input,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import React from "react";
import { ChildProps, EditSteps } from "./types";
import { webStyles } from "../../../../components/src/styles";
import { KeyboardArrowDown } from "@material-ui/icons";
import i18n from "i18next"

export const EditStep3 = ({
  handleChange,
  handleBlur,
  submitForm,
  errors,
  values,
  touched,
  setFieldValue,
  setStep,
  selectBloodGroup,
  changleScemaBack,
  countrieCode,
}: ChildProps) => (
  <>
    <Grid item xs={12} sm={6} data-id="step3">
      <label style={webStyles.textFieldLabel}>
      {i18n.t("In Case of Emergency (ICE) Number")}
      </label>
      <Grid container spacing={1} >
        <Grid item xs={12} sm={4}>
          <Select
            IconComponent={KeyboardArrowDown}
            data-id="icf_Country_code"
            disableUnderline
            displayEmpty
            renderValue={
              values.icf_Country_code
                ? undefined
                : () => (
                    <div style={{ color: "#DCDCDC" } as React.CSSProperties}>
                      {i18n.t('Code')}
                    </div>
                  )
            }
            value={values.icf_Country_code}
            name="icf_Country_code"
            onBlur={handleBlur}
            MenuProps={{
              getContentAnchorEl: null,
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              PaperProps: {
                style: {
                  width: "200px",
                  maxHeight: "200px",
                },
              },
            }}
            onChange={handleChange}
          >
            {countrieCode.map((countryCode: any, id: any) => (
              <MenuItem
                key={id}
                value={countryCode.attributes.country_code}
              ><img src={`https://flagcdn.com/48x36/${countryCode.id.toLowerCase()}.png`} style={{height:'18px',width:'24px',marginRight:'7px'}} />{`+ ${countryCode.attributes.country_code} ${countryCode.attributes.name}`}</MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Input
            placeholder={i18n.t("Emergency Contact Number")}
            fullWidth={true}
            type="text"
            disableUnderline
            name="ice_number"
            data-test-id="iceNumber"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.ice_number}
            data-id="ice_number"
          />
        </Grid>
      </Grid>
    </Grid>
    <Grid item xs={12} sm={6}>
      <label style={webStyles.textFieldLabel}>
      {i18n.t("In Case of Emergency (ICE) Name")}
      </label>
      <Input
        placeholder={i18n.t("Emergency Contact Name")}
        fullWidth={true}
        type="text"
        disableUnderline
        name="ice_name"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.ice_name}
        data-id="ice_name"
      />
    </Grid>

    <Grid
      item
      style={{ 
        ...webStyles.radioButtonContainer,
         padding: "0 0.75rem" 
        }}
    >
      <label style={webStyles.textFieldLabel}>{i18n.t("Blood Group")}</label>
      <Grid container spacing={1}>
        {selectBloodGroup.map((item: any, index: any) => (
          <Grid item xs={6} sm={3} key={index}>
            <Button
              variant="outlined"
              data-id="A+"
              style={{ borderRadius: "8px" }}
              className={
                values.blood_group == `${item.attributes.id}` ? "active" : ""
              }
              onClick={() =>
                setFieldValue("blood_group", `${item.attributes.id}`)
              }
            >
              <span
                style={{
                  width: "100%",
                  height: "27px",
                  overflow: " hidden",
                  maxWidth: "180px",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {item.attributes.label}
              </span>
            </Button>
          </Grid>
        ))}
      </Grid>
    </Grid>

    <Box
      width="100%"
      display="flex"
      justifyContent="space-between"
      padding="0.75rem"
      style={webStyles.footer}
    >
      <Button
        style={{ ...webStyles.transButton, width: "15%", borderRadius: "8px" }}
        data-id="backToPrevStep"
        onClick={() => {setStep(EditSteps.Step2); changleScemaBack && changleScemaBack(2)}}
      >
        <Typography style={webStyles.buttonStyle as React.CSSProperties}>
          {" "}
          {i18n.t("Previous")}
        </Typography>
      </Button>
      <Button
        style={{ ...webStyles.fullButton, width: "15%", borderRadius: "8px" }}
        data-id="nextBtnStep3"
        variant="contained"
        type="submit"
        onClick={submitForm}
      >
        <Typography style={webStyles.buttonStyle as React.CSSProperties}>
          {" "}
          {i18n.t("Next")}
        </Typography>
      </Button>
    </Box>
  </>
);
