import React from "react";

// Customizable Area Start
import {
  Avatar,
  Box,
  CircularProgress,
  ListItem,
  Typography,
} from "@material-ui/core";
import { profileIcon } from "./assets";
import { getTimeFromNow } from "./helpers/getTimeFromNow";
// Customizable Area End

import NotificationsBlockController, {
  Props,
} from "./NotificationsBlockController";

export default class NotificationsBlock extends NotificationsBlockController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderNotificationContent = (heading: string, username: string, content: any) => {
    const displayUserName = username || " ";
    if (heading === "post_created" ||
      heading === "story_created" ||
      heading === "liked_post" ||
      heading === "commented_on_your_post"
    ) {
      return (
        <Typography style={webStyle.userName as React.CSSProperties}>
          {displayUserName + ' '}
          <span style={webStyle.secondaryFont as React.CSSProperties}>{content}</span>
        </Typography>
      );
    } else if (heading === "safety_concern_published") {
      return (
        <Typography style={webStyle.userName as React.CSSProperties}>
          <span style={webStyle.secondaryFont as React.CSSProperties}>{content}</span>
          {' ' + displayUserName}
        </Typography>
      );
    } else if (heading === "personal_details_created" || heading === "goals_created") {
      return (
        <Typography style={webStyle.userName as React.CSSProperties}>
          <span style={webStyle.secondaryFont as React.CSSProperties}>{content}</span>
        </Typography>
      );
    } else if (heading === 'action_tracker' || 
      heading === "follow_up_action_tracke" || 
      heading === "reassign_action_tracker" || 
      heading === "verified_action_tracker" ||
      heading === "assigned_action_tracker" ||
      heading === "reply_action_tracke") {
      return (
        <Typography style={webStyle.userName as React.CSSProperties}>
          {' ' + displayUserName}
          <span style={webStyle.secondaryFont as React.CSSProperties}>{content}</span>
        </Typography>
      );
    } else {
      return (
        <Typography style={webStyle.userName as React.CSSProperties}>
          <span style={webStyle.secondaryFont2 as React.CSSProperties}>{content}</span>
          {displayUserName + ' '}
        </Typography>
      );
    }

  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { notifications, fullScreen } = this.props;
    return (
      <>
        {this.state.redirect ?
          <CircularProgress style={webStyle.loader as React.CSSProperties} />
          :
          <>
            <Box
              fontFamily='ClotherRegular'
              textAlign='center'
              onScroll={this.paginateOnScroll}
              onClick={(event) => { event.stopPropagation(); }}
              style={{
                ...(!fullScreen && webStyle.menuItem as React.CSSProperties)
              }}>
              {notifications?.data.map((notification, index) => {
                const {
                  headings,
                  user_name,
                  contents,
                  created_at,
                  profile_image
                } = notification.attributes;

                return <ListItem
                  key={notification.id}
                  style={{
                    ...webStyle.notificationItem,
                    ...(fullScreen && webStyle.fullScreenItem)
                  }}
                  data-test-id="notification"
                  onClick={() => this.postView(notification)}
                >
                  <Avatar
                    src={profile_image.length > 0 ? profile_image : profileIcon}
                    style={webStyle.profilePic}
                  />
                  <Box display={"flex"} flexDirection={"column"}>
                    {this.renderNotificationContent(headings, user_name, contents)}
                    <Box style={webStyle.Time as React.CSSProperties}>{getTimeFromNow(created_at)} ago</Box>
                  </Box>
                </ListItem>
              })}
            </Box>
          </>
        }
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  profilePic: {
    width: '40px',
    height: '40px',
    marginRight: '10px',
    borderRadius: '50%'
  },
  userName: {
    fontFamily: 'ClotherRegular',
    fontSize: '14px',
    lineHeight: '14px',
    color: '#2B2A29',
    marginRight: '5px',
    marginBottom: '4px'
  },
  notificationItem: {
    display: 'flex',
    alignItems: 'center',
    padding: '1rem',
    borderTop: '1px solid #EEEEEE',
    cursor: 'pointer',
  },
  fullScreenItem: {
    width: '95%',
    backgroundColor: '#FFFFFF',
    margin: 'auto'
  },
  menuItem: {
    height: '268px',
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  secondaryFont: {
    fontFamily: 'ClotherRegular',
    fontSize: '14px',
    lineHeight: '14px',
    color: '#A1A1A1',
  },
  Time: {
    fontFamily: 'ClotherRegular',
    fontSize: '14px',
    lineHeight: '14px',
    color: '#A1A1A1',
    display: 'flex',
  },
  secondaryFont2: {
    fontFamily: 'ClotherRegular',
    fontSize: '14px',
    lineHeight: '14px',
    color: '#2B2A29',
  },
  loader: {
    height: '40px',
    width: '40px',
    position: 'absolute',
    color: '#8735E1',
    left: '50%',
    top: '50%',
  },
};
// Customizable Area End
