import React from "react";
import { Box, Typography, styled } from "@material-ui/core";

interface CauseItem {
  description: string;
}

interface CustomD4OverviewContainerProps {
  isTopView: boolean;
  image: string;
  title: string;
  style?: React.CSSProperties;
  causeList: CauseItem[];
  shouldShowProblemView: boolean;
}

const StyledContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  height: "100%",
  "& .verticalLine": {
    borderLeft: "2px solid #8735E1",
    padding: 10,
    alignSelf: "flex-start",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  "& .text": {
    fontFamily: "ClotherRegular",
    fontSize: "18px",
    fontWeight: 700,
    lineHeight: "22px",
    color: "#2B2A29",
    margin: "1px 0",
  },
  "& .stepIdentifier": {
        display: 'flex',
        width: '105px',
        height: '74px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        flexShrink: 0,
        borderRadius: '8px',
        background: '#FAF3FD',
        boxSizing: "border-box",
        border: "2px solid var(--Purple, #8735E1)",
      },
});

const CustomD4OverviewContainer: React.FC<CustomD4OverviewContainerProps> = ({
  isTopView,
  image,
  title,
  style,
  causeList,
  shouldShowProblemView,
}) => {
  return (
    <StyledContainer
      style={{
        width: shouldShowProblemView ? 250 : 152,
        ...style,
      }}
    >
        {isTopView && (
              <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  className="stepIdentifier"
              >
                  <img src={image} alt={`${title} icon`} style={{ width: "32px", height: "32px" }} />
                  <Typography
                      style={{
                          fontWeight: 700,
                          textAlign: "center",
                          fontSize: "14px",
                          lineHeight: "22px",
                          fontFamily: "ClotherRegular"
                      }}
                  >
                      {title}
                  </Typography>
              </Box>
        )}

      <Box
        className="verticalLine"
        style={{
          transform: `rotate(${isTopView ? "-13.76deg" : "13.76deg"})`,
          paddingTop: isTopView ? 24 : 0,
          paddingBottom: isTopView ? 0 : 30,
          height: 200,
          width: 100,
          position: "relative",
          top: isTopView ? -30 : 29,
          left: isTopView ? 54 : 53,
        }}
      >
        {causeList.slice(0, 5).map((item, index) => (
          <div 
            style={{ 
              transform: `rotate(${isTopView ? "13.76deg" : "-13.76deg"})`,
              margin: "8px 0px",
            }}>
            <Typography
              key={index}
              className="text"
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: "100px",
              }}
              noWrap
            >
              {item.description}
            </Typography>
          </div>
        ))}
      </Box>

      {!isTopView && (
            <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            className="stepIdentifier"
        >
            <img src={image} alt={`${title} icon`} style={{ width: "32px", height: "32px" }} />
            <Typography
                style={{
                    fontWeight: 700,
                    textAlign: "center",
                    fontSize: "14px",
                    lineHeight: "22px",
                    fontFamily: "ClotherRegular"
                }}
            >
                {title}
            </Typography>
        </Box>
      )}
    </StyledContainer>
  );
};

export default CustomD4OverviewContainer;
