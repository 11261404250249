// Customizable Area Start
import React from "react";
import {
    Box,
    Typography,
    Grid,
    Avatar,
    IconButton,
    Button,
    CircularProgress,
    Menu,
    MenuItem,
    Dialog,
} from "@material-ui/core";
import { Email, Locations, Contact, time, comment, like, share, Unlike } from "./assets";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import OtherProfileController, { Props, configJSON, PostData, ProfileData, ContactDetailsData, CommentData, TagsData } from "./ProfileController.web";
import { webStyles } from "../../../components/src/styles";
import { NavigateNext } from "@material-ui/icons";
import { Pagination } from "@material-ui/lab";
import Loader from "../../../components/src/Loader.web";
import { profileIcon } from '../../../components/src/assets';
import { getTimeFromNow } from '../../../components/src/helpers/getTimeFromNow';
import { SuccessImage, addImg, checkImg, refreshImg } from "../../dashboard/src/assets";
import { webStylesLocal } from "../../dashboard/src/ReportPostModal.web";
import CloseIcon from "@material-ui/icons/Close";
import i18n from "i18next";

export default class OthersProfile extends OtherProfileController {
    constructor(props: Props) {
        super(props);
    }

    ProfilePic = (editProfileData: ProfileData | null) => {
        return (
            <Box position="relative" display="inline-flex">
                <Box style={webStyle.innerCircle as React.CSSProperties}></Box>
                <Box
                    top={0}
                    left={0}
                    bottom={0}
                    right={0}
                    position="absolute"
                    display="flex"
                    justifyContent="center"
                    alignItems={"center"}
                    height={'105px'}
                    width={"105px"}
                >
                    <Avatar
                        src={(typeof editProfileData?.attributes?.profile_image) !== "string" ? profileIcon : editProfileData?.attributes.profile_image}
                        alt="Custom Icon"
                        style={webStyle.avatarStyle}
                    />
                </Box>
            </Box>
        )
    }

    profileDetails = (editProfileData: ProfileData | null) => {
        return (
            <Box style={webStyle.userDetailsBox} display={"flex"} flexDirection={"column"} justifyContent={"center"}>
                <Typography style={webStyle.userName as React.CSSProperties} noWrap>
                    {editProfileData?.attributes?.full_name}
                </Typography>
                {editProfileData?.attributes?.role_name && (
                    <Typography style={webStyles.avatarList as React.CSSProperties} noWrap>
                        <span style={webStyle.role as React.CSSProperties}>
                            {editProfileData?.attributes?.role_name}
                        </span>
                    </Typography>
                )}
                <div style={webStyles.countList as React.CSSProperties}>
                    <Typography style={webStyle.following}>
                        {i18n.t(configJSON.FollowingText)}: <span style={
                            webStyle.followingValue as React.CSSProperties
                        }>{editProfileData?.attributes?.followings}</span>
                    </Typography>
                    <Typography style={webStyle.following}>
                        {i18n.t(configJSON.FollowersText)}: <span style={
                            webStyle.followingValue as React.CSSProperties
                        }>{editProfileData?.attributes?.followers}</span>
                    </Typography>
                    <Typography style={webStyle.following}>
                        {i18n.t(configJSON.PostText)}: <span style={
                            webStyle.followingValue as React.CSSProperties
                        }>{editProfileData?.attributes?.posts_count}</span>
                    </Typography>
                    <Typography style={webStyle.following}>
                        {i18n.t(configJSON.RankText)}: <span style={
                            webStyle.followingValue as React.CSSProperties
                        }>0</span>
                    </Typography>
                </div>
            </Box>
        )
    }

    renderPost = (postData: PostData) => {
        const currentLoggedinUser = JSON.parse(sessionStorage.getItem("dataObj") as string);

        return (
            <Box>
                {postData?.attributes?.image_url && (
                    <a href={postData.attributes.image_url} target="_blank" rel="noopener noreferrer">
                        <div style={{ position: "relative", display: "inline-block", width: "100%" }}>
                            <img
                                data-test-id="profile-img"
                                src={postData.attributes.image_url}
                                alt={configJSON.YourImageText}
                                style={webStyles.postImage as React.CSSProperties}
                            />
                            <div
                                style={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    width: "100%",
                                    height: "100%",
                                    pointerEvents: "none",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    transform: "rotate(-45deg)",
                                    opacity: 0.3,
                                    fontSize: "40px",
                                    fontWeight: "bold",
                                    color: "#fff",
                                }}
                            >
                                {currentLoggedinUser.userEmail}
                            </div>
                        </div>
                    </a>
                )}
            </Box>
        );
    };
    
    postCard = (postData: PostData, index: number) => {
    
        return (
            <Box
                test-id='post-view'
                display="flex"
                flexDirection="column"
                bgcolor="white"
                p={2}
                borderRadius={'8px'}
                border='1px solid #EEEEEE'
                m='20px'
                pt={2}
                key={postData.attributes.id}
            >
                <Box display="flex" alignItems="center" justifyContent="space-between" >
                    <Box display="flex" alignItems="center" style={webStyle.userDetails}>
                        <Avatar
                            data-test-id='profile-icon'
                            src={postData?.attributes?.profile_pic || profileIcon}
                            alt="Custom Icon"
                            style={webStyle.profilePic}
                        />
                        <Typography
                            data-test-id='post-heading'
                            variant="h6"
                            style={webStyle.userNameText}
                            component="div">
                            {postData?.attributes?.user_name || `${i18n.t(configJSON.NoNameText)}`} {i18n.t(configJSON.createdText)}
                        </Typography>
                    </Box>
                    <IconButton>
                        <MoreVertIcon />
                    </IconButton>
                </Box>
    
                {this.renderPost(postData)}
    
                <Box>
                    <Typography
                        data-test-id='post-description'
                        variant="body1"
                        component="p"
                        style={webStyle.descrption}
                    >
                        {postData?.attributes?.description || `${i18n.t(configJSON.NoDescrpitionText)}`}
                    </Typography>
                </Box>
                <Box display="flex"
                    alignItems="center"
                    style={webStyle.locationBox}
                >
                    <IconButton>
                        <img src={Locations} />
                    </IconButton>
                    <Typography
                        data-test-id='profile-location'
                        variant="body2"
                        component="div" style={webStyles.locationText}>
                        {postData?.attributes?.work_location || postData?.attributes?.location || `${i18n.t(configJSON.NoLocationText)}`}
                    </Typography>
                </Box>
                <Box display="flex" alignItems="center" justifyContent='space-between' flexWrap='wrap'>
                    <Box display="flex" alignItems='center' justifyContent='flex-start'>
                        <Box display="flex" alignItems='center'>
                            <IconButton style={{paddingLeft: '0px'}}>
                                <Avatar
                                    src={postData?.attributes?.profile_pic || profileIcon}
                                    alt="Custom Icon"
                                    style={webStyles.profilePic}
                                />
                            </IconButton>
                            <Typography
                                variant="body2"
                                style={webStyles.userName}
                                component="div">
                                {postData?.attributes?.user_name?.slice(0, 12)}
                                {postData?.attributes?.user_name?.length < 12 ? "" : "..."}
                            </Typography>
                        </Box>
                        <Box display="flex" alignItems='center'>
                            <IconButton>
                                <img src={time} />
                            </IconButton>
                            <Typography
                                variant="body2"
                                style={webStyles.userName}
                                component="div">
                                {getTimeFromNow(postData?.attributes?.created_at)} {i18n.t(configJSON.agoText)}
                            </Typography>
                        </Box>
                    </Box>
                    <Box display="flex" justifyContent="flex-end">
                        <Box display="flex" alignItems="center">
                            {this.state.likeCommentLoader && this.state.currentPostId === postData?.attributes?.id ?
                                <Box>
                                    <CircularProgress />
                                </Box>
                                :
                                <>
                                    <IconButton data-test-id={`like-btn${index}`} onClick={() => this.proceedToLike(postData?.attributes)}>
                                        <img src={postData?.attributes?.did_you_like ? like : Unlike} />
                                    </IconButton>
                                    <Typography variant="body2" component="div" style={webStyles.userName}>
                                        {postData?.attributes?.likes_count} {i18n.t(configJSON.LikesText)}
                                    </Typography>
                                </>
                            }
                        </Box>
                        <Box display="flex" alignItems="center">
                            <IconButton>
                                <img src={share} />
                            </IconButton>
                            <Typography variant="body2" component="div" style={webStyles.userName}>
                                0 {i18n.t(configJSON.shareText)}
                            </Typography>
                        </Box>
                        <Box data-test-id={`comments-view-btn${index}`} style={webStyle.pointerClass} display="flex" alignItems="center" onClick={() => this.showComments(postData.attributes.id, index)}>
                            <IconButton>
                                <img src={comment} />
                            </IconButton>
                            <Typography variant="body2" component="div" style={webStyles.userName}>
                                {postData?.attributes?.comments_count} {i18n.t(configJSON.CommentsText)}
                            </Typography>
                        </Box>
                    </Box>
                    {postData?.attributes?.openCommentsList && this.state.comments.length > 0 && (
                        <Box style={{ width: '99%', marginLeft: '10px' }}>
                            <Typography style={webStyle.commentsHeader}>Comments ({this.state.totalComment})</Typography>
                            {this.state.comments.map((data) => {
                                return (
                                    <div style={{ padding: '10px', marginBottom: '10px', background: '#F8F8F8' }} key={data.id}>
                                        <Grid container justifyContent="space-between">
                                            <Grid item lg={7}>
                                                <Grid container alignItems="center">
                                                    <Avatar src={data?.attributes?.account?.profile_image || profileIcon} style={webStyle.commentImage} />
                                                    <Typography style={webStyle.commentUserName}>{data?.attributes?.account?.full_name}</Typography>
                                                    <Typography style={webStyle.commentTimeText}>
                                                        <div style={webStyle.timeDotIcon}></div>
                                                        {this.timeSince(data?.attributes?.created_at)}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid item lg={4} style={{ justifyContent: 'flex-end', display: 'flex' }}>
                                                <MoreVertIcon data-test-id={`report-comment[${index}]`} style={webStyle.pointerClass} onClick={(event) => this.showReportComment(event, data?.id)}></MoreVertIcon>
                                                {this.commentReportMenu(data, index)}
                                            </Grid>
                                        </Grid>
                                        <Typography style={webStyle.commentValue}>{data?.attributes?.comment}</Typography>
                                    </div>
                                )
                            })}
                            {this.state.commentsloading &&
                                <CircularProgress />}
                            {!this.state.commentsloading && this.state.currentCommentPage !== Math.ceil(this.state.totalComment / 5) &&
                                <Typography align="center" style={webStyle.loadMore} data-test-id={`load-more2${index}`} onClick={() => this.getComment(postData?.attributes?.id)}>Load More
                                    <img src={refreshImg} style={{ width: '15px', height: '15px', marginLeft: '10px' }} />
                                </Typography>}
                        </Box>
                    )}
                </Box>
            </Box>
        )
    }

    commentReportMenu = (data: CommentData, index: number) => {
        return (
            <Menu
                id="basic-menu"
                anchorEl={this.state.anchorElReportComment}
                open={this.state.reportCommentOption}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                onClose={() => this.showReportComment({ currentTarget: null }, data?.id)}
                className='reportmenu'
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                data-test-id={`menu-btn${index}`}
                style={{ width: '110px' }}
            >
                <MenuItem
                    data-test-id={`report-btn${index}`}
                    style={webStyles.reportMenuItem}
                    onClick={() => this.openReportCommnetModal()}
                >
                    {i18n.t(configJSON.ReportText)}
                </MenuItem>
            </Menu>
        )
    }

    ContactDetails = (contactDetails: ContactDetailsData | null) => {
        return (
            <Box style={webStyle.contactDetailsBox}>
                <Grid container style={webStyle.contactContainer as React.CSSProperties}>
                    <Box style={webStyle.iconBox} display='flex' alignItems={"center"} justifyContent={"center"}>
                        <img src={Email} style={webStyle.emailIcon} />
                    </Box>
                    <Box>
                        <Typography style={webStyle.contactTitle}>{i18n.t(configJSON.WorkEmailText)}:</Typography>
                        <Typography style={webStyle.contactValue as React.CSSProperties}>{contactDetails?.work_email}</Typography>
                    </Box>
                </Grid>

                <Grid container style={webStyle.contactContainer as React.CSSProperties}>
                    <Box style={webStyle.iconBox} display='flex' alignItems={"center"} justifyContent={"center"}>
                        <img src={Email} style={webStyle.emailIcon} />
                    </Box>
                    <Box>
                        <Typography style={webStyle.contactTitle}>{i18n.t(configJSON.PersonalEmailText)}:</Typography>
                        <Typography style={webStyle.contactValue as React.CSSProperties}>{contactDetails?.personal_email}</Typography>
                    </Box>
                </Grid>

                <Grid container style={webStyle.contactContainer as React.CSSProperties}>
                    <Box style={webStyle.iconBox} display='flex' alignItems={"center"} justifyContent={"center"}>
                        <img src={Contact} style={webStyle.contactIcon} />
                    </Box>
                    <Box>
                        <Typography style={webStyle.contactTitle}>{i18n.t(configJSON.PhoneNumberText)}:</Typography>
                        <Typography style={webStyle.contactValue as React.CSSProperties}>{(contactDetails?.contact_number !== "**********" && contactDetails?.contact_number !== "*** set visibility ***") ? `+ ${contactDetails?.contact_number}` : `${contactDetails.contact_number}`}</Typography>
                    </Box>
                </Grid>

                <Grid container style={webStyle.contactContainer as React.CSSProperties}>
                    <Box style={webStyle.iconBox} display='flex' alignItems={"center"} justifyContent={"center"}>
                        <img src={Locations} style={webStyle.locationIcon} />
                    </Box>
                    <Box>
                        <Typography style={webStyle.contactTitle}>{i18n.t(configJSON.location)}:</Typography>
                        <Typography style={webStyle.contactValue as React.CSSProperties}>{contactDetails?.location || configJSON.NoLocationText}</Typography>
                    </Box>
                </Grid>
            </Box>
        )
    }

    render() {
        const { editProfileData, totalCount, currentPage } = this.state;
        return (
            this.state.isLoading ? (
                <Loader loading={this.state.isLoading} data-test-id="loader" />
            ) : (
                <div className="my-profile">
                    <Grid style={webStyle.mainContainer}>
                        <Box style={webStyles.breadcrumbWrapper}>
                            <Typography style={webStyles.breadcrumbItem} onClick={this.goToHome} >{i18n.t(configJSON.ProfileText)}</Typography>
                            <NavigateNext style={webStyles.navigateIcon} />
                            <Typography style={webStyles.breadcrumbActiveItem}>{editProfileData?.attributes?.full_name}</Typography>
                        </Box>        
                        <Grid container alignItems="center" style={webStyle.profileContainer as React.CSSProperties}>
                            <Grid item lg={8} md={8} sm={8} xs={12}>
                                <Box display={"flex"}>
                                    {this.ProfilePic(editProfileData)}
                                    {this.profileDetails(editProfileData)}
                                </Box>
                            </Grid>
                            <Grid item lg={4} md={4} sm={4} xs={12}>
                                <Grid container justifyContent="flex-end">
                                    {!this.state.isSameUser && (
                                        this.state.is_following_by_you ?
                                            <Button variant="outlined" data-test-id="following-btn" onClick={() => this.followUser()} style={webStyle.followingBtn as React.CSSProperties}>
                                                <span style={webStyle.btnText}>{i18n.t(configJSON.FollowingText)}</span>
                                            </Button>
                                            :
                                            <Button data-test-id="follow-btn" onClick={() => this.followUser()} variant="contained" style={webStyle.followBtn as React.CSSProperties}>
                                                <span style={webStyle.btnText}>{i18n.t(configJSON.FollowText)}</span>
                                            </Button>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} style={webStyle.contentContainer as React.CSSProperties}>
                            <Grid
                                item
                                xs={12}
                                md={6}
                                lg={8}
                                sm={6}
                            >
                                <Box style={webStyles.listBox}>
                                    <Box style={webStyles.headB}>
                                        <Typography style={webStyle.recentPostTitle}>{i18n.t(configJSON.RecentPostsText)}</Typography>
                                    </Box>
                                    <Box>
                                        {this.state.postData.map((postData: PostData, index: number) => (
                                            this.postCard(postData, index)
                                        ))}
                                    </Box>
                                    {
                                        this.state.postData.length === 0 && (
                                            <Typography style={webStyle.noDataText as React.CSSProperties}>{i18n.t(configJSON.NoPostsText)}</Typography>
                                        )
                                    }
                                    <Grid container justifyContent="flex-end">
                                        <Pagination
                                            count={Math.ceil(totalCount / 4)}
                                            page={currentPage}
                                            variant="outlined"
                                            style={webStyle.pagination}
                                            onChange={this.handlePageChange}
                                            data-test-id="handlePageChange"
                                        />
                                    </Grid>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6} lg={4} sm={6}>
                                <Box style={webStyles.listBox}>
                                    <Box style={webStyles.headB}>
                                        <Typography style={webStyle.recentPostTitle}>{i18n.t(configJSON.ContactDetailsText)}</Typography>
                                    </Box>
                                    {this.ContactDetails(this.state.contactDetails)}
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                    {this.state.reportCommentModal &&
                        <Dialog open={this.state.reportCommentModal} data-id="congrats-dialog" fullWidth>
                            <Box
                                style={webStylesLocal.dialogContainer}
                                justifyContent="space-between"
                                flexDirection="column"
                                display="flex"
                            >
                                <Box justifyContent={"space-between"} display={"flex"}>
                                    <Typography style={webStylesLocal.noteFont}>
                                        {i18n.t(configJSON.ReportCommentText)}
                                    </Typography>
                                    <CloseIcon
                                        onClick={() => this.closeReportModal()}
                                        style={webStylesLocal.closeIcon}
                                        data-test-id="reportcloseBtn"
                                    />
                                </Box>
                                <Typography style={webStylesLocal.subHeader}>
                                    {i18n.t(configJSON.ReportSubHeaderText)}
                                </Typography>
                                <Grid container>
                                    {this.state.reportTags.map((tag: TagsData, index: number) => {
                                        return (
                                            <Button
                                                variant="outlined"
                                                data-test-id={`tagBtn${index}`}
                                                key={index}
                                                onClick={() => this.addSelectedTags(parseInt(tag.attributes.id))}
                                                style={this.getTagColor(parseInt(tag.id)) ? webStylesLocal.SelectedTagBtn as React.CSSProperties : webStylesLocal.TagBtn as React.CSSProperties}
                                            >
                                                <Typography style={webStylesLocal.addIcon}>
                                                    {this.getTagColor(parseInt(tag.id)) ? <img src={checkImg} /> : <img src={addImg} />}</Typography>
                                                <Typography style={this.getTagColor(parseInt(tag.id))
                                                    ? webStylesLocal.selectedTagName as React.CSSProperties :
                                                    webStylesLocal.tagName as React.CSSProperties}>{tag.attributes.name}</Typography>
                                            </Button>
                                        )
                                    })}
                                </Grid>
                                <Box>
                                    <Typography style={webStylesLocal.label}>{i18n.t(configJSON.ReportDescriptionLabel)}</Typography>
                                    <textarea
                                        placeholder="Description"
                                        style={webStylesLocal.textareaStyle}
                                        onChange={(e: { target: { value: string } }) => this.handledescriptionChange(e)}
                                        value={this.state.reportdescription}
                                        className="textarea"
                                        data-test-id="handleTextAreaChange"
                                    />
                                    {
                                        this.state.isDescriptionErr && (
                                            <Typography style={webStylesLocal.errorText}>{i18n.t(configJSON.errorTextMsg)}</Typography>
                                        )
                                    }
                                </Box>
                                <Box justifyContent={"flex-end"} display={"flex"} >
                                    <Button onClick={() => this.handleReportApiCall()} style={webStylesLocal.reportBtn as React.CSSProperties} data-test-id="reportbtn">
                                        {i18n.t(configJSON.ReportText)}
                                    </Button>
                                </Box>
                            </Box>
                        </Dialog>
                    }
                    {this.state.postIsReported &&
                        <Dialog fullWidth open={this.state.postIsReported} data-id="congrats-dialog">
                            <Box
                                style={webStylesLocal.dialogContainer}
                                display="flex"
                                justifyContent="space-between"
                                flexDirection="column"
                            >
                                <Box justifyContent={"flex-end"} display={"flex"}>
                                    <CloseIcon
                                        onClick={() => this.closeReportModal()}
                                        style={webStylesLocal.closeIcon}
                                        data-test-id="reportedCloseBtn"
                                    />
                                </Box>
                                <Box justifyContent={"center"} display={"flex"}>
                                    <img style={{ width: "70px", height: "70px" }} src={SuccessImage} />
                                </Box>
                                <Typography align='center' style={webStylesLocal.ReportedFont}>
                                    {this.state.reportedModalHeading}
                                </Typography>
                                <Typography style={webStylesLocal.subHeader2}>
                                    {this.state.reportedModalBodyText}
                                </Typography>
                                <Button onClick={this.closeReportModal} style={webStylesLocal.okayBtn as React.CSSProperties} variant='outlined' data-test-id="okayBtn">
                                    {i18n.t('Okay')}
                                </Button>
                            </Box>
                        </Dialog>
                    }
                </div>)
        );
    }
}

const webStyle = {
    btnText: {
        marginBottom: '3px'
    },
    pointerClass: {
        cursor: 'pointer',
    },
    commentsHeader: {
        fontFamily: 'ClotherRegular',
        fontSize: '14px',
        lineHeight: '18px',
        color: "#18181E",
        marginBottom: '12px'
    },
    loadMore: {
        fontFamily: 'ClotherRegular',
        fontSize: '14px',
        lineHeight: '18px',
        color: '#8735E1',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
    },
    commentValue: {
        fontFamily: 'ClotherRegular',
        fontSize: '14px',
        lineHeight: '18px',
        color: '#A1A1A1',
        marginTop: '8px'
    },
    timeDotIcon: {
        width: '6px',
        height: '6px',
        background: '#D9D9D9',
        borderRadius: '50%',
        margin: '0px 6px',
    },
    commentTimeText: {
        fontFamily: 'ClotherRegular',
        fontSize: '14px',
        lineHeight: '18px',
        color: "#A1A1A1",
        display: 'flex',
        alignItems: 'center'
    },
    commentUserName: {
        fontFamily: 'ClotherRegular',
        fontSize: '14px',
        lineHeight: '18px',
        color: "#18181E",
    },
    commentImage: {
        width: '24px',
        height: '24px',
        marginRight: '5px'
    },
    postScrollBox: {
        maxHeight: '250px',
        overflowY: 'scroll'
    },
    contactDetailsBox: {
        height: '323px'
    },
    userDetailsBox: {
        marginLeft: '14px'
    },
    contentContainer: {
        flexWrap: 'wrap-reverse'
    },
    profileBox: {
        minWidth: '100px',
        marginRight: '15px'
    },
    pagination: {
        margin: "20px"
    },
    greyBox: {
        backgroundColor: "#eee"
    },
    navigateIcon: {
        fill: '#DCDCDC'
    },
    mainContainer: {
        padding: "30px",
        background: "rgb(238, 238, 238)"
    },
    locationBox: {
        margin: '0px',
        background: "#f5f5f5",
        width: "173px",
        borderRadius: "5px",
        cursor: 'pointer'
    },
    postImage: {
        width: '100%',
        height: 303,
        marginBottom: '18px',
        objectFit: 'cover',
        borderRadius: "8px",
        cursor: 'pointer'
    },
    noDataText: {
        fontFamily: 'ClotherBold',
        fontSize: '22px',
        height: '65%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    innerCircle: {
        height: '92px',
        width: '92px',
        borderRadius: '50%',
        border: '7px solid rgb(135, 53, 225)'
    },
    contactValue: {
        fontFamily: 'ClotherRegular',
        fontSize: '16px',
        lineHeight: '20px',
        color: '#2B2A29',
        overflowWrap: 'anywhere'
    },
    contactTitle: {
        fontFamily: 'ClotherRegular',
        fontSize: '16px',
        lineHeight: '20px',
        color: '#A1A1A1'
    },
    contactContainer: {
        padding: '19px 16px 12px 16px',
        flexWrap: 'nowrap',
    },
    emailIcon: {
        width: '22px',
        height: '16.5px',
        marginTop: '2.75px',
    },
    contactIcon: {
        width: '20px',
        height: '20px',
        marginLeft: '0.04px'
    },
    locationIcon: {
        width: '22px',
        height: '22px',
        marginLeft: '3.27px'
    },
    iconBox: {
        minWidth: '40px',
        height: '40px',
        borderRadius: '4px',
        background: '#F8F8F8',
        marginRight: '10px',
    },
    descrption: {
        fontSize: "16px",
        fontFamily: "ClotherRegular",
        lineHeight: '20px',
        color: '#828282',
        padding: '0px 0px 18px 0px',
    },
    userNameText: {
        fontSize: "18px",
        fontFamily: "ClotherBold",
        lineHeight: '24px',
        color: '#2B2A29',
        marginLeft: '16px',
    },
    userDetails: {
        margin: '0px 0px 18px 0px'
    },
    profilePic: {
        cursor: "pointer",
        height: '46px',
        width: '46px',
    },
    viewAllText: {
        fontFamily: 'ClotherBold',
        fontSize: '12px',
        lineHeight: '24px',
        color: '#8735E1',
        cursor: 'pointer',
        paddingBottom: '20px',
        marginTop: '22px',
    },
    recentPostTitle: {
        fontFamily: 'ClotherBold',
        fontSize: '18px',
        lineHeight: '24px',
        color: '#2B2A29',
        paddingBottom: '20px',
        marginTop: '22px',
    },
    postList: {
        borderRadius: "8px",
        backgroundColor: "#FFFFFF",
    },
    followBtn: {
        width: '180px',
        height: '46px',
        borderRadius: '8px',
        border: '1px solid #8735E1',
        fontFamily: 'ClotherBold',
        fontSize: '20px',
        lineHeight: '22px',
        color: '#FFFFFF',
        boxShadow: 'none',
        textTransform: 'none',
        background: '#8735E1',
    },
    followingBtn: {
        width: '180px',
        height: '46px',
        borderRadius: '8px',
        border: '1px solid #8735E1',
        fontFamily: 'ClotherBold',
        fontSize: '20px',
        lineHeight: '22px',
        color: '#8735E1',
        boxShadow: 'none',
        textTransform: 'none',
        background: '#FFFFFF',
    },
    followingValue: {
        fontFamily: 'ClotherBold',
        fontSize: '16px',
        lineHeight: '22px',
        color: '#2B2A29'
    },
    following: {
        fontFamily: 'ClotherRegular',
        fontSize: '16px',
        lineHeight: '22px',
        color: '#727272',
        marginRight: '20px'
    },
    userName: {
        fontFamily: 'ClotherBold',
        fontSize: '22px',
        lineHeight: '24px',
        paddingBottom: '14px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: '80%',
    },
    role: {
        fontFamily: 'ClotherRegular',
        fontSize: '16px',
        lineHeight: '18px',
        color: '#727272',
        paddingBottom: '19px',
    },
    actionTrackerHeaders: {
        fontFamily: 'ClotherRegular',
        fontSize: '14px',
        lineHeight: '22px',
        color: '#18181E',
        margin: '0px',
    },
    currentPage: {
        fontFamily: 'ClotherBold',
        fontSize: '14px',
        color: '#8735E1',
        marginLeft: '2px',
    },
    profileContainer: {
        minHeight: '140px',
        borderRadius: '8px',
        background: '#FFFFFF',
        margin: '17px 0px 20px 0px',
        padding: '12px 12px'
    },
    avatarStyle: {
        height: '86px',
        width: '86px',
    },
}
// Customizable Area End
