// Customizable Area Start
import React from "react";
import { Box, 
  Container,
  Grid,
  Typography,
  Button,
  Modal,
  TextField,styled,
  MenuItem,
  InputLabel} from "@material-ui/core";
import SafetyInteractionController, {
    Props,
} from "./SafetyInteractionController.web";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Formik, Form } from "formik";
import { NavigateNext } from "@material-ui/icons";
import { buttonFilter, buttonFilterActive, Location, Profile } from "../assets";
import { calendarIcon, closeIcon } from "../../../dashboard/src/assets";
import { Filters, Wrapper } from "../../../formapprovalworkflow/src/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

export default class SafetyInteraction extends SafetyInteractionController {
    constructor(props: Props) {
        super(props);
    }

  renderAssignNewSafetyInteraction = () => {
    return (
      <Box p={3}>
        <Typography
          style={webStyles.heading}>
          Assign New Safety Interaction
        </Typography>
        <Formik
          enableReinitialize
          data-test-id = "formik2"
          initialValues={{
            interactionNumber: "PLANT NAME/YEAR/MONTH/SERIES",
            assignee: this.state.assigneeOptions || "",
            targetDate: "",
            location: this.state.locationOptions || "",
          }}
          validationSchema={this.newInteractionSchema}
          onSubmit={(values) => {
            this.postNewInteractionData(values)
          }}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
            <Form translate={"no"} onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <InputLabel style={webStyles.labels}>Safety Interaction Number</InputLabel>
                  <TextField
                    fullWidth
                    style={{ width: "49%" }}
                    name="interactionNumber"
                    InputProps={{
                      style: webStyles.disabledInput
                    }}
                    value={values.interactionNumber}
                    disabled
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={6}>
                  <InputLabel style={webStyles.labels}>Assign To</InputLabel>
                  <CustomTextField
                    select
                    fullWidth
                    name="assignee"
                    value={values.assignee}
                    onChange={(event) => {
                      handleChange(event);
                    }}
                    onBlur={handleBlur}
                    InputProps={{style: webStyles.borderstyle}}
                    error={touched.assignee && Boolean(errors.assignee)}
                    helperText={touched.assignee && errors.assignee}
                    variant="outlined"
                    data-test-id="assign_to_options"
                  >
                    {this.state.assigneeOptions.map((options: any) => (
                      <MenuItem key={options.id} 
                        value={options.id}>
                        {options.name}
                      </MenuItem>
                    ))}
                  </CustomTextField>
                </Grid>

                <Grid item xs={6}>
                  <InputLabel style={webStyles.labels}>Target Date</InputLabel>
                  <CustomTextField
                    fullWidth
                    type="date"
                    name="targetDate"
                    InputProps={{style: webStyles.borderstyle}}
                    InputLabelProps={{ shrink: true }}
                    value={values.targetDate}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.targetDate && Boolean(errors.targetDate)}
                    helperText={touched.targetDate && errors.targetDate}
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={12}>
                  <InputLabel style={webStyles.labels}>Location</InputLabel>
                  <CustomTextField
                    select
                    fullWidth
                    name="location"
                    value={values.location}
                    onChange={(event) => {
                      handleChange(event);
                    }}
                    onBlur={handleBlur}
                    style={{ width: "49%" }}
                    error={touched.location && Boolean(errors.location)}
                    helperText={touched.location && errors.location}
                    variant="outlined"
                    InputProps={{style: webStyles.borderstyle}}
                  >
                    {this.state.locationOptions.map((option: any) => (
                      <MenuItem 
                       key={option.id} 
                       value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </CustomTextField>
                </Grid>

                <Grid item xs={12} style={{ textAlign: "right" }}>

                  <Box style={webStyles.buttonBox}>
                    <Button
                      data-test-id="add_btn"
                      variant="contained"
                      style={webStyles.assignButton}
                      type='submit'
                    >
                      Assign
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>
    );
  };

  renderStartSafetyInteraction = () => {
    return (
      <Box p={3}>
        <Typography style={webStyles.heading}>
          Start a Safety Interaction
        </Typography>
        <Formik
        enableReinitialize
          initialValues={{
            interactionNumber: this.state.formOneValue?.interaction_number || "PLANT/2025/04/01" ,
            assignTo: this.state.profileData.name ? `${this.state.profileData.name} (You)` : "",
            assigneeId: this.state.profileData.id,
            reviewer: this.state.profileData.reviewerName || "",
            reviewerId: this.state.profileData.reviewerId || "",
            targetDate: this.state.formOneValue?.target_date || "",
            location: this.state.locationOptions || "",
          }}
          data-test-id = "formik1"
          validationSchema={this.startInteractionSchema}
          onSubmit={(values) => {
            this.postStartInteractionData(values)
          }}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
            <Form translate={"no"} onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12} >
                  <InputLabel style={webStyles.labels}>Safety Interaction Number</InputLabel>
                  <TextField
                    name="interactionNumber"
                    value={values.interactionNumber || ""}
                    disabled
                    variant="outlined"
                    style={{ width: "49%" }}
                    InputProps={{
                      style: webStyles.disabledInput
                    }}
                  />
                </Grid>

                <Grid item xs={6}>
                  <InputLabel style={webStyles.labels}>Assign To</InputLabel>
                  <TextField
                    InputProps={{
                      style: webStyles.disabledInput
                    }}
                    fullWidth 
                    value={values.assignTo} 
                    disabled variant="outlined" />
                </Grid>

                <Grid item xs={6}>
                  <InputLabel style={webStyles.labels}>Reviewer</InputLabel>
                  <TextField fullWidth value={values.reviewer} 
                    InputProps={{
                      style: webStyles.disabledInput
                    }}
                    disabled variant="outlined" />
                </Grid>

                <Grid item xs={6}>
                  <InputLabel style={webStyles.labels}>Target Date</InputLabel>
                  <CustomTextField
                    fullWidth
                    type="date"
                    name="targetDate"
                    InputLabelProps={{ shrink: true }}
                    value={values.targetDate}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    InputProps={{style: webStyles.borderstyle}}
                    error={touched.targetDate && Boolean(errors.targetDate)}
                    helperText={touched.targetDate && errors.targetDate}
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={6}>
                  <InputLabel style={webStyles.labels}>Location</InputLabel>
                  <CustomTextField
                    select
                    fullWidth
                    InputProps={{ style: webStyles.borderstyle }}
                    name="location"
                    value={values.location}
                    onChange={(event) => {
                      handleChange(event);
                    }}
                    onBlur={handleBlur}
                    error={touched.location && Boolean(errors.location)}
                    helperText={touched.location && errors.location}
                    variant="outlined"
                  >
                    {this.state.locationOptions.map((option: any) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}

                  </CustomTextField>
                </Grid>

                <Grid item xs={12} style={{ textAlign: "right" }}>
                  <Box style={webStyles.buttonBox}>
                    <Button
                      data-test-id="add_btn"
                      variant="contained"
                      style={webStyles.assignButton}
                      type="submit"
                    >
                      Assign
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>
    );
  };
   
  renderLocationDate =(date: string, location: string)=>{
    return(
      <>
        <Box display="flex" alignItems="center" mt={1}>
          <img src={calendarIcon} style={webStyles.calenderIcon} />
          <Typography variant="body2">Target Date: {date}</Typography>
        </Box>

        <Box display="flex" alignItems="center" mt={1}>
          <img src={Location} style={webStyles.locationIcon} />
          <Typography variant="body2">{location}</Typography>
        </Box>
      </>
    )
  }

    renderModalFilter =()=> {
    const {
      openFilterModal,
    } = this.state;

    return (<Modal open={openFilterModal} onClose={this.closeFilterModal}>
      <Filters>
        <Box className="Filters-modal-header">
          <Typography>Filters</Typography>
          <img
            style={{ cursor: "pointer" }}
            data-test-id="close-filter"
            src={closeIcon}
            alt="close"
            onClick={this.closeFilterModal}
          />
        </Box>
      </Filters>  
      </Modal>
      )}

    render() {
        // Merge Engine - render - Start
        const {activeButton,openFilterModal, activeTab} = this.state;
      return (
        <ThemeProvider theme={theme}>
          <Grid container>
            <Wrapper style={{ padding: "0px", height: "100%",backgroundColor:"#fff",boxShadow: "4px 0px 4px 0px rgba(0, 0, 0, 0.02)" }}>
              <Container maxWidth={"xl"} style={{ position: "relative", minHeight: "100vh" }}>
                <Box style={{ padding: "30px 10px", }}>
                  <Box style={webStyles.breadcrumbWrapper1}>
                    <Typography 
                    style={webStyles.breadcrumbItem1} 
                    onClick={this.handleNavigateToSafetyInt}
                    data-test-id = "navigate_safety"
                    >Safety Interaction</Typography>
                    {(activeTab === 1 || activeTab === 2) && (
                      <>
                        <NavigateNext style={webStyles.navigateIcon1} />
                        <Typography style={webStyles.breadcrumbActiveItem1}>
                          {activeTab === 1 ? "Start a Safety Interaction" : "Assign New Safety Interaction"}
                        </Typography>
                      </>)}
                  </Box>
                  {activeTab === 0 && (<Box>
                  <Box className="myPostsTitle">
                    <Typography className="mainHeading">Safety Interaction</Typography>
                      <Box className="filter-wrapper">
                        <Button
                          data-test-id="safety_int"
                          type="submit"
                          style={webStyles.ScheduleButtonStyle1}
                          onClick={() => {
                            this.setState({
                              activeTab: this.state.activeButton === "Assigned to Others" ? 2 : 1,
                            });
                          }}
                        >
                          {this.state.activeButton === "Assigned to Others" ? "Assign New Safety Interaction" : "Start a Safety Interaction"}
                        </Button>
                        <img
                          height="32px"
                          width="32px"
                          data-test-id="filter-modal"
                          style={{ cursor: 'pointer' }}
                          src={openFilterModal || this.state.applyFilter ? buttonFilterActive : buttonFilter}
                          alt="filter"
                        />
                      </Box>
                    {this.renderModalFilter()}
                  </Box>

                  <Box style={webStyles.tabs}>
                    <Button
                      data-test-id="assign_me"
                      style={webStyles.buttons}
                      className={`buttonStyle ${activeButton === "Assigned to Me" ? "buttonStyleActive" : ""
                        }`}
                      onClick={this.handleAssignToMe}
                    >Assigned to Me</Button>
                    <Button
                      style={webStyles.buttons}
                      data-test-id="assign_others"
                      className={`buttonStyle ${activeButton === "Assigned to Others" ? "buttonStyleActive" : ""
                        }`}
                      onClick={this.handleAssignToOthers}
                    >Assigned to Others</Button>
                  </Box>
                <Grid container spacing={2}>
                  {activeButton === "Assigned to Me" ? (
                    this.state.safetyInteractions
                      .map((interaction:any) => (
                        <Grid item xs={12} sm={6} md={4} key={interaction.attributes.id}>
                          <Box style={webStyles.listing}
                          data-test-id="handleAssignToMeListing"
                             onClick={()=>this.handleAssignToMeListing(interaction.attributes.id)}
                          >
                            <Typography style={webStyles.status}>{interaction.attributes.status}</Typography>

                            <Typography style={{ fontWeight: 500, fontSize: "20px" }}>
                              {interaction.attributes.interaction_number}
                            </Typography>

                            {this.renderLocationDate(interaction.attributes.target_date,interaction.attributes.location)}
                          </Box>
                        </Grid>
                      ))
                  ) : (
                    this.state.safetyInteractions
                      .map((interaction:any,id:number) => {
                        return(
                          <Grid item xs={12} sm={6} md={4} key={interaction.attributes.id}>
                          <Box style={webStyles.listing} onClick={()=>this.handleAssignToOtherListing(interaction.attributes.id)}>
                            <Typography style={webStyles.status}>{interaction.attributes.status}</Typography>

                            <Typography style={{ fontWeight: 500, fontSize: "20px" }}>
                              {interaction.attributes.interaction_number}
                            </Typography>

                            <Box display="flex" alignItems="center" mt={1}>
                              <img src={Profile} style={webStyles.locationIcon} />
                              <Typography variant="body2">Assigned To: {interaction.attributes.assignedTo}</Typography>
                            </Box>

                            {this.renderLocationDate(interaction.attributes.target_date,interaction.attributes.location)}
                          </Box>
                        </Grid>
                        )
                      })
                  )}
                </Grid>
                </Box>)}
                {activeTab === 1 && (
                  this.renderStartSafetyInteraction()
                )}
                {activeTab === 2 && (
                  this.renderAssignNewSafetyInteraction()
                )}
                </Box>
              </Container>
            </Wrapper>
          </Grid>
        </ThemeProvider>
      );
        // Merge Engine - render - End
    }
}

export const CustomTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
      border: "1px solid #DCDCDC",
  },
  "& .MuiOutlinedInput-root:hover fieldset": {
      borderColor: "#DCDCDC",
  },
  "& .MuiOutlinedInput-root.Mui-focused fieldset": {
      borderColor: "#DCDCDC",
      borderWidth: "2px",
  },
});


export const webStyles = {
  breadcrumbActiveItem1: {
    color: "#8735E1",
    fontSize: "16px",
    lineHeight: "22px",
    fontFamily: 'ClotherBold',
    fontWeight: 700,
    cursor: "pointer",
  },
  breadcrumbWrapper1: {
    display: 'flex',
  },
  breadcrumbItem1: {
    fontFamily: 'ClotherRegular',
    fontSize: '16px',
    lineHeight: '22px',
    color: '#A1A1A1',
    fontWeight: 600,
    cursor: 'pointer',
  },
  navigateIcon1: {
    fill: '#DCDCDC'
  },
  ScheduleButtonStyle1: {
    border: "1px solid #8735E1",
    textTransform: "none" as "none",
    padding: "18px 10px",
    fontSize: "16px",
    fontFamily: "ClotherBold",
    height: "30px",
    color: "#8735E1",
    borderRadius: "8px",
    marginRight: "10px",
    alignItems: "cemter",
    "@media(max-width:601px)": {
      lineHeight: "15px",
      height: "45px"
    },
  },
  calenderIcon: {
    padding: "2px 8px"
  },
  locationIcon: {
    padding: "2px 8px 2px 6px"
  },
  status: {
    backgroundColor: "#E0F7FA",
    color: "#007BFF",
    fontWeight: "bold" as "bold",
    padding: "4px 10px",
    borderRadius: "12px",
    fontSize: "12px",
    position: "absolute" as "absolute",
    top: "8px",
    right: "8px",
    marginTop: "10px"
  },
  listing: {
    border: "1px solid #E0E0E0",
    borderRadius: "8px",
    padding: "16px",
    boxShadow: "2px 2px 10px rgba(0,0,0,0.1)",
    position: "relative" as "relative"
  },
  buttonBox: {
    display: "flex",
    justifyContent: "end",
    width: "100%",
    marginBottom: "10px",
  },
  assignButton: {
    boxShadow: "none",
    padding: "10px",
    color: "#FFF",
    textAlign: "center" as "center",
    borderRadius: "8px",
    background: "#8735E1",
    width: "160px",
    height: "50px",
    fontFamily: "ClotherBold",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 700,
    textTransform: "capitalize" as "capitalize",
  },
  tabs: {
    paddingBottom: "25px"
  },
  labels: {
    fontFamily: "ClotherRegular",
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "32px",
    letterSpacing: "-0.41px",
    color: "#18181E",
    marginBottom: "5px"
  },
  heading: {
    fontFamily: "ClotherRegular",
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: "24px",
    letterSpacing: "-0.41px",
    verticalAlign: "middle",
    marginBottom: "30px"
  },
  disabledInput: {
    backgroundColor: "#F3F3F3",
    color: "#A1A1A1",
    borderRadius: "8px",
  },
  buttons: {
    fontFamily: "ClotherBold",
    fontSize: "14px",
    padding: "8px 15px"
  },
  borderstyle: {
    borderRadius: "8px",
  }
}

// Customizable Area End
