import React, { ChangeEvent } from "react"
import {
  Backdrop,Button,
  CircularProgress, FormHelperText,
  FormControlLabel,Input,
  Dialog,FormGroup,
  Grid,IconButton,
  MenuItem,Radio,
  Typography,RadioGroup,
  TextField,Select,
  ThemeProvider,Switch,
  withStyles,Box,
  styled,Paper,
} from "@material-ui/core"
import { Close, KeyboardArrowDown } from "@material-ui/icons"
import { theme, webStyle } from "./styles"
import { ErrorMessage, Formik, FormikErrors, FormikTouched } from "formik"
import * as Yup from "yup"
import { CongratsModal } from "../../../../components/src/CongratsModal"
import { isPhone } from "../consts"
import { mic, micStart, upload, aiLoader } from "../assets"
import { webStyles } from "../../../../components/src/styles"
import { errorIcon } from "../../../../components/src/assets"
import { Autocomplete } from "@material-ui/lab"
export const configJSON = require("./config")
import i18n from "i18next";
import Incident from "../Incident.web";
import MarkupTool from "../../../../components/src/MarkupTool.web"
import { sortMenuItems } from "../../../../components/src/helpers/getTimeFromNow"
import GoodPractice from "../GoodPractice.web"
import { runEngine } from "framework/src/RunEngine"
export interface SelectEventType {
  target: {
    value: string | unknown;
  }
};

interface ReportData {
  label: string;
  id: string;
}
const CustomSwitch = withStyles((theme) => ({
  root: {
    width: theme.spacing(5),
    height: theme.spacing(3),
  },
  switchBase: {
    color: "#fff",
    "&$checked": {
      color: "#8735e1",
    },
    "&$checked + $track": {
      backgroundColor: "#8735e1",
    },
  },
  checked: {},
  track: {},
}))(Switch);

const Wrapper = withStyles((theme) => ({
  root: {
    "& *::placeholder": {
      color: '#DCDCDC'
    },
    "& .textarea": {
      resize: 'none',
    },
  },
}))(Box);

export enum PostSteps {
  Step1,
  Step2,
  Step3,
  StepComp,
}

export type PostFormData = {
  employee_id: string;
  sub_category_id: any;
  autoLocation: boolean;
  work_location_id: any;
  description: string;
  image: string;
  shift: any;
  proposed_description: string | null;
  hazard: string;
  operation_identification_id: any;
  post_concern_id: number | null;
  hazard_id: number | null;
  frequency_id: number | null;
  severity_id: number | null;
  consequence_id: number | null;
  hashtags: string[];
};

type PostProps = {
  onIncidentClick: () => void;
  emptyPostId: number;
  setShowPostPopUp: (arg: boolean) => void;
  setShowRewardPopUp: (arg: boolean) => void;
  postSettings: PostSettings;
  loading?: boolean;
  aiImageprocessing?: boolean;
  createPost: (arg: any) => void;
  userName: string;
  postCreationFinished: boolean;
  buLocationCategory: any[];
  machineWorkLocation: any[];
  machineChangeStatus: (id: string) => void;
  operationIdentificationsChange: (id: string) => void;
  operationIdentificationsData: any[];
  loaderData: boolean;
  getImageFile: (arg: any) => void;
  postDescription: string;
  showAiTag: boolean;
  aiProcessingError?: string | null;
  resetAiErrorMessage: () => void;
  uploadedImageUrl: string;
};

export type UpldFileLink = string | ArrayBuffer | null;

export type PostState = {
  postData: any[];
  postStep: PostSteps;
  inputSchema: any;
  upldFileLink: UpldFileLink;
  postSubmitted: boolean;
  listening: boolean;
  reportValue:string;
  transcription: string;
  liststep2: boolean;
  stoppedByTimeout: boolean;
  autoStopTimeout: boolean;
  imageErrorMessage: string;
  postDescription: string;
  aiLoaderText: string[];
  aiLoaderTextIndex: number;
  searchQuery:string;
  machineSearchQuery:string;
  reportData:ReportData[],
  isIncident:boolean;
  isGoodPractice: boolean;
  selectedImage: string | null;
  showEditor: boolean;
  editedImage: string | null;
  subFunctionId: string;
  sybFunctionName: string;
};

export type frequencyItem = {
  id: string
  type: string,
  attributes: {
    id: number,
    name: string
  }
}

export type PostSettings = {
  employeeIds: string[];
  machineIds: string[];
  processAndOperationIds: any[];
  location: string;
  shifts: any[];
  concerns: any[];
  hazardSelects: any[];
  frequencies: frequencyItem[];
  severities: frequencyItem[];
  consequences: any[];
  hashtags: any[] | null;
};

export type ChildProps = {
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  handleChange: {
    (e: ChangeEvent<any>): void;
    <T = string | ChangeEvent<any>>(field: T): T extends ChangeEvent<any>
      ? void
      : (e: string | ChangeEvent<any>) => void;
  };
  submitForm: () => Promise<void>;
  errors: FormikErrors<PostFormData>;
  setFieldTouched: (
    field: string,
    isTouched?: boolean | undefined,
    shouldValidate?: boolean | undefined
  ) => void;
  touched: FormikTouched<PostFormData>;
  values: PostFormData;
  handleBlur: (e: React.FocusEvent<any>) => void;
  setStep: (step: PostSteps) => void;
  setUpldFileLink: (link: UpldFileLink) => void;
  setPostSubmitted: (value: boolean) => void;
  loading?: boolean;
  upldFileLink: UpldFileLink;
  postSettings: {
    employeeIds: string[];
    machineIds: string[];
    processAndOperationIds: any[];
    location: string;
    shifts: any[];
    concerns: any[];
    hazardSelects: any[];
    frequencies: frequencyItem[];
    severities: frequencyItem[];
    consequences: any[];
    hashtags: any[] | null;
  };
  buLocationCategory: any[];
  machineWorkLocation: any[];
  machineChangeStatus: (id: string) => void;
  operationIdentificationsChange: (id: string) => void;
  operationIdentificationsData: any[];
  loaderData: boolean;
};

export default class PostCreationPopUp extends React.Component<
  PostProps,
  PostState
> {
  textFieldRef: any;
  private recognition: any | undefined;
  constructor(props: PostProps) {
    super(props);

    const inputSchema = Yup.object().shape({
      description: Yup.string().required(configJSON.descIsRequired),
      sub_category_id: Yup.string()
        .notOneOf(["0"], "Please Select Location - BU or Cell")
        .required("Please Select Location - BU or Cell"),
      work_location_id: Yup.string()
        .notOneOf(["0"], "Please Select Machine / Equipment")
        .required("Please Select Machine / Equipment"),
      operation_identification_id: Yup.string()
        .notOneOf(["0"], "Please Select Process & Operation Identification")
        .required("Please Select Process & Operation Identification"),
      youWantReport: Yup.string().required("Please Select The Report"),
      image: Yup.mixed().required("Image is required"),
    });

    this.textFieldRef = React.createRef();
    this.changeImg = this.changeImg.bind(this);

    this.state = {
      machineSearchQuery:"",
      searchQuery:"",
      postData: [],
      postStep: PostSteps.Step1,
      inputSchema,
      upldFileLink: "",
      postSubmitted: false,
      listening: false,
      reportValue:"",
      transcription: "",
      liststep2: false,
      stoppedByTimeout: false,
      autoStopTimeout: false,
      imageErrorMessage: "",
      postDescription: "",
      aiLoaderText: [
        "Applying privacy filter",
        "Analysing hazards in the image",
        "Generating explanations and actions items",
      ],
      reportData: sortMenuItems([
        { id: "1", label: "Unsafe Behaviour / Unsafe action / Unsafe condition" },
        { id: "2", label: "Good Practices" },
        { id: "3", label: "Short Form Content" },
        { id: "4", label: "Incident Reporting" }
    ], "label"),
        isIncident:false,
        isGoodPractice: false,
      aiLoaderTextIndex: 0,
      selectedImage: null,
      showEditor: false,
      editedImage: null,
      subFunctionId: "",
      sybFunctionName: ""
    };

    this.setStep = this.setStep.bind(this);
    this.setUpldFileLink = this.setUpldFileLink.bind(this);
    this.setPostSubmitted = this.setPostSubmitted.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.toggleListening = this.toggleListening.bind(this);
  }
  /* istanbul ignore next */
  componentDidMount() {
    if ("SpeechRecognition" in window || "webkitSpeechRecognition" in window) {
      this.setupSpeechRecognition();
    }
  }

  componentDidUpdate(prevProps: Readonly<PostProps>): void {
    if (prevProps.postDescription !== this.props.postDescription) {
      this.setState({ postDescription: this.props.postDescription + " " + this.state.postDescription }, () => {
        if (this.textFieldRef.current) {
          this.textFieldRef.current.setFieldValue('description', this.state.postDescription);
        }
      });
    }
  }

  setupSpeechRecognition() {
    this.recognition = new ((window as any).SpeechRecognition ||
      (window as any).webkitSpeechRecognition)();

    if (this.recognition) {
      this.recognition.lang = "en-US";
      this.recognition.continuous = true;
      this.recognition.interimResults = true;
      this.recognition.onresult = (event: any) => {
        const transcription = Array.from(event.results)
          .map((result: any) => result[0].transcript)
          .join("");
        this.setState({ transcription });
      };

      const pageHiddenProp = this.getPageHiddenProp();
      if (pageHiddenProp) {
        this.addVisibilityChangeListener(pageHiddenProp);
      }
    }
  }

  getPageHiddenProp() {
    const hiddenProps = ["hidden", "webkitHidden", "mozHidden"];
    for (const prop of hiddenProps) {
      if (prop in document) {
        return prop;
      }
    }
    return null;
  }

  addVisibilityChangeListener(pageHiddenProp: string) {
    document.addEventListener(pageHiddenProp.replace(/[H|h]idden/, '') + 'visibilitychange', () => {
      if ((document as any)[pageHiddenProp]) {
        if (this.recognition && this.state.listening) {
          this.recognition.stop();
          this.setState({ listening: false });
        }
        if (this.recognition && this.state.liststep2) {
          this.recognition.stop();
          this.setState({ liststep2: false });
        }
      }
    });
  }

  toggleListening = async () => {
    if (this.recognition) {
      if (this.state.listening) {
        this.setState({ stoppedByTimeout: true });
        this.recognition.stop();
      } else {
        this.setState({ stoppedByTimeout: false });
        await this.recognition.start();
        this.setState({ transcription: "" });
        setTimeout(() => {
          if (this.state.listening && !this.state.stoppedByTimeout) {
            this.recognition.stop();
            this.setState({ listening: false });
          }
        }, 30000);

      }
      this.setState((prevState) => ({
        listening: !prevState.listening,
      }));
    }
  };

  toggleListeningStep2 = async () => {
    if (this.recognition) {
      if (this.state.liststep2) {
        this.setState({ autoStopTimeout: true });
        this.recognition.stop();
      } else {
        this.setState({ autoStopTimeout: false });
        await this.recognition.start();
        this.setState({ transcription: "" });
        setTimeout(() => {
          if (this.state.liststep2 && !this.state.autoStopTimeout) {
            this.recognition.stop();
            this.setState({ liststep2: false });
          }
        }, 30000);
      }
      this.setState((prevState) => ({
        liststep2: !prevState.liststep2,
      }));
    }
  };

  getExtension = (filename: string | undefined) => {
    return filename?.split(".").pop()?.toLowerCase();
  };

  changeImg(event: any) {
    const files = event?.target.files;

    const fileReader = new FileReader();
    fileReader.addEventListener("load", async () => {
      this.setUpldFileLink(fileReader.result);
      this.setState({ showEditor: true }); 
    });
    if (files?.length) {
      fileReader.readAsDataURL(files[0]);
    } else {
      this.setState({ upldFileLink: "" })
    }
  }
  setStep(step: PostSteps) {
    this.setState({ postStep: step });
  }

  setUpldFileLink(upldFileLink: UpldFileLink) {
    this.setState({ upldFileLink });
  }

  setPostSubmitted(postSubmitted: boolean) {
    this.setState({ postSubmitted });
  }

  closeModal() {
    this.props.setShowPostPopUp(false);
    this.recognition.stop();
  }
  updateImgs = (
    event: ChangeEvent<HTMLInputElement> | undefined,
    setFieldValue: (field: string, value: File | undefined) => void
  ) => {
    if (!event?.target?.files?.length) {
      this.setState({ imageErrorMessage: "No file selected." });
      return;
    }
  
    const file = event.target.files[0];
    const fileExt = this.getExtension(file.name);
  
    if (!["jpg", "jpeg", "png"].includes(fileExt || "")) {
      this.setState({ imageErrorMessage: "Please select a valid image (JPG, JPEG, PNG)." });
      return;
    }
  
    setFieldValue("image", file);
  
    this.setState(
      { imageErrorMessage: "", showEditor: false, upldFileLink: "" },
      () => {
        const reader = new FileReader();
        reader.onload = () => {
          this.setState({ upldFileLink: reader.result as string, showEditor: true, editedImage: null });
        };
        reader.readAsDataURL(file);
      }
    );
  };
  
  calculateNextStep(isStep1: boolean, isStep2: boolean, isHazard: boolean) {
    return (
      (isStep1 && PostSteps.Step2) ||
      (isStep2 && isHazard && PostSteps.Step3) ||
      PostSteps.StepComp
    );
  }
  onsubmitFunction = (
    values: any,
    actions: any,
    isStep1: any,
    isStep2: any
  ) => {
    const formData = new FormData();
    const isHazard = values.hazard === "yes";
    const { reportValue, postSubmitted } = this.state;
    this.props.setShowRewardPopUp(true)
    if (reportValue === "4") {
      this.props.onIncidentClick()
    } else if(reportValue === "2"){
      this.props.setShowRewardPopUp(false)
      this.setState({ postSubmitted: true },() => {
        this.setState({ isGoodPractice: true });
      })
    }
     else {
      const nextStep = this.calculateNextStep(isStep1, isStep2, isHazard);
      if (!postSubmitted) {
        this.setState({ postStep: nextStep });
      }
    }



    this.checksForPostSubmit(values, formData)
    if (isStep1 || isStep2) {
      this.recognition?.stop();
      this.setState({ listening: false, liststep2: false });
    }
    actions.setSubmitting(false);
  };

  checksForPostSubmit = (values: { operation_identification_id: any; post_concern_id: any; hazard_id: any; frequency_id: any; severity_id: any; consequence_id: any; }
    , formData: FormData) => {
    if (this.state.postSubmitted) {
      const idValues = {
        operation_identification_id: values.operation_identification_id,
        post_concern_id: values.post_concern_id,
        hazard_id: values.hazard_id,
        frequency_id: values.frequency_id,
        severity_id: values.severity_id,
        consequence_id: values.consequence_id,
        risk_level_name:
          values.severity_id && values.frequency_id
            ? values.severity_id + values.frequency_id
            : null,
      };
      let newValues: any = values;

      delete newValues.image;

      const resValues = { ...newValues, ...idValues }
      for (let key in resValues) {
        const entity = (resValues as Record<string, any>)[key];
        if (entity !== "") {
          entity?.name
            ? formData.append(key, entity, entity?.name)
            : formData.append(String(key), String(entity));
        }
      }
      formData.append('status', 'publish');
      this.props.createPost(formData);
    }
  }

  renderMic() {
    return this.state.listening ? micStart : mic;
  }

  renderMicStep2() {
    return this.state.liststep2 ? micStart : mic;
  }

  setMikeData = async (setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void, values: { description: string; }) => {
    this.setState({ postDescription: this.state.postDescription + " " + this.state.transcription });
    setFieldValue(
      "description",
      this.state.postDescription + " " + this.state.transcription
    );
  }

  handleDescriptionChange = (event: any, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
    event.persist();
    this.setState({
      postDescription: event.target.value,
    }, () => {
      setFieldValue("description", this.state.postDescription);
    });
  }

  setActionData = async (setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void, values: { proposed_description: string; }) => {
    if (this.state.transcription && values.proposed_description) {
      setFieldValue(
        "proposed_description",
        values.proposed_description + " " + this.state.transcription
      );
    }
    if (this.state.transcription && !values.proposed_description) {
      setFieldValue(
        "proposed_description",
        this.state.transcription
      );
    }
  }

  displayLoadingText = () => {
    const loaderText = this.state.aiLoaderText;
    let currentIndex = this.state.aiLoaderTextIndex;
    const interval = setInterval(() => {
      currentIndex++;
      if (currentIndex === loaderText.length) {
        clearInterval(interval);
      } else {
        this.setState({ aiLoaderTextIndex: currentIndex });
      }
    }, 2500);
  }

  FirstStepInputs = (
    values: { employee_id: unknown; sub_category_id: unknown; },
    handleChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>,
    handleBlur: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>,
    errors: { sub_category_id: number; },
    touched: { sub_category_id: any; },
    setFieldValue: (field: string, value: any,) => void
    
  ) => {
      const { buLocationCategory, machineChangeStatus } = this.props;
      const sortedLocations = sortMenuItems(buLocationCategory, "attributes.name")
    const { searchQuery } = this.state;
    const filteredLocations = sortedLocations?.filter((menuItem) =>
      menuItem.attributes.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
      <>
        <FormGroup className="input-block" style={webStyle.inputBlock}>
          <FormHelperText style={webStyle.label}>{i18n.t('Employee ID')}</FormHelperText>
          <Input
            disabled
            name="employee_id"
            data-id="empId"
            style={{
              ...webStyle.input,
              ...webStyle.inputStd,
              ...webStyle.inputDisabled,
              height: "50px",
            }}
            value={values.employee_id}
            disableUnderline
            placeholder="Employee ID"
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </FormGroup>
        <FormGroup className="input-block" style={webStyle.inputBlock}>
          {this.renderSelectReport(setFieldValue)}
        </FormGroup>


          <FormGroup className="regular-input" style={{...webStyle.inputMargin, width: '48%'}}>
            <div
              className="labels"
              style={webStyle.inLineFlex}
            >
              <>
                <Typography
                  style={{
                    ...webStyle.label,
                    ...webStyle.smalLabel,
                  }}
                >
                  Location - BU or Cell
                </Typography>
              </>
            </div>
            <ComboBox
              data-test-id="locationTestId"
              popupIcon={<StyledDropdownIcon />} 
              value={filteredLocations.find((option) => option.id === values.sub_category_id) || null}
              onChange={(event, newValue: any) => {
                setFieldValue("sub_category_id", newValue ? newValue.id : "");
                this.setState({ subFunctionId: newValue ? newValue.id : "", sybFunctionName: newValue ? newValue?.attributes?.name : "Unknown" })
                machineChangeStatus(newValue ? newValue.id : "");
                setFieldValue("work_location_id", 0);
                setFieldValue("operation_identification_id", 0);


              }}
              options={filteredLocations}
              getOptionLabel={(option: any) => option?.attributes?.name ?? "Unknown"}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Location - BU or Cell"
                  variant="outlined"
                  className="inputclass"
                />
              )}
              PaperComponent={StyledPaper}
          />
          <Typography style={webStyle.errorStyle}>
            {!!(errors.sub_category_id && touched.sub_category_id) && errors.sub_category_id}
          </Typography>

          </FormGroup>
      </>
    )
  }
  renderSelectReport = (setFieldValue: (field: string, value: any) => void) => {
    return (
      <>
        <div className="labels" style={webStyle.inLineFlex}>
          <Typography style={{ ...webStyle.label, ...webStyle.smalLabel }}>
            You want to report
          </Typography>
        </div>
        <InputFieldDropDown
          name="report"
          displayEmpty
          IconComponent={StyledDropdownIcon}
          value={this.state.reportValue || ""}
          onChange={(event: SelectEventType) => {
            setFieldValue("youWantReport", 0);
            this.handleIncidentChange(event);
          }}
          fullWidth
          className="inputFieldDropDown"
          MenuProps={{
            disableScrollLock: true,
            anchorOrigin: {
              horizontal: "left",
              vertical: "bottom"
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left"
            },
            getContentAnchorEl: null,
          }}
          renderValue={
            this.state.reportValue !== ""
              ? undefined
              : () => (
                <div style={{
                  fontFamily: "ClotherRegular",
                  fontSize: "16px",
                  fontWeight: 400,
                  color: "#DCDCDC",
                }}>
                  Select Report
                </div>
              )
          }
        >
          <MenuItem value="" disabled>Select Report</MenuItem>
          {this.state.reportData.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.label}
            </MenuItem>
          ))}
        </InputFieldDropDown>
          <ErrorMessage name="youWantReport" component="div" className="errorTxt" />
            </>
    );
  };
  
  handleIncidentChange = (event: SelectEventType) => {
      this.setState({
        reportValue: event.target.value as string
      })
    }
  

    handleSaveEditedImage = (updatedImage: string) => {
      if (!updatedImage || typeof updatedImage !== "string" || !updatedImage.includes(",")) {
        runEngine.debugLog("Invalid updatedImage provided:", updatedImage);
        return;
      }
    
      const base64Data = updatedImage.split(",")[1];
      if (!base64Data) {
        runEngine.debugLog("Invalid base64 data in updatedImage:", updatedImage);
        return;
      }
    
      this.setState(
        {
          editedImage: updatedImage,
          upldFileLink: updatedImage,
          aiLoaderTextIndex: 0,
          showEditor: false,
        },
        () => {
          const binaryData = Buffer.from(base64Data, "base64");
          const blob = new Blob([binaryData], { type: "image/png" });
          const formData = new FormData();
          formData.append("image", blob, "edited_image.png");
    
          this.props.getImageFile(formData);
        }
      );
    };
  
  FirstStepsInputs2 = (
    values: { work_location_id: unknown; operation_identification_id: unknown; },
    handleChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>,
    handleBlur: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined,
    errors: { work_location_id: number; operation_identification_id: number; },
    touched: { work_location_id: number; operation_identification_id: number; },
    setFieldValue: (field: string, value: any,) => void
  ) => {
    const { machineWorkLocation, operationIdentificationsChange } = this.props;
    const { machineSearchQuery } = this.state;
    const sortedWorkLocations = sortMenuItems(machineWorkLocation, "attributes.name")
    const filteredMachines = sortedWorkLocations.filter((worklocation) =>
      worklocation.attributes.name.toLowerCase().includes(machineSearchQuery.toLowerCase())
    );

    

    return (
      <>
        <FormGroup className="input-block" style={webStyle.inputBlock}>
          <FormHelperText style={webStyle.label}>
            {i18n.t('Machine / Equipment')}
          </FormHelperText>
          <ComboBox
            data-test-id="machineTestId"
            popupIcon={<StyledDropdownIcon />}
            value={filteredMachines.find((machine) => machine.id === values.work_location_id) || null}
            onChange={(event, newValue: any) => {
              setFieldValue("work_location_id", newValue ? newValue.id : "");
              operationIdentificationsChange(newValue ? newValue.id : "");
              setFieldValue("operation_identification_id", 0);
            }}
            options={filteredMachines ?? []}
            getOptionLabel={(option: any) => option?.attributes?.name ?? "Unknown"}
            renderInput={(params) => (
              <TextField {...params} placeholder="Search Machine..." variant="outlined" fullWidth />
            )}
            noOptionsText="No Records Found"
            PaperComponent={StyledPaper}
          />
          {errors.work_location_id && touched.work_location_id ? (
            <Typography style={webStyle.errorStyle}>
              {errors.work_location_id}
            </Typography>
          ) : null}
        </FormGroup>
        <FormGroup className="input-block"  style={{width: '100%'}}>
          <FormHelperText style={webStyle.label}>
            {i18n.t('Process & Operation Identification')}
          </FormHelperText>
          <ComboBox
            popupIcon={<StyledDropdownIcon />}
            data-test-id="opreationTestId"
            value={
              this.props.operationIdentificationsData?.find(
                (item) => item.attributes?.id === values.operation_identification_id
              ) || null
            }
            onChange={(event, newValue: any) => {
              setFieldValue("operation_identification_id", newValue ? newValue.attributes?.id : "");
            }}
            options={this.props.operationIdentificationsData ?? []}
            getOptionLabel={(option: any) => option?.attributes?.name ?? "Unknown"}
            renderInput={(params) => (
              <TextField {...params} placeholder="Search Process & Operation" variant="outlined" fullWidth
                />
            )}
            noOptionsText="No Data"
            PaperComponent={StyledPaper}
          />

          {errors.operation_identification_id && touched.operation_identification_id ? (
            <Typography style={webStyle.errorStyle}>
              {errors.operation_identification_id}
            </Typography>
          ) : null}

        </FormGroup>
      </>
    )
  }

  firstStep = (
    setFieldValue: any,
    values: any,
    handleBlur: any,
    handleChange: any,
    submitForm: any,
    touched: any,
    errors: any
  ) => {
    const {postDescription} = this.state;
    const words = postDescription.trim().split(" ");
    const filteredHashtags = this.getFilteredHashtags(postDescription, values.hashtags);

    return (
      <div data-id="step1" style={webStyle.form as React.CSSProperties}>
        {this.FirstStepInputs(values, handleChange, handleBlur, errors, touched, setFieldValue)}
        {this.FirstStepsInputs2(values, handleChange, handleBlur, errors, touched, setFieldValue)}
        <FormGroup
          className="input-block"
          style={webStyle.wideInputBlock as React.CSSProperties}
        >
          <FormHelperText style={webStyle.label}>{i18n.t('Related Image')}</FormHelperText>
          {this.state.showEditor && this.state.upldFileLink ? (
            <div style={{ position: 'relative' }}>
              <MarkupTool
                imageUrl={this.state.upldFileLink}
                onImageUpdate={this.handleSaveEditedImage}
              />
            </div>
          ) : (
            <Button
              component="label"
              style={
                // { ...webStyle.input, ...webStyle.upload } as React.CSSProperties
                {height: '169px', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '0.5rem',
                  border: '1px solid #DCDCDC',
                  fontFamily: 'ClotherRegular',
                  backgroundColor: 'white'} as React.CSSProperties
              }
            >
              <div
                className="upload-img"
                style={webStyle.uploadImg as React.CSSProperties}
              >
                <img
                  src={this.props.uploadedImageUrl || upload}
                  alt="upload image"
                  style={webStyle.iploadedImg as React.CSSProperties}
                />
                {values.image ? (
                  <>
                    <div style={webStyle.fontBlock}>{i18n.t('Replace Image')}</div>
                  </>
                ) : (
                  <div style={webStyle.fontBlock}>{i18n.t('Upload Image')}</div>
                )}
              </div>
              <input
                data-id="upldFileId"
                type="file"
                name="image"
                id="user-image"
                accept="image/png, image/jpeg"
                onChange={(event) => {
                  setFieldValue("image", event?.target.files?.[0]);
                  this.changeImg(event);
                this.props.getImageFile(event?.target.files?.[0]);
                this.updateImgs(event, setFieldValue)
                }}
                onBlur={handleBlur}
              />
            </Button>
          )}
          <Typography style={webStyle.errorStyle}>
            {touched.image && errors.image}
          </Typography>
          <Typography style={webStyle.errorStyle}>
            {this.state.imageErrorMessage}
          </Typography>
        </FormGroup>
        <FormGroup
          className="input-block"
          style={
            {
              ...webStyle.wideInputBlock,
              ...webStyle.customTopIndent,
            } as React.CSSProperties
          }
        >
          <FormHelperText style={webStyle.label}>{i18n.t('Brief Description ')}</FormHelperText>
          <img
            height="21px"
            width="21px"
            src={this.renderMic()}
            alt="mic icon"
            style={webStyle.mic as React.CSSProperties}
            onClick={async () => {
              await this.toggleListening();
              this.setMikeData(setFieldValue, values);
            }}
            data-test-id="speechText"
          />
          <TextField
            style={webStyle.textareaStyle}
            placeholder="Brief Description"
            value={this.state.postDescription}
            maxRows={3}
            minRows={3}
            multiline
            InputProps={{ disableUnderline: true }}
            onChange={(event: any) => this.handleDescriptionChange(event, setFieldValue)}
            onBlur={handleBlur}
            data-test-id="handleTextAreaChange"
            className="textarea"
            name="description"
            data-id="descId"
          />
          {this.props.showAiTag &&
            <Typography style={webStyles.AiTagText}>
              {configJSON.aiTagText}
            </Typography>}
          <Typography style={webStyle.errorStyle}>
            {errors.description !== "" &&
              touched.description && !values.description &&
              errors.description}
          </Typography>
        </FormGroup>
        <Grid style={webStyle.hashTagBox}>
          { filteredHashtags.map((hashtag: string, index: number) => (
            <Grid key={index}>
              <Button
                variant="outlined"
                style={webStyle.hashTagChips}
                data-test-id="hashtag"
                onClick={() => {
                  words[words.length - 1] = hashtag;
                  const newDescription = words.join(" ");
                  this.setState({ postDescription: newDescription });
                  setFieldValue(
                    "description",
                    newDescription
                  );
                  setFieldValue(
                    "hashtags",
                    values.hashtags.filter((value: string) => value !== hashtag)
                  );
                }}
              >
                <span>
                  {hashtag.slice(0, 15)} {hashtag.length < 15 ? "" : "..."}
                </span>
              </Button>
            </Grid>
          ))}
        </Grid>
        {this.renderButtons(submitForm)}
      </div>
    );
  };

  renderButtons = (submitForm: any) => {
    return (
      <div className="buttons" style={webStyle.buttons2 as React.CSSProperties}>
        <Button
          data-id="nextButtonStep1"
          style={{
            ...webStyle.button,
            ...webStyle.fullButton,
            ...(isPhone && webStyle.mobileButton),
          } as React.CSSProperties}
          onClick={submitForm}
          disabled={this.props.loading || this.props.aiImageprocessing}
        >
          {i18n.t("Next")}
        </Button>
      </div>
    );
  };

  getFilteredHashtags = (postDescription: string, hashtags: string[]) => {
    const words = postDescription.trim().split(" ");
    const lastWord = words.length > 0 ? words[words.length - 1].toLowerCase() : "";
  
    return lastWord.length > 0
      ? hashtags?.filter((hashtag: string) => hashtag.toLowerCase().includes(lastWord))
      : [];
  };

  secondStep = (
    values: any,
    handleBlur: any,
    handleChange: any,
    submitForm: any,
    setFieldValue: any
  ) => {
    const buttonText = values.hazard === "no" ? "Report" : "Next";
    return (
      <>
        <div data-id="step2" style={webStyle.form as React.CSSProperties}>
          <FormGroup className="input-block" style={webStyle.inputBlock}>
            <FormHelperText style={webStyle.label}>{i18n.t('Shift')}</FormHelperText>
            <Select
              name="shift"
              data-id="shiftId"
              label="Select Shift"
              style={
                {
                  ...webStyle.input,
                  ...webStyle.inputStd,
                } as React.CSSProperties
              }
              IconComponent={StyledDropdownIcon}
              value={values.shift}
              renderValue={
                values.shift
                  ? undefined
                  : () => (
                    <div style={webStyle.selectPlaceholder as React.CSSProperties}>
                      {i18n.t('Select Shift')}
                    </div>
                  )
              }
              onChange={handleChange}
              onBlur={handleBlur}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
              displayEmpty
              disableUnderline
            >
              {this.props.postSettings.shifts.map((menuItem) => (
                <MenuItem
                  key={menuItem.attributes?.id}
                  value={menuItem.attributes?.id}
                >
                  {menuItem.attributes?.name}
                </MenuItem>
              ))}
            </Select>
          </FormGroup>
          <FormGroup className="input-block" style={webStyle.inputBlock}>
            <FormHelperText style={webStyle.label}>
              {i18n.t('Concern is Related to')}
            </FormHelperText>
            <Select
              name="post_concern_id"
              data-id="concernId"
              label="Concern is Related to"
              style={
                {
                  ...webStyle.input,
                  ...webStyle.inputStd,
                } as React.CSSProperties
              }
              IconComponent={StyledDropdownIcon}
              value={values.post_concern_id}
              renderValue={
                values.post_concern_id
                  ? undefined
                  : () => (
                    <div style={webStyle.selectPlaceholder as React.CSSProperties}>
                      {i18n.t('Concern is Related to')}
                    </div>
                  )
              }
              onChange={handleChange}
              onBlur={handleBlur}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
              displayEmpty
              disableUnderline
            >
              {this.props.postSettings.concerns?.map((concerns) => (
                <MenuItem
                  key={concerns.attributes?.id}
                  value={concerns.attributes?.id}
                >
                  {concerns.attributes?.name}
                </MenuItem>
              ))}
            </Select>
          </FormGroup>
          <FormGroup
            className="input-block"
            style={
              {
                ...webStyle.wideInputBlock,
                ...webStyle.customTopIndent,
              } as React.CSSProperties
            }
          >
            <FormHelperText style={webStyle.label}>
              {i18n.t('Proposed Action')}
            </FormHelperText>
            <img
              height="21px"
              width="21px"
              src={this.renderMicStep2()}
              onClick={async () => {
                await this.toggleListeningStep2();
                this.setActionData(setFieldValue, values)
              }}
              data-test-id="proposed_description"
              alt="mic icon"
              style={webStyle.mic as React.CSSProperties}
            />
            <textarea
              style={{ ...webStyle.textareaStyle, ...{ width: "100%" } }}
              name="proposed_description"
              data-id="proposedActId"
              placeholder="Proposed Action"
              value={values.proposed_description}
              onChange={handleChange}
              onBlur={handleBlur}
              className="textarea"
            />
          </FormGroup>
          <div className="footer" style={webStyle.footerWrap}>
            <FormGroup style={webStyle.footerWarning as React.CSSProperties}>
              <FormHelperText style={webStyle.RadioLabel}>
                  {i18n.t('Would you like to fill')}
                <span style={webStyle.bold as React.CSSProperties}>
                  {" "}
                  {i18n.t('Hazard Category & Other Details?')}{" "}
                </span>
                {i18n.t('This will help you gain more points')}.
              </FormHelperText>
              <RadioGroup
                aria-labelledby="hazard-radio-buttons-group-label"
                defaultValue="no"
                value={values.hazard}
                name="radio-buttons-group"
                style={webStyle.footerRadioG as React.CSSProperties}
              >
                <FormControlLabel
                  style={webStyle.footerRadioLabel}
                  name="hazard"
                  data-id="hazardFirstId"
                  value="yes"
                  control={<Radio />}
                  label={
                    <Typography
                      style={webStyle.formControlLabel as React.CSSProperties}
                    >
                      {i18n.t('Yes')}
                    </Typography>
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <FormControlLabel
                  style={webStyle.footerRadioLabel}
                  name="hazard"
                  data-id="hazardSecondId"
                  value="no"
                  control={<Radio />}
                  label={
                    <Typography
                      style={webStyle.formControlLabel as React.CSSProperties}
                    >
                      {i18n.t('No')}
                    </Typography>
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </RadioGroup>
            </FormGroup>
            <div
              className="buttons"
              style={
                {
                  ...webStyle.buttonsGrp2,
                  ...(isPhone && webStyle.mobileButtons),
                } as React.CSSProperties
              }
            >
              <Button
                style={
                  {
                    ...webStyle.button,
                    ...webStyle.transButton,
                    ...(isPhone && webStyle.mobileButton),
                  } as React.CSSProperties
                }
                data-id="backToPrevStep"
                onClick={() => {
                  this.setStep(PostSteps.Step1);
                  this.recognition.stop();
                  this.setState({ liststep2: false });
                }}
              >
                {i18n.t('Previous')}
              </Button>
              <Button
                style={
                  {
                    ...webStyle.button,
                    ...webStyle.fullButton,
                    ...(isPhone && webStyle.mobileButton),
                  } as React.CSSProperties
                }
                data-id="nextButtonStep2"
                disabled={values.hazard === "no" && this.props.loading}
                type="submit"
                onClick={submitForm}
                onMouseDown={() =>
                  values.hazard === "no" && this.setPostSubmitted(true)
                }
              >
                {this.props.loading ? (
                  <CircularProgress
                    data-test-id="spinner"
                    size="2rem"
                    color="inherit"
                  />
                ) : (
                  `${i18n.t(buttonText)}`
                )}
              </Button>
            </div>
          </div>
        </div>
      </>
    );
  };

  thirdStep = (
    values: any,
    handleBlur: any,
    handleChange: any,
    submitForm: any
  ) => {
    return (
      <div data-id="step3" style={webStyle.form as React.CSSProperties}>
        <FormGroup className="input-block" style={webStyle.inputBlock}>
          <FormHelperText style={webStyle.label}>{i18n.t('Hazard')}</FormHelperText>
          <Select
            name="hazard_id"
            data-id="catId"
            label="Select Category"
            style={
              { ...webStyle.input, ...webStyle.inputStd } as React.CSSProperties
            }
            IconComponent={StyledDropdownIcon}
            value={values.hazard_id}
            displayEmpty
            renderValue={
              values.hazard_id
                ? undefined
                : () => (
                  <div style={webStyle.selectPlaceholder as React.CSSProperties}>
                    {i18n.t('Select Hazard')}
                  </div>
                )
            }
            disableUnderline
            onChange={handleChange}
            onBlur={handleBlur}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              getContentAnchorEl: null,
            }}
          >
            {this.props.postSettings?.hazardSelects?.map((menuItem) => (
              <MenuItem
                key={menuItem.attributes?.id}
                value={menuItem.attributes?.id}
              >
                {menuItem.attributes?.name}
              </MenuItem>
            ))}
          </Select>
        </FormGroup>
        <FormGroup className="input-block" style={webStyle.inputBlock}>
          <FormHelperText style={webStyle.label}>{i18n.t('Frequency')}</FormHelperText>
          <Select
            name="frequency_id"
            data-id="freqId"
            label="Select Frequency"
            style={
              { ...webStyle.input, ...webStyle.inputStd } as React.CSSProperties
            }
            IconComponent={StyledDropdownIcon}
            value={values.frequency_id}
            displayEmpty
            renderValue={
              values.frequency_id
                ? undefined
                : () => (
                  <div style={webStyle.selectPlaceholder as React.CSSProperties}>
                    {i18n.t('Select Frequency')}
                  </div>
                )
            }
            disableUnderline
            onChange={handleChange}
            onBlur={handleBlur}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              getContentAnchorEl: null,
            }}
          >
            {this.props.postSettings.frequencies?.map((frequencie) => (
              <MenuItem
                key={frequencie.attributes.id}
                value={frequencie.attributes.id}
              >
                {frequencie.attributes.name}
              </MenuItem>
            ))}
          </Select>
        </FormGroup>
        <FormGroup className="input-block" style={webStyle.inputBlock}>
          <FormHelperText style={webStyle.label}>{i18n.t('Severity')}</FormHelperText>
          <Select
            name="severity_id"
            data-id="sevId"
            label="Select Severity"
            style={
              { ...webStyle.input, ...webStyle.inputStd } as React.CSSProperties
            }
            IconComponent={StyledDropdownIcon}
            value={values.severity_id}
            displayEmpty
            renderValue={
              values.severity_id
                ? undefined
                : () => (
                  <div style={webStyle.selectPlaceholder as React.CSSProperties}>
                    {i18n.t('Select Severity')}
                  </div>
                )
            }
            disableUnderline
            onChange={handleChange}
            onBlur={handleBlur}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              getContentAnchorEl: null,
            }}
          >
            {this.props.postSettings.severities?.map((severitie) => (
              <MenuItem
                key={severitie.attributes.id}
                value={severitie.attributes.id}
              >
                {severitie.attributes.name}
              </MenuItem>
            ))}
          </Select>
        </FormGroup>
        <FormGroup className="input-block" style={webStyle.inputBlock}>
          <FormHelperText style={webStyle.label}>
            {i18n.t('Consequence(HSE) - Potential Impact')}
          </FormHelperText>
          <Select
            name="consequence_id"
            data-id="consId"
            label="Select Consequences"
            style={
              { ...webStyle.input, ...webStyle.inputStd } as React.CSSProperties
            }
            IconComponent={StyledDropdownIcon}
            value={values.consequence_id}
            displayEmpty
            renderValue={
              values.consequence_id
                ? undefined
                : () => (
                  <div style={webStyle.selectPlaceholder as React.CSSProperties}>
                    {i18n.t('Select Consequences')}
                  </div>
                )
            }
            disableUnderline
            onChange={handleChange}
            onBlur={handleBlur}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              getContentAnchorEl: null,
            }}
          >
            {this.props.postSettings.consequences?.map((consequences) => (
              <MenuItem
                key={consequences.attributes?.id}
                value={consequences.attributes?.id}
              >
                {consequences.attributes?.name}
              </MenuItem>
            ))}
          </Select>
        </FormGroup>
        <div className="footer" style={webStyle.footerContainer}>
          <div
            className="buttons"
            style={webStyle.buttonsGroup}
          >
            <Button
              style={
                {
                  ...webStyle.button,
                  ...webStyle.transButton,
                  ...(isPhone && webStyle.mobileButton),
                } as React.CSSProperties
              }
              data-id="backToPrevStep"
              onClick={() => this.setStep(PostSteps.Step2)}
            >
              {i18n.t('Previous')}
            </Button>
            <Button
              style={
                {
                  ...webStyle.button,
                  ...webStyle.fullButton,
                  ...(isPhone && webStyle.mobileButton),
                } as React.CSSProperties
              }
              data-id="nextButtonStep3"
              type="submit"
              disabled={this.props.loading}
              onClick={submitForm}
              onMouseDown={() => this.setPostSubmitted(true)}
            >
              {this.props.loading ? (
                <CircularProgress size="2rem" color="inherit" />
              ) : (
                `${i18n.t("Report")}`
              )}
            </Button>
          </div>
        </div>
      </div>
    );
  };
  onModalClick = () => {
    this.setState({
      isIncident: !this.state.isIncident,
    });
  };

  onGoodPracticeModalClick = () => {
    this.setState({
      isGoodPractice: !this.state.isGoodPractice
    })
  }

  handleSubmitDoneGoodPractice = () => {
    this.setState({
      isGoodPractice: false
    })
    this.closeModal()
  }

  renderFunction = () => {
    const postSettings = this.props.postSettings;
    const { postStep } = this.state;

    const isStep1 = postStep === PostSteps.Step1;
    const isStep2 = postStep === PostSteps.Step2;
    const isStep3 = postStep === PostSteps.Step3;

    if (this.props.postCreationFinished) {
      return (
        <CongratsModal
          onClose={this.closeModal}
          userName={this.props.userName}
        />
      );
    } else {
      return (
        <Dialog
          style={webStyle.modal}
          maxWidth={"md"}
          open={true}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Wrapper
            className="post-popup"
            style={
              {
                ...webStyle.modalMaindivStyle,
                ...(isPhone && webStyle.mobileModal),
              } as React.CSSProperties
            }
          >
            <h2 style={webStyle.header}>{i18n.t('Create New Post')}</h2>
            <IconButton
              style={webStyle.closeBtn as React.CSSProperties}
              onClick={this.closeModal}
            >
              <Close />
            </IconButton>
            {this.props.loaderData ? (
              <div
                style={webStyle.loader}
              >
                <CircularProgress />
              </div>
            ) : (
              postSettings?.hashtags && (
                <Formik
                data-test-id="formik"
                  innerRef={this.textFieldRef}
                  initialValues={
                    {
                      employee_id: postSettings.employeeIds[0] || "AB143",
                      sub_category_id: 0,
                      autoLocation: false,
                      work_location_id: 0,
                      description: this.state.postDescription,
                      image: "",
                      shift: null,
                      proposed_description: null,
                      hazard: "no",
                      operation_identification_id: 0,
                      post_concern_id: null,
                      hazard_id: null,
                      frequency_id: null,
                      severity_id: null,
                      consequence_id: null,
                      youWantReport:"",
                      hashtags: postSettings.hashtags?.map((hashtag: any) => {
                        return "#" + hashtag.name;
                      }),
                    } as PostFormData
                  }
                  validationSchema={this.state.inputSchema}
                  onSubmit={(values, actions) => {
                    this.onsubmitFunction(values, actions, isStep1, isStep2);
                  }}
                >
                  {({
                    handleChange,
                    submitForm,
                    errors,
                    setFieldTouched,
                    touched,
                    values,
                    setFieldValue,
                    handleBlur,
                  }) => {
                    return (
                      <ThemeProvider theme={theme}>
                        {isStep1 &&
                          this.firstStep(
                            setFieldValue,
                            values,
                            handleBlur,
                            handleChange,
                            submitForm,
                            touched,
                            errors
                          )}
                        {isStep2 &&
                          this.secondStep(
                            values,
                            handleBlur,
                            handleChange,
                            submitForm,
                            setFieldValue
                          )}
                        {isStep3 &&
                          this.thirdStep(
                            values,
                            handleBlur,
                            handleChange,
                            submitForm
                          )}
                      </ThemeProvider>
                    );
                  }}
                </Formik>
              )
            )}
            {this.props.aiImageprocessing &&
              <>
                {this.displayLoadingText()}
                <Box style={webStyle.aiLoaderWrapper}>
                  <Box width={"150px"} marginBottom={"20px"}>
                    <img width={"100%"} height={"100%"} src={aiLoader} alt="loader" />
                  </Box>
                  <Typography style={webStyle.aiLoaderText}>{this.state.aiLoaderText[this.state.aiLoaderTextIndex]}</Typography>
                  <Typography style={webStyle.aiLoaderSubText}>{configJSON.aiLoaderSubtext}</Typography>
                </Box>
              </>
            }
            {this.props.aiProcessingError && !this.props.aiImageprocessing &&
              <Box style={webStyle.aiErrorWrapper}>
                <img style={{ marginBottom: "20px" }} src={errorIcon} />
                <Typography style={{ ...webStyle.aiLoaderText, ...{ marginBottom: "20px" } }}>{this.props.aiProcessingError || configJSON.aiProcessErrorMessage}</Typography>
              </Box>
            }
          </Wrapper>
        </Dialog >
      );
    }
  };
  render() {
    return (
      <>
        {this.renderFunction()}
        
        {this.state.isGoodPractice && (
          <GoodPractice
            isGoodPractice={this.state.isGoodPractice}
            afterImageFile={this.props.uploadedImageUrl}
            afterImageDescription={this.state.postDescription}
            onGoodPracticeModalClick={this.onGoodPracticeModalClick}
            subFunctionId={this.state.subFunctionId}
            subFunctionName={this.state.sybFunctionName}
            emptyPostId={this.props.emptyPostId}
            onSubmitDone={this.handleSubmitDoneGoodPractice}
          />
        )}
      </>
    );
  }
}

const ComboBox = styled(Autocomplete)({
  "& input::placeholder": {
    color: "#DCDCDC"
  },
  "& [class*=MuiOutlinedInput-root]": {
    border: "none",
  },
  "& [class*=MuiInputBase-input]": {
    fontFamily: "ClotherRegular",
    fontSize: "16px",
    fontWeight: 700,
    color: "#2B2A29",
    "&::placeholder": {
      fontFamily: "ClotherRegular",
      fontSize: "16px",
      fontWeight: 400,
      color: "#DCDCDC !important",
    }
  },
});

export const StyledDropdownIcon = styled(KeyboardArrowDown)({
  color: "#DCDCDC",
  cursor: "pointer",
})


const StyledPaper = withStyles({
  root: {
    backgroundColor: "#f0f0f0"
  },
})(Paper);

const InputFieldDropDown = styled(Select)({

    width: "100%",
    border: "1px solid #CBD5E1",
    borderRadius: "8px",
    height: "50px",
     display: "flex",
    justifyContent: "center",
     boxSizing: "border-box",
    "& .MuiSelect-icon": {
      color: "#0E172A",
      right: "5px",
    },
    "&[class*='MuiInput-root']": {
      padding: "20px 15px",
      paddingRight: "4px",
      "&:focus": {
        outline: "none",
      },
      "&::before": {
        content: "none",
      },
      "&::after": {
        content: "none",
      },
     },
    "&[class*='MuiInput-underline']": {
      "&::before": {
        borderBottom: "none",
      },
      "&:hover::before": {
        borderBottom: "none !important",
      },
      "&::after": {
        borderBottom: "none !important",
      }
    },
    "& [class*=MuiSelect-select]": {
      fontFamily: "ClotherRegular",
      fontSize: "16px",
      fontWeight: 700,
      color: "#2B2A29",
    }, 
    "& .MuiSelect-select:focus": {
      backgroundColor: "transparent",
  },
})