import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import * as Yup from "yup";
import { EditSteps } from "./myProfile/types";
import { getStorageData } from "framework/src/Utilities";
import { setStorageData } from "../../../framework/src/Utilities";
import moment from "moment";
export const configJSON = require("./config.js");
import i18n from "i18next"
export interface PostData {
  type: string;
  quantity: string;
  attributes: {
    description: string,
    account_id: number,
    profile_pic: string,
    user_name: string,
    id: number,
    image_url: string,
    output_image_url?: string,
    work_location: string,
    location: string,
    created_at: string,
    did_you_like: boolean,
    likes_count: number,
    comments_count: number,
    comments: any[],
    openCommentsList: boolean,
    commentLikeLoader: boolean,
    employee_id: string
    image_url_when_published:string;
    good_practice_after_published: GoodPracticeAfterPublished | null
  }
}

interface GoodPracticeAfterPublished {
  id: number;
  good_practice_no: string;
  location_id: number | null;
  category_id: number;
  before_image_description: string;
  initiative: string;
  benefit: string;
  description: string;
  tag_people_ids: number[];
  tag_people_name: string[];
  before_image: string;
  additional_images: string[];
  videos: string[];
}

export interface CommentData {
  id: number,
  attributes: {
    comment: string,
    created_at: string,
    account: {
      profile_image: string,
      full_name: string
    }
  }
}

export interface CountryData {
  attributes: {
    id: number,
    name: string,
  }
}

export interface CountryCodeData {
  attributes: {
    emoji_flag: string,
    country_code: number,
    name: string,
  }
}

export interface RoleData {
  attributes: {
    id: number, name: string
  }
  id: number,
  type: string,
}

export interface EmpTypesData {
  attributes: {
    id: number,
    name: string
  }
  id: number,
  type: string,
}

export interface BloodGrpData {
  attributes: {
    id: number,
    label: string
  }
  id: number,
  type: string,
}

export interface LocationData {
  attributes: {
    id: number,
    name: string
  }
  id: number,
  type: string,
}

export interface FunCtionData {
  attributes: {
    id: number,
    name: string
  }
  id: number,
  type: string,
}

export interface WorkLocationData {
  attributes: {
    name: string,
    profile_location: {
      country_id: number,
      created_at: string,
      id: number,
      name: string
    }
  }
}

export interface TagsData {
  attributes: {
    id: string,
    name: string
  }
  id: string,
  type: string,
}
// Customizable Area End

export const webConfigJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  closeModel: boolean;
  logoutClear: boolean;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  dashboardData: PostData[];
  type: string;
  token: string;
  step: EditSteps;
  editProfileData?: any;
  anchorEl: any;
  selectRole: any;
  selectCountrie: CountryData[];
  selectEmployeType: any;
  selectLocation: any;
  selectBloodGroupType: any;
  selectFunctionType: any;
  selectSubFunctionType: any;
  selectWorkLocation: any;
  activeStep: number;
  tooltipVisible: boolean;
  goToLast: boolean;
  percentageProfile: any;
  countrieCode: CountryCodeData[];
  isLoading: boolean;
  openPostOption: boolean;
  reportPostModal: boolean;
  anchorEl2: HTMLElement | null;
  postComment: string;
  errorMsg: string,
  errorModal: boolean,
  commentsloading: boolean,
  comments: CommentData[];
  totalComment: number;
  reportCommentOption: boolean;
  anchorElReportComment: null | HTMLElement,
  currentPostId: number;
  currentCommentPage: number;
  currentCommentId: number;
  reportCommentModal: boolean;
  postIsReported: boolean;
  selectedTags: number[];
  reportdescription: string;
  reportTags: TagsData[];
  isDescriptionErr: boolean;
  postLikeLoader: boolean;
  isPopupImgOpen: boolean;
  currentImage: string;
  // Customizable Area End
}
interface SS {}

export default class ProfileController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getAllPostDataAPICallId: string = "";
  profileSchema: object;
  getAccountDetailsAPICallID: string = "";
  getWorkDetailsAPICallID: string = "";
  getfunctionAPICallID: string = "";
  getSubfunctionAPICallID: string = "";
  getWorkLocationCallID: string = "";
  updateProfileDataCallID: string = "";
  getPercentageAPICallID: string = "";
  getLocationAPICallID: string = "";
  getCountryCodeAPICallID: string = "";
  LikePostAPICallID: string = "";
  CommentPostAPICallID: string = "";
  reportCommentsAPICallID: string = "";
  GetCommentsAPICallID: string = "";
  apiGetReportsTags: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      type: "",
      dashboardData: [],
      token: "",
      step: EditSteps.Step1,
      editProfileData: {},
      anchorEl: null,
      selectRole: [],
      selectCountrie: [],
      selectEmployeType: [],
      selectLocation: [],
      selectBloodGroupType: [],
      selectFunctionType: [],
      selectSubFunctionType: [],
      selectWorkLocation: [],
      activeStep: 1,
      tooltipVisible: false,
      goToLast: false,
      percentageProfile: null,
      countrieCode: [],
      isLoading: false,
      openPostOption: false,
      reportPostModal: false,
      anchorEl2: null,
      postComment: "",
      errorMsg: "",
      errorModal: false,
      commentsloading: false,
      comments: [],
      totalComment: 0,
      anchorElReportComment: null,
      reportCommentOption: false,
      currentPostId: 0,
      currentCommentPage: 0,
      currentCommentId: 0,
      reportCommentModal: false,
      postIsReported: false,
      selectedTags: [],
      reportdescription: "",
      reportTags: [],
      isDescriptionErr: false,
      postLikeLoader: false,
      isPopupImgOpen:false,
      currentImage:""
    };
    
    this.profileSchema = Yup.object({
      Official_email_address: Yup.string()
        .email(i18n.t("Please enter valid email"))
        .matches(
          /^[a-zA-Z0-9]+(?:\.[a-zA-Z0-9]+)*@[a-zA-Z0-9]+\.[a-zA-Z0-9]+$/,
          i18n.t("Please enter a valid email address")
        )
        .notOneOf(
          [Yup.ref("email_address")],
          i18n.t("Official email address must be different from the personal email address")
        )
        .required(i18n.t("Please enter valid email")),

      full_name: Yup.string()
        .required(i18n.t("Please enter valid Name"))
        .min(3, i18n.t("Full Name must be at least 3 characters long"))
        .max(50, i18n.t("Full Name max length must be 50 characters")),

      employee_id: Yup.string()
        .required(i18n.t("Please enter valid Employee ID"))
        .min(3, i18n.t("Employee ID must be at least 3 characters long")),

      mobile_number: Yup.string()
        .matches(/^\d+$/, i18n.t("Please enter a valid numeric mobile number"))
        .length(10, i18n.t("Please enter a 10-digit mobile number"))
        .typeError(i18n.t("Please enter valid Mobile Number"))
        .required(i18n.t("Please enter valid Mobile Number")),

      gender: Yup.string().required(i18n.t("Please Select Gender")),

      dob: Yup.string()
        .nullable()
        .required(i18n.t("Please Select Date of Birth")),

      email_address: Yup.string()
        .email(i18n.t("Please enter valid email"))
        .matches(
          /^[a-zA-Z0-9]+(?:\.[a-zA-Z0-9]+)*@[a-zA-Z0-9]+\.[a-zA-Z0-9]+$/,
          i18n.t("Please enter a valid email address")
        )
        .required(i18n.t("Please enter valid email")),
    });

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    let token = await getStorageData("authToken", false)
    this.setState({ token: token })
    this.getPostDetialData();
    this.getAccountDetailsAPI();
    this.getWorkDetailsAPI();
    this.getPercentageAPI();
    this.getCountryCode();
    this.getReportsTags();
    // Customizable Area End
  }

  // Customizable Area Start

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (webResponseJson && !webResponseJson.errors) {
        if (this.getAllPostDataAPICallId === webApiRequestCallId) {
          this.setState({ dashboardData: webResponseJson?.posts?.data || [], commentsloading: false, postLikeLoader: false });
        } else if (webApiRequestCallId === this.getAccountDetailsAPICallID) {
          this.setState({ editProfileData: webResponseJson?.data || {}, isLoading: false });
          this.getLocationAPI(webResponseJson?.data.attributes.country_id);
          this.getFunctionAPI(
            webResponseJson?.data.attributes.profile_location_id
          );
          this.getSubFunctionAPI(webResponseJson?.data.attributes.category_id);
          this.getWorkLocationAPI(
            webResponseJson?.data.attributes.sub_category_id
          );
        }
        this.editStepselectRecieveFunc(webApiRequestCallId, webResponseJson);
        this.percentageGetReciveFunc(webApiRequestCallId, webResponseJson);
        this.viewCommentsRes(webApiRequestCallId, webResponseJson)
        this.CommentReportAPIRes(webApiRequestCallId, webResponseJson)
        this.getReportTagsData(webApiRequestCallId, webResponseJson);
      } else {
        this.setState({
          isLoading: false,
          errorMsg: webResponseJson.errors[0].message,
          errorModal: true,
          commentsloading: false,
          currentCommentPage: 0,
          anchorElReportComment: null,
          reportCommentOption: false,
          postLikeLoader: false,
          currentPostId: 0,
        });
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  getReportTagsData = (apiRequestCallId: string, responseJson: { data: TagsData[]; }) => {
    if (apiRequestCallId === this.apiGetReportsTags) {
      this.setState({ reportTags: responseJson.data });
    }
  };

  CommentReportAPIRes = (webApiRequestCallId: string, webResponseJson: { data: { id: 4 }; }) => {
    if (webApiRequestCallId === this.reportCommentsAPICallID) {
      this.setState({
        commentsloading: false,
        anchorElReportComment: null,
        reportCommentOption: false,
        isLoading: false,
        postIsReported: true,
        reportPostModal: false,
      });
    }
  }

  viewCommentsRes = (apiRequestCallId: string, responseJson: {
    total_comment: number; data: CommentData[];
  }) => {
    if (apiRequestCallId === this.GetCommentsAPICallID) {
      if (responseJson.data) {
        let commentsData = this.state.comments.concat(responseJson.data);
        this.setState({ comments: commentsData, totalComment: responseJson.total_comment, commentsloading: false });
      }
    }
  }

  setStep(step: EditSteps) {
    this.setState({ step });
  }

  editStepselectRecieveFunc = (
    webApiRequestCallId: string,
    webResponseJson: {
      errors: null,
      roles: { data: RoleData[] }; employee_types: { data: EmpTypesData[] }; blood_groups: { data: BloodGrpData[] }; countries: { data: CountryData[] }; location: { data: LocationData[] };
      functions: { data: FunCtionData[] }; sub_functions: { data: FunCtionData[] }; work_locations: { data: WorkLocationData[] };
    }
  ) => {
    if (webApiRequestCallId === this.getWorkDetailsAPICallID) {
      this.setState({
        selectRole: webResponseJson?.roles?.data,
        selectEmployeType: webResponseJson?.employee_types?.data,
        selectBloodGroupType: webResponseJson?.blood_groups?.data,
        selectCountrie: webResponseJson?.countries?.data,
      });
    } else if (webApiRequestCallId === this.getLocationAPICallID) {
      this.setState({ selectLocation: webResponseJson.location.data });
    } else if (webApiRequestCallId === this.getfunctionAPICallID) {
      this.setState({ selectFunctionType: webResponseJson.functions.data });
    } else if (webApiRequestCallId === this.getSubfunctionAPICallID) {
      this.setState({
        selectSubFunctionType: webResponseJson.sub_functions.data,
      });
    } else if (webApiRequestCallId === this.getWorkLocationCallID) {
      this.setState({
        selectWorkLocation: webResponseJson.work_locations.data,
      });
    } else if (webApiRequestCallId === this.updateProfileDataCallID) {
      this.setState({ isLoading: false });
      this.getAccountDetailsAPI();
    } else if (webApiRequestCallId === this.LikePostAPICallID) {
      this.setLikeCommentRes(webResponseJson)
    } else if (webApiRequestCallId === this.CommentPostAPICallID) {
      this.setLikeCommentRes(webResponseJson)
    }
  };

  setLikeCommentRes = (webResponseJson: { errors: null, roles: { data: RoleData[]; }; employee_types?: { data: EmpTypesData[]; }; blood_groups?: { data: BloodGrpData[]; }; countries?: { data: CountryData[]; }; location?: { data: LocationData[]; }; functions?: { data: FunCtionData[]; }; sub_functions?: { data: FunCtionData[]; }; work_locations?: { data: WorkLocationData[]; }; data?: any; message?: any; }) => {
    if (webResponseJson.data || webResponseJson.message || webResponseJson.errors === null) {
      this.setState({ isLoading: false, postComment: "" });
      this.getPostDetialData();
    }
  }

  percentageGetReciveFunc = (
    webApiRequestCallId: string,
    webResponseJson: { percentage: number; country_code_and_flags: { data: CountryCodeData[]; }; }
  ) => {
    if (webApiRequestCallId === this.getPercentageAPICallID) {
      this.setState({ percentageProfile: webResponseJson.percentage });
    } else if (webApiRequestCallId === this.getCountryCodeAPICallID) {
      this.setState({
        countrieCode: webResponseJson.country_code_and_flags?.data,
      });
    }
  };

  changleScemaBack = (toStep: number) => {
    if (toStep == 1) {
      this.profileSchema = Yup.object({
        full_name: Yup.string()
          .required(configJSON.fullNameErrMsg)
          .min(3, "Full Name must be at least 3 characters long")
          .max(50, "Full Name max length must be 50 characters"),
        Official_email_address: Yup.string().email(configJSON.emailErrMsg)
          .matches(/^[a-zA-Z0-9]+(?:\.[a-zA-Z0-9]+)*@[a-zA-Z0-9]+\.[a-zA-Z0-9]+$/,
            "Please enter a valid official email address")
          .required(configJSON.emailErrMsg),
        gender: Yup.string().required(configJSON.genderErrMsg),
        employee_id: Yup.string()
          .required(configJSON.empIDErrMsg)
          .min(3, "Employee ID must be at least 3 characters long"),
        email_address: Yup.string()
          .email(configJSON.emailErrMsg)
          .matches(/^[a-zA-Z0-9]+(?:\.[a-zA-Z0-9]+)*@[a-zA-Z0-9]+\.[a-zA-Z0-9]+$/,
            "Please enter a valid personal email address")
          .required(configJSON.emailErrMsg),

        mobile_number: Yup.string()
          .matches(/^\d+$/, "Please enter a valid numeric mobile number")
          .length(10, "Please enter a 10-digit mobile number")
          .typeError(configJSON.MobileErrMsg)
          .required(configJSON.MobileErrMsg),
        dob: Yup.string()
          .nullable()
          .required(configJSON.dobErrMsg),
      });
    }
    if (toStep == 2) {
      this.profileSchema = Yup.object({
        Countrie: Yup.string().required("Please select Country"),
        work_location_desc: Yup.string()
          .when("work_location", { is: "0", then: Yup.string().required("Please enter work location description"), }),
      })
    }
  };

  getPostDetialData = () => {
    let token = localStorage.getItem("authToken");
    const headers = {
      "Content-Type": "application/json",
      token: token,
    };
    const getRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    getRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.myProfilePostsEnpoint + '?sort_by=newest'
    );
    getRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    this.getAllPostDataAPICallId = getRequestMsg.messageId;
    runEngine.sendMessage(getRequestMsg.id, getRequestMsg);
  };

  handleClick = (event: { currentTarget: EventTarget | null }) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  skipStepFunction = () => {
    if (this.state.activeStep !== 4) {
      this.activeStepFun();
    } else {
      const msg = new Message(getName(MessageEnum.NavigationMessage));
      msg.addData(getName(MessageEnum.NavigationTargetMessage), "MyProfile");
      msg.addData(
        getName(MessageEnum.NavigationPropsMessage),
        this.props
      );
      this.send(msg);
    }
  };

  copyToClipboard = async () => {
    await navigator.clipboard.writeText(
      this.state.editProfileData.attributes?.unique_auth_id
    );
    this.setState({ tooltipVisible: true });
    setTimeout(() => {
      this.setState({ tooltipVisible: false });
    }, 3000);
  };

  setEditProfileData(editProfileData: { photo: string; employee_id: number; full_name: string; country_code: number; mobile_number: string; email_address: string; Official_email_address: string; dob: Date | null; gender: string; employee_type: string; contractor_name: string; role: string; Countrie: string; location: string; function: string; subFunction: string; work_location: string; work_location_desc: string; ice_name: string; icf_Country_code: string; ice_number: number; blood_group: string; fileUpload: string | null; }) {
    this.setState({ editProfileData });
  }

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleMenuItemClick = () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "EditProfile");
    msg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(msg);
    this.handleClose();
  };

  getAccountDetailsAPI = async () => {
    let tokenValue = localStorage.getItem("authToken");
    let SignUpID = JSON.parse(await getStorageData("SignUpID"));
    const headers = {
      token: tokenValue,
    };
    const getAllRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/${SignUpID}`
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    this.setState({ isLoading: true })
    this.getAccountDetailsAPICallID = getAllRequestMsg.messageId;
    runEngine.sendMessage(getAllRequestMsg.id, getAllRequestMsg);
  };

  getWorkDetailsAPI = async () => {
    let tokenValue = localStorage.getItem("authToken");
    const headers = {
      token: tokenValue,
    };
    const getAllRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_profile/profiles/get_user_content_data`
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    this.getWorkDetailsAPICallID = getAllRequestMsg.messageId;
    runEngine.sendMessage(getAllRequestMsg.id, getAllRequestMsg);
  };

  getLocationAPI = async (index: number) => {
    let tokenValue = localStorage.getItem("authToken");
    const headers = {
      token: tokenValue,
    };
    const getAllRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_profile/profiles/get_location?country_id=${index}`
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    this.getLocationAPICallID = getAllRequestMsg.messageId;
    runEngine.sendMessage(getAllRequestMsg.id, getAllRequestMsg);
  };
  getFunctionAPI = async (index: number) => {
    let tokenValue = localStorage.getItem("authToken");
    const headers = {
      token: tokenValue,
    };
    const getAllRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_profile/profiles/get_functions?location_id=${index}`
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    this.getfunctionAPICallID = getAllRequestMsg.messageId;
    runEngine.sendMessage(getAllRequestMsg.id, getAllRequestMsg);
  };

  getSubFunctionAPI = async (index: number) => {
    let tokenValue = localStorage.getItem("authToken");
    const headers = {
      token: tokenValue,
    };
    const getAllRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_profile/profiles/get_sub_functions?function_id=${index}`
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    this.getSubfunctionAPICallID = getAllRequestMsg.messageId;
    runEngine.sendMessage(getAllRequestMsg.id, getAllRequestMsg);
  };

  getWorkLocationAPI = async (index: number) => {
    let tokenValue = localStorage.getItem("authToken");
    const headers = {
      token: tokenValue,
    };
    const getAllRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_profile/profiles/get_work_locations?sub_function_id=${index}`
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    this.getWorkLocationCallID = getAllRequestMsg.messageId;
    runEngine.sendMessage(getAllRequestMsg.id, getAllRequestMsg);
  };

  activeStepFun = () => {
    this.setState((prevState) => ({ activeStep: prevState.activeStep + 1 }));
  };

  prviousStepFun = () => {
    this.setState((prevState) => ({ activeStep: prevState.activeStep - 1 }));
    this.setState({ step: 2 });
  };
  prviousStepFunPersonalGoal = () => {
    this.setState((prevState) => ({
      activeStep: prevState.activeStep - 1,
      goToLast: true,
    }));
  };

  EditProfileDataAPI = async () => {
    let tokenValue = localStorage.getItem("authToken");
    let SignUpID = JSON.parse(await getStorageData("SignUpID"));
    const headers = {
      token: tokenValue,
    };
    let dateObject = new Date(this.state.editProfileData.dob);

    let year = dateObject.getFullYear();
    let month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
    let dayDate = dateObject
      .getDate()
      .toString()
      .padStart(2, "0");

    let formattedDate = `${year}-${month}-${dayDate}`;
    let formdata = new FormData();
    formdata.append("employee_id", this.state.editProfileData.employee_id);
    formdata.append("full_name", this.state.editProfileData.full_name);
    formdata.append("country_code", this.state.editProfileData.country_code);
    formdata.append(
      "full_phone_number",
      this.state.editProfileData.mobile_number
    );
    formdata.append("email", this.state.editProfileData.email_address);
    formdata.append(
      "official_email",
      this.state.editProfileData.Official_email_address
    );
    formdata.append("gender", this.state.editProfileData.gender);
    formdata.append(
      "contractor_name",
      this.state.editProfileData.contractor_name
    );
    formdata.append("icf_number_code", this.state.editProfileData.icf_Country_code);
    formdata.append("icf_name", this.state.editProfileData.ice_name);
    formdata.append("icf_number", this.state.editProfileData.ice_number);
    formdata.append(
      "employee_type_id",
      this.state.editProfileData.employee_type
    );
    formdata.append(
      "work_location_id",
      this.state.editProfileData.work_location == "Other"
        ? "Other"
        : this.state.editProfileData.work_location
    );

    this.state.editProfileData.work_location == "Other" &&
      formdata.append(
        "other_work_location",
        this.state.editProfileData.work_location_desc
      );
    formdata.append("blood_group_id", this.state.editProfileData.blood_group);
    formdata.append("category_id", this.state.editProfileData.function);
    formdata.append("sub_category_id", this.state.editProfileData.subFunction);
    formdata.append("role_id", this.state.editProfileData.role);
    formdata.append("country_id", this.state.editProfileData.Countrie);
    formdata.append("profile_location_id", this.state.editProfileData.location);
    this.state.editProfileData.fileUpload &&
      formdata.append("profile_image", this.state.editProfileData?.fileUpload);
    formdata.append("date_of_birth", formattedDate);
    const getAllRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/${SignUpID}`
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    this.setState({ isLoading: true })
    this.updateProfileDataCallID = getAllRequestMsg.messageId;
    runEngine.sendMessage(getAllRequestMsg.id, getAllRequestMsg);
  };

  getPercentageAPI = async () => {

    const getAllRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    let tokenValue = localStorage.getItem("authToken");
    const headers = {
      token: tokenValue,
    };
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_profile/profiles/get_profile_percentage`
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    this.getPercentageAPICallID = getAllRequestMsg.messageId;
    runEngine.sendMessage(getAllRequestMsg.id, getAllRequestMsg);
  };

  onSubmitButton = (values: {
    photo: string; employee_id: number; full_name: string; country_code: number; mobile_number: string; email_address: string; Official_email_address: string; dob: Date | null; gender: string; employee_type: string; contractor_name: string; role: string; Countrie: string; location: string; function: string; subFunction: string; work_location: string;
    work_location_desc: string; ice_name: string; icf_Country_code: string; ice_number: number; blood_group: string; fileUpload: string | null;
  }) => {
    const isStep1 = this.state.step === EditSteps.Step1;
    const isStep2 = this.state.step === EditSteps.Step2;
    const isStep3 = this.state.step === EditSteps.Step3;
    const nextStep =
      (isStep1 && EditSteps.Step2) ||
      (isStep2 && EditSteps.Step3) ||
      (isStep3 && EditSteps.StepComp) ||
      EditSteps.StepComp;
    if (isStep1) {
      this.profileSchema = Yup.object({
        work_location_desc: Yup.string().when("work_location", {
          is: "0",
          then: Yup.string().required("Please enter work location description"),
        }),
      });

    }
    if (isStep2) {
      this.profileSchema = Yup.object({
        ice_number: Yup.string()
      });
    } else if (isStep3 === true) {
      this.EditProfileDataAPI();
    }

    this.setStep(nextStep);
    if (nextStep === EditSteps.StepComp) {
      this.setEditProfileData(values);
    }
  };

  getCountryCode = async () => {
    const getAllCountryRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    let tokenValue = localStorage.getItem("authToken");
    const headers = {
      token: tokenValue,
    };
    getAllCountryRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `country_codes`
    );
    getAllCountryRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getAllCountryRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    this.getCountryCodeAPICallID = getAllCountryRequestMsg.messageId;
    runEngine.sendMessage(getAllCountryRequestMsg.id, getAllCountryRequestMsg);
  };

  navigateToFollowers = () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "Followers");
    msg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(msg);
  }

  navigateToHSEpassport = () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "HsePassport");
    msg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(msg);
  }
  navigateToFollowing = () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "Following");
    msg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(msg);
  }

  goToUserProfile = (otherUserId: number | undefined) => {
    setStorageData("otherUserId", otherUserId)
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "Profile");
    msg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(msg);
  }

  navigateToRiskProfileCard = () => {
    const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
    navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "RiskProfile");
    navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigateMsg);
  }

  proceedToLikePost = (data: { did_you_like: boolean; id: number; }) => {
    this.setState({ isLoading: false, postLikeLoader: true, currentPostId: data.id })
    if (data.did_you_like) {
      this.removeLikePost(data.id)
    } else {
      this.addLikePost(data.id)
    }
  }

  openPopupImage = (imageUrl :string) => {
    this.setState({ isPopupImgOpen: true, currentImage: imageUrl  });
  };

  closePopupImage = () => {
    this.setState({ isPopupImgOpen: false, currentImage: "" });
  };

  handleBackdropClickImage = () => {
    this.closePopupImage();
  };

  handleCondition = (condition: any, truePart: any, falsePart: any)=>{
    return condition ? true : false;
  }

  removeLikePost = async (postId: number) => {
    let formdata = {
      data: {
        type: "unlike",
        attributes: {
          post_id: postId
        }
      }
    }
    let token = await getStorageData("authToken", false);
    const headers = {
      "Content-Type": configJSON.dashboarContentType,
      token: token,
    };
    const getPostRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    getPostRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.LikePostApiEndPoint}`
    );
    getPostRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getPostRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(formdata)
    );
    getPostRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postsApiPostMethodType
    );
    this.LikePostAPICallID = getPostRequestMsg.messageId;
    runEngine.sendMessage(getPostRequestMsg.id, getPostRequestMsg);
  }

  addLikePost = async (postId: number) => {
    let authToken = await getStorageData("authToken", false);
    let formdata = {
      data: {
        type: "like",
        attributes: {
          post_id: postId
        }
      }
    }
    const headers = {
      "Content-Type": configJSON.dashboarContentType,
      token: authToken,
    };
    const likePostRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    likePostRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.LikePostApiEndPoint}`
    );
    likePostRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    likePostRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(formdata)
    );
    likePostRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postsApiPostMethodType,
    );
    this.LikePostAPICallID = likePostRequestMsg.messageId;
    runEngine.sendMessage(likePostRequestMsg.id, likePostRequestMsg);
  }

  proceedToAddPostComment = (event: { keyCode: number; target: { value: string; }; }, postId: number) => {
    this.setState({ postComment: event?.target.value })
    if (event.keyCode === 13) {
      this.AddPostComment(postId)
    }
  }

  AddPostComment = (postId: number) => {
    let formdata = {
      comment: {
        comment: this.state.postComment,
        commentable_type: "BxBlockPosts::Post",
        commentable_id: postId
      }
    }
    let tokenValue = localStorage.getItem("authToken");
    const headers = {
      "Content-Type": configJSON.dashboarContentType,
      token: tokenValue,
    };
    const getPostRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    getPostRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.AddCommentPostApiEndPoint}`
    );
    getPostRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getPostRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(formdata)
    );
    getPostRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    this.CommentPostAPICallID = getPostRequestMsg.messageId;
    runEngine.sendMessage(getPostRequestMsg.id, getPostRequestMsg);
  }

  handleReportForPost = (postId: number) => {
    setStorageData("reportPostId", postId)
    this.setState({ reportPostModal: true })
  }

  handlePostOption = (event: { currentTarget: HTMLElement | null }) => {
    this.setState({ openPostOption: !this.state.openPostOption, anchorEl2: event.currentTarget })
  }

  showPostComments = (postData: PostData, postIndex: number) => {
    if (postData?.attributes?.comments_count > 0) {
      const id = postData.attributes.id;
      this.state.dashboardData.forEach((data, index) => {
        if (index !== postIndex) {
          data.attributes.openCommentsList = false
          this.setState({
            dashboardData: this.state.dashboardData
          })
        } else {
          data.attributes.openCommentsList = !data.attributes.openCommentsList
          this.setState({
            dashboardData: this.state.dashboardData
          })
        }
      })
      this.setState({ comments: [], currentCommentPage: 0 }, () => {
        this.getPostComment(id)
      })
    }
  }

  getPostComment = (id: number | undefined) => {
    this.setState({ commentsloading: true, totalComment: 0, currentCommentPage: this.state.currentCommentPage + 1, })
    let tokenValue = localStorage.getItem("authToken");
    const headers = {
      "Content-Type": configJSON.apiContentType,
      token: tokenValue,
    };
    const getPostCommentRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    getPostCommentRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_comments/comments?commentable_id=${id}&per_page=${5}&page=${this.state.currentCommentPage + 1}`
    );
    getPostCommentRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getPostCommentRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    this.GetCommentsAPICallID = getPostCommentRequestMsg.messageId;
    runEngine.sendMessage(getPostCommentRequestMsg.id, getPostCommentRequestMsg);
  }

  reportPostComment = () => {
    this.setState({ commentsloading: true })
    let reportData = {
      "comment_id": this.state.currentCommentId,
      "description": this.state.reportdescription,
      "report_tag_ids": this.state.selectedTags,
    }
    const headers = {
      token: this.state.token,
      "Content-Type": configJSON.notificationsContentType,
    };
    const getReportRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    getReportRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.reportCommentsAPIEndPoint}`
    );
    getReportRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getReportRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(reportData)
    );
    getReportRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    this.reportCommentsAPICallID = getReportRequestMsg.messageId;
    runEngine.sendMessage(getReportRequestMsg.id, getReportRequestMsg);
  }

  timeFromSince = (date: string) => {
    let createdAtData = date;
    let currentTime = moment();
    let createdDataTime = moment(createdAtData);
    let diff = currentTime.diff(createdDataTime, 'seconds');
    if (diff < 60) {
      return diff + ' seconds ago'
    } else if (diff < 3600) {
      return Math.floor(diff / 60) + ' minutes ago'
    } else if (diff < 86400) {
      return Math.floor(diff / 3600) + ' hours ago'
    } else if (diff < 2592000) {
      return Math.floor(diff / 86400) + ' days ago'
    } else if (diff < 31536000) {
      return Math.floor(diff / 2592000) + ' months ago'
    } else {
      return Math.floor(diff / 31536000) + ' years ago'
    }
  }

  showReportComment = (event: any, id: number) => {
    this.setState({
      reportCommentOption: !this.state.reportCommentOption,
      anchorElReportComment: event.currentTarget,
      currentCommentId: id,
    })
  }

  setCurrentPostId = (id: number) => {
    const { currentPostId } = this.state;
    if (currentPostId != id) {
      this.setState({ currentPostId: id, postComment: '' });
    } else {
      this.setState({ currentPostId: id });
    }
  }

  resetCurrentPostId = () => {
    this.setState({ currentPostId: 0 });
  }

  resetCommentPagination = () => {
    this.setState({ currentCommentPage: 0 });
  }

  getReportsTags = () => {
    const headers = {
      token: this.state.token,
      "Content-Type": "application/json",
    };
    this.setState({ isLoading: true });

    const getAllRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetReportsTags = getAllRequestMsg.messageId;
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.tagsAPIEndPoint
    );

    runEngine.sendMessage(getAllRequestMsg.id, getAllRequestMsg);
  };

  openReportCommnetModal = () => {
    this.setState({ reportCommentModal: true, reportPostModal: true });
  }

  getTagColor = (id: number) => {
    return this.state.selectedTags.includes(id) ? true : false;
  }

  closeReportModal = () => {
    this.setState({
      reportCommentModal: false,
      postIsReported: false,
      reportPostModal: false,
      openPostOption: false,
      reportdescription: '',
      selectedTags: [],
    })
  }

  addSelectedTags = (id: number) => {
    this.setState({ isDescriptionErr: false });
    this.setState((prevState) => {
      const isSelected = [...prevState.selectedTags];
      const selectedTags = isSelected.indexOf(id);
      if (selectedTags === -1) {
        isSelected.push(id);
      } else {
        isSelected.splice(selectedTags, 1);
      }
      return { selectedTags: isSelected };
    });
  }

  handleReportApiCall = () => {
    if (this.state.reportCommentModal) {
      this.reportPostComment();
    }
  }

  handledescriptionChange = (event: { target: { value: string; }; }) => {
    this.setState({
      isDescriptionErr: false,
      reportdescription: event?.target.value,
    })
  }

  breadcrumbRedirect = (url: string) => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), url);
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  // Customizable Area End
}
