import React, { useState, useRef, useEffect } from "react";
import CanvasDraw from "react-canvas-draw";
import Cropper from "react-easy-crop";
import { Edit, Undo, Redo, Crop, Check, RotateLeft, RotateRight } from "@material-ui/icons";

interface MarkupToolProps {
  imageUrl: string | ArrayBuffer | null;
  onImageUpdate: (dataUrl: string) => void;
}

const MarkupTool: React.FC<MarkupToolProps> = ({ imageUrl, onImageUpdate }) => {
  const canvasRef = useRef<CanvasDraw | null>(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<any>(null);
  const [isCropping, setIsCropping] = useState(false);
  const [isDrawing, setIsDrawing] = useState(false);

  const [history, setHistory] = useState<string[]>([]);
  const [redoMarkup, setRedoMarkup] = useState<string[]>([]);


  const onCropComplete = (croppedArea: any, croppedAreaPixels: any) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };
  const handleSave = async () => {
    if (!canvasRef.current || !imageUrl) return;
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    const baseImage = new Image();
    baseImage.src = imageUrl as string;

    baseImage.onload = () => {
      const cropX = croppedAreaPixels ? croppedAreaPixels.x : 0;
      const cropY = croppedAreaPixels ? croppedAreaPixels.y : 0;
      const cropWidth = croppedAreaPixels ? croppedAreaPixels.width : baseImage.width;
      const cropHeight = croppedAreaPixels ? croppedAreaPixels.height : baseImage.height;

      canvas.width = cropWidth;
      canvas.height = cropHeight;

      ctx?.drawImage(
        baseImage,
        cropX, cropY, cropWidth, cropHeight,
        0, 0, canvas.width, canvas.height
      );

      const drawingCanvas = (canvasRef.current as any).canvas.drawing;
      if (drawingCanvas) {
        ctx?.drawImage(drawingCanvas, 0, 0, cropWidth, cropHeight);
      }

      const finalImage = canvas.toDataURL("image/png");
      onImageUpdate(finalImage);
    };
  };
  
  
  
  const handleUndo = () => {
    if (canvasRef.current) {
      const currentState = canvasRef.current.getSaveData();
      setRedoMarkup((prev) => [currentState, ...prev]);
      canvasRef.current.undo();
    }
  };

  const handleRedo = () => {
    if (redoMarkup.length > 0 && canvasRef.current) {
      const lastRedo = redoMarkup[0];
      setRedoMarkup(redoMarkup.slice(1));
      canvasRef.current.loadSaveData(lastRedo, true);
    }
  };

  const toggleCrop = () => setIsCropping(!isCropping);
  const toggleDrawing = () => setIsDrawing((prev) => !prev);

  const calculateCanvasStyle = (): React.CSSProperties => {
    const cropX = croppedAreaPixels?.x || 0;
    const cropY = croppedAreaPixels?.y || 0;
    return {
      position: "absolute",
      top: `${cropY}px`, 
      left: `${cropX}px`,
      width: `${croppedAreaPixels?.width || 400}px`,
      height: `${croppedAreaPixels?.height || 300}px`,
      pointerEvents: isDrawing ? "auto" : "none",
      zIndex: 3,
      backgroundColor: 'transparent',
    };
  };

  return (
    <div style={{ 
        position: "relative", 
        width: 400, 
        height: 300, 
        border: "1px solid rgb(238, 238, 238)", 
        margin: "auto", 
        display: "flex", 
        justifyContent: "center", 
        alignItems: "center"
      }}>
      <div style={{ position: "relative", width: "100%", height: "100%" }}>
        {isCropping ? (
          <Cropper
            image={typeof imageUrl === "string" ? imageUrl : ""}
            crop={crop}
            zoom={zoom}
            rotation={rotation}
            onCropChange={setCrop}
            onZoomChange={setZoom}
            onRotationChange={setRotation}
            onCropComplete={onCropComplete}
          />
        ) : (
          <img
            src={typeof imageUrl === "string" ? imageUrl : ""}
            alt="markup"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              objectFit: "contain",
              pointerEvents: "none",
              zIndex: 2
            }}
          />
        )}

        <CanvasDraw
          ref={canvasRef}
          brushColor="red"
          brushRadius={2}
          hideGrid
          lazyRadius={0}
          canvasWidth={400}
          canvasHeight={300}
          style={calculateCanvasStyle()}
          onChange={() => {
            if (canvasRef.current) {
              setHistory((prev) => [...prev, canvasRef.current!.getSaveData()]);
            }
          }}
        />
      </div>

      {/* Toolbar */}
      <div style={{ position: "absolute", top: "50%", left: "420px", display: "flex", transform: "translateY(-50%)", flexDirection: "column", gap: "10px" }}>
        {[ 
          { icon: <Edit />, onClick: toggleDrawing, active: isDrawing },
          { icon: <Undo />, onClick: handleUndo, disabled: history.length === 0 },
          { icon: <Redo />, onClick: handleRedo, disabled: redoMarkup.length === 0 },
          { icon: <Crop />, onClick: toggleCrop, active: isCropping },
          { icon: <RotateLeft />, onClick: () => setRotation(rotation - 90) },
          { icon: <RotateRight />, onClick: () => setRotation(rotation + 90) },
          { icon: <Check />, onClick: handleSave },
        ].map(({ icon, onClick, active, disabled }, index) => (
          <button
            key={index}
            onClick={onClick}
            disabled={disabled}
            style={{
              width: "40px",
              height: "40px",
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: active ? "rgb(135, 53, 225)" : "rgb(238, 238, 238)",
              color: active ? "#fff" : "#000",
              border: "none",
              cursor: disabled ? "not-allowed" : "pointer",
              opacity: disabled ? 0.5 : 1,
              transition: "0.2s",
            }}
            onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = "rgb(135, 53, 225)")}
            onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = active ? "rgb(135, 53, 225)" : "rgb(238, 238, 238)")}
          >
            {icon}
          </button>
        ))}
      </div>
    </div>
  );
};

export default MarkupTool;
