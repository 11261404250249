import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
export const configJSON = require("./config");
import { getStorageData } from "framework/src/Utilities";
import moment from "moment";
const host = require('../../../framework/src/config')


  export interface IPost {
    id: number;
    d6_completed: boolean;
    incident_implement_actions: IIncidentImplementAction[];
  }
  
  export interface IIncidentImplementAction {
    id: number | null;
    reason: string;
    action_description: string;
    assignee_id: string | number;
    category_id: string | number | null;
    sub_category_id: string | number | null;
    target_date: Date | null;
    status: string;
    follow_up: string;
  }

  interface Department {
    id: number;
    name: string;
    created_at: string;
    updated_at: string;
  }
  
  interface CategoriesApiResponse {
    data: Department[];
  } 
  
interface LeadershipUserResponse {
  leadership_user: {
    data: LeadershipUserData[];
    meta: MetaData;
  };
}

interface LeadershipUserData {
  id: string;
  type: string;
  attributes: UserAttributes;
}

interface UserAttributes {
  activated: boolean;
  email: string;
  official_email: string;
  user_name: string | null;
  first_name: string | null;
  last_name: string | null;
  full_name: string;
  gender: "Male" | "Female" | null;
  contractor_name: string;
  icf_name: string;
  icf_number: string | null;
  date_of_birth: string;
  country_code: number | null;
  phone_number: string | null;
  full_phone_number: string;
  type: string;
  created_at: string;
  updated_at: string;
  device_id: string | null;
  unique_auth_id: string;
  profile_image: any[];
  sub_category_id: number | null;
  category_id: number | null;
  role_id: number | null;
  employee_id: string | null;
  employee_type_id: number | null;
  profile_location_id: number | null;
  country_id: number | null;
  work_location_id: number | null;
  blood_group_id: number | null;
  is_profile_completed: boolean;
  icf_number_code: string;
  is_safety_incharge: boolean;
  level: string | null;
  deactivated: boolean;
  other_work_location: string;
  supervisor_id: number | null;
  points: number | null;
  platform: string | null;
  role_name: string | null;
  function_name: string | null;
  sub_function_name: string | null;
  employee_type_name: string | null;
  blood_group_name: string | null;
  location_name: string | null;
  country_name: string | null;
  supervisor_name: string | null;
  work_location_name: string | null;
  broad_risk_category: any[];
  my_goals: any[];
  risk_levels: any[];
  followings: number;
  followers: number;
  posts_count: number;
  posts: {
    data: any[];
  };
}

interface MetaData {
  pagination: Pagination;
}

interface Pagination {
  current_page: number;
  next_page: number | null;
  prev_page: number | null;
  total_pages: number;
  total_count: number;
  current_count: number;
  per_page: number;
}

export interface SubCategoryResponse {
  data: SubCategory[];
}

export interface SubCategory {
  id: number;
  name: string;
  audit_category_id: number;
  created_at: string;
  updated_at: string;
}

interface ResponseD6FormUpdate{
  message: string;
  errors?: string[];
}

interface IAssignee {
  id: number | null;
  email: string | null;
  full_name: string | null;
}

interface ICategory {
  id: number | null;
  name: string | null;
}

interface IIncidentD6FormResponse {
  data: Array<{
    id: string;
    type: string;
    attributes: {
      id: number | null;
      reason: string;
      action_description: string;
      target_date: string | null;
      status: string;
      follow_up: string;
      assignee: IAssignee | null;
      category: ICategory | null;
      sub_category: ICategory | null;
    };
  }>;
}

interface IFallbackResponse {
  data: {
    attributes: {
      d6_completed: boolean | null;
    };
  };
}
// Customizable Area End


export interface Props {
  navigation: any;
  // Customizable Area Start
  handlePrev:  () => void;
  handleNext:  () => void;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  incident_implement_actions: IIncidentImplementAction[],
  isSave: boolean;
  categoriesList: Department[];
  leadershipUserDetails: { id: string; full_name: string }[];
  filteredSubCategoryList: SubCategory[][];
  selectedIndex: number;
  responseStatus: "success" | "error" | null;
  errorMsg: string;
  // Customizable Area End
}
interface SS {}

export default class VarifyActionD6Controller extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getCategoryApiCallId: string = ""
  getAssigneeUserApiCallId: string = ""
  patchD6FormApiCallId: string = ""
  getD6SavedFormDataApiCallId: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
    this.state = {
        incident_implement_actions: [
            {
              id: null,
              reason: 'Lorem ipsum',
              action_description: '',
              assignee_id: '',
              category_id: '',
              sub_category_id: '',
              target_date: null,
              status: '',
              follow_up: ''
            }
          ],
          isSave: false,
          categoriesList: [],
          leadershipUserDetails: [],
          filteredSubCategoryList: [],
          selectedIndex: -1,
          responseStatus: null,
          errorMsg: ""
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getCategoryList()
    this.getAssigneeUser()
    this.getD6FormSavedData()
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (this.getCategoryApiCallId === apiRequestCallId) {
        this.handleResponseOfCategories(responseJson);
      }

      if (this.getAssigneeUserApiCallId === apiRequestCallId) {
        this.setLeadershipUserDetails(responseJson?.leadership_user.data);
      }

      if (this.patchD6FormApiCallId === apiRequestCallId) {
        this.handleResponseOfD6FormSubmit(responseJson);
      }

      if (this.getD6SavedFormDataApiCallId === apiRequestCallId) {
        this.handleResponseGetD6FormData(responseJson)
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  getCategoryList = async () => {
    const header = {
      token: await getStorageData("authToken", false),
    };

    const getAllRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCategoryApiCallId = getAllRequestMsg.messageId;

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_audittrail2/audits/get_categories"
    );

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(getAllRequestMsg.id, getAllRequestMsg);
  }

  handleResponseOfCategories = (responseJson: CategoriesApiResponse) => {
    this.setState({ categoriesList: responseJson?.data })
  }

  getAssigneeUser = async () => {
    const header = {
      token: await getStorageData("authToken", false),
    };

    const getAllRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAssigneeUserApiCallId = getAllRequestMsg.messageId;

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_posts/posts/leadership_user"
    );

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(getAllRequestMsg.id, getAllRequestMsg);
  }

  setLeadershipUserDetails = (data: LeadershipUserData[]) => {
    const userDetails = data.map((user) => ({
      id: user.id,
      full_name: user.attributes.full_name,
    }));
    this.setState({ leadershipUserDetails: userDetails });
  };

  getSubCategory = async (id: string, index: number) => {
    try {
      const token = await getStorageData("authToken", false);
      const header = {
        token: token,
        "Content-Type": "application/json",
      };
  
      const response = await fetch(
        `${host.baseURL}/bx_block_audittrail2/audits/get_sub_categories?id=${id}`,
        {
          method: "GET",
          headers: header,
        }
      );
  
      if (!response.ok) {
        return;
      }
  
      const responseJson: SubCategoryResponse = await response.json();
  
      this.handleResponseOfSubCategories(responseJson, String(index));
    } catch (error) {
    }
  };

  handleResponseOfSubCategories = (responseJson: SubCategoryResponse, index: string) => {
    const idx = parseInt(index, 10);
  
    if (!isNaN(idx)) {
      this.setState((prevState) => {
        const updatedFilteredSubCategoryList = [...prevState.filteredSubCategoryList];
        updatedFilteredSubCategoryList[idx] = responseJson?.data || [];
  
        return {
          filteredSubCategoryList: updatedFilteredSubCategoryList,
        };
      });
    }
  };
  

  handleSaveD6Form = async (values: { incident_implement_actions: IIncidentImplementAction[] },d6Completed: boolean) => {
    const formattedActions = values.incident_implement_actions.map((action) => ({
      ...action,
      target_date: action.target_date
        ? moment(action.target_date).format("YYYY/MM/DD")
        : null,
    }));

    const header = {
      token: await getStorageData("authToken", false),
      "Content-Type": "application/json"
    };
    const postId = Number(await getStorageData("createPostId"));

    const body = {
      post: {
        id: postId,
        d6_completed: d6Completed,
        incident_implement_actions: formattedActions
      }
    }

    const getAllRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.patchD6FormApiCallId = getAllRequestMsg.messageId;

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_posts/incident_posts/update_d6_form`
    );

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );
    runEngine.sendMessage(getAllRequestMsg.id, getAllRequestMsg);
  }

  handleResponseOfD6FormSubmit = (responseJson: ResponseD6FormUpdate) => {
    if(responseJson?.errors){
      this.setState({ responseStatus: 'error', errorMsg: responseJson?.errors[0] })
    }else{
      this.setState({ responseStatus: 'success', errorMsg: "Data saved succesfully" })
      if(!this.state.isSave){
        this.props.handleNext()
      }
    }
  }

  handleCloseSnackbar = () => {
    this.setState({ responseStatus: null, errorMsg: ""});
  }

  getD6FormSavedData = async () => {
    const header = {
      token: await getStorageData("authToken", false),
    };

    const getAllRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getD6SavedFormDataApiCallId = getAllRequestMsg.messageId;

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_posts/incident_posts/load_d6_form?id=${await getStorageData("createPostId")}`
    );

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(getAllRequestMsg.id, getAllRequestMsg);
  }

  handleResponseGetD6FormData = (responseJson: IIncidentD6FormResponse | IFallbackResponse) => {
    const updatedActions = this.mapIncidentDataToState(responseJson);
    if (updatedActions !== this.state.incident_implement_actions) {
      this.setState(
        { incident_implement_actions: updatedActions, filteredSubCategoryList: Array(updatedActions.length).fill([]), },
        () => {
          this.state.incident_implement_actions.forEach((action, index) => {
            if (
              action.category_id !== null &&
              action.category_id !== ""
            ) {
              this.getSubCategory(String(action.category_id), index);
            }
          });
        }
      );
    }
  }

  mapIncidentDataToState = (
    apiResponse: IIncidentD6FormResponse | IFallbackResponse
  ): IIncidentImplementAction[] => {
    if (!Array.isArray(apiResponse.data)) {
      const { d6_completed } = apiResponse.data.attributes;
      if (d6_completed === null) {
        return this.state.incident_implement_actions;
      }
    }
  
    return (apiResponse as IIncidentD6FormResponse).data.map((item) => ({
      id: item.attributes.id || null,
      reason: "Lorem ipsum",
      action_description: item.attributes.action_description || "",
      assignee_id: item.attributes.assignee?.id || "",
      category_id: item.attributes.category?.id || null,
      sub_category_id: item.attributes.sub_category?.id || null,
      target_date: item.attributes.target_date
        ? new Date(item.attributes.target_date)
        : null,
      status: item.attributes.status || "",
      follow_up: item.attributes.follow_up || "",
    }));
  };  
  // Customizable Area End
}

