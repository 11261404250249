// Customizable Area Start
//@ts-nocheck
import React from "react";

import {Container,Grid,Typography,Box,Button,Avatar} from "@material-ui/core";

import { locationIcon, clockIcon, profileIcon, defaultPostImage } from "./assets";

import ViewPostController, { Props } from "./ViewPostController";
import { Wrapper } from "./styles";
import { getTimeFromNow } from "../../../components/src/helpers/getTimeFromNow";
import Breadcrumb from "../../../components/src/Breadcrumb.web";
import i18n from "i18next";

export default class ViewPost extends ViewPostController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { post, breadcrumbData, notificationBreadcrumbData, expandedPostIdNumber } = this.state;
    
    const storedSessionData = sessionStorage.getItem("dataObj");
    const currentUserId = storedSessionData ? JSON.parse(storedSessionData) : {};
    const postId = post?.id;
    const currentImageIndex = this.state.currentImageIndexMapView[postId] || 0;
    const images = post?.good_practice
    ? [
        post?.good_practice?.before_image || "",
        post?.output_image_url 
          ? post.output_image_url 
          : post.image_url || "",
      ]
    : [];  

    console.log(post, 'post')
    return (
      <Grid container>
        <Wrapper>
          <Container maxWidth={"xl"}>
            {
              this.handleCondition(
              this.state.From === "notifications" ,
                <Breadcrumb data={notificationBreadcrumbData} navigation={this.props.navigation} />
                ,
                <>
                  <Breadcrumb data={breadcrumbData} navigation={this.props.navigation} />
                  <Box className="myPostsTitle">
                    <Typography className="mainHeading"> {i18n.t('Post1')}</Typography>
                  </Box>
                </>
            )}

            <Box className="card">
              <Box data-test-id="user-profile" className="card_title_wrapper" onClick={() => this.goToUserProfile(post?.account_id)}>
                <Avatar
                  className="Post-profile-img"
                  data-test-id="profile-icon"
                  src={post?.profile_pic || profileIcon}
                  alt="Custom Icon"
                />
                <Typography className="card-post-name">
                  {post?.user_name || `${i18n.t("No Name")}`} {i18n.t('created this post')}
                </Typography>
              </Box>
              <div style={{
                position: 'relative',
                display: 'inline-block',
                width: "100%",
              }}>
                {post?.good_practice ? (
                  <div style={{ position: "relative", width: "100%" }}>
                    <img
                      style={webStyle.postImageView}
                      src={images[currentImageIndex]}
                    />
                    <div
                      style={{
                        display: "flex",
                        position: "absolute",
                        justifyContent: "center",
                        bottom: -15,
                        marginTop: "1.5rem",
                        width: "100%",
                      }}
                    >
                      {[0, 1].map((index) => (
                        <div
                          data-test-id="dotIcons"
                          onClick={(event) => {
                            event.stopPropagation();
                            this.handleImageClickFromView(
                              postId.toString(),
                              index
                            );
                          }}
                          key={index}
                          style={{
                            cursor: "pointer",
                            margin: "0 5px",
                            height: 9,
                            width: 9,
                            borderRadius: "50%",
                            backgroundColor:
                              currentImageIndex === index ? "#555" : "#ccc",
                          }}
                        />
                      ))}
                    </div>
                  </div>
                ) : (
                  <>
                    <img
                      className="card-image"
                      src={this.handleCondition(
                        post?.output_image_url,
                        post?.output_image_url,
                        post?.image_url
                      )}
                      alt="post"
                    />
                  </>
                )}
                <div
                  style={{
                    position: 'absolute',transform: 'rotate(-45deg)',opacity: 0.3,fontSize: '40px',fontWeight: 'bold',color: '#fff',left: 0,
                    top: 0,alignItems: 'center',justifyContent: 'center', width: '100%', height: '100%',pointerEvents: 'none',display: 'flex',
                  }}
                >
                  {currentUserId.userEmail}
                </div>
              </div>

              {post?.good_practice ? (
                <Box marginTop={"1rem"}>
                  <Typography style={webStyle.goodPracticeTextView}>
                    {i18n.t("Good Practice Number")}
                  </Typography>
                  <Typography style={webStyle.goodPracticeNoView}>
                    {post.good_practice.good_practice_no}
                  </Typography>
                  <Box marginTop={"1rem"}>
                    <Typography style={webStyle.goodPracticeTextView}>
                      {i18n.t("Before Image Description")}
                    </Typography>
                    <Typography style={webStyle.goodPracticeNoView}>
                      {
                        post.good_practice
                          .before_image_description
                      }{" "}
                      {!expandedPostIdNumber.includes(post.id) && (
                        <Typography
                          component="span"
                          data-test-id="seemoreText"
                          style={webStyle.seeMoreTextView}
                          onClick={(event) => {
                            event.stopPropagation();
                            this.handleSeeMoreButton(post.id);
                          }}
                        >
                          See more
                        </Typography>
                      )}
                    </Typography>
                  </Box>
                  {expandedPostIdNumber.includes(post.id) && (
                    <React.Fragment>
                      <Box marginTop={"1rem"}>
                        <Typography style={webStyle.goodPracticeTextView}>
                          {i18n.t("After Image Description")}
                        </Typography>
                        <Typography style={webStyle.goodPracticeNoView}>
                          {post.description}
                        </Typography>
                      </Box>
                      <Box marginTop={"1rem"}>
                        <Typography style={webStyle.goodPracticeTextView}>
                          {i18n.t("Description")}
                        </Typography>
                        <Typography style={webStyle.goodPracticeNoView}>
                          {post.good_practice.description}
                        </Typography>
                      </Box>
                      <Box marginTop={"1rem"}>
                        <Typography style={webStyle.goodPracticeTextView}>
                          {i18n.t("Initiative")}
                        </Typography>
                        <Typography style={webStyle.goodPracticeNoView}>
                          {post.good_practice.initiative}
                        </Typography>
                      </Box>
                      <Box marginTop={"1rem"}>
                        <Typography style={webStyle.goodPracticeTextView}>
                          {i18n.t("Benefit")}
                        </Typography>
                        <Typography style={webStyle.goodPracticeNoView}>
                          {post.good_practice.benefit}{" "}
                          <Typography
                            component="span"
                            data-test-id="seelessText"
                            style={webStyle.seeMoreTextView}
                            onClick={(event) => {
                              event.stopPropagation();
                              this.handleSeeLessButton(post.id);
                            }}
                          >
                            See less
                          </Typography>
                        </Typography>
                      </Box>
                    </React.Fragment>
                  )}
                </Box>
              ) : (
                <Typography className="card-post-detail">
                  {post?.description || i18n.t("No Descrpition")}
                </Typography>
              )}

              <Box className="Post-time-detail">
                <Box className="Post-time-inner">
                  <Button className="Floor_btn" style={{ maxWidth: "50%" }}>
                    <img src={locationIcon} alt="location" />
                    <span className="trimWords">{(post?.work_location) || i18n.t("No Location")}</span>
                  </Button>
                  <Avatar
                    className="Post-inner-profile-img"
                    data-test-id="profile-icon"
                    src={post?.profile_pic || profileIcon}
                    alt="Custom Icon"
                  />
                  <Typography className="Post-time-content">
                    {post?.user_name || `${i18n.t("No Name")}`}
                  </Typography>
                  <img src={clockIcon} alt="time" className="time-icon" />
                  <Typography className="Post-time-content">
                    {getTimeFromNow(post?.created_at)} {i18n.t("ago")}
                  </Typography>
                </Box>
              </Box>
            </Box>
            {!post?.actioned_or_unactioned_value && (
              <Box className="process-wrapper">
                <Button onClick={this.navigateToMyPosts} variant="contained" className="Ignore-btn">
                {i18n.t("Ignore")}
                </Button>
                <Button
                  data-test-id="proceed-btn"
                  variant="contained"
                  className="process-btn"
                  onClick={this.proceedPostHandler}
                >
                  {i18n.t("Proceed")}
                </Button>
              </Box>
            )
            }
          </Container>
        </Wrapper>
      </Grid>
    );
  }
}

const webStyle = {
  goodPracticeTextView:{
    fontFamily: "clotherRegular",
    fontSize: "14px",
    color: "#2B2A29",
    fontWeight: 400,
  },
  goodPracticeNoView:{
    color: "#828282",
    fontWeight: 400,
    fontSize: "16px",
    fontFamily: "clotherRegular",
  },
  seeMoreTextView: {
    marginLeft: "3px",
    fontSize: "12px",
    color: "#8735E1",
    cursor: "pointer",
    fontWeight: 700,
    fontFamily: "clotherRegular",
  },
  postImageView: {
    width: '100%',
    marginTop: 8,
    objectFit: 'cover',
    cursor: 'pointer',
    borderRadius: "8px",
  },
}
// Customizable Area End
