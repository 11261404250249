import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Input,
  Typography,
  InputAdornment,
  IconButton,
  Avatar,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import {
  logo,
  confirmPasswordLogo,
  imgPasswordInVisible,
  imgPasswordVisible,
  rightImg,
  wrongImg,
  completedImg,
  leftLogoForgotPass,
} from "./assets";
// Customizable Area End

import ForgotPasswordController, { Props } from "./ForgotPasswordController";
import ErrorMessage from "./ErrorMessage";
import Loader from "../../../components/src/Loader.web";

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
  typography: {
    fontFamily: "ClotherRegular",
    h2: {
      fontSize: "28px",
      lineHeight: "24px",
      fontFamily: "ClotherBold",
      fontWeight: 700,
      marginBottom: "15px",
      color: "#18181E",
    },
    body2: {
      color: "#A1A1A1",
      fontSize: "18px",
      lineHeight: "18px",
    },
    caption: {
      fontWeight: 400,
      fontSize: "16px",
      "&.error": {
        marginTop: "-6px",
        color: "#F30202",
      },
      "&.success": {
        marginTop: "-6px",
        color: "#21C274",
      },
    },
  },
  overrides: {
    MuiInputBase: {
      root: {
        height: "54px",
        border: "1px solid #DCDCDC",
        padding: "15px 15px",
        fontSize: "16px",
        lineHeight: "18px",
        color: "#2B2A29",
        width: "100%",
        fontWeight: 400,
        letterSpacing: "0.5px",
        textOverflow: "ellipsis",
        justifyContent: "flex-start",
        borderRadius: "8px",
        "&.Mui-disabled": {
          cursor: "no-drop",
        },
        "&:hover, &.Mui-focused": {
          border: `1px solid #8735E1`,
        },
        "&.Mui-error": {
          border: "1px solid #F30202",
        }
      },
    },
    MuiAvatar: {
      root: {
        width: 24,
        height: 24,
        "&.error": {
          backgroundColor: "#F30202",
        },
        "&.success": {
          backgroundColor: "#21C274",
        },
      },
      img: {
        width: "60%",
        height: "60%",
      },
    },
    MuiButton: {
      root: {
        textTransform: "none",
        height: "54px",
        border: "none",
        boxShadow: "none",
        width: "100%",
        lineHeight: "22px",
      },
      contained: {
        boxShadow: "none",
        background: "#8735E1",
        backgroundColor: "#8735E1",
        borderRadius: "8px",
        fontWeight: 700,
        color: "#fff",
        marginBottom: "18px",
        "&:hover, &:focus": {
          boxShadow: "none",
          background: "#8735E1",
        },
        "&.MuiButton-contained:hover": {
          boxShadow: "none",
          background: "#8735E1",
        },
        "&.login-btn": {
          marginTop: "18px",
          fontFamily:"ClotherBold",
          background: "#fff",
          backgroundColor: "#fff",
          color: "#8735E1",
          border: "1px solid #8735E1",
          "&:hover, &:focus": {
            boxShadow: "none",
            background: "#fff",
          },
        },
      },
    },
  },
});
// Customizable Area End

export default class NewPassword extends ForgotPasswordController {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.isChangePassword = true;
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  componentDidUpdate() {
    window.history.pushState(null,  "on browser back click", window.location.pathname);
    window.addEventListener('popstate', this.backBtnClicked);
  }
  
  renderPasswordError = () => {
    return (
      <Box sx={webStyle.textLeft}>
        <Typography style={webStyle.errorTitle}>Password must have</Typography>
        <Box sx={webStyle.errorContainer}>
          <Avatar
            className={this.state.lengthPasswordCheck ? "success" : "error"}
            src={this.state.lengthPasswordCheck ? rightImg : wrongImg}
          ></Avatar>
          <Typography
            className={this.state.lengthPasswordCheck ? "success" : "error"}
            variant="caption"
          >
            At least 8 characters
          </Typography>
        </Box>
        <Box sx={webStyle.errorContainer}>
          <Avatar
            className={this.state.upperPasswordCheck ? "success" : "error"}
            src={this.state.upperPasswordCheck ? rightImg : wrongImg}
          ></Avatar>
          <Typography
            className={this.state.upperPasswordCheck ? "success" : "error"}
            variant="caption"
          >
            At least one uppercase letter
          </Typography>
        </Box>
        <Box sx={webStyle.errorContainer}>
          <Avatar
            className={this.state.lowerPasswordCheck ? "success" : "error"}
            src={this.state.lowerPasswordCheck ? rightImg : wrongImg}
          ></Avatar>
          <Typography
            className={this.state.lowerPasswordCheck ? "success" : "error"}
            variant="caption"
          >
            At least one lowercase letter
          </Typography>
        </Box>
        <Box sx={webStyle.errorContainer}>
          <Avatar
            className={this.state.specialPasswordCheck ? "success" : "error"}
            src={this.state.specialPasswordCheck ? rightImg : wrongImg}
          ></Avatar>
          <Typography
            className={this.state.specialPasswordCheck ? "success" : "error"}
            variant="caption"
          >
            At least one special character
          </Typography>
        </Box>
        <Box sx={webStyle.errorContainer}>
          <Avatar
            data-test-id="number-password-check"
            className={this.state.numberPasswordCheck ? "success" : "error"}
            src={this.state.numberPasswordCheck ? rightImg : wrongImg}
          ></Avatar>
          <Typography
            className={this.state.numberPasswordCheck ? "success" : "error"}
            variant="caption"
          >
            At least one number
          </Typography>
        </Box>
      </Box>
    );
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Box sx={webStyle.confirmPasswordSec}>
          <Box sx={webStyle.confirmPasswordLeft}>
            <figure>
              <img width={500} src={leftLogoForgotPass} alt="" />
            </figure>
          </Box>
          <Box sx={webStyle.confirmPasswordRight}>
            <Box style={webStyle.confirmPasswordRightInner}>
              <Box sx={webStyle.confirmPasswordHeader}>
                <Box sx={webStyle.confirmPasswordLogo}>
                  <figure>
                    <img src={logo} alt="" />
                  </figure>
                </Box>
              </Box>
              {!this.state.isConfirmPassword ? (
                <Box sx={webStyle.textCenter}>
                  <Box
                    sx={webStyle.logoContainer}
                    style={{
                      background: "rgba(135,53,225,0.1)",
                    }}
                  >
                    <figure>
                      <img src={confirmPasswordLogo} alt="" />
                    </figure>
                  </Box>
                  <Typography variant="h2">Create new Password</Typography>
                  <Typography variant="body2">
                    Your new password must be different from previously used
                    passwords.
                  </Typography>
                  <Box sx={webStyle.textField}>
                    <label style={webStyle.textFieldLabel}>New Password</label>
                    <Input
                      data-test-id="txtInputPassword"
                      disableUnderline
                      type={
                        this.state.enablePasswordField ? "password" : "text"
                      }
                      placeholder={"New Password"}
                      fullWidth={true}
                      {...this.newPasswordHandler}
                      error={this.hasNewPasswordHasError()}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            edge="end"
                            onClick={this.confiremPasswordHideAndShow.bind(this)}
                          >
                            {this.state.enablePasswordField ? (
                              <img src={imgPasswordInVisible} alt="" />
                              ) : (
                              <img src={imgPasswordVisible} alt="" />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </Box>

                  {this.hasNewPasswordHasError() && (
                    <React.Fragment>
                      {this.renderPasswordError()}
                    </React.Fragment>
                  )}

                  <Box sx={webStyle.textField}>
                    <label style={webStyle.textFieldLabel}>
                      Confirm Password
                    </label>

                    <Input
                      data-test-id="txtInputConfirmPassword"
                      disableUnderline
                      type={
                        this.state.btnConfirmPasswordShowHide
                          ? "password"
                          : "text"
                      }
                      placeholder={"Confirm New Password"}
                      fullWidth={true}
                      {...this.confirmPasswordHandler}
                      error={this.hasConfirmPasswordError()}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            edge="end"
                            onClick={this.newPasswordHideAndShow.bind(this)}
                          >
                            {this.state.btnConfirmPasswordShowHide ? (
                              <img src={imgPasswordInVisible} alt="" />
                              ) : (
                              <img src={imgPasswordVisible} alt="" />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      onKeyPress={(e) => this.handleConfirmPasswordOnEnterKey(e)}
                    />
                    {this.hasConfirmPasswordError() && (
                      <Typography className={"error"} variant="caption">
                        New Password and Confirm Password should be same.
                      </Typography>
                    )}
                    <ErrorMessage error={this.state.changePasswordError} />
                  </Box>
                  <Button
                    onClick={this.handleConfirmPassword}
                    variant="contained"
                    data-test-id="saveBtn"
                  >
                    Save
                  </Button>
                </Box>
              ) : (
                <Box sx={webStyle.textCenter}>
                  <Box
                    sx={webStyle.logoContainer}
                  >
                    <figure>
                      <img src={completedImg} alt="" />
                    </figure>
                  </Box>
                  <Typography variant="h2">
                    Password Reset Successfully
                  </Typography>
                  <Typography variant="body2">
                    Your password has been changed successfully. Please login
                    with new password to continue!
                  </Typography>

                  <Button
                    className="login-btn"
                    onClick={()=> this.navigationToAnyPage("Home")}
                    variant="contained"
                    data-test-id="loginBtn"
                  >
                    Login
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
        <Loader loading={this.state.loader} />
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  confirmPasswordSec: {
    width: "100%",
    display: "flex",
    height: "100vh",
    overflow: "hidden",
  },
  confirmPasswordLeft: {
    maxwidth: "60%",
    width: "100%",
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: "60%",
  },
  confirmPasswordRight: {
    background: "#fff",
    maxWidth: "40%",
    width: "100%",
    padding: "20px 0",
    display: "flex",
    overflowY: "auto",
  },
  confirmPasswordRightInner: {
    maxWidth: "400px",
    width: "100%",
    margin: "0 auto",
    fontFamily: "ClotherRegular",
  },
  confirmPasswordHeader: { marginBottom: "50px", textAlign: "center" },
  confirmPasswordLogo: {
    display: "inline-block",
    marginBottom: "15px",
    width: "100%",
    textAlign: "center",
  },
  textField: {
    width: "100%",
    marginBottom: "18px",
    marginTop: "18px",
    textAlign: "left",
  },
  textFieldLabel: {
    display: "block",
    marginBottom: "8px",
    color: "#18181E",
    fontSize: "16px",
    lineHeight: "18px",
  },
  forgotPasswordBtn: {
    color: "#8735E1",
    textAlign: "right",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "18px",
    marginBottom: "18px",
    cursor: "pointer",
  },
  textLeft: {
    textAlign: "left",
  },
  errorTitle: {
    fontSize: "16px",
    marginBottom: "12px",
    color: "#A1A1A1",
  },
  errorContainer: {
    display: "flex",
    marginBottom: "4px",
  },
  logoContainer: {
    margin: "0 auto",
    marginBottom: "20px",
    width: "90px",
    height: "90px",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  textCenter: {
    textAlign: "center",
  },
};
// Customizable Area End
