import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { greenPlusIcon, redPlusIcon, yellowPlusIcon } from "./assets";
import { setStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  openFilterModal: boolean;
  openSortModal: boolean;
  sortBy: string;
  activeButton: string;
  publishPostData: any[];
  currentPage: number;
  totalCount: number;
  isLoader: boolean;
  published: boolean;
  hazardData: any[];
  selectedHazards: any[];
  selectedRiskLevel: any[];
  selectedPost: any[];
  RisklevelFilter: any[];
  isSafetyIncharge: boolean;
  openSelectedPostModal: boolean;
  activeFilter: boolean;
  dummyHazards: any[];
  dummydRiskLevel: any[];
  isClearAll: boolean;
  isClearHazards: boolean;
  isClearRiskLevels: boolean;
  expandedPostIdPublishPost: number[];
  currentImageIndexMapPublishPost: {
    [key: string]: number;
  };
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class PublishPostController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getMyPostsApiCallId: string = "";
  getOptionsApiCallId: string = "";
  getPublishAPostApiCallID: string = "";
  getHazardAPICallID: string = "";
  getFilteredDataAPICallID: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.descriptionSlice = this.descriptionSlice.bind(this);
    this.getFilteredPublishApostAPI = this.getFilteredPublishApostAPI.bind(this);
    this.getPublishApostAPI = this.getPublishApostAPI.bind(this);
    this.PostStepPage = this.PostStepPage.bind(this);
    this.openSelectedPostModal = this.openSelectedPostModal.bind(this);
    this.getHazardDataAPI = this.getHazardDataAPI.bind(this);
    this.closeSelectedPostModal = this.closeSelectedPostModal.bind(this);
    this.closeFilterModal = this.closeFilterModal.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      openFilterModal: false,
      openSortModal: false,
      sortBy: "",
      activeButton: "Un Actioned",
      publishPostData: [],
      totalCount: 0,
      currentPage: 1,
      isLoader: false,
      published: false,
      hazardData: [],
      selectedHazards: [],
      selectedRiskLevel: [],
      selectedPost: [],
      activeFilter: false,
      RisklevelFilter: [
        { name: "1A", color: "#21C274", icon: greenPlusIcon },
        { name: "1B", color: "#21C274", icon: greenPlusIcon },
        { name: "1C", color: "#21C274", icon: greenPlusIcon },
        { name: "1D", color: "#ffe52c", icon: yellowPlusIcon },
        { name: "1E", color: "#ffe52c", icon: yellowPlusIcon },
        { name: "2A", color: "#21C274", icon: greenPlusIcon },
        { name: "2B", color: "#21C274", icon: greenPlusIcon },
        { name: "2C", color: "#ffe52c", icon: yellowPlusIcon },
        { name: "2D", color: "#ffe52c", icon: yellowPlusIcon },
        { name: "2E", color: "#ffe52c", icon: yellowPlusIcon },
        { name: "3A", color: "#ffe52c", icon: yellowPlusIcon },
        { name: "3B", color: "#ffe52c", icon: yellowPlusIcon },
        { name: "3C", color: "#ffe52c", icon: yellowPlusIcon },
        { name: "3D", color: "#ffe52c", icon: yellowPlusIcon },
        { name: "3E", color: "#F30202", icon: redPlusIcon },
        { name: "4A", color: "#F30202", icon: redPlusIcon },
        { name: "4B", color: "#F30202", icon: redPlusIcon },
        { name: "4C", color: "#F30202", icon: redPlusIcon },
        { name: "4D", color: "#F30202", icon: redPlusIcon },
        { name: "4E", color: "#F30202", icon: redPlusIcon },
        { name: "5A", color: "#F30202", icon: redPlusIcon },
        { name: "5B", color: "#F30202", icon: redPlusIcon },
        { name: "5C", color: "#F30202", icon: redPlusIcon },
        { name: "5D", color: "#F30202", icon: redPlusIcon },
        { name: "5E", color: "#F30202", icon: redPlusIcon },
      ],
      isSafetyIncharge: false,
      openSelectedPostModal: false,
      dummyHazards: [],
      dummydRiskLevel: [],
      isClearAll: false,
      isClearHazards: false,
      isClearRiskLevels: false,
      expandedPostIdPublishPost: [],
      currentImageIndexMapPublishPost: {}
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    if (
      message.id === getName(MessageEnum.RestAPIResponceMessage) &&
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      responseJson
    ) {
      if (
        apiRequestCallId === this.getPublishAPostApiCallID ||
        apiRequestCallId === this.getFilteredDataAPICallID
      ) {
        if (responseJson.message == "You are not a safety in charge.") {
          this.setState({ isSafetyIncharge: true });
        } else {
          this.setState({
            activeFilter:
              this.state.selectedHazards.length > 0 ||
              this.state.selectedRiskLevel.length > 0,
            publishPostData: responseJson.posts.data,
            totalCount: responseJson.posts.meta.pagination.total_count,
            isLoader: false,
          });
          this.closeFilterModal();
        }
      } else if (apiRequestCallId === this.getHazardAPICallID) {
        this.setState({ hazardData: responseJson.hazards.data });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    this.getPublishApostAPI(1);
  }

  handleFilter = () => {
    this.setState({ openFilterModal: true });
    this.getHazardDataAPI();
  };

  alertBoxOkButton = () => {
    this.props.navigation.goBack();
    this.setState({ isSafetyIncharge: false });
  };

  closeFilterModal = () => {
    if (this.state.isClearAll) {
      this.setState({ selectedHazards: this.state.dummyHazards, selectedRiskLevel: this.state.dummydRiskLevel, isClearAll: false });
    }
    if (this.state.isClearHazards) {
      this.setState({ selectedHazards: this.state.dummyHazards, isClearHazards: false });
    }
    if (this.state.isClearRiskLevels) {
      this.setState({ selectedRiskLevel: this.state.dummydRiskLevel, isClearRiskLevels: false });
    }
    this.setState({ openFilterModal: false });
  };

  handleSorting = () => {
    this.setState({ openSortModal: true });
  };

  closeSortModal = () => {
    this.setState({ openSortModal: false });
  };

  openSelectedPostModal = () => {
    this.setState({ openSelectedPostModal: true });
  };

  closeSelectedPostModal = () => {
    this.setState({ openSelectedPostModal: false });
  };

  handleClear = () => {
    this.setState({ isClearHazards: true, dummyHazards: this.state.selectedHazards }, () => {
      this.setState({ selectedHazards: [] })
    });
  };

  clearRiskLevel = () => {
    this.setState({ isClearRiskLevels: true, dummydRiskLevel: this.state.selectedRiskLevel }, () => {
      this.setState({ selectedRiskLevel: [] })
    });
  };

  handleClearAll = () => {
    this.setState({ dummyHazards: this.state.selectedHazards, dummydRiskLevel: this.state.selectedRiskLevel }, () => {
      this.setState({ selectedHazards: [], selectedRiskLevel: [], isClearAll: true });
    });
  };

  handlePublishButtonClick = (item: any) => {
    if (item === "Published" && !this.state.published) {
      this.setState(
        {
          activeButton: item,
          published: true,
          selectedHazards: [],
          selectedRiskLevel: [],
          selectedPost: [],
          sortBy: ''
        },
        () => {
          this.getPublishApostAPI(1);
        }
      );
    } else if (item !== "Published") {
      this.setState(
        {
          activeButton: item,
          published: false,
          selectedHazards: [],
          selectedRiskLevel: [],
          sortBy: '',
          selectedPost: [],
        },
        () => {
          this.getPublishApostAPI(1);
        }
      );
    }
  };

  getFilteredPublishApostAPI = async (curruntPage: any) => {
    this.setState({ currentPage: curruntPage, openFilterModal: false, selectedPost: [], dummydRiskLevel: [], dummyHazards: [], isClearAll: false, isClearHazards: false, isClearRiskLevels: false })
    const { published, sortBy, selectedHazards, selectedRiskLevel } =
      this.state;
    const Selectedquery: string[] = [];
    selectedHazards.forEach((value: any) => {
      Selectedquery.push(`&hazard_ids[]=${value}`);
    });
    const selectedRisklevels: string[] = [];
    selectedRiskLevel.forEach((value: any) => {
      selectedRisklevels.push(`&risk_level_names[]=${value}`);
    });
    this.setState({ isLoader: true });
    let tokenValue = localStorage.getItem("authToken");
    const header = {
      token: tokenValue,
    };
    const getAllRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_posts/posts/sic_publish_a_posts?page=${curruntPage}&per_page=10&published=${published}&sort_by=${sortBy}${Selectedquery.join(
        ""
      )}${selectedRisklevels.join("")}`
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    this.getFilteredDataAPICallID = getAllRequestMsg.messageId;
    runEngine.sendMessage(getAllRequestMsg.id, getAllRequestMsg);
  };

  getPublishApostAPI = async (curruntPage: any) => {
    const { published, sortBy, selectedHazards, selectedRiskLevel } = this.state;
    const queryParams: string[] = [];
    selectedHazards.forEach((value: any) => {
      queryParams.push(`&hazard_ids[]=${value}`);
    });
    const risklevelParams: string[] = [];
    selectedRiskLevel.forEach((value: any) => {
      risklevelParams.push(`&risk_level_names[]=${value}`);
    });
    let tokenValue = localStorage.getItem("authToken");
    const headers = {
      token: tokenValue,
    };
    const getAllRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_posts/posts/sic_publish_a_posts?page=${curruntPage}&per_page=10&published=${published}&sort_by=${sortBy}${queryParams.join(
        ""
      )}${risklevelParams.join("")}`
    );

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    this.setState({ isLoader: true });
    this.getPublishAPostApiCallID = getAllRequestMsg.messageId;
    runEngine.sendMessage(getAllRequestMsg.id, getAllRequestMsg);
  };

  getHazardDataAPI = async () => {
    let tokenValue = localStorage.getItem("authToken");
    const headers = {
      token: tokenValue,
    };
    const getAllRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_posts/posts/load_hazards`
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    this.getHazardAPICallID = getAllRequestMsg.messageId;
    runEngine.sendMessage(getAllRequestMsg.id, getAllRequestMsg);
  };

  pageChange = (_e: any, newPage: number) => {
    this.setState({ currentPage: newPage });
    this.getPublishApostAPI(newPage);
  };

  handleSortingChange = (event: any) => {
    this.setState({ sortBy: event.target.value, selectedPost: [] }, () => {
      this.getPublishApostAPI(this.state.currentPage);
      this.closeSortModal();
    });
  };

  hazardSelectFun = (id: string) => {
    this.setState((prevState) => {
      const selectedHazards = [...prevState.selectedHazards];

      const hazardSelect = selectedHazards.indexOf(id);

      if (hazardSelect === -1) {
        selectedHazards.push(id);
      } else {
        selectedHazards.splice(hazardSelect, 1);
      }
      return { selectedHazards: selectedHazards };
    });
  };

  handleRiskLevels = (id: string) => {
    this.setState((prevState) => {
      const selectedRiskLevel = [...prevState.selectedRiskLevel];

      const RiskSelect = selectedRiskLevel.indexOf(id);

      if (RiskSelect === -1) {
        selectedRiskLevel.push(id);
      } else {
        selectedRiskLevel.splice(RiskSelect, 1);
      }
      return { selectedRiskLevel: selectedRiskLevel };
    });
  };

  onClickPostSelect = (postdata: any) => {
    this.setState((prevState) => {
      const selectPost = [...this.state.selectedPost];
      const selectedPost = selectPost.indexOf(postdata);
      if (postdata.isSelected) {
        postdata.isSelected = false
        selectPost.splice(selectedPost, 1);
      } else if (selectedPost === -1) {
        selectPost.push(postdata);
      } else {
        selectPost.splice(selectedPost, 1);
      }
      return { selectedPost: selectPost };
    });
  };

  removeQueuePost = (postIdToRemove: any) => {
    this.setState((prevState) => ({
      selectedPost: prevState.selectedPost.filter(
        (id) => id !== postIdToRemove
      ),
    }));
    if (this.state.selectedPost.length === 1) {
      this.closeSelectedPostModal();
    }
  };

  PostStepPage = () => {
    setStorageData("selectedPost", JSON.stringify(this.state.selectedPost));
    this.props.navigation.navigate("SafetyInchargeApprovalForm");
  };

  descriptionSlice = (description: string) => {
    return description?.length < 50 ? "" : "...";
  };

  goToUserProfile = (id: number | undefined) => {
    setStorageData("otherUserId", id)
    this.props.navigation.navigate("Profile")
  }

  setSelectedData = (postData: any) => {
    this.state.selectedPost.forEach((data) => {
      if (data.attributes.id === postData.attributes.id) {
        postData.isSelected = true
      }
    })
    return postData
  }

  handleSeeMorePublishPost = (postId: number) => {
    this.setState((prevState) => ({
      expandedPostIdPublishPost: [...prevState.expandedPostIdPublishPost, postId],
    }));
  };  

  handleSeeLessPublishPost = (postId: number) => {
    this.setState((prevState) => ({
      expandedPostIdPublishPost: prevState.expandedPostIdPublishPost.filter((id) => id !== postId),
    }));
  };

  handleImageClickPublishPost = (postId: string, index: number) => {
    this.setState((prevState) => ({
      currentImageIndexMapPublishPost: {
        ...prevState.currentImageIndexMapPublishPost,
        [postId]: index,
      },
    }));
  };
  // Customizable Area End
}
