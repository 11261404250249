import React from "react";
// Customizable Area Start
import { Box, Button, Grid, MenuItem, Select, Snackbar, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@material-ui/core";
import ObserveAndTimingController, { Props } from "./ObserveAndTimingController.web";
import { ErrorMessage, Form, Formik } from "formik";
import { webStyle } from "../../../dashboard/src/postCreationPopUp/styles";
import { Alert } from "@material-ui/lab";
// Customizable Area End

export default class ObserveAndTiming extends ObserveAndTimingController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderHeadingStepper = () => {
        return (
            <HeadingStepperBox>
                <Typography className="stepperHeading">Safety Interaction</Typography>
                <Typography className="stepperHeading">{">"}</Typography>
                <Typography className="stepperHeading">Safety Interaction Number</Typography>
                <Typography className="stepperHeading">{">"}</Typography>
                <Typography className="stepperHeading activeStepperHeading">Not able to Observe</Typography>
            </HeadingStepperBox>
        )
    };
  
    renderSafetyForm = () => {
        const { isEdit, safetyInteractionData } = this.state;
    
        return (
            <>
                <FormBox>
                    <Typography className="subHeading">Not able to Observe</Typography>
                    <Formik
                        data-test-id="safetyFormTestId"
                        enableReinitialize={true}
                        initialValues={{
                            reactionOfPeople: safetyInteractionData?.reason_not_able_observe?.s1 || "",
                            positionOfPeople: safetyInteractionData?.reason_not_able_observe?.s2 || "",
                            ppe: safetyInteractionData?.reason_not_able_observe?.s3 || "",
                            toolsEquipment: safetyInteractionData?.reason_not_able_observe?.s4 || "",
                            procedure: safetyInteractionData?.reason_not_able_observe?.s5 || "",
                            housekeeping: safetyInteractionData?.reason_not_able_observe?.s6 || "",
                            startTime: "",
                            endTime: "",
                            durations: "",
                            s1_observed: safetyInteractionData?.s1_observed || false,   
                            s2_observed: safetyInteractionData?.s2_observed || false,
                            s3_observed: safetyInteractionData?.s3_observed || false,
                            s4_observed: safetyInteractionData?.s4_observed || false,
                            s5_observed: safetyInteractionData?.s5_observed || false,
                            s6_observed: safetyInteractionData?.s6_observed || false,
                        }}
                        
                        validationSchema={this.safetyFormValidation}
                        onSubmit={(values) => {
                            this.postUpdateTime(values);
                        }}
                    >
                        {({ values, setFieldValue }) => (
                            <Form translate={undefined}>
                                <Box style={{ background: "#FFFFFF" }}>
                                    <Grid container style={{ padding: "20px" }} spacing={2}>
                                        {!safetyInteractionData?.s1_observed && (
                                            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                                <Box>
                                                    <Typography className="fieldText">S1: Reaction of People</Typography>
                                                    <TextField
                                                    value={values.reactionOfPeople}
                                                        data-test-id="reactionOfPeopleTestId"
                                                        disabled={!isEdit}
                                                        name="reactionOfPeople"
                                                        onChange={(event) =>
                                                            setFieldValue("reactionOfPeople", event.target.value)
                                                        }
                                                        className="inputField"
                                                        multiline
                                                        rows={6}
                                                        placeholder="Lorem Ipsum"
                                                        fullWidth
                                                    />
                                                    <ErrorMessage name="reactionOfPeople" component="div" className="errorTxt" />
                                                </Box>
                                            </Grid>
                                        )}
                                        {!safetyInteractionData?.s2_observed && (
                                            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                                <Box>
                                                    <Typography className="fieldText">S2: Position of People</Typography>
                                                    <TextField
                                                    value={values.positionOfPeople}

                                                        data-test-id="positionOfPeopleTestId"
                                                        disabled={!isEdit}
                                                        name="positionOfPeople"
                                                        onChange={(event) =>
                                                            setFieldValue("positionOfPeople", event.target.value)
                                                        }
                                                        className="inputField"
                                                        multiline
                                                        rows={6}
                                                        placeholder="Lorem Ipsum"
                                                        fullWidth
                                                    />
                                                    <ErrorMessage name="positionOfPeople" component="div" className="errorTxt" />
                                                </Box>
                                            </Grid>
                                        )}
                                        {!safetyInteractionData?.s3_observed && (
                                            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                                <Box>
                                                    <Typography className="fieldText">S3: Personal Protective Equipment</Typography>
                                                    <TextField
                                                    value={values.ppe}

                                                        data-test-id="ppeTestId"
                                                        disabled={!isEdit}
                                                        name="ppe"
                                                        onChange={(event) =>
                                                            setFieldValue("ppe", event.target.value)
                                                        }
                                                        className="inputField"
                                                        multiline
                                                        rows={6}
                                                        placeholder="Lorem Ipsum"
                                                        fullWidth
                                                    />
                                                    <ErrorMessage name="ppe" component="div" className="errorTxt" />
                                                </Box>
                                            </Grid>
                                        )}
                                        {!safetyInteractionData?.s4_observed && (
                                            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                                <Box>
                                                    <Typography className="fieldText">S4: Tools & Equipment</Typography>
                                                    <TextField
                                                    value={values.toolsEquipment}

                                                        data-test-id="toolsEquipmentTestId"
                                                        disabled={!isEdit}
                                                        name="toolsEquipment"
                                                        onChange={(event) =>
                                                            setFieldValue("toolsEquipment", event.target.value)
                                                        }
                                                        className="inputField"
                                                        multiline
                                                        rows={6}
                                                        placeholder="Lorem Ipsum"
                                                        fullWidth
                                                    />
                                                    <ErrorMessage name="toolsEquipment" component="div" className="errorTxt" />
                                                </Box>
                                            </Grid>
                                        )}
                                        {!safetyInteractionData?.s5_observed && (
                                            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                                <Box>
                                                    <Typography className="fieldText">S5: Procedure</Typography>
                                                    <TextField
                                                    value={values.procedure}

                                                        data-test-id="procedureTestId"
                                                        disabled={!isEdit}
                                                        name="procedure"
                                                        onChange={(event) =>
                                                            setFieldValue("procedure", event.target.value)
                                                        }
                                                        className="inputField"
                                                        multiline
                                                        rows={6}
                                                        placeholder="Lorem Ipsum"
                                                        fullWidth
                                                    />
                                                    <ErrorMessage name="procedure" component="div" className="errorTxt" />
                                                </Box>
                                            </Grid>
                                        )}
                                        {!safetyInteractionData?.s6_observed && (
                                            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                                <Box>
                                                    <Typography className="fieldText">S6: Housekeeping</Typography>
                                                    <TextField
                                                    value={values.housekeeping}

                                                        data-test-id="housekeepingTestId"
                                                        disabled={!isEdit}
                                                        name="housekeeping"
                                                        onChange={(event) =>
                                                            setFieldValue("housekeeping", event.target.value)
                                                        }
                                                        className="inputField"
                                                        multiline
                                                        rows={6}
                                                        placeholder="Lorem Ipsum"
                                                        fullWidth
                                                    />
                                                    <ErrorMessage name="housekeeping" component="div" className="errorTxt" />
                                                </Box>
                                            </Grid>
                                        )}
                                    <Typography className="subHeading" style={{  width:"100%"}}>Timing</Typography>
                                    <Grid container style={{ padding: "20px" }} spacing={2}>
                                        <Grid item xs={12} md={6}>
                                            <Typography className="fieldText">Start Time</Typography>
                                            <input
                                                data-test-id="startTimeTextId"
                                                type="time"
                                                name="startTime"
                                                value={values.startTime}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                                    setFieldValue("startTime", event.target.value)
                                                }
                                                placeholder="Select Start Time"
                                                className="inputField"
                                                style={{ width: "100%", padding: "10px", fontSize: "14px" }}
                                                disabled={!isEdit}
                                            />
                                            <ErrorMessage name="startTime" component="div" className="errorTxt" />
                                        </Grid>
    
                                        <Grid item xs={12} md={6}>
                                            <Typography className="fieldText">End Time</Typography>
                                            <input
                                                data-test-id="endTimeTestId"
                                                type="time"
                                                name="endTime"
                                                value={values.endTime}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                                    setFieldValue("endTime", event.target.value)
                                                }
                                                placeholder="Select End Time"
                                                className="inputField"
                                                style={{ width: "100%", padding: "10px", fontSize: "14px" }}
                                                disabled={!isEdit}
                                            />
                                            <ErrorMessage name="endTime" component="div" className="errorTxt" />
                                        </Grid>
    
                                        <Grid item xs={12} md={6}>
                                            <Typography className="fieldText">Duration Time</Typography>
                                            <Select
                                                data-test-id="durationTestId"
                                                name="durations"
                                                value={values.durations}
                                                onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
                                                    setFieldValue("durations", event.target.value as string)
                                                }
                                                displayEmpty
                                                renderValue={
                                                    values.durations
                                                        ? undefined
                                                        : () => (
                                                            <div style={webStyle.selectPlaceholder as React.CSSProperties}>
                                                                Select Durations
                                                            </div>
                                                        )
                                                }
                                                fullWidth
                                                className="inputFieldDropDown"
                                                MenuProps={{
                                                    disableScrollLock: true,
                                                    anchorOrigin: { horizontal: "left", vertical: "bottom" },
                                                    transformOrigin: { vertical: "top", horizontal: "left" },
                                                }}
                                                disabled={!isEdit}
                                            >
                                                <CustomMenuItem value="" disabled>
                                                    Select Duration
                                                </CustomMenuItem>
                                                <CustomMenuItem value="1">1 Hour</CustomMenuItem>
                                                <CustomMenuItem value="2">2 Hours</CustomMenuItem>
                                                <CustomMenuItem value="3">3 Hours</CustomMenuItem>
                                            </Select>
                                            <ErrorMessage name="durations" component="div" className="errorTxt" />
                                        </Grid>
                                    </Grid>
                                    </Grid>
                                </Box>
    
                                {this.renderNavigation()}
                            </Form>
                        )}
                    </Formik>
                </FormBox>
            </>
        );
    };
    
    renderNavigation = () => {
        const { isEdit, activeStep } = this.state;

        return (
            <BtnBox>
                <Button className="prevBtn" onClick={this.handlePrevious}>
                    Previous
                </Button>

                <Box style={{ display: "flex", gap: "20px" }}>
                    <Button
                        type={!isEdit ? "submit" : "button"}
                        onClick={() => this.onEdit()}
                        className="prevBtn"
                        data-test-id="onEditBtn"
                    >
                        {isEdit ? "Save" : "Edit"}
                    </Button>

                    <Button className="nextBtn" type="submit"
                        onClick={() => this.setState({ isCheckValue: "submit" })}
                        >
                        {activeStep === 1 ? "Next" : "Mark as Complete"}
                    </Button>
                </Box>
            </BtnBox>
        );
    };

    renderKpi = () => {
        const { isEdit, staticCount } = this.state;
        const initialValues = {
            safe_observation_per_hour: this.state.safetyInteractionData.safe_observation_per_hour || "",
            safe_observation_per_day: this.state.safetyInteractionData.safe_observation_per_day || "",
            safe_observation_per_year: this.state.safetyInteractionData.safe_observation_per_year || "",

            at_risk_observation_per_hour: this.state.safetyInteractionData.at_risk_observation_per_hour || "",
            at_risk_observation_per_day: this.state.safetyInteractionData.at_risk_observation_per_day || "",
            at_risk_observation_per_year: this.state.safetyInteractionData.at_risk_observation_per_year || "",

            not_able_to_observe_per_hour: this.state.safetyInteractionData.at_risk_observation_per_hour || "",
            not_able_to_observe_per_day: this.state.safetyInteractionData.not_able_to_observe_per_day || "",
            not_able_to_observe_per_year: this.state.safetyInteractionData.not_able_to_observe_per_year || "",


            positive_action_per_hour: this.state.safetyInteractionData.positive_action_per_hour || "",
            positive_action_per_day: this.state.safetyInteractionData.positive_action_per_day || "",
            positive_action_per_year: this.state.safetyInteractionData.positive_action_per_year || "",



            system_action_per_hour: this.state.safetyInteractionData.system_action_per_hour || "",
            system_action_per_day: this.state.safetyInteractionData.system_action_per_day || "",
            system_action_per_year: this.state.safetyInteractionData.system_action_per_year || "",
        };
        
    
         const safeObservation = staticCount?.static_infos?.safe_observation
   

        return (
            <FormKpiBox>
                <Typography className="subHeading">KPIs</Typography>
                <Formik
                data-test-id="kpiFormTestId"
                    enableReinitialize
                    key={JSON.stringify(initialValues)}
                    initialValues={initialValues}
                    onSubmit={this.kpiDataUpdate}
                >
                    {({ values, setFieldValue }) => (
                        <Form translate={undefined}>
                            <Box style={{ background: "#FFFFFF" }}>
                                <TableContainer style={{ padding: "20px", boxSizing: "border-box" }}>
                                    <Table style={{ background: "#EEEEEE" }}>
                                        <TableHead style={{ background: "#8735E1", borderRadius: "10px" }}>
                                            <TableRow>
                                                {["KPIs", "Count", "Observation / hr", "Observation / Day", "Observation / Year"].map((header) => (
                                                    <TableCell
                                                        key={header}
                                                        style={{ color: "#FFFFFF", fontSize: "14px", fontWeight: 600 }}
                                                    >
                                                        {header}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell style={{ color: "#2B2A29", fontSize: "13px", fontWeight: 600 }}>
                                                    Safe Observations
                                                </TableCell>
                                                <TableCell style={{ color: "#2B2A29", fontSize: "13px", fontWeight: 600 }}>
                                                    {safeObservation}
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        data-test-id="safeObservationsPerHour"
                                                        className="inputField"
                                                        disabled={!isEdit}
                                                        name="safe_observation_per_hour"  
                                                        value={values["safe_observation_per_hour"] || ""|| this.state.safetyInteractionData.safe_observation_per_hour}
                                                        placeholder="Enter hr"
                                                        fullWidth
                                                        onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
                                                            setFieldValue("safe_observation_per_hour", event.target.value as string)
                                                        }
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        data-test-id="safeObservationsPerDay"
                                                        className="inputField"
                                                        disabled={!isEdit}
                                                        name="safe_observation_per_day"  
                                                        value={values["safe_observation_per_day"] || ""|| this.state.safetyInteractionData.safe_observation_per_day}
                                                        placeholder="Enter hr"
                                                        fullWidth
                                                        onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
                                                            setFieldValue("safe_observation_per_day", event.target.value as string)
                                                        }
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        data-test-id="safeObservationsPerYear"
                                                        className="inputField"
                                                        disabled={!isEdit}
                                                        name="safe_observation_per_year"  
                                                        value={values["safe_observation_per_year"] || this.state.safetyInteractionData.safe_observation_per_year ||"" }
                                                        placeholder="Enter hr"
                                                        fullWidth
                                                        onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
                                                            setFieldValue("safe_observation_per_year", event.target.value as string)
                                                        }
                                                    />
                                                </TableCell>
                                            </TableRow>

                                            <TableRow>
                                                <TableCell style={{ color: "#2B2A29", fontSize: "13px", fontWeight: 600 }}>
                                                At Risk Observations                                                </TableCell>
                                                <TableCell style={{ color: "#2B2A29", fontSize: "13px", fontWeight: 600 }}>
                                                    {safeObservation}
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        data-test-id="atRiskObservationsPerHour"
                                                        className="inputField"
                                                        disabled={!isEdit}
                                                        name="at_risk_observation_per_hour"  
                                                        value={values["at_risk_observation_per_hour"] || ""|| this.state.safetyInteractionData.at_risk_observation_per_hour}
                                                        placeholder="Enter hr"
                                                        fullWidth
                                                        onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
                                                            setFieldValue("at_risk_observation_per_hour", event.target.value as string)
                                                        }
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        data-test-id="atRiskObservationsPerDay"
                                                        className="inputField"
                                                        disabled={!isEdit}
                                                        name="at_risk_observation_per_day"  
                                                        value={values["at_risk_observation_per_day"] || ""|| this.state.safetyInteractionData.at_risk_observation_per_day}
                                                        placeholder="Enter hr"
                                                        fullWidth
                                                        onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
                                                            setFieldValue("at_risk_observation_per_day", event.target.value as string)
                                                        }
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        data-test-id="atRiskObservationsPerYear"
                                                        className="inputField"
                                                        disabled={!isEdit}
                                                        name="at_risk_observation_per_year"  
                                                        value={values["at_risk_observation_per_year"] || this.state.safetyInteractionData.at_risk_observation_per_year ||"" }
                                                        placeholder="Enter hr"
                                                        fullWidth
                                                        onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
                                                            setFieldValue("at_risk_observation_per_year", event.target.value as string)
                                                        }
                                                    />
                                                </TableCell>
                                            </TableRow>

                                            <TableRow>
                                                <TableCell style={{ color: "#2B2A29", fontSize: "13px", fontWeight: 600 }}>
                                                    Not able to Observe                                              </TableCell>
                                                <TableCell style={{ color: "#2B2A29", fontSize: "13px", fontWeight: 600 }}>
                                                    {safeObservation}
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        data-test-id="notAbleToObservePerHour"
                                                        className="inputField"
                                                        disabled={!isEdit}
                                                        name="not_able_to_observe_per_hour"  
                                                        value={values["not_able_to_observe_per_hour"] || ""|| this.state.safetyInteractionData.not_able_to_observe_per_hour}
                                                        placeholder="Enter hr"
                                                        fullWidth
                                                        onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
                                                            setFieldValue("not_able_to_observe_per_hour", event.target.value as string)
                                                        }
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        data-test-id="notAbleToObservePerDay"
                                                        className="inputField"
                                                        disabled={!isEdit}
                                                        name="not_able_to_observe_per_day"  
                                                        value={values["not_able_to_observe_per_day"] || ""|| this.state.safetyInteractionData.not_able_to_observe_per_day}
                                                        placeholder="Enter hr"
                                                        fullWidth
                                                        onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
                                                            setFieldValue("not_able_to_observe_per_day", event.target.value as string)
                                                        }
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        data-test-id="notAbleToObservePerYear"
                                                        className="inputField"
                                                        disabled={!isEdit}
                                                        name="not_able_to_observe_per_year"  
                                                        value={values["not_able_to_observe_per_year"] || this.state.safetyInteractionData.not_able_to_observe_per_year ||"" }
                                                        placeholder="Enter hr"
                                                        fullWidth
                                                        onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
                                                            setFieldValue("not_able_to_observe_per_year", event.target.value as string)
                                                        }
                                                    />
                                                </TableCell>
                                            </TableRow>

                                            <TableRow>
                                                <TableCell style={{ color: "#2B2A29", fontSize: "13px", fontWeight: 600 }}>
                                                Positive Action                                                </TableCell>
                                                <TableCell style={{ color: "#2B2A29", fontSize: "13px", fontWeight: 600 }}>
                                                    {safeObservation}
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        data-test-id="positiveActionPerHour"
                                                        className="inputField"
                                                        disabled={!isEdit}
                                                        name="positive_action_per_hour"  
                                                        value={values["positive_action_per_hour"] || ""|| this.state.safetyInteractionData.positive_action_per_hour}
                                                        placeholder="Enter hr"
                                                        fullWidth
                                                        onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
                                                            setFieldValue("positive_action_per_hour", event.target.value as string)
                                                        }
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        data-test-id="positiveActionPerDay"
                                                        className="inputField"
                                                        disabled={!isEdit}
                                                        name="positive_action_per_day"  
                                                        value={values["positive_action_per_day"] || ""|| this.state.safetyInteractionData.positive_action_per_day}
                                                        placeholder="Enter hr"
                                                        fullWidth
                                                        onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
                                                            setFieldValue("positive_action_per_day", event.target.value as string)
                                                        }
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        data-test-id="positiveActionPerYear"
                                                        className="inputField"
                                                        disabled={!isEdit}
                                                        name="positive_action_per_year"  
                                                        value={values["positive_action_per_year"] || this.state.safetyInteractionData.positive_action_per_year ||"" }
                                                        placeholder="Enter hr"
                                                        fullWidth
                                                        onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
                                                            setFieldValue("positive_action_per_year", event.target.value as string)
                                                        }
                                                    />
                                                </TableCell>
                                            </TableRow>

                                            <TableRow>
                                                <TableCell style={{ color: "#2B2A29", fontSize: "13px", fontWeight: 600 }}>
                                                System Action                                                </TableCell>
                                                <TableCell style={{ color: "#2B2A29", fontSize: "13px", fontWeight: 600 }}>
                                                    {safeObservation}
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        data-test-id="systemActionPerHour"
                                                        className="inputField"
                                                        disabled={!isEdit}
                                                        name="system_action_per_hour"  
                                                        value={values["system_action_per_hour"] || ""|| this.state.safetyInteractionData.system_action_per_hour}
                                                        placeholder="Enter hr"
                                                        fullWidth
                                                        onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
                                                            setFieldValue("system_action_per_hour", event.target.value as string)
                                                        }
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        data-test-id="systemActionPerDay"
                                                        className="inputField"
                                                        disabled={!isEdit}
                                                        name="system_action_per_day"  
                                                        value={values["system_action_per_day"] || ""|| this.state.safetyInteractionData.system_action_per_day}
                                                        placeholder="Enter hr"
                                                        fullWidth
                                                        onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
                                                            setFieldValue("system_action_per_day", event.target.value as string)
                                                        }
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        data-test-id="systemActionPerYear"
                                                        className="inputField"
                                                        disabled={!isEdit}
                                                        name="system_action_per_year"  
                                                        value={values["system_action_per_year"] || this.state.safetyInteractionData.system_action_per_year ||"" }
                                                        placeholder="Enter hr"
                                                        fullWidth
                                                        onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
                                                            setFieldValue("system_action_per_year", event.target.value as string)
                                                        }
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>

                                    </Table>
                                </TableContainer>
                            </Box>
                            {this.renderNavigation()}
                        </Form>
                    )}
                </Formik>
            </FormKpiBox>
        );
    };
    
    renderForm = () => {
        const { activeStep } = this.state;
    
        return (
            <Box  >
                <Snackbar
                    open={this.state.isAlert}
                    autoHideDuration={3000}
                    onClose={this.alertClose}
                    data-test-id="alertTestId"
                >
                    <Alert severity="success">{this.state.alertMsg}</Alert>
                </Snackbar>
                {activeStep === 1 && this.renderSafetyForm()}
                {activeStep === 2 && this.renderKpi()}
            </Box>
        );
    };
    

    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <Box style={{ padding: "20px" }}>
                {this.renderHeadingStepper()}
                <Typography style={{
                    fontFamily: "clotherRegular",
                    color: "#2B2A29",
                    fontSize: "20px",
                    fontWeight: "bold",
                    padding: "20px 0px"
                }}>Safety Interaction</Typography>
                {this.renderForm()}
            </Box>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const BtnBox = styled(Box)({
    padiing: "0px 20px",
    display: "flex", justifyContent: "space-between", marginTop: "40px",
    "& .nextBtn": {
        boxSizing: "border-box",
        textTransform: "capitalize",
        color: "#FFFF",
        backgroundColor: "#8735E1",
        border: "5px",
        width: "150px",
        marginLeft: "auto",
        fontFamily: "clotherRegular",
    },
    "& .prevBtn": {
        boxSizing: "border-box",
        textTransform: "capitalize",
        color: "#8735E1",
        backgroundColor: "#FFF",
        border: "1px solid #8735E1",
        width: "150px",
        fontFamily: "clotherRegular",
    },
});

const HeadingStepperBox = styled(Box)({
    display: "flex",
    alignItems: "center",
    gap: "8px",
    flexWrap: "wrap",
    "& .stepperHeading": {
        color: "#A1A1A1",
        fontSize: "12px",
        fontFamily: "clotherRegular",
        whiteSpace: "nowrap",
        transition: "all 0.3s ease",

        "@media (max-width: 1024px)": {
            fontSize: "20px",
            height: "40px",
        },
        "@media (max-width: 768px)": {
            fontSize: "18px",
            height: "35px",
        },
        "@media (max-width: 480px)": {
            fontSize: "16px",
            height: "30px",
        },
    },
    "& .activeStepperHeading": {
        color: "#8735E1"
    }


})

const FormBox = styled(Box)({
    "& .errorTxt": {
        color: "red",
        fontFamily: "clotherRegular",
      },
    borderRadius: "8px",
    "& .fieldText": {
        fontFamily: "clotherRegular",
        color: "#2B2A29",
        fontSize: "13px",
    },
    "& .inputField": {
        width: "100%",
        border: "1px solid #CBD5E1",
        borderRadius: "8px",
        margin: "5px 0px",
        display: "flex",
        justifyContent: "center",
        padding: "0px 10px",
        boxSizing: "border-box",
        fontSize: "14px",
        fontFamily: "clotherRegular",
        fontWeight: "bold",
        "& .MuiInput-root": {
            "&:focus": {
                outline: "none",
            },
            "&::before": {
                content: "none",
            },
            "&::after": {
                content: "none",
            },
        },
    },
    "& .inputFieldDropDown": {
        cursor: "pointer",
        width: "100%",
        border: "1px solid #CBD5E1",
        borderRadius: "8px",
        height: "50px",
        margin: "5px 0px",
        display: "flex",
        justifyContent: "center",
        padding: "0px 10px",
        boxSizing: "border-box",
        fontFamily: "clotherRegular",
        "& .MuiSelect-icon": {
            color: "#0E172A",
            right: "5px",
        },
        "& .MuiInput-root": {
            "&:focus": {
                outline: "none",
            },
            "&::before": {
                content: "none",
            },
            "&::after": {
                content: "none",
            },
        },
        "&.MuiInput-underline:before": {
            borderBottom: "0px solid red",
        },
        "&.MuiInput-underline:after": {
            borderBottom: "0px solid red",
        },
        "&:hover:not(.Mui-disabled):before": {
            borderBottom: "0px solid red",
        },
        "&:hover:not(.Mui-disabled):after": {
            borderBottom: "0px solid red",
        },
        "& .MuiSelect-select:focus": {
            backgroundColor: "transparent",
        },
    },
    "& .subHeading": {
        background: "#FAF3FD",
        padding: "20px",
        color: "#18181E",
        fontSize: "15px",
        fontFamily: "clotherRegular",
        whiteSpace: "nowrap",
        transition: "all 0.3s ease",
        fontWeight: "bold"
    }
})

const CustomMenuItem = styled(MenuItem)({
    "&:hover": {
        backgroundColor: "#F9F3FF",
        color: "#8735E1",
    },
});

const FormKpiBox = styled(Box)({
    borderRadius: "8px",

    "& .inputField": {
        width: "100%",
        border: "1px solid #CBD5E1",
        borderRadius: "8px",
        height: "50px",
        justifyContent: "center",
        margin: "5px 0px",
        display: "flex",
        boxSizing: "border-box",
        padding: "0px 10px",
        fontSize: "14px",
        fontFamily: "clotherRegular",
        fontWeight: "bold",
        "& .MuiInput-root": {
            "&:focus": {
                outline: "none",
            },
            "&::before": {
                content: "none",
            },
            "&::after": {
                content: "none",
            },
        },
    },

    "& .subHeading": {
        background: "#FAF3FD",
        padding: "20px",
        whiteSpace: "nowrap",
        color: "#18181E",
        fontSize: "15px",
        fontFamily: "clotherRegular",
        transition: "all 0.3s ease",
        fontWeight: "bold"
    }
})
// Customizable Area End
