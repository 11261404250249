// Customizable Area Start
//@ts-nocheck
import React from "react";

import {
  Avatar,
  Container,
  Grid,
  Typography,
  Box,
  Button,
  Modal,
  Radio,
  RadioGroup,
  FormControlLabel,
  CircularProgress,
} from "@material-ui/core";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { NavigateNext, Done, Add } from "@material-ui/icons";
import { Pagination } from "@material-ui/lab";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

import {
  buttonFilter,
  buttonFilterActive,
  buttonSort,
  buttonSortActive,
  locationIcon,
  clockIcon,
  optionsIcon,
  closeIcon,
  profileIcon,
  defaultPostImage
} from "./assets";

import MyPostsController, { Props } from "./MyPostsController";
import { Wrapper, Filters, Sorting } from "./styles";
import { getTimeFromNow } from "../../../components/src/helpers/getTimeFromNow";
import { webStyles } from "../../../components/src/styles";
import i18n from "i18next"

export default class MyPosts extends MyPostsController {
  constructor(props: Props) {
    super(props);
  }

  renderFilterModal = () => {
    const {
      openFilterModal,
      hazardOptions,
    } = this.state;

    return (<Modal open={openFilterModal} onClose={this.closeFilterModal}>
      <Filters>
        <Box className="Filters-modal-header">
          <Typography>{i18n.t('Filters')}</Typography>
          <img
            style={{ cursor: "pointer" }}
            data-test-id="close-filter"
            src={closeIcon}
            alt="close"
            onClick={this.closeFilterModal}
          />
        </Box>

        <Box className="Filters-modal-body">
          <Box className="risk-card">
            <Typography className="risk-card-title">
              {i18n.t('Hazard Risk')}
            </Typography>
            <Button
              data-test-id="clear-hazard"
              className="clear-btn"
              onClick={this.handleClear}
            >
              {i18n.t('clear')}
            </Button>
          </Box>
          <span>
            {hazardOptions?.data?.map((hazard) => (
              <label key={hazard.id} className="Fillters-label">
                <input
                  data-test-id="hazard-options"
                  id={`checkbox_${hazard.id}`}
                  onChange={(e) =>
                    this.handleFilterChange(e, hazard.id)
                  }
                  type="checkbox"
                  name={hazard.attributes.name}
                  checked={
                    this.state.selectedHazards[
                    hazard.attributes.name
                    ] || false
                  }
                />
                <span>
                  {this.state.selectedHazards[
                    hazard.attributes.name
                  ] ? (
                    <Done className="selectedIcon" />
                  ) : (
                    <Add className="selectedIcon" />
                  )}{" "}
                  {hazard.attributes.name}{" "}
                </span>
              </label>
            ))}
          </span>
        </Box>
        <Box className="Filters-modal-body risk-level-body">
          <Box className="risk-card">
            <Typography className="risk-card-title">
              {i18n.t('Risk Levels')}
            </Typography>
            <Button
              data-test-id="clear-risk"
              className="clear-btn"
              onClick={this.clearRiskLevel}
            >
              clear
            </Button>
          </Box>
          {this.generateValues().map((value) => {
            let className = "borderGreen";

            Object.entries(this.conditions).forEach(
              ([classNameCondition, condition]) => {
                Object.entries(condition).forEach(
                  ([key, conditionValues]) => {
                    if (
                      conditionValues.includes(value.charAt(1)) &&
                      key === value.charAt(0)
                    ) {
                      className = classNameCondition;
                    }
                  }
                );
              }
            );
            return (
              <label key={value} className="Fillters-label">
                <input
                  data-test-id="filter-risk-level"
                  type="checkbox"
                  onChange={(e) => this.handleRiskLevels(e, value)}
                  name={value}
                  checked={
                    this.state.selectedRiskLevel[value] || false
                  }
                />
                <span key={value} className={className}>
                  {this.state.selectedRiskLevel[value] ? (
                    <Done className="selectedIcon" />
                  ) : (
                    <Add className="selectedIcon" />
                  )}{" "}
                  {value}
                </span>
              </label>
            );
          })}
        </Box>

        <Box className="bottom-wrapper">
          <Button
            data-test-id="clear-all"
            variant="contained"
            className="clear-all-btn"
            onClick={this.handleClearAll}
          >
            {i18n.t('Clear All')}
          </Button>
          <Button
            data-test-id="apply-filter"
            variant="contained"
            className="apply-btn"
            onClick={this.applyFiters}
          >
            {i18n.t('Apply')}
          </Button>
        </Box>
      </Filters>
    </Modal>)
  }

  renderSortModal = () => {
    const {
      openSortModal,
      sortBy,
    } = this.state;

    return (<Modal open={openSortModal} onClose={this.closeSortModal}>
      <Sorting>
        <Box className="sorting-modal-header">
          <Typography>Sort By</Typography>
          <img
            style={{ cursor: "pointer" }}
            data-test-id="close-sort"
            src={closeIcon}
            alt="close"
            onClick={this.closeSortModal}
          />
        </Box>
        <Box className="sorting-modal-body">
          <RadioGroup
            data-test-id="sort-options"
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue={sortBy}
            name="radio-buttons-group"
            onChange={this.handleChange}
          >
            <FormControlLabel
              className={`radio-label ${sortBy === "default" ? "selected-label" : ""
                }`}
              value="default"
              control={<Radio />}
              label="Default"
            />
            <FormControlLabel
              className={`radio-label ${sortBy === "newest" ? "selected-label" : ""
                }`}
              value="newest"
              control={<Radio />}
              label="Newest First"
            />
            <FormControlLabel
              className={`radio-label ${sortBy === "oldest" ? "selected-label" : ""
                }`}
              value="oldest"
              control={<Radio />}
              label="Oldest First"
            />
          </RadioGroup>
        </Box>
      </Sorting>
    </Modal>)
  }

  renderPostCards = (post) => {
    const storedDataPost = sessionStorage.getItem("dataObj");
    const currentLoggedinUserPost = storedDataPost ? JSON.parse(storedDataPost) : {};
    const {
      frequencyOptions,
      severitiyOptions,
    } = this.state;

    //Frequency
    const frequencyId = post.attributes.frequency_id;
    const frequency = frequencyOptions?.data?.find(item => item.id == frequencyId);
    const frequencyName = frequency ? frequency.attributes.name : '';

    //Severity 
    const severityId = post.attributes.severity_id;
    const severity = severitiyOptions?.data?.find(item => item.id == severityId);
    const severityName = severity ? severity.attributes.name : '';

    const riskLevelClass = this.updateBorderColor(severityName, frequencyName);

    const { expandedPostId } = this.state;
    const postId = post?.attributes?.id;
    const currentImageIndex = this.state.currentImageIndexMap[postId] || 0;
    const images = post?.attributes?.good_practice
    ? [
        post?.attributes?.good_practice?.before_image || "",
        post?.attributes?.output_image_url 
          ? post.attributes.output_image_url 
          : post.attributes.image_url || "",
      ]
    : [];  

    return (
      <Box
        data-test-id="post-card"
        key={post.id}
        className={`card ${riskLevelClass}`}
        onClick={() => this.onClickPost(post)}
      >
        <Box className="card_title_wrapper inner-options">
          <Box className="post-profile-left" data-test-id="user-name" onClick={() => this.goToUserProfile(post.attributes?.account_id)}>
            <img
              className="Post-profile-img"
              data-test-id="profile-icon"
              src={post.attributes.profile_pic || profileIcon}
              alt="Custom Icon"
            />
            <Typography className="card-post-name">
              {post.attributes.user_name || "No Name"} {i18n.t('created this post')}
            </Typography>
          </Box>
          <img src={optionsIcon} />
        </Box>

        <div
          style={{
            position: "relative",
            display: "inline-block",
            width: "100%",
          }}
        >
          {post?.attributes?.good_practice ? (
            <div style={{ position: "relative", width: "100%" }}>
              <img
                src={images[currentImageIndex]}
                style={webStyle.postImage}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  position: "absolute",
                  marginTop: "1.5rem",
                  bottom: -15,
                  width: "100%",
                }}
              >
                {[0, 1].map((index) => (
                  <div
                    key={index}
                    data-test-id="dotIcons"
                    style={{
                      height: 9,
                      width: 9,
                      borderRadius: "50%",
                      backgroundColor:
                        currentImageIndex === index ? "#555" : "#ccc",
                      cursor: "pointer",
                      margin: "0 5px",
                    }}
                    onClick={(event) => {
                      event.stopPropagation();
                      this.handleImageClick(postId.toString(), index);
                    }}                    
                  />
                ))}
              </div>
            </div>
          ) : (
            <>
              <img
                className="card-image"
                src={
                  post.attributes.output_image_url
                    ? post.attributes.output_image_url
                    : post.attributes.image_url
                }
              />
            </>
          )}
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              pointerEvents: "none",
              display: "flex",
              opacity: 0.3,
              alignItems: "center",
              fontSize: "40px",
              fontWeight: "bold",
              justifyContent: "center",
              color: "#fff",
              transform: "rotate(-45deg)",
            }}
          >
            {currentLoggedinUserPost.userEmail}
          </div>
        </div>

        {post?.attributes?.good_practice ? (
          <Box marginTop={"1rem"}>
            <Typography style={webStyle.goodPracticeText}>
              {i18n.t("Good Practice Number")}
            </Typography>
            <Typography style={webStyle.goodPracticeNo}>
              {
                post?.attributes?.good_practice
                  ?.good_practice_no
              }
            </Typography>
            <Box marginTop={"1rem"}>
              <Typography style={webStyle.goodPracticeText}>
                {i18n.t("Before Image Description")}
              </Typography>
              <Typography style={webStyle.goodPracticeNo}>
                {
                  post?.attributes?.good_practice
                    ?.before_image_description
                }{" "}
                {!expandedPostId.includes(post?.attributes?.id) && (
                  <Typography
                    component="span"
                    data-test-id="seemoreText"
                    style={webStyle.seeMoreText}
                    onClick={(event) => {
                      event.stopPropagation();
                      this.handleSeeMore(post?.attributes?.id);
                    }}
                  >
                    See more
                  </Typography>
                )}
              </Typography>
            </Box>
            {expandedPostId.includes(post?.attributes?.id) && (
              <React.Fragment>
                <Box marginTop={"1rem"}>
                  <Typography style={webStyle.goodPracticeText}>
                    {i18n.t("After Image Description")}
                  </Typography>
                  <Typography style={webStyle.goodPracticeNo}>
                    { post?.attributes?.description || configJSON.NoDescrpitionText }
                  </Typography>
                </Box>
                <Box marginTop={"1rem"}>
                  <Typography style={webStyle.goodPracticeText}>
                    {i18n.t("Description")}
                  </Typography>
                  <Typography style={webStyle.goodPracticeNo}>
                    { post?.attributes?.good_practice
                    ?.description }
                  </Typography>
                </Box>
                <Box marginTop={"1rem"}>
                  <Typography style={webStyle.goodPracticeText}>
                    {i18n.t("Initiative")}
                  </Typography>
                  <Typography style={webStyle.goodPracticeNo}>
                    { post?.attributes?.good_practice
                    ?.initiative }
                  </Typography>
                </Box>
                <Box marginTop={"1rem"}>
                  <Typography style={webStyle.goodPracticeText}>
                    {i18n.t("Benefit")}
                  </Typography>
                  <Typography style={webStyle.goodPracticeNo}>
                    {
                      post?.attributes?.good_practice
                        ?.benefit
                    }{" "}
                    <Typography
                      component="span"
                      data-test-id="seelessText"
                      style={webStyle.seeMoreText}
                      onClick={(event) => {
                        event.stopPropagation();
                        this.handleSeeLess(post?.attributes?.id);
                      }}
                    >
                      See less
                    </Typography>
                  </Typography>
                </Box>
              </React.Fragment>
            )}
          </Box>
        ) : (
          <Typography 
            className="card-post-detail">
            {post.attributes.description || "No Descrpition"}
          </Typography>
        )}
        <Box className="Post-time-detail">
          <Box className="Post-time-inner">
            <Button className="Floor_btn">
              <img src={locationIcon} alt="location" />
              <span className="trimWords">{post?.attributes.work_location || "No Location"}</span>
            </Button>
          </Box>
        </Box>
        <Box className="Post-time-inner" data-test-id="user-name2" onClick={() => this.goToUserProfile(post.attributes?.account_id)}>
          <Avatar
            className="Post-inner-profile-img"
            data-test-id="profile-icon"
            src={post.attributes.profile_pic || profileIcon}
            alt="Custom Icon"
          />
          <Typography className="Post-time-content trimWords">
            {post.attributes.user_name || "No Name"}
          </Typography>
          <img src={clockIcon} alt="time" className="time-icon" />
          <Typography className="Post-time-content">
            {getTimeFromNow(post.attributes.created_at)} {i18n.t('ago')}
          </Typography>
        </Box>
      </Box>
    )
  }

  render() {
    const {
      openFilterModal,
      openSortModal,
      activeButton,
      postData,
      loading,
      totalCount,
      currentPage,
      perPage,
    } = this.state;

    return (
      <ThemeProvider theme={theme}>
        <Grid container>
          <Wrapper style={{ padding: "0px", height: "100%" }}>
            <Container maxWidth={"xl"} style={{ position: "relative", minHeight: "100vh" }}>
              <Box style={{ padding: "30px 10px", }}>
                <Box style={webStyles.breadcrumbWrapper}>
                  <Typography style={webStyles.breadcrumbItem}>{i18n.t('My Posts')}</Typography>
                  <NavigateNext style={webStyles.navigateIcon} />
                  <Typography style={webStyles.breadcrumbActiveItem}>{i18n.t(activeButton)}</Typography>
                </Box>
                <Box className="myPostsTitle">
                  <Typography className="mainHeading">{i18n.t('My Posts')}</Typography>
                  <Box className="filter-wrapper">
                    <img
                      height="32px"
                      width="32px"
                      data-test-id="sort-icon"
                      style={{ cursor: 'pointer' }}
                      src={openSortModal ? buttonSortActive : buttonSort}
                      alt="sort"
                      onClick={this.handleSorting}
                    />
                    <img
                      height="32px"
                      width="32px"
                      data-test-id="filter-modal"
                      style={{ cursor: 'pointer' }}
                      src={openFilterModal || this.state.applyFilter ? buttonFilterActive : buttonFilter}
                      alt="filter"
                      onClick={this.handleFilter}
                    />
                  </Box>

                  {this.renderFilterModal()}

                  {this.renderSortModal()}

                </Box>
                <Box>
                  <Button
                    data-test-id="published-post"
                    className={`buttonStyle ${activeButton === "Actioned" ? "buttonStyleActive" : ""
                      }`}
                    onClick={this.handlePublishButtonClick}
                  >{i18n.t('Actioned')}</Button>
                  <Button
                    data-test-id="unactioned-post"
                    className={`buttonStyle ${activeButton === "Un Actioned" ? "buttonStyleActive" : ""
                      }`}
                    onClick={this.handleUnactionedButtonClick}
                  >{i18n.t('Un Actioned')}</Button>
                </Box>
                {postData?.length > 0 && postData?.map((post) => this.renderPostCards(post))}

                {postData?.length === 0 && !loading &&
                  <Box className="noData">
                    <Typography>{i18n.t('No posts found')}.</Typography>
                  </Box>
                }

                {loading && (
                  <Box data-test-id={"activityLoader"} className="loader-outer">
                    <Box className="loader-inner">
                      <CircularProgress />
                    </Box>
                  </Box>
                )}

                {postData?.length > 0 && <Grid style={{ display: "flex", justifyContent: "end", padding: "0px 0px" }}>
                  <Pagination
                    count={Math.ceil(totalCount / perPage)}
                    page={currentPage}
                    variant="outlined"
                    onChange={this.handlePageChange}
                    data-test-id="handlePageChange"
                  />
                </Grid>
                }
              </Box>
            </Container>
          </Wrapper>
        </Grid>
      </ThemeProvider>
    );
  }
}

const webStyle = {
  goodPracticeText:{
    fontSize: "14px",
    fontWeight: 400,
    fontFamily: "clotherRegular",
    color: "#2B2A29",
  },
  goodPracticeNo:{
    fontSize: "16px",
    fontWeight: 400,
    fontFamily: "clotherRegular",
    color: "#828282",
  },
  seeMoreText: {
    fontSize: "12px",
    fontWeight: 700,
    fontFamily: "clotherRegular",
    color: "#8735E1",
    cursor: "pointer",
    marginLeft: "3px"
  },
  postImage: {
    width: '100%',
    objectFit: 'cover',
    marginTop: 8,
    borderRadius: "8px",
    cursor: 'pointer'
  },
}