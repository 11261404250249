// Customizable Area Start
Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.validationApiContentType = "application/json";
exports.investApiContentType="multipart/form-data"
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "DashboardGuests";
exports.labelBodyText = "DashboardGuests Body";
exports.phoneInputRegex=/^\d{10}$/
exports.btnExampleTitle = "CLICK ME";
exports.getVerificationOtp="bx_block_login/logins/send_login_otp";
exports.validateOtpApiMethod="PUT";
exports.validateOtpApiEndpoint="bx_block_login/logins/otp_login";
exports.getCompainesApiEnpoint="bx_block_dashboardguests/companies";
exports.investApiEndpoint="bx_block_dashboardguests/dashboard_guests";
exports.getPortfolioApiEndPoint="bx_block_dashboardguests/portfolio";
exports.assignToAPiEndPoint="/bx_block_dashboardguests/safety_interactions";
exports.startInteractionApiEndPoint="/bx_block_dashboardguests/safety_interactions";
exports.locationEndPoint = "/bx_block_profile/profiles/get_all_sub_functions";
exports.activeUsersEndpoint = "/bx_block_posts/active_accounts";
exports.assignToItemAPiEndPoint="/bx_block_dashboardguests/safety_interactions/";
exports.getProfileEndPoint="account_block/accounts/";
exports.portfolioPublicUrl="anupam-265950-react.b265950.dev.eastus.az.svc.builder."
exports.patchType="PATCH"
exports.patchMethodType="PATCH"
exports.observationCategoriesData = [
  {
    section: "S1: Reaction of People",
    form_name: "reaction_of_people",
    items: [
      { 
        id: null, 
        name: "Adjusting PPE’s", 
        status: "safe", 
        positive_action: false, 
        form_name: "", 
        custom_action: false, 
        in_the_spot_close: false, 
        comment: "", 
        action: "",
        risk_potential: "", 
      },
      { 
        id: null, 
        name: "Leaving the work site as you enter", 
        status: "safe", 
        positive_action: false, 
        form_name: "", 
        in_the_spot_close: false, 
        custom_action: false, 
        comment: "", 
        risk_potential: "", 
        action: "" 
      },
      { 
        id: null, 
        name: "Others", 
        status: "safe", 
        positive_action: false, 
        custom_action: false, 
        form_name: "", 
        in_the_spot_close: false, 
        risk_potential: "", 
        comment: "", 
        action: "" 
      },
      { 
        id: null, 
        name: "Stopping or Changing work", 
        status: "safe", 
        form_name: "", 
        positive_action: false, 
        custom_action: false, 
        in_the_spot_close: false, 
        comment: "", 
        risk_potential: "", 
        action: "" 
      },
      { 
        id: null, 
        name: "Switching to Correct Tool", 
        status: "safe", 
        positive_action: false, 
        form_name: "", 
        custom_action: false, 
        comment: "", 
        in_the_spot_close: false, 
        risk_potential: "", 
        action: "" 
      },
      { id: null, name: "Uneasiness", status: "safe", positive_action: false, form_name: "", custom_action: false, in_the_spot_close: false, comment: "", risk_potential: "", action: "" },
    ],
  },
  {
    section: "S2: Position of People",
    form_name: "position_of_people",
    items: [
      { 
        id: null, 
        name: "Absorbing", 
        status: "safe", 
        positive_action: false, 
        form_name: "", 
        custom_action: false, 
        in_the_spot_close: false, 
        comment: "", 
        risk_potential: "", 
        action: "" 
      },
      { 
        id: null, 
        name: "Caught Between", 
        status: "safe", 
        positive_action: false, 
        form_name: "", 
        in_the_spot_close: false, 
        custom_action: false, 
        comment: "", 
        action: "",
        risk_potential: "", 
      },
      { id: null, name: "Electrical Current", status: "safe", positive_action: false, form_name: "", custom_action: false, in_the_spot_close: false, comment: "", risk_potential: "", action: "" },
      { id: null, name: "Falling", status: "safe", positive_action: false, form_name: "", custom_action: false, in_the_spot_close: false, comment: "", risk_potential: "", action: "" },
      { id: null, name: "Inhaling", status: "safe", positive_action: false, form_name: "", custom_action: false, in_the_spot_close: false, comment: "", risk_potential: "", action: "" },
      { id: null, name: "Lighting", status: "safe", positive_action: false, form_name: "", custom_action: false, in_the_spot_close: false, comment: "", risk_potential: "", action: "" },
      { id: null, name: "Noise", status: "safe", positive_action: false, form_name: "", custom_action: false, in_the_spot_close: false, comment: "", risk_potential: "", action: "" },
      { id: null, name: "Posture", status: "safe", positive_action: false, form_name: "", custom_action: false, in_the_spot_close: false, comment: "", risk_potential: "", action: "" },
      { id: null, name: "Striking Against", status: "safe", positive_action: false, form_name: "", custom_action: false, in_the_spot_close: false, risk_potential: "", comment: "", action: "" },
      { id: null, name: "Struck by", status: "safe", positive_action: false, form_name: "", custom_action: false, in_the_spot_close: false, comment: "", risk_potential: "", action: "" },
      { id: null, name: "Swallowing", status: "safe", positive_action: false, form_name: "", custom_action: false, in_the_spot_close: false, comment: "", risk_potential: "", action: "" },
      { id: null, name: "Temperature Extremes", status: "safe", positive_action: false, form_name: "", custom_action: false, in_the_spot_close: false, comment: "", risk_potential: "", action: "" },
      { id: null, name: "Tools & Grips", status: "safe", positive_action: false, form_name: "", custom_action: false, in_the_spot_close: false, risk_potential: "", comment: "", action: "" },
      { id: null, name: "Type & Number of Motions", status: "safe", positive_action: false, form_name: "", custom_action: false, in_the_spot_close: false, comment: "", risk_potential: "", action: "" },
      { id: null, name: "Vibration", status: "safe", form_name: "", positive_action: false, custom_action: false, in_the_spot_close: false, comment: "", risk_potential: "", action: "" },
      { 
        id: null, 
        status: "safe", 
        name: "Work Area Design", 
        positive_action: false, 
        form_name: "", 
        custom_action: false, 
        in_the_spot_close: false, 
        risk_potential: "", 
        comment: "", 
        action: "" 
      },
      { 
        id: null, 
        name: "Others", 
        positive_action: false, 
        status: "safe", 
        form_name: "", 
        custom_action: false, 
        in_the_spot_close: false, 
        comment: "", 
        risk_potential: "", 
        action: "" 
      },
    ],
  },
  {
      section: "S3: Personal  Protective Equipment",
      form_name: "personal_protective_equipment",
      items: [
        { 
          id: null, 
          name: "Face Mask", 
          status: "safe", 
          positive_action: false, 
          form_name: "", 
          custom_action: false, 
          in_the_spot_close: false, 
          comment: "", 
          risk_potential: "", 
          action: "" },
        { id: null, name: "Ears", status: "safe", positive_action: false, form_name: "", custom_action: false, in_the_spot_close: false, risk_potential: "", comment: "", action: "" },
        { id: null, name: "Eyes & Face", status: "safe",  form_name: "", positive_action: false, custom_action: false, in_the_spot_close: false, comment: "", risk_potential: "", action: "" },
        { id: null, name: "Feet & Legs", status: "safe", positive_action: false, form_name: "", custom_action: false, in_the_spot_close: false, comment: "", risk_potential: "", action: "" },
        { id: null, name: "Hands & Arms", status: "safe", positive_action: false, form_name: "", custom_action: false, in_the_spot_close: false, comment: "", risk_potential: "", action: "" },
        { id: null, name: "Head", status: "safe",  form_name: "", positive_action: false, custom_action: false, in_the_spot_close: false, risk_potential: "", comment: "", action: "" },
        { id: null, name: "Respiratory", status: "safe", positive_action: false, form_name: "", custom_action: false, in_the_spot_close: false, comment: "", risk_potential: "", action: "" },
        { id: null, name: "Right for Job", status: "safe", positive_action: false, form_name: "", custom_action: false, in_the_spot_close: false, risk_potential: "", comment: "", action: "" },
        { 
          id: null, 
          name: "Trunk", 
          status: "safe", 
          positive_action: false, 
          form_name: "", 
          custom_action: false, 
          in_the_spot_close: false, 
          comment: "", 
          risk_potential: "", 
          action: "" },
      ],
    },
    {
      section: "S4: Tools & Equipment",
      form_name: "tools_and_equipment",
      items: [
        { id: null, name: "In Safe Condition", status: "safe", positive_action: false, form_name: "", custom_action: false, in_the_spot_close: false, comment: "", risk_potential: "", action: "" },
        { 
          id: null, 
          name: "Bad Condition", 
          status: "safe", 
          form_name: "", 
          positive_action: false, 
          custom_action: false, 
          in_the_spot_close: false, 
          comment: "", 
          risk_potential: "",
          action: "" 
        },
        { id: null, name: "Right for Job", status: "safe", positive_action: false, form_name: "", custom_action: false, in_the_spot_close: false, risk_potential: "", comment: "", action: "" },
        { id: null, name: "Not suitable for job", status: "safe", positive_action: false, form_name: "", custom_action: false, in_the_spot_close: false, comment: "", risk_potential: "", action: "" },
        { id: null, name: "Used Correctly", status: "safe", positive_action: false, form_name: "", in_the_spot_close: false, custom_action: false, comment: "", risk_potential: "", action: "" },
        { 
          id: null, 
          name: "Not used Correctly", 
          status: "safe", 
          positive_action: false, 
          custom_action: false, 
          form_name: "", 
          in_the_spot_close: false, 
          risk_potential: "", 
          action: "",
          comment: "", 
        },
      ],
    },
    {
      section: "S5: Procedure",
      form_name: "procedure",
      items: [
        { 
          id: null, 
          name: "Adequate for job", 
          status: "safe", 
          form_name: "", 
          positive_action: false, 
          in_the_spot_close: false, 
          custom_action: false, 
          comment: "", 
          risk_potential: "", 
          action: "" },
        { id: null, name: "Not Adequate for Job", status: "safe", positive_action: false, form_name: "", custom_action: false, in_the_spot_close: false, risk_potential: "", comment: "", action: "" },
        { id: null, name: "Being Followed", status: "safe", positive_action: false, form_name: "", custom_action: false, in_the_spot_close: false, comment: "", risk_potential: "", action: "" },
        { id: null, name: "Not being Followed", status: "safe", positive_action: false, form_name: "", custom_action: false, in_the_spot_close: false, comment: "", risk_potential: "", action: "" },
        { id: null, name: "Existing and Updated", status: "safe", positive_action: false, form_name: "", custom_action: false, in_the_spot_close: false, comment: "", risk_potential: "", action: "" },
        { id: null, name: "Not Existing", status: "safe", positive_action: false, form_name: "", custom_action: false, in_the_spot_close: false, risk_potential: "", comment: "", action: "" },
      ],
    },
    {
      section: "S6: House Keeping",
      form_name: "house_keeping",
      items: [
        { id: null, name: "Materials & tools kept safely", status: "safe", form_name: "", positive_action: false, custom_action: false, in_the_spot_close: false, comment: "", risk_potential: "", action: "" },
        { id: null, name: "Materials & tools not kept safely", status: "safe", positive_action: false, form_name: "", custom_action: false, in_the_spot_close: false, risk_potential: "", comment: "", action: "" },
        { id: null, name: "Workplace Orderly", status: "safe", positive_action: false, form_name: "", custom_action: false, in_the_spot_close: false, comment: "", risk_potential: "", action: "" },
        { id: null, name: "Workplace Disorderly", status: "safe", positive_action: false, form_name: "", custom_action: false, in_the_spot_close: false, risk_potential: "", comment: "", action: "" },
      ],
    },
];

exports.safetyInteractionByIdApiEndPoint = "bx_block_dashboardguests/safety_interactions";
exports.postSendEndPoint = "/bx_block_dashboardguests/safety_interaction_details/update_not_able_to_observe";
exports.timimgEndPoint = "/bx_block_dashboardguests/safety_interaction_details/update_timing";
exports.kpiCountEndPoint = "/bx_block_dashboardguests/safety_interaction_details/get_static_info?id=";
exports.updateKpiEndPoint ="/bx_block_dashboardguests/safety_interaction_details/update_kpi";
exports.safetyInteractionSelectionApiEndPoint = "bx_block_dashboardguests/safety_interaction_details/load_all_details"
exports.safetyInteractionUpdateSelectionApiEndPoint = "bx_block_dashboardguests/safety_interaction_details/update_all_details"
// Customizable Area End
