// Customizable Area Start
import { BlockComponent } from "../../../../framework/src/BlockComponent";
export const configJSON = require(".././config");
import * as Yup from "yup";
import { getStorageData } from "../../../../framework/src/Utilities";
import { Message } from "../../../../framework/src/Message";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { IBlock } from "../../../../framework/src/IBlock";
// Customizable Area End


export interface Props {
    // Customizable Area Start
    // Customizable Area End
}

// Customizable Area Start
interface KpiData {
    label: string;
    count: string;
    observationHr?: string;
    observationDay?: string;
    observationYear?: string;
}
export interface FormValues {
    [key: string]: string;
}
export interface SafetyFormValues {
    procedure: string;
    ppe: string;
    toolsEquipment: string;
    reactionOfPeople: string;
    positionOfPeople: string;
    housekeeping: string;
    startTime: string;
    endTime: string;
    durations: string;
}
interface KpiData {
    label: string;
    count: string;
}

interface SafetyForm {
    positionOfPeople: string;
    housekeeping: string;
    startTime: string;
    endTime: string;
    durations: string;
}

interface APIPayloadType {
    contentType?: string;
    method: string;
    endPoint: string;
    body?: object;
    token?: string;
    type?: string;
  };


interface ValidResponseType {
    safety_interaction: SafetyInteraction;
    message: string;
    data: Record<string, unknown>;
    meta: {
      message: string;
    };
    
      "static_infos": {
          "safe_observation":number;
          "at_risk_observation":number;
          "not_able_to_observe": number;
          "positive_action":number;
          "system_action":number;      
  }
  }
  
  interface SafetyInteraction {
     
        "id": 2,
        "interaction_number": "/2025/3/2",
        "target_date": null,
        "status": "planned",
        "s1_observed": true,
        "s2_observed": true,
        "s3_observed": true,
        "s4_observed": true,
        "s5_observed": true,
        "s6_observed": true,
        "reason_not_able_observe": {
            "s1": "",
            "s2": "",
            "s3": "",
            "s4": "",
            "s5": "",
            "s6": "",
        },
        "start_time": null,
        "end_time": null,
        "duration": null,
        "location_id": null,
        "assignee_id": 2,
        "reviewer_id": null,
        "creator_id": 142,
        "created_at": "2025-03-13T11:46:14.780+05:30",
        "updated_at": "2025-03-20T15:04:47.021+05:30",
        "safe_observation_count": null,
        "safe_observation_per_hour": null,
        "safe_observation_per_day": null,
        "safe_observation_per_year": null,
        "at_risk_observation_count": null,
        "at_risk_observation_per_hour": null,
        "at_risk_observation_per_day": null,
        "at_risk_observation_per_year": null,
        "not_able_to_observe_count": null,
        "not_able_to_observe_per_hour": null,
        "not_able_to_observe_per_day": null,
        "not_able_to_observe_per_year": null,
        "positive_action_count": null,
        "positive_action_per_hour": null,
        "positive_action_per_day": null,
        "positive_action_per_year": null,
        "system_action_count": null,
        "system_action_per_hour": null,
        "system_action_per_day": null,
        "system_action_per_year": null
}
interface StaticCount {   
  "static_infos": {
  "safe_observation": 0,
  "at_risk_observation": 0,
  "not_able_to_observe": 5,
  "positive_action": 0,
  "system_action": 0
}

}
// Customizable Area End

interface S {
    // Customizable Area Start
    activeStep: number;
    isEdit: boolean;
    kpiData: KpiData[];
    safetyForm: SafetyForm;
    isLoader: boolean;
    safetyInteractionData:SafetyInteraction;
    isAlert:boolean;
    alertMsg:string;
    isCheckValue:string;
    staticCount:StaticCount
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    // Customizable Area End
}

export default class ObserveAndTimingController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getSafetyInteractionspCallId: string = "";
    postSendApicallId: string = "";
    postTimeSendApicallId: string = "";
    getKpiCallId: string = "";
    updateKpiCallId: string = "";
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.SessionResponseMessage)
          ];
          this.receive = this.receive.bind(this);
          runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)
        this.state = {
            activeStep: 1,
            isEdit: false,
            isAlert:false,
            alertMsg:"",
          isCheckValue: "",
          staticCount:{} as StaticCount,
            kpiData: [
                { label: "Safe Observations", count: "02" },
                { label: "At Risk Observations", count: "03" },
                { label: "Not able to Observe", count: "01" },
                { label: "Not able to Observe", count: "09" },
                { label: "Positive Action", count: "01" },
                { label: "System Action", count: "07" }
            ],
            safetyForm: {
                positionOfPeople: "",
                housekeeping: "",
                startTime: "",
                endTime: "",
                durations: ""
            },
            isLoader: false,
            safetyInteractionData: {} as SafetyInteraction
        };
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        this.getSafetyInteractionsData();
        this.getcountKpi();
         };
        safetyFormValidation = Yup.object().shape({
          reactionOfPeople: Yup.string().when("s1_observed", {
              is: false,
              then: Yup.string().required("Reaction of People is required"),
              otherwise: Yup.string().notRequired(),
          }),
          positionOfPeople: Yup.string().when("s2_observed", {
              is: false,
              then: Yup.string().required("Position of People is required"),
              otherwise: Yup.string().notRequired(),
          }),
          ppe: Yup.string().when("s3_observed", {
              is: false,
              then: Yup.string().required("Personal Protective Equipment is required"),
              otherwise: Yup.string().notRequired(),
          }),
          toolsEquipment: Yup.string().when("s4_observed", {
              is: false,
              then: Yup.string().required("Tools & Equipment is required"),
              otherwise: Yup.string().notRequired(),
          }),
          procedure: Yup.string().when("s5_observed", {
              is: false,
              then: Yup.string().required("Procedure is required"),
              otherwise: Yup.string().notRequired(),
          }),
          housekeeping: Yup.string().when("s6_observed", {
              is: false,
              then: Yup.string().required("Housekeeping is required"),
              otherwise: Yup.string().notRequired(),
          }),
          startTime: Yup.string().required("Start Time is required"),
          endTime: Yup.string()
              .required("End Time is required")
              .test("is-greater", "End Time must be greater than Start Time", function (value) {
                  const { startTime } = this.parent;
                  return startTime && value ? value > startTime : true;
              }),
          durations: Yup.string().required("Duration Time is required"),
      });
      
      

    handleSafetyForm = (values: SafetyFormValues) => {
        this.setState({
            activeStep: this.state.activeStep + 1,
            isEdit: false
        })
    };


    handlePrevious = () => {
        this.setState((prevState) => ({ activeStep: prevState.activeStep - 1 }));
    };

    onEdit = () => {
       this.setState((prevState) => ({
          isEdit: !prevState.isEdit
      }), () => {
          if (!this.state.isEdit) {
              this.setState({ isCheckValue: "save" });
          }
      });
  };
  
    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
          let responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
          let apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
          );
           if (this.isValidResponse(responseJson)) {
  
            this.apiSucessCall(apiRequestCallId, responseJson);
          }
        }
      }

      apiSucessCall = async (apiRequestCallId: string, responseJson: ValidResponseType) => {
        
        if (apiRequestCallId === this.getSafetyInteractionspCallId) {
          this.safetyInteractionSucessCallBack(responseJson);
        }
        if (apiRequestCallId === this.postSendApicallId) {

          this.postSendApiSucessCallBack(responseJson);
        }

        if (apiRequestCallId === this.postTimeSendApicallId) {

          this.postTimeSendApiSucessCallBack(responseJson);
        }

        if (apiRequestCallId === this.getKpiCallId) {

          this.kpiApiSucessCallBack(responseJson);
        }


        if (apiRequestCallId === this.updateKpiCallId) {

          this.updateKpiApiSucessCallBack(responseJson);
        }
      };
    
      isValidResponse = (responseJson: ValidResponseType) => {
        return (responseJson && responseJson.data)
          || responseJson.message
          || responseJson.safety_interaction
          || responseJson.static_infos
      };

      safetyInteractionSucessCallBack = (responseJson: ValidResponseType) => {
         this.setState({
           isLoader:false,
           safetyInteractionData:responseJson.safety_interaction
        },
      )
      };

      updateKpiApiSucessCallBack=(responseJson: ValidResponseType)=>{
        this.setState({
          isLoader:false,
        },()=>{
          this.getSafetyInteractionsData()
        })
      }
      kpiApiSucessCallBack = (responseJson: ValidResponseType) => {
        const { safe_observation, at_risk_observation, not_able_to_observe, positive_action, system_action } = responseJson.static_infos;
      
        const updatedKpiData = [
          { label: "Safe Observations", count: safe_observation.toString() },
          { label: "At Risk Observations", count: at_risk_observation.toString() },
          { label: "Not able to Observe", count: not_able_to_observe.toString() },
          { label: "Positive Action", count: positive_action.toString() },
          { label: "System Action", count: system_action.toString() }
        ];
      
        this.setState(
          {
            isLoader: false,
            kpiData: updatedKpiData,
           },
        );
      };
      
      postSendApiSucessCallBack= (responseJson: ValidResponseType)=>{
        this.setState({
          isLoader:false
        })
      }
     
      postTimeSendApiSucessCallBack = (responseJson: ValidResponseType) => {
        if (this.state.isCheckValue === "save") {
            this.setState({
                isLoader: false,
                isEdit: false,
                alertMsg:responseJson.message,
                isAlert:true
            });
        }
    
        if (this.state.isCheckValue === "submit") {
            this.setState({
                isLoader: false,
                isEdit: false,
                activeStep: this.state.activeStep + 1,
                alertMsg:responseJson.message,
                isAlert:true
            });
        }
    };
    
    apiCall = async (apiData: APIPayloadType) => {
        const { contentType, method, endPoint, body, type } = apiData;
        let token = await getStorageData("authToken")
         const header = {
          "Content-Type": contentType,
          token: token
        };
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          endPoint
        )
        
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        )
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          method
        )
        body && type !== "formData"
          ? requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(body)
          )
          : requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            body
          );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return requestMessage.messageId;
      };
      

    getSafetyInteractionsData = async () => {
    const safetyInteractionId = await getStorageData("safetyInteractionId");

        this.setState({ isLoader: true })
        this.getSafetyInteractionspCallId = await this.apiCall({
            contentType: configJSON.validationApiContentType,
            method: configJSON.validationApiMethodType,
            endPoint: configJSON.safetyInteractionByIdApiEndPoint +"/" +safetyInteractionId,
        });
    };

    postSendApi = async (values: SafetyFormValues) => {
       const safetyInteractionId = await getStorageData("safetyInteractionId");
    
       const reasonNotAbleObserve: Record<string, string> = {
        s1: values.reactionOfPeople,
        s2: values.positionOfPeople,
        s3: values.ppe,
        s4: values.toolsEquipment,
        s5: values.procedure,
        s6: values.housekeeping,
      };
    
       Object.keys(reasonNotAbleObserve).forEach(
        (key) => reasonNotAbleObserve[key] === "" && delete reasonNotAbleObserve[key]
      );
    
      const body = {
        safety_interaction: {
          id: safetyInteractionId,
          reason_not_able_observe: reasonNotAbleObserve,
        },
      };
    
      this.postSendApicallId = await this.apiCall({
        contentType: configJSON.validationApiContentType,
        method: configJSON.patchType,
        endPoint: configJSON.postSendEndPoint,
        body: body,
      });
      this.postUpdateTime(values)
    };

    postUpdateTime = async (values: SafetyFormValues) => {
      const safetyInteractionId = await getStorageData("safetyInteractionId");
      const body = {
        "safety_interaction": {
            "id": safetyInteractionId,
            "start_time": values.startTime,
            "end_time":  values.endTime,
            "duration": values.durations,
        }
    }
    
      this.postTimeSendApicallId = await this.apiCall({
        contentType: configJSON.validationApiContentType,
        method: configJSON.patchType,
        endPoint: configJSON.timimgEndPoint,
        body: body,
      });
    }

    alertClose=()=>{
      this.setState({isAlert:!this.state.isAlert})
    }

    kpiDataUpdate = async (values:any) => {
      const safetyInteractionId = await getStorageData("safetyInteractionId");
      const body =
      {
        "safety_interaction": {
          "id": safetyInteractionId,
          "safe_observation_count": this.state.kpiData[0].count,
          "safe_observation_per_hour": values.safe_observation_per_hour,
          "safe_observation_per_day": values.safe_observation_per_day,
          "safe_observation_per_year": values.safe_observation_per_year,

          "at_risk_observation_count":  this.state.kpiData[1].count,
          "at_risk_observation_per_hour":   values.at_risk_observation_per_hour,
          "at_risk_observation_per_day":   values.at_risk_observation_per_day,
          "at_risk_observation_per_year":  values.at_risk_observation_per_year,

          "not_able_to_observe_count":this.state.kpiData[2].count,
          "not_able_to_observe_per_hour":  values.not_able_to_observe_per_hour,
          "not_able_to_observe_per_day":  values.not_able_to_observe_per_day,
          "not_able_to_observe_per_year":  values.not_able_to_observe_per_year,

          "positive_action_count": this.state.kpiData[3].count,
          "positive_action_per_hour": values.positive_action_per_hour,
          "positive_action_per_day": values.positive_action_per_day,
          "positive_action_per_year": values.positive_action_per_year,

          "system_action_count": this.state.kpiData[4].count,
          "system_action_per_hour": values.system_action_per_hour,
          "system_action_per_day": values.system_action_per_day,
          "system_action_per_year": values.system_action_per_year,

          "done": true
        }
      }
          this.setState({ isLoader: true })
          this.updateKpiCallId = await this.apiCall({
              contentType: configJSON.validationApiContentType,
              method: configJSON.patchType,
              endPoint: configJSON.updateKpiEndPoint,

              body:body
          });
      };

  getcountKpi = async () => {
    const safetyInteractionId = await getStorageData("safetyInteractionId");

    this.setState({ isLoader: true })
    this.getKpiCallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.kpiCountEndPoint + safetyInteractionId,
    });
  };
    
    // Customizable Area End
}
