import React from "react";
import {
  Box,
  Typography,
  Grid,
} from "@material-ui/core";
// Customizable Area Start
import HelpController, { Props, configJSON } from "./HelpController";
import { NavigateNext } from "@material-ui/icons";
import Loader from "../../../components/src/Loader.web";
import { webStyles } from "../../../components/src/styles";
import i18n from "i18next";

export default class HelpDetails extends HelpController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    return (
      <Grid container style={webStyle.Container}>
        <Loader loading={this.state.loader} data-test-id="loader" />
        <Grid item lg={12} sm={12} xs={12}>
          <Box style={{ padding: '30px' }}>
            <Box style={webStyles.breadcrumbWrapper}>
              <Typography style={webStyles.breadcrumbItem} data-test-id="account-text">{i18n.t(configJSON.helpText)}</Typography>
              <NavigateNext style={webStyles.navigateIcon} />
              <Typography style={webStyles.breadcrumbActiveItem} data-test-id="delete-text">{this.state.is_terms_condition ? i18n.t(configJSON.TermsandConditionsText ): i18n.t(configJSON.privacyPolicyText)}</Typography>
            </Box>
            {this.state.is_privacy_policy && <Typography style={webStyle.accoundHeading} data-test-id="account-delete-header">{i18n.t(configJSON.privacyPolicyText)}</Typography>}
            {this.state.is_terms_condition && <Typography style={webStyle.accoundHeading} data-test-id="account-delete-header">{i18n.t(configJSON.TermsandConditionsText)}</Typography>}
            <Typography className="privacyPolicy" style={webStyle.policyData} dangerouslySetInnerHTML={{ __html: this.state.data }}></Typography>
          </Box>
        </Grid>
      </Grid>
    );
  }
}
// Customizable Area End

// Customizable Area Start

const webStyle = {
  navigateIcon: {
    fill: '#DCDCDC'
  },
  currentPage: {
    color: "#8735E1",
    fontFamily: "ClotherBold",
    fontSize: "14px",
    lineHeight: "22px",
    cursor: 'pointer'
  },
  Container: {
    background: 'rgb(237, 238, 238)',
    height: '100vh',
  },
  policyData: {
    padding: '0px 30px'
  },
  headerBox: {
    fontSize: '0.875rem',
    color: '#A1A1A1',
    display: 'flex',
    gap: '0.3rem',
    alignItems: 'center',
    marginBottom: '1.25rem'
  },
  accountText: {
    fontFamily: 'ClotherRegular',
    fontSize: "14px",
    lineHeight: "22px",
    cursor: 'pointer',
    color: '#18181E',
  },
  accoundHeading: {
    fontFamily: "ClotherBold",
    fontSize: '24px',
    lineHeight: "24px",
    paddingBottom: '25px',
    marginTop: '17px',
  },
};

// Customizable Area End

