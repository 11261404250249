import React, { Component } from "react";
import { Box, Typography, IconButton } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { upload } from "../../blocks/dashboard/src/assets";
import { webStyle } from "../../blocks/dashboard/src/postCreationPopUp/styles";

interface ImageUploaderProps {
  images: string[];
  onUpload: (files: FileList) => void;
  onRemove: (index: number) => void;
}

const StyledContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "10px",
  "& .upload-box": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid #DCDCDC",
    borderRadius: "8px",
    height: "169px",
    cursor: "pointer",
    textAlign: "center",
    "& svg": {
      fontSize: "24px",
      color: "#999",
    },
  },
  "& .uploadImageText": {
    fontSize: "14px",
    fontFamily: "ClotherRegular",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#A1A1A1",
  },
  "& .preview-container": {
    display: "flex",
    gap: "10px",
  },
  "& .preview-item": {
    position: "relative",
    width: "100px",
    height: "100px",
    borderRadius: "4px",
    overflow: "hidden",
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
    "& .remove-btn": {
      position: "absolute",
      top: 5,
      right: 5,
      backgroundColor: "#fff",
      color: "#A1A1A1",
      padding: "2px",
      borderRadius: "50%",
      "&:hover": {
        color: "#A1A1A1",
      }
    },
  },
});

class ImageUploader extends Component<ImageUploaderProps> {
  handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      this.props.onUpload(event.target.files);
    }
  };

  render() {
    const { images, onRemove } = this.props;
    return (
      <StyledContainer>
        <label htmlFor="upload-input" className="upload-box">
          <Box>
            <img src={upload} alt="upload image" style={webStyle.iploadedImg as React.CSSProperties} />
            <Typography className="uploadImageText">Upload Image</Typography>
          </Box>
          <input
            id="upload-input"
            type="file"
            multiple
            style={{ display: "none" }}
            onChange={this.handleFileChange}
          />
        </label>
        <Box className="preview-container">
          {images.map((src, index) => (
            <Box key={index} className="preview-item">
              <img src={src} alt="upload preview" />
              <IconButton
                size="small"
                className="remove-btn"
                onClick={() => onRemove(index)}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </Box>
          ))}
        </Box>
      </StyledContainer>
    );
  }
}

export default ImageUploader;
