import {
    FormControl,
    FormHelperText,
    MenuItem,
    Select,
    styled,
  } from "@material-ui/core";
  import React, { Component } from "react";
  import { KeyboardArrowDown } from "@material-ui/icons";
import { webStyle } from "../../blocks/dashboard/src/postCreationPopUp/styles";
  
  interface Props {
    variant?: "outlined" | "filled" | "standard";
    className?: string;
    placeholder: string;
    onChange: (
      event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>
    ) => void;
    value?: string | number;
    options: { name: string; value: number | string }[];
    error?: boolean;
    errorMsg?: string;
    selectByName?: boolean;
    name?: string;
  }
  
  export default class CustomSelect extends Component<Props> {
    constructor(props: Props) {
      super(props);
    }
  
    render() {
      const {
        value,
        placeholder,
        onChange,
        variant = "outlined",
        className = "",
        options,
        error = false,
        errorMsg = "",
        selectByName = false,
        name = "",
      } = this.props;
      return (
        <Wrapper>
          <FormControl fullWidth>
            <Select
              variant={variant}
              value={value || placeholder}
              IconComponent={KeyboardArrowDown}
              name={name}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
              renderValue={
                value
                  ? undefined
                  : () => (
                    <div style={webStyle.selectPlaceholder as React.CSSProperties}>
                     {placeholder}
                    </div>
                  )
              }
              className={className}
              onChange={onChange}
              error={error}
            >
              <MenuItem value={placeholder} disabled>
                {placeholder}
              </MenuItem>
              {options.map((item) => {
                return (
                  <MenuItem key={item.value} value={selectByName ? item.name : item.value}>
                    {item.name}
                  </MenuItem>
                );
              })}
            </Select>
            {error ? (
              <FormHelperText className="errorMsg">
                {"* " + errorMsg}
              </FormHelperText>
            ) : null}
          </FormControl>
        </Wrapper>
      );
    }
  }
  
  const Wrapper = styled("div")(({ theme }) => ({
    "& .MuiOutlinedInput-root": {
          borderRadius: "8px",
          fontFamily: "ClotherRegular",
          color: "#DCDCDC",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "22px",
    },
    "& .MuiInputBase-root": {
      "& .Mui-focused": {
        borderColor: "#DCDCDC",
      }
    },
    "& .MuiOutlinedInput-notchedOutline": {
       borderColor: "#DCDCDC",
    },
    "& .adornmentColor": {
      color: "#DCDCDC",
    },
    "& .MuiSvgIcon-root": {
        color: "#A1A1A1",
    },
    "& .errorMsg": {
      color: "red",
    },
    "& [class*=MuiSelect-select]": {
      fontFamily: "ClotherRegular",
      fontSize: "16px",
      fontWeight: 700,
      color: "#2B2A29",
    },
    "& .MuiSelect-placeholder": {
      color: "#DCDCDC",
    }
  }));