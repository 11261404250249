import React from "react";
// Customizable Area Start
import {
  Backdrop,
  Box,
  Dialog,
  FormGroup,
  Typography,
  Grid,
  IconButton,
  Input,
  ThemeProvider,
  withStyles,
  TextField,
  Button,
  styled,
  Paper,
  Chip,
  Snackbar,
  CircularProgress,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import GoodPracticeController, {
  Account,
  Category,
  FormValues,
  FormValuesStep2,
  Props,
  S,
  SS,
} from "./GoodPracticeController";
import { isPhone } from "./consts";
import i18n from "i18next";
import { Form, Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { Close } from "@material-ui/icons";
import { theme } from "./postCreationPopUp/styles";
import { aiLoader, mic, upload } from "./assets";
import { Autocomplete } from "@material-ui/lab";
import { errorIcon } from "../../../components/src/assets";

export const configJSON = require("./config");
// Customizable Area End

export default class GoodPractice extends GoodPracticeController {
  textFieldRef: any;
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.toggleListeningGoodPractice = this.toggleListeningGoodPractice.bind(
      this
    );
    this.textFieldRef = React.createRef<FormikProps<any>>();
    // Customizable Area End
  }

  // Customizable Area Start
  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<S>,
    snapshot?: SS | undefined
  ): void {
    if (
      prevState.beforeImageDescription !== this.state.beforeImageDescription
    ) {
      this.setState(
        { beforeImageDescription: this.state.beforeImageDescription },
        () => {
          if (this.textFieldRef.current) {
            this.textFieldRef.current.setFieldValue(
              "beforeImageDescription",
              this.state.beforeImageDescription
            );
          }
        }
      );
    }
  }

  initialValues: FormValues = {
    goodPracticeNumber: this.state.goodPracticeNumber,
    location: this.props.subFunctionName,
    category: "",
    afterImage: this.props.afterImageFile,
    afterImageDescription: this.props.afterImageDescription,
    beforeImage: null,
    beforeImageDescription: this.state.beforeImageDescription,
    initiative: "",
    benefit: "",
    briefDescription: "",
  };

  initialValuesStep2: FormValuesStep2 = {
    media: null,
    taggedPeople: [] as Account[],
  };

  validationSchema = Yup.object().shape({
    category: Yup.string().required("Category is required"),
    beforeImage: Yup.mixed().required("Before Image is required"),
    beforeImageDescription: Yup.string().required(
      "Before Image Description is required"
    ),
    initiative: Yup.string().required("Initiative is required"),
    benefit: Yup.string().required("Benefit is required"),
    briefDescription: Yup.string().required("Brief Description is required"),
  });

  validationSchemaForStep2 = Yup.object().shape({
    media: Yup.mixed().required("Media is required"),
    taggedPeople: Yup.array().min(1, "At least one person must be tagged"),
  });

  renderFormikStep = () => {
    return (
      <Formik
        innerRef={this.textFieldRef}
        initialValues={this.initialValues}
        enableReinitialize={true}
        validationSchema={this.validationSchema}
        onSubmit={(values) => {
          this.setState({
            formikFirstStepValues: values,
            isStep1: false,
            isStep2: true,
          });
        }}
      >
        {({
          values,
          handleChange,
          handleBlur,
          errors,
          touched,
          setFieldValue,
        }) => (
          <Form translate="no">
            <ThemeProvider theme={theme}>
              <Grid container spacing={2} style={{ marginTop: "12px" }}>
                <Grid item xs={12} md={4}>
                  <FormGroup className="input-block">
                    <Typography style={webStyles.label}>
                      {i18n.t("Good Practice Number")}
                    </Typography>
                    <Input
                      disabled
                      name="goodPracticeNumber"
                      data-id="goodPracticeNumber"
                      style={{
                        ...webStyles.input,
                        ...webStyles.inputStd,
                        ...webStyles.inputDisabled,
                      }}
                      value={this.state.goodPracticeNumber}
                      disableUnderline
                      placeholder="Good Practice Number"
                      onBlur={handleBlur}
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormGroup className="input-block">
                    <Typography style={webStyles.label}>
                      {i18n.t("Location")}
                    </Typography>
                    <Input
                      disabled
                      name="location"
                      data-id="location"
                      style={{
                        ...webStyles.input,
                        ...webStyles.inputStd,
                        ...webStyles.inputDisabled,
                      }}
                      value={this.props.subFunctionName}
                      disableUnderline
                      placeholder="Location"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormGroup className="input-block">
                    <Typography style={webStyles.label}>
                      {i18n.t("Category")}
                    </Typography>
                    <ComboBox
                      data-test-id="categoryTextField"
                      value={
                        this.state.categories.find(
                          (option) => option.id === Number(values.category)
                        ) || null
                      }
                      onChange={(_, newValue) => {
                        const category = newValue as Category | null;
                        setFieldValue("category", category ? category.id : "");
                      }}
                      options={this.state.categories}
                      getOptionLabel={(option) => (option as Category).name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Category"
                          variant="outlined"
                          fullWidth
                        />
                      )}
                      noOptionsText="No Data"
                      PaperComponent={StyledPaper}
                    />
                    {errors.category && touched.category && (
                      <Typography
                        variant="body2"
                        color="error"
                        style={{ marginTop: "4px" }}
                      >
                        {errors.category}
                      </Typography>
                    )}
                  </FormGroup>
                </Grid>
              </Grid>
              <Box mt={"0.75rem"}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <FormGroup className="input-block">
                      <Typography style={webStyles.label}>
                        {i18n.t("After Image")}
                      </Typography>
                      <img
                        src={values.afterImage as string | undefined}
                        width={"100%"}
                        height={"144px"}
                        style={{
                          borderRadius: "8px",
                        }}
                        alt="afterImage"
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormGroup className="input-block">
                      <Box
                        alignItems="center"
                        display="flex"
                        justifyContent="space-between"
                      >
                        <Typography style={webStyles.label}>
                          {i18n.t("After Image Description")}
                        </Typography>
                        <img
                          style={webStyles.mic as React.CSSProperties}
                          data-test-id="afterImageSpeechText"
                          src={mic}
                          alt="mic icon"
                          height="21px"
                          width="21px"
                        />
                      </Box>
                      <TextField
                        disabled
                        style={{
                          ...webStyles.textareaStyle,
                          backgroundColor: "#F3F3F3",
                        }}
                        placeholder="After Image Description"
                        value={values.afterImageDescription}
                        maxRows={5}
                        minRows={5}
                        multiline
                        InputProps={{ disableUnderline: true }}
                        onBlur={handleBlur}
                        className="textarea"
                        name="afterImageDescription"
                        data-test-id="afterImageDescription"
                      />
                    </FormGroup>
                  </Grid>
                </Grid>
              </Box>
              <Box mt={"0.75rem"}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <FormGroup className="input-block">
                      <Typography style={webStyles.label}>
                        {i18n.t("Before Image")}
                      </Typography>
                      <Button
                        component="label"
                        style={
                          {
                            ...webStyles.input,
                            ...webStyles.upload,
                          } as React.CSSProperties
                        }
                      >
                        <div
                          className="upload-img"
                          style={webStyles.uploadImg as React.CSSProperties}
                        >
                          <img
                            src={this.state.beforeImageFileLink || upload}
                            alt="upload image"
                            style={
                              this.state.beforeImageFileLink
                                ? (webStyles.imageUploaded as React.CSSProperties)
                                : (webStyles.iploadedImg as React.CSSProperties)
                            }
                          />
                          {!values.beforeImage && (
                            <div style={webStyles.fontBlock}>
                              {i18n.t("Upload Image")}
                            </div>
                          )}
                        </div>
                        <input
                          data-test-id="upldFileId"
                          type="file"
                          name="image"
                          id="user-image"
                          accept="image/png, image/jpeg"
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            setFieldValue(
                              "beforeImage",
                              event?.target.files?.[0]
                            );
                            this.changeImg(event);
                            this.updateImgs(event, setFieldValue);
                          }}
                          onBlur={handleBlur}
                        />
                      </Button>
                      {errors.beforeImage && touched.beforeImage && (
                        <Typography
                          variant="body2"
                          color="error"
                          style={{ marginTop: "4px" }}
                        >
                          {errors.beforeImage}
                        </Typography>
                      )}
                      {this.state.imageErrorMessage && (
                        <Typography
                          variant="body2"
                          color="error"
                          style={{ marginTop: "4px" }}
                        >
                          {this.state.imageErrorMessage}
                        </Typography>
                      )}
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormGroup className="input-block">
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Typography style={webStyles.label}>
                          {i18n.t("Before Image Description")}
                        </Typography>
                        <img
                          height="21px"
                          width="21px"
                          src={this.renderMic("beforeImageDescription")}
                          alt="mic icon"
                          onClick={async () => {
                            await this.toggleListeningGoodPractice(
                              "beforeImageDescription"
                            );
                            this.setDynamicData(
                              "beforeImageDescription",
                              setFieldValue
                            );
                          }}
                          style={webStyles.mic as React.CSSProperties}
                          data-test-id="beforeImageDescriptionSpeechText"
                        />
                      </Box>
                      <TextField
                        style={webStyles.textareaStyle}
                        placeholder="Before Image Description"
                        value={this.state.beforeImageDescription}
                        maxRows={3}
                        minRows={3}
                        multiline
                        InputProps={{ disableUnderline: true }}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) =>
                          this.handleBeforeImageDescriptionChange(
                            event,
                            setFieldValue
                          )
                        }
                        onBlur={handleBlur}
                        className="textarea"
                        name="beforeImageDescription"
                        data-test-id="beforeImageDescription"
                      />
                      {errors.beforeImageDescription &&
                        touched.beforeImageDescription && (
                          <Typography
                            variant="body2"
                            color="error"
                            style={{ marginTop: "4px" }}
                          >
                            {errors.beforeImageDescription}
                          </Typography>
                        )}
                    </FormGroup>
                  </Grid>
                </Grid>
              </Box>
              <Box mt={"0.75rem"}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <FormGroup className="input-block">
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Typography style={webStyles.label}>
                          {i18n.t("Initiative")}
                        </Typography>
                        <img
                          height="21px"
                          src={this.renderMic("initiative")}
                          style={webStyles.mic as React.CSSProperties}
                          alt="mic icon"
                          data-test-id="initiativeSpeechText"
                          onClick={async () => {
                            await this.toggleListeningGoodPractice(
                              "initiative"
                            );
                            this.setDynamicData("initiative", setFieldValue);
                          }}
                          width="21px"
                        />
                      </Box>
                      <TextField
                        style={webStyles.textareaStyle}
                        placeholder="Initiative"
                        value={this.state.initiative}
                        maxRows={3}
                        minRows={3}
                        multiline
                        InputProps={{ disableUnderline: true }}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => this.handleInitiativeChange(event, setFieldValue)}
                        onBlur={handleBlur}
                        className="textarea"
                        name="initiative"
                        data-test-id="initiative"
                      />
                      {errors.initiative && touched.initiative && (
                        <Typography
                          variant="body2"
                          color="error"
                          style={{ marginTop: "4px" }}
                        >
                          {errors.initiative}
                        </Typography>
                      )}
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormGroup className="input-block">
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Typography style={webStyles.label}>
                          {i18n.t("Benefit")}
                        </Typography>
                        <img
                          data-test-id="benefitSpeechText"
                          onClick={async () => {
                            await this.toggleListeningGoodPractice("benefit");
                            this.setDynamicData("benefit", setFieldValue);
                          }}
                          height="21px"
                          src={this.renderMic("benefit")}
                          width="21px"
                          style={webStyles.mic as React.CSSProperties}
                          alt="mic icon"
                        />
                      </Box>
                      <TextField
                        style={webStyles.textareaStyle}
                        placeholder="Benefit"
                        value={this.state.benefit}
                        maxRows={3}
                        minRows={3}
                        multiline
                        InputProps={{ disableUnderline: true }}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => this.handleBenefitChange(event, setFieldValue)}
                        onBlur={handleBlur}
                        className="textarea"
                        name="benefit"
                        data-test-id="benefit"
                      />
                      {errors.benefit && touched.benefit && (
                        <Typography
                          variant="body2"
                          color="error"
                          style={{ marginTop: "4px" }}
                        >
                          {errors.benefit}
                        </Typography>
                      )}
                    </FormGroup>
                  </Grid>
                </Grid>
              </Box>
              <Box mt={"0.75rem"}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormGroup className="input-block">
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Typography style={webStyles.label}>
                          {i18n.t("Brief Description")}
                        </Typography>
                        <img
                          height="21px"
                          width="21px"
                          src={this.renderMic("briefDescription")}
                          alt="mic icon"
                          style={webStyles.mic as React.CSSProperties}
                          data-test-id="briefDescriptionSpeechText"
                          onClick={async () => {
                            await this.toggleListeningGoodPractice(
                              "briefDescription"
                            );
                            this.setDynamicData(
                              "briefDescription",
                              setFieldValue
                            );
                          }}
                        />
                      </Box>
                      <TextField
                        style={webStyles.textareaStyle}
                        placeholder="Brief Description"
                        value={this.state.briefDescription}
                        maxRows={3}
                        minRows={3}
                        multiline
                        InputProps={{ disableUnderline: true }}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) =>
                          this.handleBriefDescriptionChange(
                            event,
                            setFieldValue
                          )
                        }
                        onBlur={handleBlur}
                        className="textarea"
                        name="briefDescription"
                        data-test-id="briefDescription"
                      />
                      {errors.briefDescription && touched.briefDescription && (
                        <Typography
                          variant="body2"
                          color="error"
                          style={{ marginTop: "4px" }}
                        >
                          {errors.briefDescription}
                        </Typography>
                      )}
                    </FormGroup>
                  </Grid>
                </Grid>
              </Box>
              <Box
                display="flex"
                justifyContent="end"
                alignItems="center"
                mt={"1.875rem"}
                ml={"auto"}
              >
                <Button
                  type="submit"
                  style={
                    {
                      ...webStyles.button,
                      ...webStyles.fullButton,
                      ...(isPhone && webStyles.mobileButton),
                    } as React.CSSProperties
                  }
                >
                  {i18n.t("Next")}
                </Button>
              </Box>
            </ThemeProvider>
          </Form>
        )}
      </Formik>
    );
  };

  renderFormikStep2 = () => {
    return (
      <Formik
        initialValues={this.initialValuesStep2}
        validationSchema={this.validationSchemaForStep2}
        onSubmit={(values) => {
          this.handleSubmitFinal(values);
        }}
      >
        {({ values, handleBlur, errors, touched, setFieldValue }) => (
          <Form translate={"no"}>
            <ThemeProvider theme={theme}>
              <Grid container spacing={2} style={{ marginTop: "12px" }}>
                <Grid item xs={12}>
                  <FormGroup className="input-block">
                    <Typography style={webStyles.label}>
                      {i18n.t("Alternate Media (Video/Image)")}
                    </Typography>
                    <Button
                      component="label"
                      style={
                        {
                          ...webStyles.input,
                          ...webStyles.uploadStep2,
                        } as React.CSSProperties
                      }
                    >
                      <div
                        className="upload-img"
                        style={webStyles.uploadImg as React.CSSProperties}
                      >
                        <img
                          alt="upload image"
                          src={upload}
                          style={webStyles.iploadedImg as React.CSSProperties}
                        />
                        <div style={webStyles.fontBlock}>
                          {i18n.t("Upload Image")}
                        </div>
                      </div>
                      <input
                        data-test-id="videoImageFile"
                        type="file"
                        name="media"
                        style={{ display: "none" }}
                        id="user-image-video"
                        accept="image/png, image/jpeg, video/mp4, video/mov"
                        multiple
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          this.handleVideoImageFileChange(event);
                          this.updateVideoImage(event, setFieldValue);
                        }}
                        onBlur={handleBlur}
                      />
                    </Button>
                    {errors.media && touched.media && (
                      <Typography
                        variant="body2"
                        color="error"
                        style={{ marginTop: "4px" }}
                      >
                        {errors.media}
                      </Typography>
                    )}
                    <Box
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: "10px",
                        marginTop: "10px",
                      }}
                    >
                      {this.state.previewUrlImages.map((image, index) => (
                        <Box
                          key={index}
                          style={{
                            position: "relative",
                            width: "100px",
                            height: "100px",
                            borderRadius: "8px",
                            overflow: "hidden",
                            border: "1px solid #ddd",
                          }}
                        >
                          <img
                            src={image}
                            alt={`preview-${index}`}
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                            }}
                          />
                          <IconButton
                            data-test-id="removeImageButton"
                            onClick={() => this.removeFile(index, "image")}
                            style={{
                              position: "absolute",
                              top: 4,
                              right: 4,
                              background: "rgba(0,0,0,0.6)",
                              color: "#fff",
                              width: "24px",
                              height: "24px",
                            }}
                          >
                            <Close fontSize="small" />
                          </IconButton>
                        </Box>
                      ))}
                      {this.state.previewUrlVideos.map((video, index) => (
                        <Box
                          key={index}
                          style={{
                            position: "relative",
                            width: "100px",
                            height: "100px",
                            borderRadius: "8px",
                            overflow: "hidden",
                            border: "1px solid #ddd",
                          }}
                        >
                          <video
                            src={video}
                            controls
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                            }}
                          />
                          <IconButton
                            data-test-id="removeVideoButton"
                            onClick={() => this.removeFile(index, "video")}
                            style={{
                              position: "absolute",
                              top: 4,
                              color: "#fff",
                              width: "24px",
                              height: "24px",
                              right: 4,
                              background: "rgba(0,0,0,0.6)",
                            }}
                          >
                            <Close fontSize="small" />
                          </IconButton>
                        </Box>
                      ))}
                    </Box>
                  </FormGroup>
                </Grid>
                <Grid item xs={12}>
                  <FormGroup className="input-block">
                    <Typography style={webStyles.label}>
                      {i18n.t("Tag People")}
                    </Typography>
                    <ComboBox
                      multiple
                      options={this.state.activePeopleList}
                      data-test-id="taggedPeopleAutoComplete"
                      value={values.taggedPeople}
                      onChange={(event, newValue) =>
                        setFieldValue("taggedPeople", newValue)
                      }
                      getOptionLabel={(option) =>
                        ((option as Account).full_name ?? "").toString()
                      }
                      renderTags={() => null}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select User"
                          variant="outlined"
                          fullWidth
                        />
                      )}
                      noOptionsText="No Data"
                      PaperComponent={StyledPaper}
                      disableClearable
                    />
                    {errors.taggedPeople && touched.taggedPeople && (
                      <Typography
                        variant="body2"
                        color="error"
                        style={{ marginTop: "4px" }}
                      >
                        {errors.taggedPeople}
                      </Typography>
                    )}
                    <Box
                      style={{
                        marginTop: "8px",
                        display: "flex",
                        flexWrap: "wrap",
                        gap: "8px",
                      }}
                    >
                      {values.taggedPeople.map((option) => (
                        <CustomChip
                          data-test-id="customChip"
                          key={option.id}
                          label={option.full_name}
                          onDelete={() => {
                            const newTaggedPeople = values.taggedPeople.filter(
                              (person) => person.id !== option.id
                            );
                            setFieldValue("taggedPeople", newTaggedPeople);
                          }}
                          deleteIcon={
                            <Close fontSize="small" style={{ color: "#fff" }} />
                          }
                        />
                      ))}
                    </Box>
                  </FormGroup>
                </Grid>
              </Grid>
              <Box
                display="flex"
                justifyContent="end"
                alignItems="center"
                mt={"16rem"}
                ml={"auto"}
              >
                <Button
                  type="submit"
                  style={
                    {
                      ...webStyles.button,
                      ...webStyles.fullButton,
                      ...(isPhone && webStyles.mobileButton),
                    } as React.CSSProperties
                  }
                >
                  {this.state.loading ? (
                    <CircularProgress size="2rem" color="inherit" />
                  ) : (
                    `${i18n.t("Post")}`
                  )}
                </Button>
              </Box>
            </ThemeProvider>
          </Form>
        )}
      </Formik>
    );
  };

  renderSnackbar() {
    const { responseStatus, errorMsg } = this.state;
    if (!errorMsg) return null;

    return (
      <Snackbar
        data-test-id="snackbar"
        open={responseStatus !== null}
        autoHideDuration={3000}
        onClose={this.handleCloseSnackbar}
      >
        <Alert
          onClose={this.handleCloseSnackbar}
          severity={responseStatus || undefined}
        >
          {errorMsg}
        </Alert>
      </Snackbar>
    );
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <React.Fragment>
        {this.renderSnackbar()}
        <Dialog
          open
          style={webStyles.modal}
          maxWidth={"md"}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <Wrapper
            style={
              {
                ...webStyles.modalMaindivStyle,
                ...(isPhone && webStyles.mobileModal),
              } as React.CSSProperties
            }
          >
            <Typography style={webStyles.goodPracticeText}>
              {i18n.t("Good Practice")}
            </Typography>
            <IconButton
              style={webStyles.closeBtn as React.CSSProperties}
              onClick={this.props.onGoodPracticeModalClick}
            >
              <Close />
            </IconButton>
            {this.state.isStep1 && this.renderFormikStep()}
            {this.state.isStep2 && this.renderFormikStep2()}
          </Wrapper>
        </Dialog>
      </React.Fragment>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyles = {
  aiLoaderSubText: {
    fontSize: "14px",
    fontWeight: 500,
    color: "#A1A1A1",
    fontStyle: "italic",
  },
  aiLoaderText: {
    fontSize: "16px",
    fontWeight: 500,
    color: "#18181E",
  },
  aiLoaderWrapper: {
    width: "100%",
    height: "92%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    position: "absolute",
    background: "#fff",
    top: 60,
    left: 0,
  } as const,
  aiErrorWrapper: {
    width: "100%",
    height: "92%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    position: "absolute",
    background: "#fff",
    top: 60,
    left: 0,
  } as const,
  AiTagText: {
    fontSize: "10px",
    color: "#a8a8a8",
    marginBottom: "10px",
    marginTop: "8px",
    textAlign: "right",
    fontWeight: 600,
    fontStyle: "italic",
  } as const,
  uploadStep2: {
    height: "12.875rem",
    textTransform: "none",
    display: "block",
    padding: 0,
  },
  upload: {
    height: "9rem",
    textTransform: "none",
    display: "block",
    padding: 0,
  },
  goodPracticeText: {
    color: "#2B2A29",
    fontFamily: "ClotherBold",
    fontSize: "24px",
    fontWeight: 700,
  },
  iploadedImg: {
    maxWidth: "5rem",
    maxHeight: "5rem",
    objectFit: "contain",
  },
  imageUploaded: {
    width: "100%",
    height: "100%",
    borderRadius: "8px",
  },
  mobileButton: {
    position: "relative",
    width: "100%",
    margin: 0,
  },
  fullButton: {
    color: "#fff",
    backgroundColor: "#8735E1",
    fontFamily: "ClotherBold",
    fontSize: "20px",
  },
  button: {
    width: "10rem",
    height: "3rem",
    borderRadius: "8px",
  },
  uploadImg: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  textareaStyle: {
    borderRadius: "8px",
    minHeight: "100px",
    border: "1px solid #DCDCDC",
    fontFamily: "ClotherRegular",
    fontSize: "16px",
    lineHeight: "18px",
    "& .MuiInputBase-input::placeholder": {
      color: "#DCDCDC",
      fontWeight: 400,
      fontSize: "1rem",
    },
  },
  mic: {
    cursor: "pointer",
  },
  input: {
    borderRadius: "0.5rem",
    border: "1px solid #DCDCDC",
    fontFamily: "ClotherRegular",
    backgroundColor: "white",
  },
  inputStd: {
    height: "3.625rem",
  },
  inputDisabled: {
    background: "#F3F3F3",
  },
  label: {
    color: "var(--Black, #18181E)",
    fontSize: "1rem",
    fontFamily: "ClotherRegular",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    height: "100%",
  },
  mobileModal: {
    width: "85vw",
    minHeight: "115vh",
    left: 0,
    top: 0,
    position: "absolute",
  },
  modalMaindivStyle: {
    background: "#fff",
    fontFamily: "ClotherRegular",
    width: "42rem",
    borderRadius: "8px",
    padding: "30px",
    position: "relative",
  },
  fontBlock: {
    fontSize: "0.875rem",
    color: "#A1A1A1",
    fontFamily: "ClotherRegular",
  },
  closeBtn: {
    position: "absolute",
    right: "0.5rem",
    top: "1rem",
  },
};

const Wrapper = withStyles({
  root: {
    "& *::placeholder": {
      color: "#DCDCDC",
    },
    "& .textarea": {
      resize: "none",
    },
  },
})(Box);

const ComboBox = styled(Autocomplete)({
  "& input::placeholder": {
    color: "grey",
  },
});

const StyledPaper = withStyles({
  root: {
    backgroundColor: "#f0f0f0",
  },
})(Paper);

const CustomChip = styled(Chip)({
  backgroundColor: "#8735E1",
  color: "#FFFFFF",
  fontWeight: 400,
  fontSize: "1rem",
  borderRadius: "8px",
  fontFamily: "ClotherRegular",
  padding: "14px 10px",
});
// Customizable Area End
