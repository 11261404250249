import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { ICause } from "./RootCauseInvestigationController.web";
// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
interface ValidResponseType {
  message: string;
  data: Category[];
  meta: {
    message: string;
  };
}
interface RootCause {
  id: number;
  description: string;
  category: string;
  order: number;
  post_id: number;
  created_at?: string;
  updated_at?: string;
}
interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
  token?: string;
  type?: string;
};

export const configJSON = require("./config");

export interface IAnotherCause {
    describe_cause: string;
    checked_cause: boolean;
    result: string;
}

export interface IWhyAnalysis {
    valid_causes_attributes: ICause[];
}
  
  export interface IRootCause {
    id: string;
    category_id: string;
    cause_type: string;
    cause_description: string;
    another_causes_attributes: IAnotherCause[];
    whyAnalysis: IWhyAnalysis[];
    valid_causes: string[];
    document: File | null;
}
interface Category {
  category: string;
  "root_causes": [
    {
      "id": number;
      "description": string;
      "category": string;
      "order": number;
      "post_id": number;

    },
  ]
}
interface ValidResponseTypeSecond {
  data: D5Data;
}

interface D5Data {
  id: string;
  type: string;
  attributes: D5Attributes;
}

interface D5Attributes {
  id: number;
  category_name: string | null;
  cause_type: string;
  select_causes: string[];
  cause_description: string;
  post_id: number;
  d5_completed: boolean | null;
  another_causes: AnotherCause[];
  valid_causes: any;
  root_cause_document: RootCauseDocument;
}

interface AnotherCause {
  id: number;
  describe_cause: string;
  checked_cause: string;
  result: string;
  post_id: number | null;
  root_cause_id: number;
  created_at: string;
  updated_at: string;
}

interface RootCauseDocument {
  file_name: string;
  content_type: string;
  file_url: string;
}



  interface CategoryData {
    data: Category[];
  }
  interface Cause {
    name: string;
    isActive: boolean;
  }
  interface ValidCause {
    description: string;
}

interface WhyAnalysis {
    valid_causes_attributes: ValidCause[];
}

interface FormValues {
    whyAnalysis: WhyAnalysis[];
}

// Customizable Area End


export interface Props {
    navigation: any;
    // Customizable Area Start
    handlePrev:  () => void;
    handleNext:  () => void;
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    step: 1 | 2;
    isSave:boolean,
  isLoader: boolean;
  categoryData: CategoryData;
  rootCauses: RootCause[]; 
  category:string;
  d5FormData: D5Data;
  selectedCauses: Cause[];
  isCheckValue:string |undefined;
    // Customizable Area End
}
interface SS { }

export default class RootCauseValidationController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
  getD4APICallId: string = "";
  getD5APICallId: string = "";
  postD5ApicallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
        ];
        this.state = {
          isSave:false,
            step: 1,
          isLoader: false,
        categoryData: {
          data: []
        },
        rootCauses: [],
        category:"", d5FormData: {
          id: "",
          type: "",
          attributes: {
            id: 0,
            category_name: null,
            cause_type: "",
            select_causes: [],
            cause_description: "",
            post_id: 0,
            d5_completed: null,
            another_causes: [],
            valid_causes: [],
            root_cause_document: {
              file_name: "",
              content_type: "",
              file_url: ""
            }
          }
        },
        selectedCauses: [
          { name: "No Training", 
            isActive: true },
          { name: "Irregularity in Machine Inspection", 
            isActive: false },
          { name: "Lack of PPE availability", 
            isActive: true },
          { name: "Outdated Machinery", 
            isActive: false },
          { name: "Inadequate SOPs", 
            isActive: false },
          { name: "Poorly written SOPs", 
            isActive: false }
        ],
        isCheckValue:""
         
      };

        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start
        this.getD5Data();
        this.getD4Data();
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
          let responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
          let apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
          );
           if (this.isValidResponse(responseJson)) {
  
            this.apiSucessCall(apiRequestCallId, responseJson);
          }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    
  isValidResponse = (responseJson: ValidResponseType) => {
    return (responseJson && responseJson.data)
      || responseJson.message
  };

  apiSucessCall = async (apiRequestCallId: string, responseJson: ValidResponseType & ValidResponseTypeSecond) => {
    if (apiRequestCallId === this.getD5APICallId) {
      this.getD5SucessCallBack(responseJson);
    }
     if (apiRequestCallId === this.getD4APICallId) {

      this.getD4SucessCallBack(responseJson);
    }
    if (apiRequestCallId === this.postD5ApicallId) {
      this.postD5SucessCallBack(responseJson);
    }
    
  };
  postD5SucessCallBack = (responseJson: ValidResponseType) => {
    if (this.state.isCheckValue === "next") {
      this.handleNext();
      this.getD5Data();
    } else if (this.state.isCheckValue === "remove") {
      this.getD5Data();
    }
    else if (this.state.isCheckValue === "save") {
      this.getD5Data();
    }
    this.setState({ isLoader: false, isCheckValue:"" });

};


  getD4SucessCallBack = (responseJson: ValidResponseType) => {
     this.setState({
      isLoader: false,
      categoryData:responseJson,
    },()=>{
      this.getRootCause()
    })
  };
  getRootCause = () => {
    const data = this.state.categoryData?.data;
    const rootCauses = Array.isArray(data)
        ? data.find((item: { category: string }) => item.category.trim() === this.state.category)?.root_causes || []
        : [];

    this.setState({ rootCauses });
};


  getD5SucessCallBack = (responseJson: ValidResponseTypeSecond) => {
    const causesFromApi = responseJson.data.attributes.select_causes || [];

    const allCauses = [
      { name: "No Training", isActive: false },
      { name: "Irregularity in Machine Inspection", isActive: false },
      { name: "Lack of PPE availability", isActive: false },
      { name: "Outdated Machinery", isActive: false },
      { name: "Inadequate SOPs", isActive: false },
      { name: "Poorly written SOPs", isActive: false }
    ];

    const updatedCauses = allCauses.map(cause => ({
      ...cause,
      isActive: causesFromApi.includes(cause.name)
    }));

    this.setState({
      d5FormData: responseJson.data,
      selectedCauses: updatedCauses,
      isLoader: false,
      category: responseJson.data.attributes.category_name as string

    },
      () => {
        this.getRootCause()
      });
  };


  apiCall = async (apiData: APIPayloadType) => {
    const { contentType, method, endPoint, body, type } = apiData;
    let token = await getStorageData("authToken")
    const header = {
      "Content-Type": contentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    )
    body && type !== "formData"
      ? requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      )
      : requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  getD5Data = async () => {
    let createPostId = await getStorageData("createPostId");

    this.setState({ isLoader: true })
    this.getD5APICallId = await this.apiCall({
      contentType: configJSON.notificationsContentType,
      method: configJSON.notificationsApiMethodType,
      endPoint: configJSON.d5ApiEndpoint + createPostId
    });
  };

  getD4Data = async () => {
    let createPostId = await getStorageData("createPostId");

    this.setState({ isLoader: true })
    this.getD4APICallId = await this.apiCall({
      contentType: configJSON.notificationsContentType,
      method: configJSON.notificationsApiMethodType,
      endPoint:  `${configJSON.getRootCausesAPIEndPoint}?id=${createPostId}`
    });
  };
      
  postD5Form = async (values: any, valueCheck?:string) => {
     this.setState({ isLoader: true, isCheckValue: valueCheck });
    const createPostId = await getStorageData("createPostId");
     const formdata = new FormData();
    formdata.append("id", createPostId);
    formdata.append("root_cause[category_name]", values.category);
    formdata.append("root_cause[cause_type]", values.cause);

    values.selectedCauses
      .filter((cause: { isActive: boolean }) => cause.isActive)
      .forEach((cause: { name: string }) => {
        formdata.append("root_cause[select_causes][]", cause.name);
      });
    formdata.append("root_cause[cause_description]", values.causeText);
    values.causesList.forEach((cause: any, index: number) => {
      if (cause.id) {

        formdata.append(`root_cause[another_causes_attributes][${index}][id]`, cause.id);

        formdata.append(`root_cause[another_causes_attributes][${index}][describe_cause]`, cause.description);
        formdata.append(`root_cause[another_causes_attributes][${index}][checked_cause]`, cause.howChecked);
        formdata.append(`root_cause[another_causes_attributes][${index}][result]`, cause.result);
      }
      else {
        formdata.append(`root_cause[another_causes_attributes][${index}][describe_cause]`, cause.description);
        formdata.append(`root_cause[another_causes_attributes][${index}][checked_cause]`, cause.howChecked);
        formdata.append(`root_cause[another_causes_attributes][${index}][result]`, cause.result);
      }
    });

    if (!values.imageUrl) {
      formdata.append("document", values.image, values.image.name)
    } 
    this.postD5ApicallId = await this.apiCall({
      method: configJSON.patchApiMethodType,
      endPoint: `${configJSON.d5PostApiEndpoint}${createPostId}`,
      type: "formData",
      body: formdata
    });
  };

  removeD5First = async (values: any,valueCheck?:string) => {
    this.setState({ isLoader: true,isCheckValue:valueCheck });
    const createPostId = await getStorageData("createPostId");
    const formdata = new FormData();
    formdata.append("root_cause[delete_another_cause_ids][]", values);
    this.postD5ApicallId = await this.apiCall({
      method: configJSON.patchApiMethodType,
      endPoint: `${configJSON.d5PostApiEndpoint}${createPostId}`,
      type: "formData",
      body: formdata
    });
  };

  postD5PartTwoForm = async (values: any,valueCheck?:string) => {
    this.setState({ isLoader: true,isCheckValue:valueCheck });

    const createPostId = await getStorageData("createPostId");
    const formdata = new FormData();
    formdata.append("id", createPostId);

    values.whyAnalysis.forEach((whyGroup: any, groupIndex: number) => {
        whyGroup.valid_causes_attributes.forEach((cause: any, causeIndex: number) => {            
            formdata.append(
                `root_cause[valid_causes_attributes][${groupIndex}][description${causeIndex + 1}]`,
                cause.description
            );
            if (cause.id) {
                formdata.append(
                    `root_cause[valid_causes_attributes][${groupIndex}][id]`,
                    cause.id
                );
            }
        });
    });

    this.postD5ApicallId = await this.apiCall({
        method: configJSON.patchApiMethodType,
        endPoint: `${configJSON.d5PostApiEndpoint}${createPostId}`,
        type: "formData",
        body: formdata
    });
};


  removeD5PartTwoForm = async (values: any , valueCheck?:string) => {
    this.setState({ isLoader: true,isCheckValue:valueCheck });
      const createPostId = await getStorageData("createPostId");
    const formdata = new FormData();
    formdata.append("root_cause[delete_valid_cause_ids][]", values);
    this.postD5ApicallId = await this.apiCall({
      method: configJSON.patchApiMethodType,
      endPoint: `${configJSON.d5PostApiEndpoint}${createPostId}`,
      type: "formData",
      body: formdata
    });
  };

  handleNext = () => {
    if (this.state.step === 2) {
      this.props.handleNext()
    }
    if (!this.state.isSave) {
      this.setState({ step: 2 });
    }
  };

    handleBack = () => {
        this.setState({ step: 1 });
    };

  
    // Customizable Area End
}

